import { create } from "zustand";
interface MenuStripState {
  ShowStamp?: boolean;
  AddBlankPage?: boolean;
  UploadFrontPage?: boolean;
  CreateFrontPage?: boolean;
  ShowPageNumbering?: boolean;
}
interface MenuStripStore {
  MenuStripState: MenuStripState;
  SetMenuStripStore: (MenuStripState: MenuStripState) => void;
}
const useMenuStripStore = create<MenuStripStore>()((set) => ({
  MenuStripState: {
    ShowStamp: false,
    AddBlankPage: false,
    ShowPageNumbering: false,
  },
  SetMenuStripStore: (tagState) =>
    set((state) => {
      return {
        ...state,
        MenuStripState: { ...state.MenuStripState, ...tagState },
      };
    }),
}));
export default useMenuStripStore;

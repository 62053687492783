import { useState } from "react";
import * as Api from "../../../../Api/src/index";
import { v4 } from "uuid";
import useTagStore from "../../../../ZustandStore/TagStore";
import { FormikErrors, useFormik } from "formik";
import * as yup from "yup";
import { useLang } from "../../../../i18n/useLang";
import UseConfirmDialogStore from "../../../../ZustandStore/ConfirmDialogStore";
import { AddTagCategoryParam } from "../../../../Api/src/index";
const TagApi = new Api.TagApi();
export interface MDTagCategoryProps {
  open?: boolean;
  onClose?: (tagCategory: Api.TagCategory | null) => void;
}
export const MDTagCategoryService = ({ onClose }: MDTagCategoryProps) => {
  const { TagState, SetTagStore } = useTagStore();
  const { SetConfirmDialogState } = UseConfirmDialogStore();
  const { tr } = useLang();
  const validationTagCategorySchema = yup.object({
    key: yup.string().required(tr("pleaseSetName")),
  });
  const showErrors = (error: FormikErrors<AddTagCategoryParam>) => {
    if (error.key) {
      return <p>{error.key}</p>;
    }
  };
  const formik = useFormik({
    initialValues: {
      key: "",
      color: "red",
      isDefault: false,
      id: v4(),
    } as Api.AddTagCategoryParam,
    validationSchema: validationTagCategorySchema,
    onSubmit: async (values) => {
      TagApi.apiLegalxtractAddTagCategoryPost(values).then((res) => {
        if (res && res.data.isSuccess) {
          SetTagStore({
            Tags: { ...TagState.Tags, tagCategories: res.data.result },
          });
          onClose &&
            onClose(
              res.data.result?.find((o) => o.id === values?.id || "") || null
            );
        } else {
          SetConfirmDialogState({
            Show: true,
            Type: "Alert",
            CancelText: tr("no"),
            Msg: `${tr("tagName")} ${values.key} ${tr("alreadyExists")}`,
          });
        }
      });
    },
  });
  return { formik, showErrors };
};

import * as Api from "../../../../Api/src/index";
import { FormikErrors, useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { AppCache } from "../../../../Cache/AppCache";
import { PageService } from "../../../NotHook/PageService";
const StampApi = new Api.StampApi();
export interface MDStampIndexServiceProps {
  open?: boolean;
  onClose?: () => void;
  editItem: Api.TabStamp | null;
}
export const MDStampIndexService = ({
  onClose,
  editItem,
}: MDStampIndexServiceProps) => {
  const { SetDataCurrentPage } = PageService();
  const InitCheck = useRef<boolean>(false);
  useEffect(() => {
    if (InitCheck.current) return;
    SetInitDataForm();
    InitCheck.current = true;
  }, []);
  const SetInitDataForm = (): Api.TabStamp => {
    if (editItem) return editItem;
    return { index: "" };
  };

  const formik = useFormik({
    initialValues: SetInitDataForm(),
    onSubmit: async (values) => {
      StampApi.apiLegalxtractChangeCustomStampIndexPost(
        AppCache.CurrentTabId || "",
        values.index || ""
      ).then((res) => {
        if (res && res.data.isSuccess) {
          AppCache.DocumentInfo = res.data.result?.data || null;
          SetDataCurrentPage(
            res.data.result?.pageData ! || null,
            AppCache.CurrentPageNo || 0
          );
          onClose && onClose();
        }
      });
    },
  });
  const ResetForm = () => {
    formik.setValues({ index: "" });
  };
  return {
    formik,
    ResetForm,
  };
};

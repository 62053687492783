import { Space, TableProps } from "antd";
import * as Api from "../../../Api/src";
import { useLang } from "../../../i18n/useLang";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import useReplaceStore from "../../../ZustandStore/ReplaceStore";
import UseModalStore, { ModaType } from "../../../ZustandStore/ModalStore";
import UseConfirmDialogStore from "../../../ZustandStore/ConfirmDialogStore";
import { AppCache } from "../../../Cache/AppCache";
import { DeleteReplaceCommand } from "../../../UndoRedo/Commands/DeleteReplaceCommand";
const ReplaceApi = new Api.ReplaceApi();
export const ReplacePanelService = () => {
  const { tr } = useLang();
  const { SetModalState } = UseModalStore();
  const { ReplaceState, SetReplaceStore, GetReplaceState } = useReplaceStore();
  const { SetConfirmDialogState } = UseConfirmDialogStore();
  const deleteReplaceCommand = DeleteReplaceCommand();
  const columns: TableProps<Api.ReplaceObjDisplay>["columns"] = [
    {
      title: tr("wordsReplaceBy"),
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <div>
          <div style={{ whiteSpace: "break-spaces" }}>
            {record.originalWords}
          </div>
          --------------------------
          <div style={{ whiteSpace: "break-spaces" }}>{record.replaceBy}</div>
        </div>
      ),
    },
    {
      width: 50,
      title: tr("color"),
      key: "fontColor",
      dataIndex: "fontColor",
      render: (_, record) => (
        <div
          style={{
            color: record.fontColor,
            backgroundColor: record.extractColor,
            fontSize: record.fontSize,
            fontFamily: record.fontName || "Arial",
            width: 28,
            height: 28,
            textAlign: "center",
            alignContent: "center",
          }}
        >
          A
        </div>
      ),
    },
    {
      width: 85,
      title: tr("count"),
      key: "action",
      render: (_, record) => (
        <>
          {record.replaceCount} <br />/{record.totalCount}
        </>
      ),
    },
    {
      width: 50,
      key: "action",
      render: (_, record, i) => {
        return (
          <Space>
            <a
              onClick={() => {
                EditReplaceClick(record);
              }}
            >
              <EditOutlined />
            </a>
            <a
              onClick={() => {
                DeleteReplace(record.id || "");
              }}
            >
              <DeleteOutlined />
            </a>
          </Space>
        );
      },
    },
  ];
  const AddReplaceWordClick = () => {
    SetReplaceStore({
      ParamReplaceWord: {
        ...GetReplaceState().ParamReplaceWord,
        ReplaceType: "ReplaceWord",
        disableReplaceOne: true,
      },
    });
    SetModalState({
      Show: true,
      Status: "Add",
      Type: ModaType.MDReplaceWord,
    });
  };
  const EditReplaceClick = (item: Api.ReplaceObjDisplay) => {
    SetReplaceStore({
      ParamReplaceWord: {
        ...GetReplaceState().ParamReplaceWord,
        ReplaceType: "ReplaceWord",
        extractColor: item.extractColor,
        fontColor: item.fontColor,
        fontSize: item.fontSize,
        fontName: item.fontName,
        matchCase: item.matchCase,
        viewerPageNo: AppCache.CurrentPageNo,
        originalWords: item.originalWords,
        replaceBy: item.replaceBy,
        wholeWord: item.wholeWord,
        id: item.id,
        disableReplaceOne: true,
      },
    });
    SetModalState({
      Show: true,
      Status: "Edit",
      Type: ModaType.MDReplaceWord,
    });
  };

  const LoadReplaceObjDisplays = async () => {
    const data = await ReplaceApi.apiLegalxtractCalculateReplaceGet();
    SetReplaceStore({ ReplaceObjDisplays: data.data });
    return data;
  };
  const DeleteReplace = (id: string) => {
    const Delete = async () => {
      await deleteReplaceCommand.Do({
        pageNo: AppCache.CurrentPageNo,
        replaceId: id,
      });
    };
    SetConfirmDialogState({
      Show: true,
      Type: "Confirm",
      Icon:"Question",
      CancelText: tr("no"),
      YesText: tr("yes"),
      Msg: tr("doYouWantToDelete"),
      OnYes: () => {
        Delete();
      },
    });
  };
  return {
    ReplaceState,
    columns,
    LoadReplaceObjDisplays,
    AddReplaceWordClick,
    DeleteReplace,
  };
};

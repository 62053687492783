import { Button, Col, Row, Space, Table, TableProps, Tag } from "antd";
import { useLang } from "../../../i18n/useLang";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { ReplacePanelService } from "../../../Services/Hook/LeftPanel/ReplacePanelService";
interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}
export const ReplacePanel = () => {
  const { tr } = useLang();
  const { columns, ReplaceState, AddReplaceWordClick } = ReplacePanelService();
  return (
    <div className="left-panel-container">
      <Row gutter={[8, 8]}>
        <Col span={24} className="header-panel">
          {tr("replace")}
        </Col>
        <Col span={24}>
          <Button onClick={AddReplaceWordClick} style={{ minWidth: 75 }}>
            {tr("addReplaceWords")}
          </Button>
        </Col>
        <Col span={24}>
          <Table
            size="small"
            columns={columns}
            rowKey={"id"}
            dataSource={ReplaceState.ReplaceObjDisplays}
            pagination={false}
          />
        </Col>
      </Row>
    </div>
  );
};

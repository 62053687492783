import { useEffect, useState } from "react";
import * as Api from "../../../Api/src/index";
import { AppCache } from "../../../Cache/AppCache";
import { AnnoSubject, AppLocalStorage } from "../../../Constant/AppContant";
import { StampSettingsParam } from "../Modal/Stamp/MDStamSettingService";
import { PageService } from "../../NotHook/PageService";
import useMenuIconStore from "../../../ZustandStore/MenuIconStore";
import { ToolService } from "../../NotHook/ToolService";
import { ToolMode } from "../../../Constant/AppEnum";
import { HightLightPageCommand } from "../../../UndoRedo/Commands/HightLightPageCommand";
import { HightLightWordCommand } from "../../../UndoRedo/Commands/HightLightCommand";
import {
  getCenterPointOfRect,
  isPointInRectangle,
} from "../../../Common/CommonFunction";
import { HightLightLineCommand } from "../../../UndoRedo/Commands/HightLightLineCommand";
import { DeleteReplaceCommand } from "../../../UndoRedo/Commands/DeleteReplaceCommand";
import UseModalStore, { ModaType } from "../../../ZustandStore/ModalStore";
import { useLang } from "../../../i18n/useLang";
import UseConfirmDialogStore from "../../../ZustandStore/ConfirmDialogStore";
import useReplaceStore from "../../../ZustandStore/ReplaceStore";
import { DeleteReplaceOneWordCommand } from "../../../UndoRedo/Commands/DeleteReplaceOneWordCommand";
import { NoteService } from "../../NotHook/NoteService";
import useSearchTabStore from "../../../ZustandStore/SearchTabStore";
import useLeftPanelStore from "../../../ZustandStore/LeftPanelStore";
import useTagStore from "../../../ZustandStore/TagStore";
import { useContextMenu } from "react-contexify";
import { MENU_ID } from "../../../Components/MainPanel/MenuContext";
import { RemoveReplaceBoxCommand } from "../../../UndoRedo/Commands/RemoveReplaceBoxCommand";
import { DeleteLineCommand } from "../../../UndoRedo/Commands/Line/DeleteLineCommand";
import { EditLineCommand } from "../../../UndoRedo/Commands/Line/EditLineCommand";
import { BoxCommand } from "../../../UndoRedo/Commands/Box/BoxCommand";
import { HightLightMaskCommand } from "../../../UndoRedo/Commands/HightLightMaskCommand";
import { Core } from "@pdftron/webviewer";
import { PdfTronService } from "../../NotHook/PdfTronService";
const StampApi = new Api.StampApi();
const ReplaceApi = new Api.ReplaceApi();
export interface MenuContextProps {
  ShowMenuContext: boolean;
  onVisibilityChange?: (visibility: boolean) => void;
}
interface MenuState {
  mniComment: boolean;
  mniBox: boolean;
  mniSection: boolean;
  mniAddNote: boolean;
  mniEditNote: boolean;
  mniDeleteNote: boolean;
  mniDeleteBox: boolean;
  mniDeleteReplaceBox: boolean;
  mniDeleteReplace: boolean;
  mniUnSelect: boolean;
  mniSelect: boolean;
  mniSelectLine: boolean;
  mniSelectPage: boolean;
  mniFind: boolean;
  mniReplaceBox: boolean;
  mniReplace: boolean;
  mniAddStampIndex: boolean;
  mniRemoveStamp: boolean;
  mniAddStamp: boolean;
  mniEditStamp: boolean;
  mniAddFrontPageContent: boolean;
  mniRemoveFrontPageContent: boolean;
  mniSelectedBy: boolean;
  mniFullName: boolean;
  mniDate: boolean;
  mniEditReplace: boolean;
  mniEditReplaceBox: boolean;
  mniLine1px: boolean;
  mniLine2px: boolean;
  mniLine4px: boolean;
  mniLine8px: boolean;
  mniLineChangeColor: boolean;
  mniDeskew: boolean;
  mniDeleteLine: boolean;
  mniTag: boolean;
  mniUnSelectMask: boolean;
  mniSelectMask: boolean;
  mniReplaceMask: boolean;
  mniAddBookMark: boolean;
  mniTocSettings: boolean;
  mniPageBreakToc: boolean;
  mniRemovePageBreakToc: boolean;
  mniUnselectPage: boolean;
}
export const MenuContextService = ({
  ShowMenuContext,
  onVisibilityChange,
}: MenuContextProps) => {
  const { tr } = useLang();
  const { SetDataCurrentPage } = PageService();
  const { TagState } = useTagStore();
  const GetMenuContext = () => {
    const StampInfo = AppLocalStorage.GetItem(AppLocalStorage.StampInfo);
    let stampAutomaticCount = false;
    if (StampInfo) {
      stampAutomaticCount = StampInfo.stampAutomaticCount || false;
    }
    const menu = {
      mniComment: false,
      mniBox: false,
      mniSection: false,
      mniAddNote: false,
      mniEditNote: false,
      mniDeleteNote: false,
      mniDeleteBox: false,
      mniDeleteReplaceBox: false,
      mniDeleteReplace: false,
      mniUnSelect: false,
      mniSelect: false,
      mniSelectLine: false,
      mniSelectPage: false,
      mniFind: false,
      mniReplaceBox: false,
      mniReplace: false,
      mniAddStampIndex: false,
      mniRemoveStamp: false,
      mniAddStamp: false,
      mniEditStamp: false,
      mniAddFrontPageContent: false,
      mniRemoveFrontPageContent: false,
      mniSelectedBy: false,
      mniFullName: false,
      mniDate: false,
      mniEditReplace: false,
      mniEditReplaceBox: false,
      mniLine1px: false,
      mniLine2px: false,
      mniLine4px: false,
      mniLine8px: false,
      mniLineChangeColor: false,
      mniDeskew: false,
      mniDeleteLine: false,
      mniTag: false,
      mniUnSelectMask: false,
      mniSelectMask: false,
      mniReplaceMask: false,
      mniAddBookMark: false,
      mniTocSettings: false,
      mniPageBreakToc: false,
      mniRemovePageBreakToc: false,
      mniUnselectPage: false,
    };
    if (!AppCache.WebViewerInstance?.Core) return menu;
    const { documentViewer } = AppCache.WebViewerInstance?.Core;
    var selectedTextQuads = documentViewer.getSelectedTextQuads();
    if (
      selectedTextQuads[AppCache.CurrentPageNo]?.length > 0 &&
      AppCache.ToolMode === ToolMode.Mask
    ) {
      menu.mniSelectMask = true;
      menu.mniUnSelectMask = true;
      menu.mniReplaceMask = true;
      return menu;
    }

    const tabFileType = AppCache.CurrentTab()?.tabFileType;
    const page = AppCache.CurrentPageObject();
    let annoSubject = AppCache.AnnotationSelected?.Subject;
    if (
      (annoSubject == AnnoSubject.focushighlighttemp ||
        annoSubject == AnnoSubject.highlight ||
        annoSubject == AnnoSubject.highlighttemp) &&
      AppCache.WordSelected !== null
    ) {
      menu.mniBox = true;
      menu.mniSection = true;
      menu.mniAddNote = true;
      menu.mniAddBookMark = true;
      menu.mniSelect = true;
      menu.mniSelectLine = true;
      if (AppCache.DataCurrentPage?.isHightLightAllPage) {
        menu.mniUnselectPage = true;
      } else {
        menu.mniSelectPage = true;
      }
      if (annoSubject == AnnoSubject.highlight) {
        menu.mniComment = true;
        menu.mniSelect = false;
        menu.mniUnSelect = true;
      } else {
        menu.mniUnSelect = false;
      }
      menu.mniFind = true;
      menu.mniReplace = true;
      menu.mniSelectedBy = true;
    }
    switch (annoSubject) {
      case AnnoSubject.stamp:
        menu.mniRemoveStamp = true;
        if (!stampAutomaticCount) {
          menu.mniAddStampIndex = true;
        }
        menu.mniEditStamp = true;
        menu.mniSelectedBy = true;
        menu.mniFullName = true;
        menu.mniDate = true;
        break;
      case AnnoSubject.note:
        menu.mniEditNote = true;
        menu.mniDeleteNote = true;
        menu.mniFullName = true;
        menu.mniDate = true;
        break;
      case AnnoSubject.tag:
        menu.mniBox = true;
        menu.mniSection = true;
        menu.mniAddNote = true;
        menu.mniAddBookMark = true;
        if (annoSubject == AnnoSubject.tag) {
          menu.mniTag = true;
        } else {
          menu.mniTag = false;
        }
        menu.mniSelectLine = true;
        if (AppCache.DataCurrentPage?.isHightLightAllPage) {
          menu.mniUnselectPage = true;
        } else {
          menu.mniSelectPage = true;
        }
        menu.mniFind = true;
        menu.mniReplace = true;
        menu.mniSelectedBy = true;
        menu.mniFullName = true;
        menu.mniDate = true;
        break;
      case AnnoSubject.box:
        menu.mniComment = true;
        menu.mniBox = true;
        menu.mniAddNote = true;
        menu.mniAddBookMark = true;
        menu.mniDeleteBox = true;
        menu.mniSelectPage = true;
        menu.mniReplaceBox = true;
        menu.mniSelectedBy = true;
        menu.mniFullName = true;
        menu.mniDate = true;
        break;
      case AnnoSubject.replacebox:
        menu.mniComment = true;
        menu.mniDeleteReplaceBox = true;
        menu.mniEditReplaceBox = true;
        menu.mniSelectedBy = true;
        menu.mniFullName = true;
        menu.mniDate = true;
        break;
      case AnnoSubject.replace:
        menu.mniComment = true;
        menu.mniDeleteReplace = true;
        menu.mniEditReplace = true;
        menu.mniSelectedBy = true;
        menu.mniFullName = true;
        menu.mniDate = true;
        break;
      case AnnoSubject.line:
        (menu.mniLine1px = true),
          (menu.mniLine2px = true),
          (menu.mniLine4px = true),
          (menu.mniLine8px = true),
          (menu.mniLineChangeColor = true),
          (menu.mniDeleteLine = true);
        break;
      default:
        if (tabFileType?.toString() === "0") {
          menu.mniBox = true;
          menu.mniSection = true;
          if (AppCache.DataCurrentPage?.isHightLightAllPage) {
            menu.mniUnselectPage = true;
          } else {
            menu.mniSelectPage = true;
          }
        } else if (
          AppCache.CurrentPageObject()?.isBlankPage ||
          (tabFileType || Api.TabFileType.Normal) !== Api.TabFileType.Normal
        ) {
          menu.mniBox = false;
          menu.mniSection = false;
          menu.mniSelectPage = false;
        }
        menu.mniAddNote = true;
        menu.mniAddBookMark = true;
        // if (tabFileType == 3) {
        //   menu.mniTocSettings = true;
        //   const tab = docuviewareMain.DocuviewareInit?.tabDetails?.find(
        //     (o) => o.uniqueId == (CacheApp.AnnotState?.ClientTag || "")
        //   );
        //   if (
        //     (CacheApp.AnnotState?.ClientTag || "").indexOf("_") < 0 &&
        //     (CacheApp.AnnotState?.ClientTag || "").indexOf("Title") < 0
        //   ) {
        //     if (tab) {
        //       if (tab.isTocPageBreak) {
        //         menu.mniRemovePageBreakToc = true;
        //       } else {
        //         menu.mniPageBreakToc = true;
        //       }
        //     }
        //   }
        // }
        if (
          AppCache.DocumentInfo?.showStamp &&
          !AppCache.CurrentTab()?.includeStamp
        ) {
          menu.mniAddStamp = true;
        }
        if ((page?.pageImageCount || 0) > 0) {
          menu.mniDeskew = true;
        }
        break;
    }
    return menu;
  };
  const [MenuState, SetMenuState] = useState<MenuState | null>(null);
  const { BoxTool, SectionTool } = ToolService();
  const { SetModalState } = UseModalStore();
  const { SearchTabState, SetSearchTabStore } = useSearchTabStore();
  const { SetLeftPanelStore } = useLeftPanelStore();
  const { ReplaceState, SetReplaceStore, GetReplaceState } = useReplaceStore();
  const { SetMenuIconStore } = useMenuIconStore();
  const { SetConfirmDialogState } = UseConfirmDialogStore();
  const [ShowStampSetting, SetShowStampSetting] = useState<{
    open: boolean;
    editItem: StampSettingsParam | null;
  }>({ open: false, editItem: null });
  const [ShowStampIndex, SetShowStampIndex] = useState<{
    open: boolean;
    editItem: Api.TabStamp | null;
  }>({ open: false, editItem: null });
  const [ShowChangeLineColor, SetShowChangeLineColor] = useState<{
    open: boolean;
    line: Api.LineAnnotation | null;
  }>({ open: false, line: null });
  const [ShowAddBookMark, SetShowAddBookMark] = useState<{
    open: boolean;
  }>({ open: false });
  const [ShowAddNote, SetShowAddNote] = useState<{
    open: boolean;
    editItem: Api.NoteTextObj | null;
  }>({ open: false, editItem: null });
  const [ShowAddComment, SetShowAddComment] = useState<{
    open: boolean;
  }>({ open: false });
  const hightLightMaskCommand = HightLightMaskCommand();
  const hightLightPageCommand = HightLightPageCommand();
  const hightLightWordCommand = HightLightWordCommand();
  const hightLightLineCommand = HightLightLineCommand();
  const deleteLineCommand = DeleteLineCommand();
  const editLineCommand = EditLineCommand();
  const deleteReplaceOneWordCommand = DeleteReplaceOneWordCommand();
  const removeReplaceBoxCommand = RemoveReplaceBoxCommand();
  const boxCommand = BoxCommand();
  const pdfTronService = PdfTronService();
  useEffect(() => {
    if (ShowMenuContext) SetMenuState(GetMenuContext());
  }, [ShowMenuContext]);
  const CloseMDStampIndex = () => {
    SetShowStampIndex({ open: false, editItem: null });
  };
  const CloseMDAddBookMark = () => {
    SetShowAddBookMark({ open: false });
  };
  const OpenMDStampIndex = () => {
    SetShowStampIndex({
      open: true,
      editItem: AppCache.CurrentTab()?.tabStamp as StampSettingsParam,
    });
  };
  const CloseMDStamp = () => {
    SetShowStampSetting({ open: false, editItem: null });
  };
  const OpenMDStamp = () => {
    SetShowStampSetting({
      open: true,
      editItem: AppCache.CurrentTab()?.tabStamp as StampSettingsParam,
    });
  };
  const RemoveStamp = () => {
    StampApi.apiLegalxtractDeleteStampPost(AppCache.CurrentTabId || "").then(
      (res) => {
        if (res && res.data.isSuccess) {
          AppCache.DocumentInfo = res.data.result?.data || null;
          SetDataCurrentPage(
            res.data.result?.pageData! || null,
            AppCache.CurrentPageNo || 0
          );
        }
      }
    );
  };
  const AddStamp = () => {
    StampApi.apiLegalxtractAddStampPut(AppCache.CurrentTabId || "").then(
      (res) => {
        if (res && res.data.isSuccess) {
          AppCache.DocumentInfo = res.data.result?.data || null;
          SetDataCurrentPage(
            res.data.result?.pageData! || null,
            AppCache.CurrentPageNo || 0
          );
        }
      }
    );
  };

  const BoxClick = () => {
    SetMenuIconStore({ ToolMode: ToolMode.Box });
    BoxTool();
  };
  const SectionClick = () => {
    SetMenuIconStore({ ToolMode: ToolMode.Section });
    SectionTool();
  };
  const HightLightAllPage = () => {
    hightLightPageCommand.Do(
      AppCache.CurrentPageNo || 0,
      Api.HighLightMode.Hightlight
    );
  };
  const UnHightLightAllPage = () => {
    hightLightPageCommand.Do(
      AppCache.CurrentPageNo || 0,
      Api.HighLightMode.Hightlight
    );
  };
  const HightLightWord = () => {
    const point = getCenterPointOfRect(AppCache.WordSelected?.rects![0]);
    hightLightWordCommand.Do(AppCache.CurrentPageNo || 0, {
      x: point?.x || 0,
      y: point?.y || 0,
    });
  };
  const UnHightLightWord = () => {
    const point = getCenterPointOfRect(AppCache.WordSelected?.rects![0]);
    hightLightWordCommand.Do(AppCache.CurrentPageNo || 0, {
      x: point?.x || 0,
      y: point?.y || 0,
    });
  };
  const HightLightLine = () => {
    const point = getCenterPointOfRect(AppCache.WordSelected?.rects![0]);
    hightLightLineCommand.Do(
      AppCache.CurrentPageNo || 0,
      {
        x: point?.x || 0,
        y: point?.y || 0,
      },
      Api.HighLightMode.Hightlight
    );
  };
  const AddReplaceClick = async () => {
    const res = await ReplaceApi.apiLegalxtractGetSelectedWordContinuousPost({
      viewerPageNo: AppCache.CurrentPageNo,
      x: AppCache.LastMousePositionServer.x,
      y: AppCache.LastMousePositionServer.y,
    });

    SetReplaceStore({
      ParamReplaceWord: {
        ...GetReplaceState().ParamReplaceWord,
        ReplaceType: "ReplaceWord",
        originalWords: res.data.value,
        rectangleWords: res.data.rectangleSelections,
        viewerPageNo: AppCache.CurrentPageNo,
        disableReplaceOne: false,
      },
    });
    SetModalState({
      Show: true,
      Status: "Add",
      Type: ModaType.MDReplaceWord,
    });
  };

  const EditReplaceClick = () => {
    const replaceObjId =
      AppCache.AnnotationSelected?.getCustomData("replaceObjId");
    const item = AppCache.ReplaceObjects.replaceObjs?.find(
      (o) => o.id == replaceObjId
    );
    var rect = AppCache.DataCurrentPage?.rectangeWithColors?.filter(
      (o) =>
        o.replaceObjId === replaceObjId &&
        isPointInRectangle(
          AppCache.LastMousePositionServer.x,
          AppCache.LastMousePositionServer.y,
          o.rect?.left!,
          o.rect?.top!,
          o.rect?.width!,
          o.rect?.height!
        )
    );
    SetReplaceStore({
      ParamReplaceWord: {
        ...GetReplaceState().ParamReplaceWord,
        ReplaceType: "ReplaceWord",
        extractColor: item?.extractColor,
        fontColor: item?.fontColor,
        fontSize: item?.fontSize,
        fontName: item?.fontName,
        matchCase: item?.matchCase,
        originalWords: item?.originalWords,
        rectangleWords:
          rect?.map(
            (o) =>
              ({
                height: o.rect?.height,
                left: o.rect?.left,
                top: o.rect?.top,
                width: o.rect?.width,
              } as Api.RectangleSelection)
          ) || [],
        viewerPageNo: AppCache.CurrentPageNo,
        replaceBy: item?.replaceBy,
        wholeWord: item?.wholeWord,
        id: item?.id,
        disableReplaceOne: false,
      },
    });
    SetModalState({
      Show: true,
      Status: "Edit",
      Type: ModaType.MDReplaceWord,
    });
  };

  const DeleteReplace = async () => {
    await deleteReplaceOneWordCommand.Do({
      pageNo: AppCache.CurrentPageNo,
      x: AppCache.LastMousePositionServer.x,
      y: AppCache.LastMousePositionServer.y,
    });
  };
  const DeleteNote = async () => {
    const { DeleteNote } = NoteService();
    DeleteNote(AppCache.AnnotationSelected?.getCustomData("noteId") || "");
  };
  const EditNote = () => {
    const Id = AppCache.AnnotationSelected?.getCustomData("noteId") || "";
    const item = AppCache.DataCurrentPage?.noteTextObjs?.find(
      (o) => o.id == Id
    );
    SetShowAddNote({ open: true, editItem: item || null });
  };
  const Find = () => {
    SetSearchTabStore({
      ...SearchTabState,
      SearchTabVm: {
        ...SearchTabState.SearchTabVm,
        searchKeywordParam: {
          ...SearchTabState.SearchTabVm?.searchKeywordParam,
          keyword: AppCache.LastWordFocus?.text,
        },
        searchTabType: Api.SearchTabType.Keyword,
      },
      runSearch: true,
    });
    setTimeout(() => {
      SetLeftPanelStore({
        LeftTabActive: "pnFind",
      });
    }, 300);
  };
  const FindTag = () => {
    TagData = GetTagData(
      AppCache.AnnotationSelected?.getCustomData("tagId") || ""
    );
    SetSearchTabStore({
      ...SearchTabState,
      SearchTabVm: {
        ...SearchTabState.SearchTabVm,
        searchTagParam: {
          ...SearchTabState.SearchTabVm?.searchTagParam,
          selectedTags: [TagData.tagInfo?.tagNameId || ""],
        },
        searchTabType: Api.SearchTabType.Tag,
      },
      runSearch: true,
    });
    setTimeout(() => {
      SetLeftPanelStore({
        LeftTabActive: "pnFind",
      });
    }, 300);
  };
  const GetTagData = (tagId: string) => {
    const tagInfo = TagState?.Tags?.tagInfos?.find((o) => o.id == tagId);
    const tagName = TagState?.Tags?.tagNames?.find(
      (o) => o.id == tagInfo?.tagNameId
    );
    const tagCategory = TagState?.Tags?.tagCategories?.find(
      (o) => o.id == tagName?.tagCategoryId
    );
    return { tagInfo, tagName, tagCategory };
  };
  let TagData = GetTagData(
    AppCache.AnnotationSelected?.getCustomData("tagId") || ""
  );
  const onSelectOrUnSelectAllTag = (
    e: any,
    isSelect: "selectAll" | "deSelectAll" | "none"
  ) => {
    TagData = GetTagData(
      AppCache.AnnotationSelected?.getCustomData("tagId") || ""
    );
    SetSearchTabStore({
      ...SearchTabState,
      SearchTabVm: {
        ...SearchTabState.SearchTabVm,
        searchTagParam: {
          ...SearchTabState.SearchTabVm?.searchTagParam,
          selectedTags: [TagData.tagInfo?.tagNameId || ""],
        },
        searchTabType: Api.SearchTabType.Tag,
      },
      runSearch: true,
      runSelect: isSelect,
    });
    setTimeout(() => {
      SetLeftPanelStore({
        LeftTabActive: "pnFind",
      });
    }, 300);
  };
  const { hideAll } = useContextMenu({
    id: MENU_ID,
  });
  const ReplaceBox = () => {
    hideAll();
    SetModalState({
      Show: true,
      Status: "Add",
      Type: ModaType.MDReplaceBox,
    });
  };
  const EditReplaceBox = () => {
    hideAll();
    const replaceObjId =
      AppCache.AnnotationSelected?.getCustomData("replaceObjId");
    const item = AppCache.ReplaceObjects.replaceObjs?.find(
      (o) => o.id == replaceObjId
    );
    SetReplaceStore({
      ParamReplaceWord: {
        ...GetReplaceState().ParamReplaceWord,
        ReplaceType: "ReplaceWord",
        extractColor: item?.extractColor,
        fontColor: item?.fontColor,
        fontSize: item?.fontSize,
        fontName: item?.fontName,
        matchCase: item?.matchCase,
        viewerPageNo: AppCache.CurrentPageNo,
        replaceBy: item?.replaceBy,
        wholeWord: item?.wholeWord,
        id: item?.id,
        disableReplaceOne: false,
      },
    });
    SetModalState({
      Show: true,
      Status: "Edit",
      Type: ModaType.MDReplaceBox,
    });
  };
  const DeleteReplaceBox = async () => {
    await removeReplaceBoxCommand.Do(
      AppCache.AnnotationSelected?.getCustomData("boxId") || ""
    );
  };
  const DeleteBox = async () => {
    await boxCommand.DoDelete(
      AppCache.CurrentPageNo || 0,
      AppCache.AnnotationSelected?.getCustomData("boxId") || ""
    );
  };
  const DeleteLine = async () => {
    await deleteLineCommand.Do(
      AppCache.AnnotationSelected?.getCustomData("lineId") || "",
      AppCache.CurrentPageNo || 0
    );
  };
  const EditSizeLine = (borderWidth: number) => {
    editLineCommand.Do(AppCache.CurrentPageNo, {
      id: AppCache.AnnotationSelected?.getCustomData("lineId") || "",
      borderWidth: borderWidth,
    });
  };
  const OnShowChangeLineColor = () => {
    const line = AppCache.DataCurrentPage?.lineAnnotations?.find(
      (o) => o.id === AppCache.AnnotationSelected?.getCustomData("lineId")
    );
    SetShowChangeLineColor({ open: true, line: line || null });
  };
  const hightLightMask = (isHightLight: boolean) => {
    if (!AppCache.WebViewerInstance?.Core) return;
    const { documentViewer, Math } = AppCache.WebViewerInstance?.Core;
    var allQuads = documentViewer.getSelectedTextQuads();
    const rectangleSelections: Api.RectangleSelection[] | null = [];
    (allQuads[AppCache.CurrentPageNo] as any as Core.Math.Quad[]).forEach(
      (quad: Core.Math.Quad) => {
        var newQuad = new Math.Quad(
          quad.x1,
          quad.y1,
          quad.x2,
          quad.y2,
          quad.x3,
          quad.y3,
          quad.x4,
          quad.y4
        );
        const rect = newQuad.toRect();
        const newRect = pdfTronService.RectConvertToPDFCoordinates(
          {
            left: rect.getLeft(),
            top: rect.getTop(),
            right: rect.getRight(),
            bottom: rect.getBottom(),
          },
          AppCache.CurrentPageNo
        );
        rectangleSelections.push({
          height: window.Math.abs(newRect?.height || 0),
          left: newRect?.left,
          top: newRect?.top,
          width: newRect?.width,
        });
      }
    );
    hightLightMaskCommand.Do(
      isHightLight
        ? Api.HighLightMode.Hightlight
        : Api.HighLightMode.UnHightlight,
      AppCache.CurrentPageNo,
      rectangleSelections
    );
  };
  const AddReplaceMaskClick = async () => {
    if (!AppCache.WebViewerInstance?.Core) return;
    const { documentViewer, Math } = AppCache.WebViewerInstance?.Core;
    var allQuads = documentViewer.getSelectedTextQuads();
    const rectangleSelections: Api.RectangleSelection[] | null = [];
    (allQuads[AppCache.CurrentPageNo] as any as Core.Math.Quad[]).forEach(
      (quad: Core.Math.Quad) => {
        var newQuad = new Math.Quad(
          quad.x1,
          quad.y1,
          quad.x2,
          quad.y2,
          quad.x3,
          quad.y3,
          quad.x4,
          quad.y4
        );
        const rect = newQuad.toRect();
        const newRect = pdfTronService.RectConvertToPDFCoordinates(
          {
            left: rect.getLeft(),
            top: rect.getTop(),
            right: rect.getRight(),
            bottom: rect.getBottom(),
          },
          AppCache.CurrentPageNo
        );
        rectangleSelections.push({
          height: window.Math.abs(newRect?.height || 0),
          left: newRect?.left,
          top: newRect?.top,
          width: newRect?.width,
        });
      }
    );
    var res = await ReplaceApi.apiLegalxtractGetSelectedWordMaskPost({
      pageNo: AppCache.CurrentPageNo,
      rectangleSelections: rectangleSelections,
    });
    SetReplaceStore({
      ParamReplaceWord: {
        ...GetReplaceState().ParamReplaceWord,
        ReplaceType: "ReplaceWord",
        originalWords: res.data.value,
        rectangleWords: res.data.rectangleSelections,
        viewerPageNo: AppCache.CurrentPageNo,
        disableReplaceOne: false,
      },
    });
    SetModalState({
      Show: true,
      Status: "Add",
      Type: ModaType.MDReplaceWord,
    });
  };
  return {
    GetMenuContext,
    MenuState,
    ShowStampSetting,
    CloseMDStamp,
    OpenMDStamp,
    RemoveStamp,
    AddStamp,
    BoxClick,
    SectionClick,
    HightLightAllPage,
    UnHightLightAllPage,
    UnHightLightWord,
    HightLightWord,
    HightLightLine,
    ShowStampIndex,
    EditReplaceClick,
    AddReplaceClick,
    DeleteReplace,
    OpenMDStampIndex,
    CloseMDStampIndex,
    ShowAddBookMark,
    CloseMDAddBookMark,
    SetShowAddBookMark,
    ShowAddNote,
    SetShowAddNote,
    DeleteNote,
    EditNote,
    ShowAddComment,
    SetShowAddComment,
    FindTag,
    TagData,
    onSelectOrUnSelectAllTag,
    ReplaceBox,
    DeleteReplaceBox,
    EditReplaceBox,
    DeleteLine,
    EditSizeLine,
    ShowChangeLineColor,
    SetShowChangeLineColor,
    OnShowChangeLineColor,
    DeleteBox,
    hightLightMask,
    AddReplaceMaskClick,
    Find,
  };
};

import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  ColorPicker,
  InputNumber,
  Modal,
  Row,
  Select,
} from "antd";
import { useLang } from "../i18n/useLang";
import {
  MDPageNumberingProps,
  MDPageNumberingService,
} from "../Services/Hook/Modal/MDPageNumberingService";
const MDPageNumbering = ({ onClose, open }: MDPageNumberingProps) => {
  const { tr } = useLang();
  const { formik, POSITION_OPTIONS, fontList, ResetForm } =
    MDPageNumberingService({
      onClose,
    });
  return (
    <>
      <Modal
        title={tr("pageNumbering")}
        footer={
          <>
            <Button
              style={{ minWidth: 100 }}
              type="default"
              onClick={() => {
                onClose && onClose();
              }}
            >
              {tr("close")}
            </Button>
            <Button
              style={{ minWidth: 100 }}
              onClick={() => {
                ResetForm();
              }}
              type="default"
            >
              {tr("clear")}
            </Button>
            <Button
              style={{ minWidth: 100 }}
              onClick={() => {
                formik.handleSubmit();
              }}
              type="primary"
            >
              {tr("submit")}
            </Button>
          </>
        }
        centered
        open={open}
        maskClosable={false}
        width={550}
        onCancel={() => {
          onClose && onClose();
        }}
      >
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <label>{tr("position")}</label>
            <Select
              value={formik.values.position || 0}
              onChange={(value) => {
                formik.setFieldValue("position", value);
              }}
              style={{ width: "100%" }}
              options={POSITION_OPTIONS}
            />
          </Col>
          <Col span={12}>
            <div style={{ width: "100%" }}>
              <label style={{ color: "transparent" }}>{"sss"}</label>
            </div>
            <Checkbox
              name="addFrame"
              checked={formik.values.addFrame}
              onChange={formik.handleChange}
              style={{ width: "100%" }}
            >
              {tr("addFrame")}
            </Checkbox>
          </Col>
          <Col span={12}>
            <label>{`${tr("margin")} (cm)`}</label>
            <InputNumber
              min={1}
              value={formik.values.margin}
              onChange={(value) => {
                formik.setFieldValue("margin", value);
              }}
              style={{ width: "100%" }}
            ></InputNumber>
          </Col>
          <Col span={12}>
            <label>{`${tr("marginSide")} (cm)`}</label>
            <InputNumber
              min={1}
              value={formik.values.marginSide}
              onChange={(value) => {
                formik.setFieldValue("marginSide", value);
              }}
              style={{ width: "100%" }}
            ></InputNumber>
          </Col>
          <Col span={12}>
            <label>{tr("font")}</label>
            <Select
              value={formik.values.fontName}
              onChange={(value) => {
                formik.setFieldValue("fontName", value);
              }}
              style={{ width: "100%" }}
              options={fontList}
            />
          </Col>
          <Col span={12}>
            <label>{tr("size")}</label>
            <InputNumber
              min={1}
              step={0.5}
              value={formik.values.fontSize}
              onChange={(value) => {
                formik.setFieldValue("fontSize", value);
              }}
              style={{ width: "100%" }}
            ></InputNumber>
          </Col>
          <Col span={12}>
            <label>{tr("fontColor")}</label>
            <ColorPicker
              value={formik.values.fontColor}
              onChange={(value) => {
                formik.setFieldValue("fontColor", value.toHexString());
              }}
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={12}>
            <label>{tr("backColor")}</label>
            <ColorPicker
              value={formik.values.backColor}
              onChange={(value) => {
                formik.setFieldValue("backColor", value.toHexString());
              }}
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={12}>
            <Checkbox
              checked={formik.values.showFromFrontPage}
              name="showFromFrontPage"
              onChange={formik.handleChange}
              style={{ width: "100%" }}
            >
              {tr("showFromFrontPage")}
            </Checkbox>
          </Col>
          <Col span={12}>
            <Checkbox
              checked={formik.values.showFromTOC}
              name="showFromTOC"
              onChange={formik.handleChange}
              style={{ width: "100%" }}
            >
              {tr("showFromTableOfContent")}
            </Checkbox>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default MDPageNumbering;

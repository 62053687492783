import { DndContext, DragOverlay, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import React, { FC, ReactNode, useCallback, useState } from "react";
import { SortableTabItem, TabItem } from "./TabItem";
import "../../Assets/Styles/TabFiles/Tab.scss";
import { TabFileInfoService } from "../../Services/Hook/TabFileInfoService";
import * as Api from "../../Api/src/index";
const VTabs = () => {
  const {
    handleDragCancel,
    handleDragEnd,
    handleDragStart,
    sensors,
    TabDetails,
    GetTabFileInfoState,
    activeId,
    CloseOneTab,
    OnClickTab,
  } = TabFileInfoService();
  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
      >
        <div className="vtabfile">
          {TabDetails.filter(
            (o) => o.tabFileType !== Api.TabFileType.Normal
          ).map((o) => (
            <TabItem
              tabType="Vtab"
              key={o.id}
              id={o.id}
              TabDetail={o}
              selected={
                o.uniqueId === GetTabFileInfoState().CurrentTab?.uniqueId
              }
              height={38}
              onClick={() => {
                OnClickTab(o);
              }}
            />
          ))}
          <SortableContext
            items={TabDetails}
            strategy={verticalListSortingStrategy}
          >
            {TabDetails.filter(
              (o) => o.tabFileType === Api.TabFileType.Normal
            )?.map((tab) => (
              <SortableTabItem
                tabType="Vtab"
                selected={
                  GetTabFileInfoState()?.CurrentTab?.uniqueId === tab.uniqueId
                }
                key={tab.uniqueId}
                id={tab.uniqueId || ""}
                TabDetail={tab}
                placement="left"
                height={38}
                onCloseTab={(id) => {
                  CloseOneTab(id);
                }}
                onClick={() => {
                  OnClickTab(tab);
                }}
              />
            ))}
          </SortableContext>
        </div>
        <DragOverlay adjustScale style={{ transformOrigin: "0 0" }}>
          {activeId ? (
            <TabItem
              tabType="Vtab"
              id={activeId || ""}
              selected={activeId === GetTabFileInfoState().CurrentTab?.uniqueId}
              TabDetail={
                TabDetails.find((x) => x.uniqueId === activeId) || undefined
              }
              isDragging
              placement={"left"}
            />
          ) : null}
        </DragOverlay>
      </DndContext>
    </>
  );
};

export default VTabs;

import React, { useState } from "react";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  ColorPicker,
  InputNumber,
  Modal,
  Row,
  Select,
} from "antd";
import { useLang } from "../../i18n/useLang";
import TextArea from "antd/es/input/TextArea";
import {
  MDTagDetailProps,
  MDTagDetailService,
} from "../../Services/Hook/Modal/Tags/MDTagDetailService";

const MDTagDetail = ({
  tagCategoryId,
  tagNameId,
  onClose,
  open,
  color,
  tagId,
  keyWord,
}: MDTagDetailProps) => {
  const { tr } = useLang();
  const {
    formik,
    showErrors,
    SelectCategoryChange,
    GetDataSelectCategory,
    GetDataSelectTagName,
  } = MDTagDetailService({
    tagCategoryId: tagCategoryId,
    tagNameId: tagNameId,
    onClose: onClose,
    color: color,
    tagId: tagId,
    keyWord: keyWord,
  });
  return (
    <>
      <Modal
        title={tr("pageNumbering")}
        footer={
          <>
            <Button
              style={{ minWidth: 100 }}
              onClick={() => {
                onClose && onClose();
              }}
              type="default"
            >
              {tr("close")}
            </Button>
            <Button
              style={{ minWidth: 100 }}
              onClick={() => {
                formik.submitForm();
              }}
              type="primary"
            >
              {tr("save")}
            </Button>
          </>
        }
        centered
        maskClosable={false}
        open={open}
        width={550}
        onCancel={onClose}
      >
        <Row gutter={[8, 8]}>
          <Col span={24}>
            {!formik.isValid && (
              <Alert
                description={showErrors(formik.errors)}
                type="warning"
                showIcon
              />
            )}
          </Col>
          <Col span={24}>
            <label>{tr("keywords")}</label>
            <TextArea
              name="keyWord"
              value={formik.values.keyWord || ""}
              onChange={formik.handleChange}
              style={{ height: 80 }}
            />
          </Col>
          <Col span={8}>
            <Checkbox
              disabled={!!formik.values.tagId}
              style={{ width: "100%" }}
              name="comma"
              onChange={formik.handleChange}
            >
              {tr("comma")}
            </Checkbox>
          </Col>
          <Col span={8}>
            <Checkbox
              disabled={!!formik.values.tagId}
              style={{ width: "100%" }}
              name="space"
              onChange={formik.handleChange}
            >
              {tr("space")}
            </Checkbox>
          </Col>
          <Col span={8}>
            <Checkbox
              disabled={!!formik.values.tagId}
              style={{ width: "100%" }}
              name="line"
              onChange={formik.handleChange}
            >
              {tr("line")}
            </Checkbox>
          </Col>
          <Col span={8}>
            <Checkbox
              disabled={!!formik.values.tagId}
              style={{ width: "100%" }}
              name="semicolon"
              onChange={formik.handleChange}
            >
              {tr("semicolon")}
            </Checkbox>
          </Col>
          <Col span={16}>
            <Checkbox
              disabled={!!formik.values.tagId}
              style={{ width: "100%" }}
              name="comma"
              onChange={formik.handleChange}
            >
              {tr("slash")}
            </Checkbox>
          </Col>
          <Col span={12}>
            <label>{tr("category")}</label>
            <Select
              value={formik.values.categoryID}
              style={{ width: "100%" }}
              options={GetDataSelectCategory()}
              onChange={(value) => {
                SelectCategoryChange(value);
              }}
            />
          </Col>
          <Col span={12}>
            <label>{tr("name")}</label>
            <Select
              value={formik.values.tagNameId}
              style={{ width: "100%" }}
              options={GetDataSelectTagName(formik.values.categoryID || "")}
              onChange={(value) => {
                formik.setFieldValue("tagNameId", value);
              }}
            />
          </Col>
          <Col span={24}>
            <label>{tr("tagColor")}</label>
            <ColorPicker
              style={{ width: "100%" }}
              value={formik.values.backColor}
              onChange={(color) => {
                formik.setFieldValue("backColor", color.toHexString());
              }}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default MDTagDetail;

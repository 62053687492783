import { useEffect, useRef, useState } from "react";
import * as Api from "../../../../Api/src/index";
import { v4 } from "uuid";
import { useFormik } from "formik";
import { AppLocalStorage } from "../../../../Constant/AppContant";
import { AppCache } from "../../../../Cache/AppCache";
import UseModalStore, { ModaType } from "../../../../ZustandStore/ModalStore";
import useReplaceStore from "../../../../ZustandStore/ReplaceStore";
import { ReplaceBoxCommand } from "../../../../UndoRedo/Commands/ReplaceBoxCommand";
import { EditReplaceBoxCommand } from "../../../../UndoRedo/Commands/EditReplaceBoxCommand";
const FontApi = new Api.FontApi();
const ReplaceBoxApi = new Api.ReplaceBoxApi();

export interface MDReplaceWordProps {
  open?: boolean;
  onClose?: () => void;
}
let timeOutPreviewReplateBox: any = null;
export const MDReplaceBoxService = () => {
  const { ModalState, GetModalState, SetModalState } = UseModalStore();
  const { GetReplaceState, SetReplaceStore } = useReplaceStore();
  const replaceBoxCommand = ReplaceBoxCommand();
  const editReplaceBoxCommand = EditReplaceBoxCommand();
  const [fontList, setFontList] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);
  const [imageState, setImageState] = useState<string>("");
  const lxSetting = AppLocalStorage.GetItem(AppLocalStorage.LxSetting);
  const InitCheck = useRef<boolean>(false);
  useEffect(() => {
    if (InitCheck.current) return;
    GetFontList();
    InitCheck.current = true;
  }, []);

  useEffect(() => {
    if (ModalState.Show === true && ModalState.Type === ModaType.MDReplaceBox) {
      if (timeOutPreviewReplateBox) {
        clearTimeout(timeOutPreviewReplateBox);
      }
      timeOutPreviewReplateBox = setTimeout(() => {
        const boxId = AppCache.AnnotationSelected?.getCustomData("boxId");
        ReplaceBoxApi.apiLegalxtractGetPreviewReplateBoxGet(
          boxId,
          AppCache.CurrentPageNo!
        ).then((res) => {
          setImageState("data:image/png;base64," + res.data);
        });
        timeOutPreviewReplateBox = null;
      }, 200);
    } else {
      setImageState("");
    }
  }, [ModalState.Show]);
  const GetInitDataForm = (): Api.ReplaceParam => {
    if (GetModalState().Status === "Add") {
      return {
        ...GetReplaceState().ParamReplaceWord,
        fontName:
          GetReplaceState().ParamReplaceWord?.fontName ||
          lxSetting.replaceFontName,
        fontSize:
          GetReplaceState().ParamReplaceWord?.fontSize ||
          lxSetting.replaceFontSize,
      };
    } else {
      return (
        GetReplaceState().ParamReplaceWord || {
          fontColor: "#e74c3c",
          extractColor: "#ffffff",
          fontName: lxSetting.replaceFontName,
          fontSize: lxSetting.replaceFontSize,
        }
      );
    }
  };
  const formik = useFormik({
    initialValues: GetInitDataForm(),
    onSubmit: async (values) => {
      if (ModalState.Status === "Add") {
        replaceBoxCommand.Do({
          box: values,
          id: AppCache.AnnotationSelected?.getCustomData("boxId"),
          viewerPageNo: AppCache.CurrentPageNo!,
        });
      } else {
        replaceBoxCommand.Do({
          box: values,
          id: AppCache.AnnotationSelected?.getCustomData("boxId"),
          viewerPageNo: AppCache.CurrentPageNo!,
        });
      }

      SetModalState({ Show: false, Type: ModaType.None });
      SetReplaceStore({
        ParamReplaceWord: {
          ...GetReplaceState().ParamReplaceWord,
          originalWords: "",
          replaceBy: "",
          id: "",
          matchCase: false,
          wholeWord: false,
        },
      });
    },
  });
  const GetFontList = () => {
    FontApi.apiLegalxtractGetListFontGet().then((res) => {
      if (res && res.data) {
        setFontList(res.data.map((item) => ({ value: item, label: item })));
      }
    });
  };
  const Close = () => {
    SetModalState({ Show: false, Type: ModaType.None });
  };
  return { formik, fontList, GetModalState, Close, ModalState, imageState };
};

import UndoRedoManagerObj from "../UndoRedomanager";
import * as Api from "../../Api/src/index";
import { AppCache } from "../../Cache/AppCache";
import { PageService } from "../../Services/NotHook/PageService";
import { PdfTronService } from "../../Services/NotHook/PdfTronService";
import usePageStore from "../../ZustandStore/PageStore";
import useMenuStripStore from "../../ZustandStore/MenuStripStore";
const BlankPageApi = new Api.BlankPageApi();
export const AddBlankPageCommand = () => {
  const { InsertBlankPage } = PageService();
  const { SetPageStore } = usePageStore();
  const { SetMenuStripStore } = useMenuStripStore();
  const { DeletePages } = PdfTronService();
  const AddBlankPageProcess = async (isShowBlankPage: boolean) => {
    var res = await BlankPageApi.apiLegalxtractProcessBlankPageGet(
      isShowBlankPage
    );
    if (res && res.data.isSuccess) {
      AppCache.DocumentInfo = res.data.result?.documentInfo || null;
      if ((res.data.result?.pageAdds?.length || 0) > 0) {
        res.data.result?.pageAdds?.forEach((pageAdd: Api.BlankPageInfo) => {
          InsertBlankPage(pageAdd.pageNo!, pageAdd.width!, pageAdd.height!);
        });
      }
      if ((res.data.result?.pageDeletes?.length || 0) > 0) {
        DeletePages(res.data.result?.pageDeletes || []);
      }
      SetPageStore({
        TotalPages: AppCache.DocumentInfo?.totalPage || 0,
      });
      SetMenuStripStore({
        AddBlankPage: AppCache.DocumentInfo?.isBlankPage,
      });
    }
    return res;
  };
  const Do = async (isShowBlankPage: boolean) => {
    var res = await AddBlankPageProcess(isShowBlankPage);
    UndoRedoManagerObj.add({
      Redo: () => {
        Redo(isShowBlankPage);
      },
      Undo: () => {
        Undo(!isShowBlankPage);
      },
    });
  };
  const Undo = (isShowBlankPage: boolean) => {
    AddBlankPageProcess(isShowBlankPage);
  };
  const Redo = (isShowBlankPage: boolean) => {
    AddBlankPageProcess(isShowBlankPage);
  };
  return { Do };
};

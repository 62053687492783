import { useEffect, useRef } from "react";
import * as Api from "../../Api/src/index";
import { AppCache } from "../../Cache/AppCache";
import globalAxiosConfig from "../../Api/axiosConfig";
import useTagStore from "../../ZustandStore/TagStore";
import { GetLxSetting } from "./Modal/MDAppSettingsService";
const OpenApi = new Api.OpenApi();
const TagApi = new Api.TagApi();
const ReplaceApi = new Api.ReplaceApi();
const SettingApi = new Api.SettingApi();
const UseAppService = () => {
  let initCheck = useRef(false);
  const { SetTagStore } = useTagStore();
  useEffect(() => {
    if (initCheck.current) return;
    globalAxiosConfig();
    InitData();
    initCheck.current = true;
  }, []);
  const InitData = () => {
    TagApi.apiLegalxtractLoadTagDictionaryForClientGet().then((res) => {
      SetTagStore({ Tags: res.data });
    });
    SettingApi.apiLegalxtractUploadSettingPost(false, GetLxSetting());
    OpenApi.apiLegalxtractFileTypeSupportGet().then((res) => {
      const data = res.data;
      let acceptFileExtensions: string[] = [];
      Object.keys(data).forEach((key: string) => {
        acceptFileExtensions = acceptFileExtensions.concat(
          data[key].split(";").map((ext: any) => `.${ext.toLowerCase()}`)
        );
      });
      AppCache.FileTypeSupport = data;
      AppCache.FileInputAccept =
        acceptFileExtensions.join(",") + ",.xtract" || "";
    });
    ReplaceApi.apiLegalxtractGetReplaceObjectsGet().then((res) => {
      AppCache.ReplaceObjects = res.data;
    });
  };
  return {};
};
export default UseAppService;

import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  MenuProps,
  Row,
  Space,
  Table,
  TableProps,
  Tag,
} from "antd";
import { useLang } from "../../../i18n/useLang";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  MenuID,
  WatchDogPanelService,
} from "../../../Services/Hook/LeftPanel/WatchDogPanelService";
import "../../../Assets/Styles/LeftPanels/WatchdogPanel.scss";
import { Item, Menu } from "react-contexify";

interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}
const items: MenuProps["items"] = [
  {
    label: "1st menu item",
    key: "1",
  },
  {
    label: "2nd menu item",
    key: "2",
  },
  {
    label: "3rd menu item",
    key: "3",
  },
];
export const WatchdogPanel = () => {
  const { tr } = useLang();
  const {
    columns,
    expandedRowKeys,
    setExpandedRowKeys,
    SetWatchDogParamWeb,
    WatchDogParamWeb,
    LoadWatchDogData,
    SetWatchdogDatas,
    WatchdogDatas,
    SelectAll,
    DeSelectAll,
    ShowContextMenu,
    onRowClick,
    MenuDeSelectAllClick,
    MenuSelectAllClick,
    watchDogChecked,
  } = WatchDogPanelService();

  return (
    <div className="left-panel-container">
      <Row style={{ height: "100%" }} gutter={[8, 8]}>
        <Col span={24} className="header-panel">
          {tr("watchdog")}
        </Col>
        <Col span={24}>
          <Space>
            <Checkbox
              checked={WatchDogParamWeb?.wholeWord || false}
              onChange={(val) => {
                LoadWatchDogData({ wholeWord: val.target.checked });
              }}
            >
              {tr("matchWholeWord")}
            </Checkbox>
            <Checkbox
              checked={WatchDogParamWeb?.matchCase || false}
              onChange={(val) => {
                LoadWatchDogData({ matchCase: val.target.checked });
              }}
            >
              {tr("matchCase")}
            </Checkbox>
            <Checkbox
              checked={WatchDogParamWeb?.combine || false}
              onChange={(val) => {
                LoadWatchDogData({ combine: val.target.checked });
              }}
            >
              {tr("combine")}
            </Checkbox>
          </Space>
        </Col>
        <Col
          style={{ height: "calc(100% - 136px)", overflow: "auto" }}
          span={24}
        >
          <Table
            size="small"
            rowKey={"id"}
            columns={columns}
            expandable={{
              expandedRowKeys: expandedRowKeys,
              onExpand: (expanded, record) => {
                if (expanded) {
                  setExpandedRowKeys([...expandedRowKeys!, record.id!]);
                } else {
                  setExpandedRowKeys(
                    expandedRowKeys!.filter((o) => o !== record.id)
                  );
                }
              },
            }}
            onRow={(record, index) => {
              return {
                onContextMenu: (event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  ShowContextMenu(event, record);
                },
                onClick: (event) => {
                  onRowClick(record, event);
                },
              };
            }}
            dataSource={WatchdogDatas || []}
            pagination={false}
          />
          <Menu id={MenuID}>
            {(watchDogChecked.every((o) => o.totalCount == o.selectedCount) ||
              watchDogChecked.some((o) => o.totalCount != o.selectedCount)) && (
              <Item
                onClick={() => {
                  MenuDeSelectAllClick();
                }}
              >
                {tr("deselectAll")}
              </Item>
            )}
            {watchDogChecked.some((o) => o.totalCount != o.selectedCount) && (
              <Item
                onClick={() => {
                  MenuSelectAllClick();
                }}
              >
                {tr("selectAll")}
              </Item>
            )}
          </Menu>
        </Col>
        <Col span={12}>
          <Button
            onClick={() => {
              DeSelectAll();
            }}
            style={{ width: "100%" }}
          >
            {tr("deselectAll")}
          </Button>
        </Col>
        <Col span={12}>
          <Button
            onClick={() => {
              SelectAll();
            }}
            style={{ width: "100%" }}
          >
            {tr("selectAll")}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

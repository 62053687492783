import { AppCache } from "../../Cache/AppCache";
import { PageService } from "./PageService";

export const BlankPageService = () => {
  const { InsertBlankPage } = PageService();
  const InsertPage = () => {
    if (AppCache.DocumentInfo?.isBlankPage === false) return;
    var blankPages = AppCache.DocumentInfo?.tabDetails
      ?.flatMap((o) => o.pages || [])
      .filter((o) => o.isBlankPage === true)
      .map((o) => {
        return {
          pageNo: o.viewerPageNo,
          width: o.pageWidth,
          height: o.pageHeight,
        };
      });
    if ((blankPages?.length || 0) > 0) {
      blankPages?.forEach((pageAdd) => {
        InsertBlankPage(pageAdd.pageNo!, pageAdd.width!, pageAdd.height!);
      });
    }
  };
  return { InsertPage };
};

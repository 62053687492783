import { useEffect, useRef, useState } from "react";
import * as Api from "../../../Api/src/index";
import { SearchService } from "../../NotHook/SearchService";
import { AppLocalStorage } from "../../../Constant/AppContant";
import useSearchTabStore from "../../../ZustandStore/SearchTabStore";
export const FindPanelService = () => {
  // const { GetSearchTabVm } = SearchService();
  const { SearchTabState, SetSearchTabStore } = useSearchTabStore();
  // const [SearchTabVm, SetSearchTabVm] = useState<Api.SearchTabVm>(
  //   GetSearchTabVm()
  // );
  // const initCheck = useRef<boolean>(false);
  // useEffect(() => {
  //   if (!initCheck.current) {
  //     SetSearchTabVm(GetSearchTabVm());
  //     initCheck.current = true;
  //   }
  // }, []);
  // useEffect(() => {
  //   AppLocalStorage.SetItem(AppLocalStorage.SearchTabVm, SearchTabVm);
  // }, [SearchTabVm]);
  return { SearchTabState, SetSearchTabStore };
};

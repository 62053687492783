import UndoRedoManagerObj from "../UndoRedomanager";
import * as Api from "../../Api/src/index";
import { AppCache } from "../../Cache/AppCache";
import { PageService } from "../../Services/NotHook/PageService";
import { UndoHightLight } from "./HightLightCommand";
import { ReplaceService } from "../../Services/NotHook/ReplaceService";
import useReplaceStore from "../../ZustandStore/ReplaceStore";
import { ReplacePanelService } from "../../Services/Hook/LeftPanel/ReplacePanelService";
const ReplaceBoxApi = new Api.ReplaceBoxApi();
export const EditReplaceBoxCommand = () => {
  const { SetDataCurrentPage } = PageService();
  const { LoadReplaceObjDisplays } = ReplacePanelService();
  const EditReplaceBox = async (param: Api.EditReplaceBoxParam) => {
    if (!param) return null;
    const res = await ReplaceBoxApi.apiLegalxtractEditReplaceBoxPost(param);
    SetDataCurrentPage(res.data.result?.pageData!, AppCache.CurrentPageNo || 0);
    ReplaceService().GetReplaceObjects();
    if (AppCache.LeftTabActive === "pnReplace") {
      LoadReplaceObjDisplays();
    }
    return res;
  };
  const Do = async (param: Api.EditReplaceBoxParam) => {
    const res = await EditReplaceBox(param);
    UndoRedoManagerObj.add({
      Redo: async () => {
        await Redo(param);
      },
      Undo: async () => {
        await Undo(res?.data.result?.data!);
      },
    });
    return res;
  };
  const Redo = async (param: Api.EditReplaceBoxParam) => {
    await EditReplaceBox(param);
  };
  const Undo = async (param: Api.EditReplaceBoxParam) => {
    EditReplaceBox(param);
  };
  return { Do };
};

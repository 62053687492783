import React, { useState } from "react";
import { Button, Space, TableColumnsType, Tag } from "antd";
import * as Api from "../../../../Api/src/index";
import { useLang } from "../../../../i18n/useLang";
import useTagStore from "../../../../ZustandStore/TagStore";
import { useEffect, useRef } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import UseConfirmDialogStore from "../../../../ZustandStore/ConfirmDialogStore";
import { saveAs } from "file-saver";
import { AppCache } from "../../../../Cache/AppCache";
import { PageService } from "../../../NotHook/PageService";
const TagApi = new Api.TagApi();
export interface MDTagListProps {
  open: boolean;
  onClose: () => void;
}
export const TagListService = () => {
  const { tr, i18n } = useLang();
  const { SetDataCurrentPage } = PageService();
  const { TagState, SetTagStore } = useTagStore();
  const [tagInfos, setTagInfos] = useState<Api.TagInfo[]>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>();
  const [selectedTagNameId, setSelectedTagNameId] = useState<string | null>();
  const [categoryColor, setCategoryColor] = useState<string>("yellow");
  const { SetConfirmDialogState } = UseConfirmDialogStore();
  const [tagInfoChecked, SetTagInfoChecked] = useState<Api.TagInfo[]>([]);
  const [showMDCategory, setShowMDCategory] = useState<boolean>(false);
  const [showMDTagName, setShowMDTagName] = useState<boolean>(false);
  const [showMDTagDetail, setShowMDTagDetail] = useState<{
    open: boolean;
    editItem: Api.TagInfo | null;
    tagId: string | null;
  }>({ open: false, tagId: null, editItem: null });
  const fileInputRef = React.useRef(null);
  const initCheck = useRef(false);
  useEffect(() => {
    if (initCheck.current) return;
    GetTagData();

    initCheck.current = true;
  }, []);
  const columns: TableColumnsType<Api.TagInfo> = [
    {
      title: tr("keywords"),
      dataIndex: "keyWord",
      render: (text: string) => {
        return text;
      },
    },
    {
      title: tr("backColor"),
      dataIndex: "backColor",
      render: (text: string) => {
        return (
          <Space>
            <Button style={{ pointerEvents: "none", background: text }} />
            <p>{text}</p>
          </Space>
        );
      },
    },
    {
      width: "10%",
      key: "action",
      render: (_, record, i) => {
        return (
          <Space>
            <a
              onClick={() => {
                EditTagInfo(record);
              }}
            >
              <EditOutlined />
            </a>
            <a
              onClick={() => {
                var tagIds = tagInfoChecked;
                tagIds = [...tagInfoChecked, record];
                DeleteTagInfo(tagIds.map((o) => o.id || ""));
              }}
            >
              <DeleteOutlined />
            </a>
          </Space>
        );
      },
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: Api.TagInfo[]) => {
      SetTagInfoChecked(selectedRows);
    },
    getCheckboxProps: (record: Api.TagInfo) => ({
      id: record.id,
    }),
  };
  const GetTagData = () => {
    TagApi.apiLegalxtractLoadTagDictionaryForClientGet().then((res) => {
      var data = res.data;
      if (data) {
        data.tagCategories?.forEach((item) => {
          item.keyword =
            i18n.language == "da"
              ? item.keywordDa || item.keyword
              : item.keyword;
        });
        data.tagNames?.forEach((item) => {
          item.keyword =
            i18n.language == "da"
              ? item.keywordDa || item.keyword
              : item.keyword;
        });
        SetTagStore({ Tags: data });
        if (data && data.tagCategories && data.tagCategories.length > 0) {
          var cateId = data.tagCategories[0]?.id || null;
          setSelectedCategoryId(cateId);
          setCategoryColor(data.tagCategories[0]?.color || "yellow");
          if (data?.tagNames && data.tagNames.length > 0) {
            var tagNames =
              data.tagNames?.filter((o) => o.tagCategoryId == (cateId || "")) ||
              [];
            setSelectedTagNameId(tagNames[0]?.id || null);
            setTagInfos(tagNames);
          }
        }
      } else {
        SetTagStore({ Tags: null });
      }
    });
  };
  const GetDataSelectCategory = () => {
    return (
      TagState.Tags?.tagCategories?.map((o) => {
        return { value: o.id, label: o.keyword };
      }) || []
    );
  };
  const GetDataTagInfos = (tagNameId: string) => {
    var tagInfos =
      TagState.Tags?.tagInfos?.filter((o) => o.tagNameId == tagNameId) || [];
    return tagInfos;
  };
  const GetDataSelectTagName = (categoryId: string) => {
    var tagNames =
      TagState.Tags?.tagNames?.filter((o) => o.tagCategoryId == categoryId) ||
      [];

    return (
      tagNames.map((o) => {
        return { value: o.id, label: o.keyword };
      }) || []
    );
  };
  const closeCategoryTag = (obj: Api.TagCategory) => {
    setSelectedCategoryId(obj.id);
    var tagNames =
      TagState.Tags?.tagNames?.filter((o) => o.tagCategoryId == obj.id) || [];
    if (tagNames.length > 0) {
      setSelectedTagNameId(tagNames[0].id);
    } else {
      setSelectedTagNameId(null);
    }
    setCategoryColor(obj?.color || "yellow");
  };
  const SelectCategoryChange = (id: string) => {
    setSelectedCategoryId(id);
    var tagNames =
      TagState.Tags?.tagNames?.filter((o) => o.tagCategoryId == id) || [];
    if (tagNames.length > 0) {
      setSelectedTagNameId(tagNames[0].id);
    } else {
      setSelectedTagNameId(null);
    }
    var category = TagState.Tags?.tagCategories?.find((o) => o.id == id);
    setCategoryColor(category?.color || "yellow");
  };
  const SelectTagNameChange = (id: string) => {
    setSelectedTagNameId(id);
  };
  const DeleteTagInfo = (tagIds: string[]) => {
    const Delete = async () => {
      var data = await TagApi.apiLegalxtractDeleteTagPost(tagIds);
      if (data.data) {
        var tags = TagState.Tags?.tagInfos?.filter(
          (o) => tagIds.includes(o.id || "") === false
        );
        SetTagStore({ Tags: { ...TagState.Tags, tagInfos: tags } });
        RemoveAllTagNotExists();
      }
    };
    SetConfirmDialogState({
      Show: true,
      Type: "Confirm",
      Icon: "Question",
      CancelText: tr("no"),
      YesText: tr("yes"),
      Msg:
        tr("doYouWantToDelete") +
        ` [${tagInfoChecked.map((o) => o.keyWord).join(",")}]?`,
      OnYes: () => {
        Delete();
      },
    });
  };
  const categoryColorChange = (tagCategoryId?: string, color?: string) => {
    if (!tagCategoryId) return;
    var category = TagState.Tags?.tagCategories?.find(
      (o) => o.id == tagCategoryId
    );
    if (!category) return;
    category.color = color;
    TagApi.apiLegalxtractEditTagCategoryPost(category).then((res) => {
      if (res.data) {
        var categories = TagState.Tags?.tagCategories?.map((o) => {
          if (o.id == category?.id) {
            return category || {};
          }
          return o;
        });
        SetTagStore({ Tags: { ...TagState.Tags, tagCategories: categories } });
      }
    });
  };
  const DeleteCategory = (tagCategoryId?: string) => {
    if (!tagCategoryId) return;
    var category = TagState.Tags?.tagCategories?.find(
      (o) => o.id == tagCategoryId
    );
    if (!category) return;
    const Delete = async () => {
      var data = await TagApi.apiLegalxtractDeleteTagCategoryGet(
        category?.id || ""
      );
      if (data.data) {
        var categories = TagState.Tags?.tagCategories?.filter(
          (o) => o.id != category?.id
        );
        SetTagStore({ Tags: { ...TagState.Tags, tagCategories: categories } });
        if (categories && categories.length > 0) {
          SelectCategoryChange(categories[0]?.id || "");
        } else {
          SelectCategoryChange("");
        }
        RemoveAllTagNotExists();
      }
    };
    SetConfirmDialogState({
      Show: true,
      Type: "Confirm",
      Icon: "Question",
      CancelText: tr("no"),
      YesText: tr("yes"),
      Msg: tr("doYouWantToDelete") + ` ${category.keyword}?`,
      OnYes: () => {
        Delete();
      },
    });
  };
  const RemoveAllTagNotExists = () => {
    TagApi.apiLegalxtractRemoveAllTagNotExistsPost(AppCache.CurrentPageNo).then(
      (res) => {
        SetDataCurrentPage(
          res.data.result! || null,
          AppCache.CurrentPageNo || 0
        );
      }
    );
  };
  const DeleteTagName = (tagNameId?: string) => {
    if (!tagNameId) return;
    var tagName = TagState.Tags?.tagNames?.find((o) => o.id == tagNameId);
    if (!tagName) return;
    const Delete = async () => {
      var data = await TagApi.apiLegalxtractDeleteTagNameGet(tagName?.id || "");
      if (data.data) {
        var tagNames = TagState.Tags?.tagNames?.filter(
          (o) => o.id != tagName?.id
        );
        SetTagStore({ Tags: { ...TagState.Tags, tagNames: tagNames } });
        if (tagNames && tagNames.length > 0) {
          SelectTagNameChange(tagNames[0]?.id || "");
        } else {
          SelectTagNameChange("");
        }
        RemoveAllTagNotExists();
      }
    };
    SetConfirmDialogState({
      Show: true,
      Type: "Confirm",
      Icon: "Question",
      CancelText: tr("no"),
      YesText: tr("yes"),
      Msg: tr("doYouWantToDelete") + ` ${tagName.keyword}?`,
      OnYes: () => {
        Delete();
      },
    });
  };
  const EditTagInfo = (item: Api.TagInfo) => {
    setShowMDTagDetail({ open: true, editItem: item, tagId: item.id || null });
  };
  const handleExport = async () => {
    const filename = "TagData.dat";
    const response = await TagApi.apiLegalxtractExportTagDictionaryGet(
      filename,
      {
        responseType: "blob",
      }
    );
    saveAs(response.data, `${filename}`);
    SetConfirmDialogState({
      Show: true,
      Type: "Alert",
      Msg: tr("exportDone"),
    });
  };

  const onSelectedImportFile = async (e: any) => {
    if (!e.target.files || e.target.files.length === 0) return;
    const response = await TagApi.apiLegalxtractInportFromFilePost(
      e.target.files[0]
    );
    if (response.data) {
      SetTagStore({ Tags: response.data });
      SetConfirmDialogState({
        Show: true,
        Type: "Alert",
        Msg: tr("importDone"),
      });
    }
  };

  const handleImport = async () => {
    (fileInputRef.current as any).click();
  };
  return {
    rowSelection,
    columns,
    TagState,
    closeCategoryTag,
    SetTagStore,
    GetDataSelectCategory,
    GetDataSelectTagName,
    selectedCategoryId,
    selectedTagNameId,
    categoryColor,
    GetDataTagInfos,
    SelectCategoryChange,
    SelectTagNameChange,
    DeleteTagInfo,
    categoryColorChange,
    DeleteTagName,
    DeleteCategory,
    showMDCategory,
    setShowMDCategory,
    setShowMDTagName,
    showMDTagName,
    showMDTagDetail,
    setShowMDTagDetail,
    EditTagInfo,
    handleExport,
    fileInputRef,
    onSelectedImportFile,
    handleImport,
  };
};

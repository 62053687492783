import {
  DragEndEvent,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { useCallback, useEffect, useState } from "react";
import useTabFileInfoStore from "../../ZustandStore/TabFileInfoStore";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import * as Api from "../../Api/src/index";
import { PdfTronService } from "../NotHook/PdfTronService";
import usePageStore from "../../ZustandStore/PageStore";
import { AppCache } from "../../Cache/AppCache";
import {
  CheckVisibleElement,
  createArrayNumber,
} from "../../Common/CommonFunction";
import { OpenFileService } from "./OpenFileService";
import { Item, Menu, useContextMenu } from "react-contexify";
import { useLang } from "../../i18n/useLang";
import { LeftPanelService } from "./LeftPanel/LeftPanelService";
export const MenuID = "TabMenu";
const CloseFileApi = new Api.CloseFileApi();
const ReorderApi = new Api.ReorderApi();
const DocumentApi = new Api.DocumentApi();
export const TabFileInfoService = () => {
  const { tr } = useLang();
  const { SetPageStore } = usePageStore();
  const { LoadLeftPanelData } = LeftPanelService();
  const [ShowMDRenameTab, SetShowMDRenameTab] = useState<{
    open: boolean;
    name: string;
    tabId: string;
  }>({ open: false, tabId: "", name: "" });
  const { SetCurrentPageNo } = PdfTronService();
  const {
    TabFileInfoState,
    GetTabFileInfoState,
    GetTabInfoById,
    SetTabFileInfoState,
  } = useTabFileInfoStore();
  const { OpenFiles } = OpenFileService();
  const [TabDetails, setTabDetails] = useState(
    (GetTabFileInfoState().TabDetails || []).map((x) => {
      return { ...x, id: x.uniqueId || "" };
    })
  );
  useEffect(() => {
    setTabDetails(
      (GetTabFileInfoState().TabDetails || []).map((x) => {
        return { ...x, id: x.uniqueId || "" };
      })
    );
  }, [TabFileInfoState.TabDetails]);
  const [isVTabShow, setIsVTabShow] = useState(false);

  const ShowVTabs = () => {
    setIsVTabShow(!isVTabShow);
  };
  const { DeletePages } = PdfTronService();
  const [tabfocus, setTabforcus] = useState<Api.TabDetail | undefined | null>(
    null
  );
  const [activeId, setActiveId] = useState(null);
  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 8 } }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  useEffect(() => {
    if (TabFileInfoState.CurrentTab) {
      const htab = document.getElementById(
        "Htab" + TabFileInfoState.CurrentTab?.uniqueId || ""
      );
      const vtab = document.getElementById(
        "Vtab" + TabFileInfoState.CurrentTab?.uniqueId || ""
      );
      htab?.scrollIntoView();
      vtab?.scrollIntoView();
    }
  }, [TabFileInfoState.CurrentTab]);

  const handleDragStart = useCallback((event: any) => {
    const { active } = event;
    setActiveId(active.id);
  }, []);
  const handleDragEnd = useCallback(async (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id && over) {
      const fromTab = GetTabFileInfoState().TabDetails?.find(
        (x) => x.uniqueId == active.id
      );
      const totab = GetTabFileInfoState().TabDetails?.find(
        (x) => x.uniqueId == over?.id
      );
      const res = await ReorderApi.apiLegalxtractProcessReorderGet(
        fromTab?.tabIndex,
        totab?.tabIndex
      );
      if (fromTab?.tabIndex! < totab?.tabIndex!) {
        AppCache.OldCurrentPageNo =
          (AppCache.CurrentPageNo || 0) + (totab?.pageCount || 0);
      } else {
        AppCache.OldCurrentPageNo =
          (AppCache.CurrentPageNo || 0) - (totab?.pageCount || 0);
      }
      if (res.data.isSuccess && res.data.result) {
        AppCache.DocumentInfo = res.data.result.data!;
        SetTabFileInfoState({
          TabDetails: res.data.result.data?.tabDetails || [],
        });
        var file = await DocumentApi.apiLegalxtractGetDocumentFromCacheGet({
          responseType: "blob",
        });
        if (file) {
          AppCache.WebViewerInstance?.Core.documentViewer.loadDocument(
            file.data
          );
        }
      }
    }
    setActiveId(null);
  }, []);
  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);
  const CloseOneTab = (id: string) => {
    CloseFileApi.apiLegalxtractCloseOneFileGet(id).then((res) => {
      if (res.data.isSuccess && res.data.result) {
        const tabDetails = TabFileInfoState.TabDetails?.find(
          (x) => x.uniqueId == id
        );
        AppCache.DocumentInfo = res.data.result;
        const lastPage =
          (tabDetails?.startPage || 0) + (tabDetails?.pageCount || 0) - 1;
        const pagesToDelete = createArrayNumber(
          tabDetails?.startPage || 0,
          lastPage
        );
        if (
          res.data.result.tabDetails &&
          res.data.result.tabDetails.length > 0
        ) {
          SetTabFileInfoState({
            TabDetails: [...(res.data.result.tabDetails || [])],
          });
        } else {
          SetTabFileInfoState({ TabDetails: [] });
        }
        SetPageStore({ TotalPages: AppCache.DocumentInfo?.totalPage || 0 });
        if (AppCache.DocumentInfo.totalPage == 0) {
          AppCache.WebViewerInstance?.Core.documentViewer.closeDocument();
          SetPageStore({
            TotalPages: 0,
            CurrentPageNo: 0,
          });
        } else {
          DeletePages(pagesToDelete);
        }
      }

      LoadLeftPanelData([]);
    });
  };
  const OnClickTab = (tab: Api.TabDetail) => {
    SetPageStore({ CurrentPageNo: tab.startPage || 0 });
    const { SetCurrentPageNo } = PdfTronService();
    SetCurrentPageNo(tab.startPage || 0);
  };
  const AppendFile = () => {
    OpenFiles(false);
  };
  const getHeight = () => {
    const elmnt = document.getElementsByClassName("webviewer")[0];
    if (elmnt) {
      return elmnt.clientWidth -
        80 -
        180 * (AppCache.DocumentInfo?.tabDetails?.length || 0) >=
        0
        ? 40
        : 33;
    }
    return 40;
  };

  const { show, hideAll } = useContextMenu({
    id: MenuID,
  });
  function ShowMenuContext(event: any, tab: Api.TabDetail | null) {
    setTabforcus(tab);
    show({
      event,
    });
  }
  const DrawMenuItem = () => {
    return (
      <Menu id={MenuID}>
        <Item
          onClick={() => {
            SetShowMDRenameTab({
              name: tabfocus?.fileName || "",
              open: true,
              tabId: tabfocus?.uniqueId || "",
            });
          }}
        >
          {tr("rename")}
        </Item>
        <Item onClick={() => {}}>{tr("ocrPage")}</Item>
        <Item onClick={() => {}}>{tr("ocrTab")}</Item>
      </Menu>
    );
  };
  return {
    handleDragStart,
    handleDragEnd,
    handleDragCancel,
    sensors,
    TabDetails,
    GetTabFileInfoState,
    ShowVTabs,
    isVTabShow,
    CloseOneTab,
    OnClickTab,
    getHeight,
    AppendFile,
    activeId,
    ShowMDRenameTab,
    SetShowMDRenameTab,
    DrawMenuItem,
    ShowMenuContext,
  };
};

import UndoRedoManagerObj from "../UndoRedomanager";
import * as Api from "../../Api/src/index";
import { AppCache } from "../../Cache/AppCache";
import { PageService } from "../../Services/NotHook/PageService";
import { AppLocalStorage } from "../../Constant/AppContant";
import { LeftPanelService } from "../../Services/Hook/LeftPanel/LeftPanelService";
const HighlightApi = new Api.HighlightApi();
export const HightLightWordCommand = () => {
  const { SetDataCurrentPage } = PageService();
  const { LoadLeftPanelData } = LeftPanelService();
  const HightLight = async (
    pageNo: number,
    point: { x: number; y: number } | null
  ) => {
    if (!point || !AppCache.WordSelected) return;
    localStorage.setItem(AppLocalStorage.DocumentHasChanged, "true");
    var res =
      await HighlightApi.apiLegalxtractHighLightOrUnHighLightClickWordsPost({
        viewerPageNo: pageNo,
        x: point.x,
        y: point.y,
      });
    if (AppCache.CurrentPageNo === pageNo) {
      SetDataCurrentPage(res.data.lxPageData!, AppCache.CurrentPageNo || 0);
    }
    LoadLeftPanelData(["pnFind", "pnWatchDog", "pnLog"]);
    return res;
  };
  const Do = async (pageNo: number, point: { x: number; y: number } | null) => {
    if (!point || !AppCache.WordSelected) return;
    const res = await HightLight(pageNo, point);
    UndoRedoManagerObj.add({
      Redo: () => {
        Redo(pageNo, point);
      },
      Undo: () => {
        Undo({
          preState: JSON.stringify(res?.data.dicPreState),
          viewerPageNo: pageNo,
        });
      },
    });
  };
  const Redo = (pageNo: number, point: { x: number; y: number } | null) => {
    HightLight(pageNo, point);
  };
  const Undo = async (param: Api.UndoHightLightParam) => {
    await UndoHightLight(param);
    LoadLeftPanelData();
  };
  return { Do };
};
export const UndoHightLight = async (param: Api.UndoHightLightParam) => {
  const { LoadLeftPanelData } = LeftPanelService();
  const { SetDataCurrentPage } = PageService();
  const res = await HighlightApi.apiLegalxtractUndoHightLightPost(param);
  SetDataCurrentPage(res.data!, AppCache.CurrentPageNo || 0);
  LoadLeftPanelData(["pnFind", "pnWatchDog", "pnLog"]);
};

import { Checkbox, Col, Row, Tree, TreeDataNode, TreeProps } from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";
import { useLang } from "../../../i18n/useLang";
import { TagSearchPanelService } from "../../../Services/Hook/Search/TagSearchPanelService";
export const TagSearchPanel = () => {
  const { tr } = useLang();
  const {
    SearchTabState,
    SetSearchTabStore,
    TreeTag,
    onCheckTagNode,
    CheckAll,
  } = TagSearchPanelService();

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col style={{ height: 250 }} md={24}>
          <Tree
            checkable
            checkedKeys={
              SearchTabState.SearchTabVm?.searchTagParam?.selectedTags || []
            }
            onCheck={onCheckTagNode}
            treeData={TreeTag}
            height={250}
          />
        </Col>
        <Col md={12}>
          <Checkbox
            checked={SearchTabState.SearchTabVm?.searchTagParam?.wholeWord}
            onChange={(e) => {
              SetSearchTabStore({
                ...SearchTabState,
                SearchTabVm: {
                  ...SearchTabState.SearchTabVm,
                  searchTagParam: {
                    ...SearchTabState.SearchTabVm?.searchTagParam,
                    wholeWord: e.target.checked,
                  },
                },
              });
            }}
          >
            {tr("matchWholeWord")}
          </Checkbox>
        </Col>
        <Col md={12}>
          <Checkbox
            checked={SearchTabState.SearchTabVm?.searchTagParam?.checkAll}
            onChange={(e) => {
              CheckAll(e.target.checked);
            }}
          >
            {tr("checkAll")}
          </Checkbox>
        </Col>
        <Col md={12}>
          <Checkbox
            checked={SearchTabState.SearchTabVm?.searchTagParam?.matchCase}
            onChange={(e) => {
              SetSearchTabStore({
                ...SearchTabState,
                SearchTabVm: {
                  ...SearchTabState.SearchTabVm,
                  searchTagParam: {
                    ...SearchTabState.SearchTabVm?.searchTagParam,
                    matchCase: e.target.checked,
                  },
                },
              });
            }}
          >
            {tr("matchCase")}
          </Checkbox>
        </Col>
        <Col md={12}>
          <Checkbox
            checked={
              SearchTabState.SearchTabVm?.searchTagParam?.highlightWholeWord
            }
            onChange={(e) => {
              SetSearchTabStore({
                ...SearchTabState,
                SearchTabVm: {
                  ...SearchTabState.SearchTabVm,
                  searchTagParam: {
                    ...SearchTabState.SearchTabVm?.searchTagParam,
                    highlightWholeWord: e.target.checked,
                  },
                },
              });
            }}
          >
            {tr("selectEntireWord")}
          </Checkbox>
        </Col>
      </Row>
    </>
  );
};

import { FileDialog } from "../../Common/FileDialog";

import * as Api from "../../Api/src/index";
import { AppCache } from "../../Cache/AppCache";
import { PageService } from "../NotHook/PageService";
import UseConfirmDialogStore from "../../ZustandStore/ConfirmDialogStore";
import { useLang } from "../../i18n/useLang";
import { AppLocalStorage } from "../../Constant/AppContant";
import useLoaddingAppStore from "../../ZustandStore/LoaddingAppStore";
import UseModalStore, { ModaType } from "../../ZustandStore/ModalStore";
import { SaveMode, SaveType } from "../../Modal/MDSaveFile";
import { LeftPanelService } from "./LeftPanel/LeftPanelService";
import useSearchTabStore from "../../ZustandStore/SearchTabStore";
import useLeftPanelStore from "../../ZustandStore/LeftPanelStore";
const OpenApi = new Api.OpenApi();
const DocumentApi = new Api.DocumentApi();
export const OpenFileService = () => {
  const { tr } = useLang();
  const { GetDataCurrentPage } = PageService();
  const { SetLoaddingAppStore } = useLoaddingAppStore();
  const { LeftPanelState } = useLeftPanelStore();
  const { SearchTabState, SetSearchTabStore } = useSearchTabStore();
  const { LoadLeftPanelData } = LeftPanelService();
  const { ConfirmDialogState, SetConfirmDialogState } = UseConfirmDialogStore();
  const { SetModalState } = UseModalStore();
  const OpenFiles = async (forceOpen: boolean) => {
    FileDialog.ShowFileTypeSupportDialog().then(async (files) => {
      if (!files || files.length == 0) return;
      if (forceOpen) {
        if ((AppCache.DocumentInfo?.tabDetails?.length || 0) > 0) {
          SetConfirmDialogState({
            Show: true,
            Type: "Confirm",
            Msg: tr("openNewFilesWillClearCurrentFile"),
            OnYes: () => {
              const docChange = localStorage.getItem(
                AppLocalStorage.DocumentHasChanged
              );
              if ((docChange || "").toLocaleLowerCase() == "true") {
                AppCache.SaveParam = {
                  afterSave: () => {
                    CallOpenFilesAPI(files);
                  },
                  saveMode: SaveMode.SaveFileXtract,
                  saveType: SaveType.SaveExtract,
                };
                SetConfirmDialogState({
                  Show: true,
                  Type: "ConfirmYesNoCancel",
                  Msg: tr("doYouWantToSaveChanges"),
                  YesText: tr("yes"),
                  OnYes: () => {
                    SetModalState({
                      Show: true,
                      Type: ModaType.MDSaveFile,
                      Status: "Edit",
                    });
                  },
                  OnNo: () => {
                    CallOpenFilesAPI(files);
                  },
                });
              } else {
                CallOpenFilesAPI(files);
              }
            },
          });
          return;
        }
        CallOpenFilesAPI(files);
      } else {
        if ((AppCache.DocumentInfo?.tabDetails?.length || 0) > 0) {
          CallAppendFiles(files);
        } else {
          CallOpenFilesAPI(files);
        }
      }
    });
  };
  const CallAppendFiles = async (files: File[]) => {
    SetLoaddingAppStore({ Show: true });
    var res = await OpenApi.apiLegalxtractAppendFilesPost(files);
    if (res.data.result) {
      var file = await DocumentApi.apiLegalxtractGetDocumentFromCacheGet({
        responseType: "blob",
      });
      if (file)
        AppCache.WebViewerInstance?.Core.documentViewer.loadDocument(file.data);
      GetDataCurrentPage(1);
      LoadLeftPanelData([]);
    }
  };
  const CallOpenFilesAPI = async (files: File[]) => {
    SetLoaddingAppStore({ Show: true });
    var res = await OpenApi.apiLegalxtractOpenFilesPost(files);
    if (res.data.result) {
      var file = await DocumentApi.apiLegalxtractGetDocumentFromCacheGet({
        responseType: "blob",
      });
      if (file)
        AppCache.WebViewerInstance?.Core.documentViewer.loadDocument(file.data);
      GetDataCurrentPage(1);
      if (
        SearchTabState.SearchTabVm?.autoSearch &&
        LeftPanelState.LeftTabActive === "pnFind"
      ) {
        SetSearchTabStore({ runSearch: true });
      } else if (LeftPanelState.LeftTabActive !== "pnFind") {
        LoadLeftPanelData([]);
      } else {
        SetSearchTabStore({ clearSearch: true });
      }
    }
  };
  return {
    OpenFiles,
  };
};

import UseMainViewService from "../../Services/Hook/MainPanel/MainViewService";
import React from "react";
import { MenuContext } from "./MenuContext";

export const MainView = () => {
  const { viewer, menuContextState, MenuContextVisibilityChange, page } =
    UseMainViewService();
  page && document.body.appendChild(page);
  return (
    <>
      <div
        className="webviewer"
        ref={viewer}
        style={{ height: "calc(100% - 60px)" }}
      >
        <MenuContext
          ShowMenuContext={menuContextState?.show || false}
          onVisibilityChange={MenuContextVisibilityChange}
        ></MenuContext>
      </div>
    </>
  );
};

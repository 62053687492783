import { useEffect, useRef, useState } from "react";
import * as Api from "../../../Api/src/index";
import { v4 } from "uuid";
import { FormikErrors, useFormik } from "formik";
import * as yup from "yup";
import { useLang } from "../../../i18n/useLang";
import UseConfirmDialogStore from "../../../ZustandStore/ConfirmDialogStore";
import { AddTagNameParam } from "../../../Api/src/index";
import { PageNumberingCommand } from "../../../UndoRedo/Commands/PageNumberingCommand";
import { AppLocalStorage } from "../../../Constant/AppContant";
import { AppCache } from "../../../Cache/AppCache";
import { App } from "antd";
import { LeftPanelService } from "../LeftPanel/LeftPanelService";
import { PageService } from "../../NotHook/PageService";
import { EditLineCommand } from "../../../UndoRedo/Commands/Line/EditLineCommand";
const FontApi = new Api.FontApi();
const NoteApi = new Api.NoteApi();
export interface MDChangeLineColorProps {
  open?: boolean;
  onClose?: () => void;
  line?: Api.LineAnnotation | null;
}
export const MDChangeLineColorService = ({ onClose, line }: MDChangeLineColorProps) => {
  const editLineCommand = EditLineCommand();
  const GetInitDataForm = (): Api.LineAnnotation => {
    return line!;
  };
  const formik = useFormik({
    initialValues: GetInitDataForm(),
    onSubmit: async (values) => {
      editLineCommand.Do(AppCache.CurrentPageNo, {
        color: values.color,
        id: values.id,
      });
      onClose && onClose();
    },
  });
  return { formik };
};

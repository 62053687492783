import UndoRedoManagerObj from "../../UndoRedomanager";
import * as Api from "../../../Api/src/index";
import { AppCache } from "../../../Cache/AppCache";
import { PageService } from "../../../Services/NotHook/PageService";
import lib from "@ant-design/icons";
import { App } from "antd";
const LineApi = new Api.LineApi();
export const EditLineCommand = () => {
  const { DrawLineAnnotation } = PageService();
  const EditLine = async (pageNo: number, param: Api.AddLineParam | null) => {
    if (!param) return;
    const line = AppCache.DataCurrentPage?.lineAnnotations?.find(
      (o) => o.id === param.id
    )!;
    console.log(AppCache.LineBorderWidth);
    if (param.borderWidth)
      AppCache.LineBorderWidth = {
        colorAdd: AppCache.LineBorderWidth?.colorAdd,
        borderAdd: param.borderWidth,
      };
    if (param.color) {
      AppCache.LineBorderWidth = {
        borderAdd: AppCache.LineBorderWidth?.borderAdd,
        colorAdd: param.color,
      };
    }

    var res = await LineApi.apiLegalxtractUpdateLinePost(pageNo, {
      borderWidth: param.borderWidth || line?.borderWidth,
      color: param.color || line?.color,
      height: param.height || line.height,
      id: line.id,
      left: param.left || line.left,
      top: param.top || line.top,
      width: param.width || line.width,
    });
    if (AppCache.CurrentPageNo === pageNo) {
      AppCache.DataCurrentPage = res.data.result?.pageData || null;
      DrawLineAnnotation(pageNo || 0);
    }
    return { line, pageNo: AppCache.CurrentPageNo };
  };
  const Do = async (pageNo: number, param: Api.AddLineParam | null) => {
    var res = await EditLine(pageNo, param);
    UndoRedoManagerObj.add({
      Redo: () => {
        Redo(pageNo, param);
      },
      Undo: () => {
        Undo(res?.pageNo!, res?.line!);
      },
    }); //add undo redo
  };
  const Redo = (pageNo: number, param: Api.AddLineParam | null) => {
    EditLine(pageNo, param);
  };
  const Undo = async (pageNo: number, line: Api.LineAnnotation | null) => {
    var res = await LineApi.apiLegalxtractUpdateLinePost(pageNo, {
      borderWidth: line?.borderWidth,
      color: line?.color,
      height: line?.height,
      id: line?.id,
      left: line?.left,
      top: line?.top,
      width: line?.width,
    });
    if (AppCache.CurrentPageNo === pageNo) {
      AppCache.DataCurrentPage = res.data.result?.pageData || null;
      DrawLineAnnotation(pageNo || 0);
    }
  };
  return { Do };
};

import { Core, WebViewerInstance } from "@pdftron/webviewer";
import * as Api from "../Api/src/index";
import { ToolMode } from "../Constant/AppEnum";
import { LeftTabName } from "../ZustandStore/LeftPanelStore";
import { ThumbnailPage } from "../Services/Hook/LeftPanel/ThumbnailPanelService";
import { SaveMode, SaveType } from "../Modal/MDSaveFile";
import { after } from "node:test";
export const AppCache = {
  FileTypeSupport: null as any | null,
  FileInputAccept: "" as string,
  ThumbnailPages: [] as ThumbnailPage[],
  WebViewerInstance: null as WebViewerInstance | null,
  AnnotationSelected: null as Core.Annotations.Annotation | null,
  DocumentInfo: null as Api.DocumentInfo | null,
  DataCurrentPage: null as Api.LxPageData | null,
  LastWordFocus: null as Api.LxWordResponse | null,
  WordSelected: null as Api.LxWordResponse | null,
  LastMousePositionServer: { x: 0, y: 0 } as { x: number; y: number },
  CurrentPageNo: 0 as number,
  OldCurrentPageNo: 0 as number,
  WordTagsFocus: [] as Api.LxWordResponse[],
  CurrentTabId: null as string | null,
  LeftTabActive: LeftTabName.pnFind as LeftTabName,
  ToolMode: ToolMode.Select as ToolMode,
  PageWidth: 0 as number,
  ReplaceObjects: { boxs: [], replaceObjs: [] } as Api.ReplaceObjects,
  LineBorderWidth: {
    borderAdd: 0.02 * 72,
    colorAdd: "#323130",
  } as {
    borderAdd?: number;
    colorAdd?: string;
  } | null,
  CurrentPageObject: () => {
    return (
      AppCache.CurrentTab()?.pages?.find(
        (o) => o.viewerPageNo == AppCache.CurrentPageNo
      ) || null
    );
  },
  CurrentTab: () => {
    return (
      AppCache.DocumentInfo?.tabDetails?.find(
        (o) => o.uniqueId == AppCache.CurrentTabId
      ) || null
    );
  },
  GetLxPage: (pageNo: number) => {
    const page = AppCache.DocumentInfo?.tabDetails
      ?.flatMap((o) => o.pages)
      .find((o) => o?.viewerPageNo == pageNo);
    return page;
  },
  SaveParam: {
    saveMode: SaveMode.Extract,
    saveType: SaveType.SaveExtract,
    SavePdfType: Api.PdfFileType.Pdf,
    afterSave: null,
  } as {
    saveMode: SaveMode;
    saveType: SaveType;
    SavePdfType?: Api.PdfFileType;
    afterSave: (() => void) | null;
  },
};
export const AppTools = {
  BoxTool: null as Core.Tools.Tool | null,
  SectionTool: null as Core.Tools.Tool | null,
  SelectTool: null as Core.Tools.Tool | null,
  MaskTool: null as Core.Tools.Tool | null,
};

import UndoRedoManagerObj from "../UndoRedomanager";
import * as Api from "../../Api/src/index";
import { AppCache } from "../../Cache/AppCache";
import { PageService } from "../../Services/NotHook/PageService";
import { AppLocalStorage } from "../../Constant/AppContant";
import { LeftPanelService } from "../../Services/Hook/LeftPanel/LeftPanelService";
const HighlightApi = new Api.HighlightApi();
export const HightLightPageCommand = () => {
  const { LoadLeftPanelData } = LeftPanelService();
  const { SetDataCurrentPage } = PageService();
  const HightLight = async (pageNo: number, mode: Api.HighLightMode) => {
    if (!mode) return;
    localStorage.setItem(AppLocalStorage.DocumentHasChanged, "true");
    var res = await HighlightApi.apiLegalxtractHighLightPagePost({
      viewerPageNo: pageNo,
      mode: mode,
    });
    if (AppCache.CurrentPageNo === pageNo) {
      AppCache.DataCurrentPage = res.data.lxPageData || null;
      SetDataCurrentPage(res.data.lxPageData!, AppCache.CurrentPageNo || 0);
    }
    LoadLeftPanelData(["pnFind", "pnWatchDog", "pnLog"]);
    return res;
  };
  const Do = async (pageNo: number, mode: Api.HighLightMode) => {
    if (!mode) return;
    const res = await HightLight(pageNo, mode);
    UndoRedoManagerObj.add({
      Redo: () => {
        Redo(pageNo, mode);
      },
      Undo: () => {
        Undo({
          preState: JSON.stringify(res?.data.dicPreState),
          viewerPageNo: pageNo,
        });
      },
    });
  };
  const Redo = (pageNo: number, mode: Api.HighLightMode) => {
    HightLight(pageNo, mode);
  };
  const Undo = async (param: Api.UndoHightLightParam) => {
    await UndoHightLight(param);
  };
  return { Do };
};
export const UndoHightLight = async (param: Api.UndoHightLightParam) => {
  const { SetDataCurrentPage } = PageService();
  const res = await HighlightApi.apiLegalxtractUndoHightLightPost(param);
  SetDataCurrentPage(res.data!, AppCache.CurrentPageNo || 0);
};

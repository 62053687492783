import * as React from "react";
import "../Assets/Styles/IconButtonTopMenu.css";
export const IconButtonTopMenu = (props: {
  onClick: (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  icon: string;
  text: string;
  disabled?: boolean;
  className?: string;
}) => {
  return (
    <a
      className={`btn-icon-menu ${props.className || ""}`}
      style={{
        pointerEvents: props.disabled ? "none" : "auto",
        backgroundColor: props.disabled ? "#ececec" : "#fff",
      }}
      onClick={(e) => {
        props.onClick(e);
      }}
    >
      <img src={props.icon}></img>
      <div>{props.text}</div>
    </a>
  );
};

import React, { useState, useEffect, useContext } from "react";
import * as Api from "../../../Api/src";
import { useLang } from "../../../i18n/useLang";
import { Button, Col, Row, Select } from "antd";
import { userService } from "../../../Services/NotHook/UserService";
import Moment from "react-moment";
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  EnterOutlined,
  FileTextOutlined,
  UpOutlined,
} from "@ant-design/icons";
import useCommentStore from "../../../ZustandStore/CommentStore";
import "../../../Assets/Styles/LeftPanels/CommentPanel.scss";
import { CommentService } from "../../../Services/NotHook/CommentService";
import MDComment from "../../../Modal/MDComment";
import UseConfirmDialogStore from "../../../ZustandStore/ConfirmDialogStore";
import { AppCache } from "../../../Cache/AppCache";
import { PageService } from "../../../Services/NotHook/PageService";
import { LeftPanelService } from "../../../Services/Hook/LeftPanel/LeftPanelService";
export const CommentPanel = () => {
  const { SetCommentStore, CommentState } = useCommentStore();
  const { deleteComment } = CommentService();
  const { tr } = useLang();
  const [ExpandAllContent, SetExpandAllContent] = useState(true);
  const { SetConfirmDialogState } = UseConfirmDialogStore();
  const { DrawRectangleFocusWord } = PageService();
  const { LoadCommentData } = LeftPanelService();
  const [ShowMDComment, SetShowMDComment] = useState<{
    open: boolean;
    editItem: Api.CommentObjDisplayWeb | null;
    parentId: string | null;
    pageNo?: number;
  }>({ open: false, editItem: null, parentId: null });
  const Expand_Click = () => {
    SetExpandAllContent(!ExpandAllContent);
  };
  const Edit_click = (data: Api.CommentObjDisplayWeb | null) => {
    SetShowMDComment({
      open: true,
      editItem: data,
      parentId: null,
      pageNo: data?.commentObjDisplay?.pageNo,
    });
  };
  const item_click = (parentId?: string | null) => {
    if (!AppCache.WebViewerInstance?.Core) return;
    const { annotationManager } = AppCache.WebViewerInstance.Core;
    const commentParent = CommentState.CommentObjDisplayWebs?.find(
      (o) => o.commentObjDisplay?.id == parentId
    );
    if (commentParent) {
      if (commentParent.commentObjDisplay?.commentTypeEnum === (1 as any)) {
        const anno = DrawRectangleFocusWord(
          commentParent.rectangleFs || [],
          commentParent.commentObjDisplay?.pageNo || 0
        );
        if (anno) annotationManager.jumpToAnnotation(anno);
      } else {
        const annos = annotationManager.getAnnotationsList();
        const anno = annos.find(
          (o) =>
            o.getCustomData("boxId") === commentParent.commentObjDisplay?.boxId
        );
        if (anno) annotationManager.jumpToAnnotation(anno);
      }
    }
  };
  const onReplyComment = (
    commentParentId?: string | null,
    data?: Api.CommentObjDisplayWeb | null
  ) => {
    SetShowMDComment({
      open: true,
      editItem: null,
      parentId: commentParentId!,
      pageNo: data?.commentObjDisplay?.pageNo,
    });
  };
  const onDeleteComment = (data: Api.CommentObjDisplay | null) => {
    const DeleteComment = async () => {
      deleteComment(data?.id || "", data?.pageNo || 0);
      SetCommentStore({
        CommentObjDisplayWebs: CommentState.CommentObjDisplayWebs?.filter(
          (o) => o.commentObjDisplay?.id !== data?.id
        ),
      });
    };
    SetConfirmDialogState({
      Show: true,
      Type: "Confirm",
      Icon: "Question",
      CancelText: tr("no"),
      YesText: tr("yes"),
      Msg: tr("doYouWantToDelete"),
      OnYes: () => {
        DeleteComment();
      },
    });
  };
  const CommentOrder = [
    { value: Api.CommentOrderByEnum.Page, label: tr("page") },
    { value: Api.CommentOrderByEnum.Author, label: tr("author") },
    { value: Api.CommentOrderByEnum.Date, label: tr("date") },
  ];

  return (
    <div className="left-panel-container comment-pn">
      <Row gutter={[8, 8]}>
        <Col className="header-panel" span={24}>
          {tr("comment")}
        </Col>
        <Col span={24}>
          <div style={{ display: "flex" }}>
            <label style={{ marginRight: "8px", alignContent: "center" }}>
              {tr("sortBy")}
            </label>
            <div style={{ flex: "1" }}>
              <Select
                style={{ width: "100%" }}
                value={CommentState.OrderBy || 0}
                options={CommentOrder}
                onChange={(value, option) => {
                  SetCommentStore({ OrderBy: value as Api.CommentOrderByEnum });
                  LoadCommentData(value as Api.CommentOrderByEnum);
                }}
              ></Select>
            </div>
            <div
              style={{ marginLeft: "8px", alignContent: "center", width: 43 }}
            >
              <Button
                style={{ height: 28 }}
                onClick={() => {
                  Expand_Click();
                }}
              >
                {(ExpandAllContent && <DownOutlined />) || <UpOutlined />}
              </Button>
            </div>
          </div>
        </Col>
        <Col span={24}>
          {CommentState.CommentObjDisplayWebs?.filter(
            (o) => !o.commentObjDisplay?.parentId
          ).map((el) => (
            <CommemtItem
              key={el.commentObjDisplay?.id}
              Edit_click={Edit_click}
              onReplyComment={onReplyComment}
              item_click={item_click}
              DataComment={el}
              ChildComments={
                el.commentObjDisplay?.parentId
                  ? []
                  : CommentState.CommentObjDisplayWebs?.filter(
                      (o) =>
                        o.commentObjDisplay?.parentId ==
                        el.commentObjDisplay?.id
                    )
              }
              ExpandAllContent={ExpandAllContent}
              onDeleteComment={onDeleteComment}
            ></CommemtItem>
          ))}
        </Col>
      </Row>
      {ShowMDComment.open && (
        <MDComment
          editItem={ShowMDComment.editItem}
          open={ShowMDComment.open}
          onClose={() => {
            SetShowMDComment({ ...ShowMDComment, open: false });
          }}
          parentId={ShowMDComment.parentId}
          pageNo={ShowMDComment.pageNo}
        ></MDComment>
      )}
    </div>
  );
};
interface CommentItemInterface {
  DataComment: Api.CommentObjDisplayWeb | null;
  ChildComments?: Api.CommentObjDisplayWeb[];
  ExpandAllContent: boolean;
  Edit_click: (data: Api.CommentObjDisplayWeb | null) => void;
  item_click: (parentId?: string) => void;
  onReplyComment: (
    parentId?: string | null,
    data?: Api.CommentObjDisplayWeb | null
  ) => void;
  onDeleteComment: (item: Api.CommentObjDisplay | null) => void;
}
const CommemtItem = ({
  DataComment,
  Edit_click,
  onReplyComment,
  item_click,
  ExpandAllContent,
  ChildComments,
  onDeleteComment,
}: CommentItemInterface) => {
  const [ExpandContent, SetExpandContent] = useState(ExpandAllContent);
  const { CommentState, SetCommentStore } = useCommentStore();
  const { editComment } = CommentService();
  const { tr } = useLang();
  const Expand_Click = (Expand: boolean) => {
    SetExpandContent(Expand);
  };
  useEffect(() => {
    SetExpandContent(ExpandAllContent);
  }, [ExpandAllContent]);
  const statusCommentOptions = [
    {
      value: 0,
      label: tr("none"),
    },
    {
      value: 1,
      label: tr("approved"),
    },
    {
      value: 2,
      label: tr("rejected"),
    },
  ];
  const ChangeStatus = async (
    value: Api.CommentStatusEnum,
    comment: Api.CommentObjDisplayWeb | null
  ) => {
    if (!comment) return;
    await editComment(
      {
        author: comment.commentObjDisplay?.author,
        boxId: comment.commentObjDisplay?.boxId,
        comment: comment.commentObjDisplay?.comment,
        id: comment.commentObjDisplay?.id,
        parentId: comment.commentObjDisplay?.parentId,
        status: value,
        type: comment.commentObjDisplay?.commentTypeEnum,
      },
      comment.commentObjDisplay?.pageNo || 0
    );
    SetCommentStore({
      CommentObjDisplayWebs:
        CommentState.CommentObjDisplayWebs?.map((o) => {
          if (o.commentObjDisplay?.id === comment.commentObjDisplay?.id) {
            return {
              ...o,
              commentObjDisplay: { ...o.commentObjDisplay, status: value },
            };
          }
          return o;
        }) || [],
    });
  };
  return (
    <>
      <div className="comment-item">
        <div
          className={`comment ${
            DataComment?.commentObjDisplay?.parentId
              ? "comment-child"
              : " comment-parent"
          }`}
          onClick={() => {
            item_click(DataComment?.commentObjDisplay?.id || "");
          }}
        >
          <div
            className={"comment-header status-Finished"}
            style={{ backgroundColor: DataComment?.commentObjDisplay?.color }}
          >
            <div
              className={"comment-toggle"}
              onClick={() => {
                Expand_Click(!ExpandContent);
              }}
            >
              {(ExpandContent && <DownOutlined />) || <UpOutlined />}
            </div>
            <div className={"comment-user"}>
              {DataComment?.commentObjDisplay?.author || ""}
            </div>
            <div className={"comment-date"}>
              <Moment format="DD/MM/YYYY hh:mm A">
                {DataComment?.commentObjDisplay?.createdDate}
              </Moment>
            </div>
          </div>
          <div
            style={{ display: ExpandContent ? "block" : "none" }}
            className={"comment-body"}
          >
            {DataComment?.commentObjDisplay?.comment}
          </div>
          <div
            style={{ display: ExpandContent ? "flex" : "none" }}
            className={"comment-footer"}
          >
            <div className="type">
              <FileTextOutlined />
              <label>
                &nbsp;
                {tr("page") + " " + DataComment?.commentObjDisplay?.pageNo}
              </label>
            </div>
            <div style={{ flex: 1 }}></div>
            <div className="action">
              <Select
                style={{ width: 125 }}
                value={DataComment?.commentObjDisplay?.status}
                options={statusCommentOptions}
                onChange={(value, option) => {
                  ChangeStatus(
                    value as Api.CommentStatusEnum,
                    DataComment || null
                  );
                }}
              ></Select>
            </div>
            <div
              className="action"
              onClick={() => {
                if (DataComment)
                  onReplyComment(
                    DataComment?.commentObjDisplay?.parentId
                      ? DataComment?.commentObjDisplay?.parentId
                      : DataComment.commentObjDisplay?.id,
                    DataComment
                  );
              }}
            >
              <EnterOutlined />
            </div>

            <div
              className="action"
              onClick={() => {
                if (DataComment) Edit_click(DataComment || null);
              }}
            >
              <EditOutlined />
            </div>
            <div
              className="action"
              onClick={() => {
                if (DataComment)
                  onDeleteComment(DataComment.commentObjDisplay || null);
              }}
            >
              <DeleteOutlined />
            </div>
          </div>
        </div>
        {ChildComments?.map((element) => (
          <CommemtItemChild
            key={element.commentObjDisplay?.id}
            commentChild={element}
            Edit_click={Edit_click}
            onReplyComment={onReplyComment}
            item_click={() => {
              item_click(element?.commentObjDisplay?.parentId || "");
            }}
            Expand={ExpandContent}
            ExpandAllContent={ExpandContent}
            onDeleteComment={onDeleteComment}
          ></CommemtItemChild>
        ))}
      </div>
    </>
  );
};
interface CommemtItemChildProps {
  commentChild: Api.CommentObjDisplayWeb;
  Expand: boolean;
  ExpandAllContent: boolean;
  Edit_click: (data: Api.CommentObjDisplayWeb | null) => void;
  item_click: (parentId?: string) => void;
  onReplyComment: (
    parentId?: string | null,
    data?: Api.CommentObjDisplayWeb | null
  ) => void;
  onDeleteComment: (item: Api.CommentObjDisplay | null) => void;
}
const CommemtItemChild = ({
  commentChild,
  Expand,
  ExpandAllContent,
  Edit_click,
  item_click,
  onReplyComment,
  onDeleteComment,
}: CommemtItemChildProps) => {
  return (
    <>
      <CommemtItem
        key={commentChild.commentObjDisplay?.id}
        Edit_click={Edit_click}
        onReplyComment={onReplyComment}
        item_click={item_click}
        ExpandAllContent={ExpandAllContent || Expand}
        onDeleteComment={onDeleteComment}
        DataComment={commentChild}
      ></CommemtItem>
    </>
  );
};

import { useState } from "react";
import * as Api from "../../../Api/src/index";
import { v4 } from "uuid";
import useTagStore from "../../../ZustandStore/TagStore";
import { FormikErrors, useFormik } from "formik";
import * as yup from "yup";
import { useLang } from "../../../i18n/useLang";
import UseConfirmDialogStore from "../../../ZustandStore/ConfirmDialogStore";
import { AddTagNameParam } from "../../../Api/src/index";
import { AppCache } from "../../../Cache/AppCache";
import useTabFileInfoStore from "../../../ZustandStore/TabFileInfoStore";
import useTocStore from "../../../ZustandStore/TocStore";
import { PageService } from "../../NotHook/PageService";
import usePageStore from "../../../ZustandStore/PageStore";
const BlankFileApi = new Api.BlankFileApi();
export interface MDAddBlankPageProps {
  name: string;
  selectedTabIds: string[];
  open?: boolean;
  onClose?: () => void;
}
export const MDAddBlankPageService = ({
  onClose,
  name,
  selectedTabIds,
}: MDAddBlankPageProps) => {
  const { PageState, SetPageStore } = usePageStore();
  const { SetTabFileInfoState } = useTabFileInfoStore();
  const { SetTocStore } = useTocStore();
  const { InsertBlankPage } = PageService();
  const { tr } = useLang();
  const validationTagNameSchema = yup.object({
    name: yup.string().required(tr("pleaseSetName")),
  });
  const showErrors = (error: FormikErrors<{ name: string }>) => {
    if (error.name) {
      return <p>{error.name}</p>;
    }
  };
  const formik = useFormik({
    initialValues: {
      name: name,
    },
    validationSchema: validationTagNameSchema,
    onSubmit: async (values) => {
      BlankFileApi.apiLegalxtractCreateBlankFilePost({
        name: values.name,
        selectedTabIds: selectedTabIds,
      }).then((res) => {
        if (res && res.data) {
          const tabNews =
            res.data.documentInfo?.tabDetails?.filter(
              (o) =>
                AppCache.DocumentInfo?.tabDetails?.findIndex(
                  (p) => p.uniqueId === o.uniqueId
                ) === -1
            ) || [];
          AppCache.DocumentInfo = res.data.documentInfo || null;
          SetPageStore({
            TotalPages: res.data.documentInfo?.totalPage || 0,
          });
          SetTocStore({
            TocRows: res.data.tocRows || [],
          });
         const CurrentTab = res.data.documentInfo?.tabDetails?.find((o) =>
            o.pages?.some((o) => o.viewerPageNo === AppCache.CurrentPageNo)
          );
          SetTabFileInfoState({
            TabDetails: res.data.documentInfo?.tabDetails || [],
            CurrentTab: CurrentTab || null,
          });
          tabNews
            .flatMap((o) => o.pages || [])
            .forEach((o) => {
              InsertBlankPage(o.viewerPageNo || 0, 8.27 * 72, 11.69 * 72);
            });
          if (!AppCache.WebViewerInstance?.Core) return;
          const { documentViewer } = AppCache.WebViewerInstance?.Core;
          documentViewer.refreshAll();
        }
        onClose && onClose();
      });
    },
  });
  return { formik, showErrors };
};

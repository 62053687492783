import { create } from "zustand";
import * as Api from "../Api/src/index";
interface WatchdogState {
  WatchDogParamWeb?: Api.WatchDogParamWeb;
  WatchdogDatas?: Api.WatchDogNode[];
  expandedRowKeys?: string[];
}
interface WatchdogStore {
  WatchDogParamWeb?: Api.WatchDogParamWeb;
  WatchdogDatas?: Api.WatchDogNode[];
  expandedRowKeys?: string[];
  setExpandedRowKeys: (expandedRowKeys: string[]) => void;
  SetWatchDogParamWeb: (WatchDogParamWeb: Api.WatchDogParamWeb) => void;
  SetWatchdogDatas: (WatchdogDatas: Api.WatchDogNode[]) => void;
}
const useWatchdogStore = create<WatchdogStore>()((set) => ({
  WatchdogDatas: [] as Api.WatchDogNode[],
  WatchDogParamWeb: {
    combine: false,
    matchCase: false,
    wholeWord: false,
    orderBy: "",
  },
  expandedRowKeys: [] as string[],
  setExpandedRowKeys(expandedRowKeys) {
    set((state) => {
      return {
        ...state,
        expandedRowKeys: expandedRowKeys,
      };
    });
  },
  SetWatchDogParamWeb: (watchDogParamWeb) =>
    set((state) => {
      return {
        ...state,
        WatchDogParamWeb: { ...state.WatchDogParamWeb, ...watchDogParamWeb },
      };
    }),
  SetWatchdogDatas: (watchdogDatas) =>
    set((state) => {
      return {
        ...state,
        WatchdogDatas: watchdogDatas,
      };
    }),
}));

export default useWatchdogStore;

import React, { useState } from "react";
import { Button, Input, Tree, TreeDataNode, TreeProps } from "antd";
import "../../../Assets/Styles/LeftPanels/TocPanel.scss";
import * as Api from "../../../Api/src/index";
import { useLang } from "../../../i18n/useLang";
import { TocPanelService } from "../../../Services/Hook/LeftPanel/TocPanelService";
import TreeToc from "./tree-Toc";
export const TocPanel = () => {
  const { tr } = useLang();

  return (
    <div className="toc-panel">
      <div className="header-panel">{tr("toc")}</div>
      <TreeToc
      ></TreeToc>
    </div>
  );
};

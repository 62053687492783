import { useEffect, useRef, useState } from "react";
import * as Api from "../../../Api/src/index";
import { v4 } from "uuid";
import { FormikErrors, useFormik } from "formik";
import * as yup from "yup";
import { useLang } from "../../../i18n/useLang";
import UseConfirmDialogStore from "../../../ZustandStore/ConfirmDialogStore";
import { AddTagNameParam } from "../../../Api/src/index";
import { PageNumberingCommand } from "../../../UndoRedo/Commands/PageNumberingCommand";
import { AppLocalStorage } from "../../../Constant/AppContant";
import { AppCache } from "../../../Cache/AppCache";
import { App } from "antd";
import { LeftPanelService } from "../LeftPanel/LeftPanelService";
import { PageService } from "../../NotHook/PageService";
const FontApi = new Api.FontApi();
const NoteApi = new Api.NoteApi();
export interface MDNoteProps {
  open?: boolean;
  onClose?: () => void;
  editItem?: Api.NoteTextObj | null;
}
export const MDNoteService = ({ onClose, editItem }: MDNoteProps) => {
  const { LoadNoteData } = LeftPanelService();
  const { SetDataCurrentPage } = PageService();
  const { tr } = useLang();
  const [fontList, setFontList] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);
  const InitCheck = useRef<boolean>(false);
  useEffect(() => {
    if (InitCheck.current) return;
    GetFontList();
    InitCheck.current = true;
  }, []);
  const GetInitDataForm = (): Api.NoteTextParam => {
    if (editItem)
      return {
        fontSize: editItem.fontSize,
        fontName: editItem.fontName,
        opacity: editItem.opacity,
        backColor: editItem.backColor,
        foreColor: editItem.foreColor,
        visibleOnExtract: editItem.visibleOnExtract,
        id: editItem.id,
        noteText: editItem.noteText,
        rectangleF: editItem.rectangleF,
      } as Api.NoteTextParam;

    return defaultNoteParam;
  };
  const formik = useFormik({
    initialValues: GetInitDataForm(),
    onSubmit: async (values) => {
      if (!editItem) {
        values.pageNo = AppCache.CurrentPageNo;
        values.rectangleF = {
          y: AppCache.LastMousePositionServer.y,
          x: AppCache.LastMousePositionServer.x,
          height: 0,
          width: 0,
        };
        const res = await NoteApi.apiLegalxtractAddNotePost(values);
        SetDataCurrentPage(
          res.data.result?.pageData!,
          AppCache.CurrentPageNo || 0
        );
      } else {
        const res = await NoteApi.apiLegalxtractEditNotePost(values);
        SetDataCurrentPage(
          res.data.result?.pageData!,
          AppCache.CurrentPageNo || 0
        );
      }
      if (AppCache.LeftTabActive === "pnNote") {
        LoadNoteData();
      }
      onClose && onClose();
    },
  });
  const GetFontList = () => {
    FontApi.apiLegalxtractGetListFontGet().then((res) => {
      if (res && res.data) {
        setFontList(res.data.map((item) => ({ value: item, label: item })));
      }
    });
  };
  const ResetForm = () => {
    formik.setValues(defaultNoteParam);
  };
  return { formik, fontList };
};
export const defaultNoteParam: Api.NoteTextParam = {
  fontSize: 12,
  fontName: "Arial",
  opacity: 50,
  backColor: "#fff",
  foreColor: "#000",
  visibleOnExtract: false,
};

import { useEffect, useRef, useState } from "react";
import * as Api from "../../../Api/src/index";
import { v4 } from "uuid";
import { FormikErrors, useFormik } from "formik";
import * as yup from "yup";
import { useLang } from "../../../i18n/useLang";
import UseConfirmDialogStore from "../../../ZustandStore/ConfirmDialogStore";
import { AddTagNameParam } from "../../../Api/src/index";
import { PageNumberingCommand } from "../../../UndoRedo/Commands/PageNumberingCommand";
import { AnnoSubject, AppLocalStorage } from "../../../Constant/AppContant";
import { AppCache } from "../../../Cache/AppCache";
import { App } from "antd";
import { LeftPanelService } from "../LeftPanel/LeftPanelService";
import { PageService } from "../../NotHook/PageService";
import { userService } from "../../NotHook/UserService";
import { CommentService } from "../../NotHook/CommentService";
const FontApi = new Api.FontApi();
const CommentApi = new Api.CommentApi();
export interface MDNoteProps {
  open?: boolean;
  onClose?: () => void;
  parentId?: string | null;
  pageNo?: number;
  editItem?: Api.CommentObjDisplayWeb | null;
}
export const MDCommentService = ({
  onClose,
  editItem,
  pageNo,
  parentId,
}: MDNoteProps) => {
  const { LoadNoteData } = LeftPanelService();
  const { LoadLeftPanelData } = LeftPanelService();
  const { getUser } = userService();
  const { tr } = useLang();
  const { editComment } = CommentService();
  const InitCheck = useRef<boolean>(false);
  useEffect(() => {
    if (InitCheck.current) return;
    InitCheck.current = true;
  }, []);
  const GetInitDataForm = (): Api.CommentParam => {
    if (editItem)
      return {
        author: editItem.commentObjDisplay?.author,
        boxId: editItem.commentObjDisplay?.boxId,
        comment: editItem.commentObjDisplay?.comment,
        id: editItem.commentObjDisplay?.id,
        parentId: editItem.commentObjDisplay?.parentId,
        status: editItem.commentObjDisplay?.status,
        type: editItem.commentObjDisplay?.commentTypeEnum,
      } as Api.CommentParam;

    return {
      author: getUser()?.userDataLogin?.result?.user_info?.fullName,
      comment: "",
      parentId: parentId,
      status: 0 as any,
      type:
        AppCache.AnnotationSelected?.Subject != AnnoSubject.box &&
        AppCache.AnnotationSelected?.Subject !== AnnoSubject.replacebox
          ? Api.CommentTypeEnum.Text
          : Api.CommentTypeEnum.Box,
    };
  };
  const formik = useFormik({
    initialValues: GetInitDataForm(),
    onSubmit: async (values) => {
      if (!editItem) {
        await AddComent(values);
      } else {
        await editComment(values, pageNo || 0);
      }
      setTimeout(() => {
        LoadLeftPanelData(["pnComment"]);
        onClose && onClose();
      }, 100);
    },
  });
  const AddComent = (comment: Api.CommentParam) => {
    if (comment?.parentId) {
      CommentApi.apiLegalxtractAddReplyCommentPut(
        comment?.parentId,
        pageNo,
        comment
      ).then((o) => {});
    } else {
      if (comment?.type === Api.CommentTypeEnum.Text) {
        CommentApi.apiLegalxtractAddTextCommentPut(
          AppCache.LastMousePositionServer.x,
          AppCache.LastMousePositionServer.y,
          AppCache.CurrentPageNo,
          comment
        ).then((o) => {});
      } else {
        const boxId = AppCache.AnnotationSelected?.getCustomData("boxId");
        CommentApi.apiLegalxtractAddBoxCommentPut(
          boxId || v4(),
          AppCache.CurrentPageNo,
          comment
        ).then((o) => {});
      }
    }
  };

  const statusCommentOptions = [
    {
      value: 0,
      label: tr("none"),
    },
    {
      value: 1,
      label: tr("approved"),
    },
    {
      value: 2,
      label: tr("rejected"),
    },
  ];
  return { formik, statusCommentOptions };
};

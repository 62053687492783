import { Core } from "@pdftron/webviewer";
import App from "../../App";
import { AppCache } from "../../Cache/AppCache";
import { GetTextSite, isPointInRectangle } from "../../Common/CommonFunction";
import * as Api from "../../Api/src/index";
import { AnnoSubject, SpecialChars } from "../../Constant/AppContant";
import { ColorHelper } from "../../Common/AppHelpers";
import { AnnotationManagerService } from "./AnnotationManagerService";
import { PdfTronService } from "./PdfTronService";
import { GetLxSetting } from "../Hook/Modal/MDAppSettingsService";
const PageApi = new Api.PageApi();
const ViewApi = new Api.ViewApi();
export const PageService = () => {
  const { GetAnnotationsList } = AnnotationManagerService();
  const {
    RectConvertToViewerCoordinates,
    GetAnnotationByMousePosition,
    RotatePages,
  } = PdfTronService();
  const GetDataCurrentPage = async (pageNo: number) => {
    const res = await PageApi.apiLegalxtractGetPageDataGet(pageNo);
    AppCache.DataCurrentPage = res.data.result || null;
  };
  const GetWordFocus: (
    pointX: number,
    pointY: number
  ) => Api.LxWordResponse | null = (pointX, pointY) => {
    if (AppCache.DataCurrentPage && AppCache.DataCurrentPage.words) {
      const word = AppCache.DataCurrentPage?.words?.find((o) => {
        let ok = false;
        o.rects?.forEach((r) => {
          ok = isPointInRectangle(
            pointX,
            pointY,
            r.x || 0,
            r.y || 0,
            r.width || 0,
            r.height || 0
          );
          if (ok) return;
        });
        return ok;
      });
      return word || null;
    }
    return null;
  };
  const RemoveAllChooseWordTag = async () => {
    if (!AppCache.WebViewerInstance?.Core) return;
    const { annotationManager } = AppCache.WebViewerInstance?.Core;
    const pageAnnotations = await annotationManager.getAnnotationsList();
    const annotagtemp = pageAnnotations.filter(
      (o) => o.Subject === AnnoSubject.tagtemp
    );
    annotationManager.deleteAnnotations(annotagtemp);
  };
  const DrawHighlightTextAnnotation = async (
    word: Api.LxWordResponse | null,
    pageNo: number
  ) => {
    if (!AppCache.WebViewerInstance?.Core || !word) return;
    const { annotationManager, Annotations, Math } =
      AppCache.WebViewerInstance?.Core;
    DeleteHighlighttemp(pageNo);
    if (!word || !AppCache.WebViewerInstance?.Core) {
      return;
    }
    const color = ColorHelper.colorStringToRGB("yellow");
    if (!color) return;
    const annot = new Annotations.TextHighlightAnnotation({
      PageNumber: pageNo,
      StrokeColor: new Annotations.Color(
        color?.r,
        color?.g,
        color?.b,
        color?.a
      ),
      Subject: AnnoSubject.highlighttemp,
    });
    const quads = [] as Core.Math.Quad[];

    word.rects?.forEach((r) => {
      if (!r) return;
      const newRect = RectConvertToViewerCoordinates(
        {
          bottom: r.bottom || 0,
          left: r.left || 0,
          right: r.right || 0,
          top: r.top || 0,
        },
        pageNo
      );
      const rect = new Math.Rect(
        newRect?.left || 0,
        newRect?.top || 0,
        newRect?.right || 0,
        newRect?.bottom || 0
      );
      quads.push(rect.toQuad());
    });
    annot.Quads = quads;
    annotationManager.addAnnotation(annot);
    annotationManager.redrawAnnotation(annot);
  };
  const DrawFocusWord = (word: Api.LxWordResponse | null, pageNo: number) => {
    if (!AppCache.WebViewerInstance?.Core || !word) return;
    DrawRectangleFocusWord(word.rects || [], pageNo);
  };
  const DrawRectangleFocusWord = (
    rectangleFs: Array<Api.RectangleF> | null,
    pageNo: number
  ) => {
    if (
      !AppCache.WebViewerInstance?.Core ||
      !rectangleFs ||
      rectangleFs.length === 0
    )
      return;
    const { annotationManager, Annotations, Math, documentViewer } =
      AppCache.WebViewerInstance?.Core;
    DeleteHighlighttemp(pageNo);
    const annot = new Annotations.TextHighlightAnnotation({
      PageNumber: pageNo,
      StrokeColor: new Annotations.Color(188, 188, 188, 1),
      Subject: AnnoSubject.focushighlighttemp,
    });
    const quads = [] as Core.Math.Quad[];
    rectangleFs?.forEach((r) => {
      if (!r) return;
      const newRect = RectConvertToViewerCoordinates(
        {
          bottom: r.bottom || 0,
          left: r.left || 0,
          right: r.right || 0,
          top: r.top || 0,
        },
        pageNo
      );
      const rect = new Math.Rect(
        newRect?.left,
        newRect?.top,
        newRect?.right,
        newRect?.bottom
      );
      quads.push(rect.toQuad());
    });
    annot.Quads = quads;
    annot.NoResize = true;
    annotationManager.addAnnotation(annot);
    annotationManager.redrawAnnotation(annot);
    return annot;
  };
  const DrawFocusWordSearchResults = (searchResult: Api.SearchResult) => {
    if (!AppCache.WebViewerInstance?.Core) return;
    const { annotationManager, Annotations, Math, documentViewer } =
      AppCache.WebViewerInstance?.Core;
    const anno = DrawRectangleFocusWord(
      searchResult.rectangleFs || [],
      searchResult.viewerPageNo || 0
    );
    if (anno) annotationManager.jumpToAnnotation(anno);
  };
  const getSubject = (rect: Api.RectangeWithColor) => {
    let subject = AnnoSubject.highlight;
    if (rect.tagId) {
      subject = AnnoSubject.tag;
    }
    if (rect.replaceObjId) {
      subject = AnnoSubject.replace;
    }
    return subject;
  };
  const DrawAnnotationOnPage = async (pageNo: number) => {
    if (!AppCache.WebViewerInstance?.Core) return;
    const { annotationManager, Annotations, Math } =
      AppCache.WebViewerInstance?.Core;
    const pageAnnotations = GetAnnotationsList(pageNo);
    const annos = pageAnnotations.filter(
      (o) =>
        o.Subject !== AnnoSubject.focushighlighttemp &&
        o.Subject !== AnnoSubject.pagenumber &&
        o.Subject !== AnnoSubject.toc
    );
    annotationManager.deleteAnnotations(annos);
    const textHighlightAnnotations =
      [] as Core.Annotations.TextHighlightAnnotation[];
    AppCache.DataCurrentPage?.rectangeWithColors?.forEach(async (o) => {
      const r = o.rect;
      if (!r) return;
      const newRect = RectConvertToViewerCoordinates(
        {
          bottom: r.bottom || 0,
          left: r.left || 0,
          right: r.right || 0,
          top: r.top || 0,
        },
        pageNo
      );
      const rect = new Math.Rect(
        newRect?.left,
        newRect?.top || 0,
        newRect?.right || 0,
        newRect?.bottom || 0
      );
      const color = ColorHelper.colorStringToRGB(o.color || "yellow");
      if (!color) return;
      const annotation = new Annotations.TextHighlightAnnotation({
        PageNumber: pageNo,
        StrokeColor: new Annotations.Color(
          color?.r,
          color?.g,
          color?.b,
          color?.a
        ),
        Quads: [rect.toQuad()],
        NoResize: true,
        Subject: getSubject(o),
      });
      annotation.setCustomData("replaceObjId", o.replaceObjId || "");
      annotation.setCustomData("tagId", o.tagId || "");
      textHighlightAnnotations.push(annotation);
    });
    DrawBoxAnnotation(pageNo);
    DrawLineAnnotation(pageNo);
    const page = AppCache.DocumentInfo?.tabDetails
      ?.flatMap((o) => o.pages)
      .find((o) => o?.viewerPageNo === pageNo);
    DrawPageNumbering(pageNo, page?.pageWidth || 0, page?.pageHeight || 0);
    annotationManager.addAnnotations(textHighlightAnnotations);
    textHighlightAnnotations.forEach(async (o) => {
      annotationManager.redrawAnnotation(o);
    });
    // Add the stamp annotation to the document.
    DrawStamp(pageNo);
    DrawNoteAnnotationOnPage(AppCache.CurrentPageNo);
  };
  const DrawBoxAnnotation = async (pageNo: number) => {
    if (!AppCache.WebViewerInstance?.Core) return;
    const { annotationManager, Annotations, Math } =
      AppCache.WebViewerInstance?.Core;
    const rectangleAnnotations = [] as Core.Annotations.RectangleAnnotation[];
    const yellowColor = ColorHelper.colorStringToRGB(
      GetLxSetting().option_Color_selection || "yellow"
    )!;
    const blueRGB = ColorHelper.colorStringToRGB("#338FFF")!;
    const yellow = new Annotations.Color(
      yellowColor.r,
      yellowColor.g,
      yellowColor.b,
      0.5
    );
    const blue = new Annotations.Color(blueRGB.r, blueRGB.g, blueRGB.b, 0.5);
    AppCache.DataCurrentPage?.boxObjs?.forEach(async (o) => {
      let r = o.rect;
      if (!r) return;

      const newRect = RectConvertToViewerCoordinates(
        {
          bottom: r.bottom || 0,
          left: r.left || 0,
          right: r.right || 0,
          top: r.top || 0,
        },
        pageNo
      );
      const w = newRect?.width || 0;
      const h = newRect?.height || 0;
      const annotation = new Annotations.RectangleAnnotation({
        PageNumber: pageNo,
        X: newRect?.left || 0,
        Y: newRect?.top || 0,
        Width: w,
        Height: h,
        StrokeColor: o.replaceObjId ? blue : yellow,
        FillColor: o.replaceObjId ? blue : yellow,
        StrokeThickness: 0.1,
        Subject: o.replaceObjId ? AnnoSubject.replacebox : AnnoSubject.box,
      });
      annotation.setCustomData("replaceObjId", o.replaceObjId || "");
      annotation.setCustomData("boxId", o.id || "");
      rectangleAnnotations.push(annotation);
    });
    annotationManager.addAnnotations(rectangleAnnotations);
    rectangleAnnotations.forEach(async (o) => {
      annotationManager.redrawAnnotation(o);
    });
  };
  const DrawLineAnnotation = async (pageNo: number) => {
    if (!AppCache.WebViewerInstance?.Core) return;
    const { annotationManager, Annotations, Math } =
      AppCache.WebViewerInstance?.Core;
    const annos = GetAnnotationsList(pageNo);
    const annoline = annos?.filter((o) => o.Subject === AnnoSubject.line);
    annotationManager.deleteAnnotations(annoline);
    const lineAnnotations = [] as Core.Annotations.LineAnnotation[];
    AppCache.DataCurrentPage?.lineAnnotations?.forEach(async (o) => {
      const color = ColorHelper.colorStringToRGB(o.color || "black");
      if (!color) return;
      const newRect = RectConvertToViewerCoordinates(
        {
          bottom: (o.top || 0) + (o.height || 0),
          left: o.left || 0,
          right: (o.left || 0) + (o.width || 0),
          top: o.top || 0,
        },
        pageNo
      );
      const annotation = new Annotations.LineAnnotation({
        PageNumber: pageNo,
        Start: new Math.Point(newRect?.left || 0, newRect?.top || 0),
        End: new Math.Point(
          (newRect?.left || 0) + (newRect?.width || 0),
          (newRect?.top || 0) + (newRect?.height || 0)
        ),
        StrokeColor: new Annotations.Color(
          color?.r,
          color?.g,
          color?.b,
          color?.a
        ),
        NoRotate: true,
        NoResize: true,
        Subject: AnnoSubject.line,
        StrokeThickness: o.borderWidth || 0.02 * 72,
      });
      annotation.setCustomData("lineId", o.id || "");
      lineAnnotations.push(annotation);
    });
    annotationManager.addAnnotations(lineAnnotations);
    lineAnnotations.forEach(async (o) => {
      annotationManager.redrawAnnotation(o);
    });
  };
  const DrawStamp = async (pageNo: number) => {
    if (!AppCache.WebViewerInstance?.Core) return;
    const { annotationManager, Annotations } = AppCache.WebViewerInstance?.Core;
    if (
      !AppCache.DataCurrentPage?.stampInfo ||
      !AppCache.DataCurrentPage?.stampInfo?.stampImg
    )
      return;
    const annos = GetAnnotationsList(pageNo);
    const annotagtemp = annos?.filter((o) => o.Subject === AnnoSubject.stamp);
    annotationManager.deleteAnnotations(annotagtemp);
    const annotation = new Annotations.StampAnnotation({
      PageNumber: pageNo,
      X: AppCache.DataCurrentPage?.stampInfo?.x || 0,
      Y: AppCache.DataCurrentPage?.stampInfo?.y || 0,
      Width: AppCache.DataCurrentPage?.stampInfo?.width || 0,
      Height: AppCache.DataCurrentPage?.stampInfo?.height || 0,
      Subject: AnnoSubject.stamp,
    });
    await annotation.setImageData(
      (
        "data:image/png;base64," +
          AppCache.DataCurrentPage?.stampInfo?.stampImg || ""
      ).toString()
    );
    annotationManager.addAnnotation(annotation);
    annotationManager.redrawAnnotation(annotation);
  };
  const DrawPageNumbering = (
    pageNo: number,
    pageWidth: number,
    pageHeight: number
  ) => {
    if (
      !AppCache.WebViewerInstance?.Core ||
      !AppCache.DocumentInfo?.pageNumberingInfo
    )
      return;
    const { annotationManager, Annotations } = AppCache.WebViewerInstance?.Core;
    const pageNumberingInfo = AppCache.DocumentInfo?.pageNumberingInfo;
    const annos = GetAnnotationsList(pageNo);
    const annoPageNumber = annos?.filter(
      (o) => o.Subject === AnnoSubject.pagenumber
    );
    annotationManager.deleteAnnotations(annoPageNumber);
    if (!AppCache.DocumentInfo?.pageNumberingInfo.included) return;
    pageWidth = pageWidth;
    const size = GetTextSite(pageNo.toString(), {
      fontName: AppCache.DocumentInfo?.pageNumberingInfo.fontName || "Arial",
      fontSize:
        AppCache.DocumentInfo?.pageNumberingInfo.fontSize?.toString() || "12",
    });
    let point = { X: 0, Y: 0 };
    const marginLeftRight = 0.393701 * (pageNumberingInfo.marginSide || 0) * 72; //cm=>inch => px
    const marginTopBottom = 0.393701 * (pageNumberingInfo.margin || 0) * 72;
    switch (pageNumberingInfo.position) {
      case 0:
        point = { X: marginLeftRight, Y: marginTopBottom };
        break;
      case 1:
        point = { X: pageWidth / 2 - size.width / 2, Y: marginTopBottom };
        break;
      case 2:
        point = {
          X: pageWidth - marginLeftRight - size.width,
          Y: marginTopBottom,
        };
        break;
      case 3:
        point = {
          X: marginLeftRight,
          Y: pageHeight - marginTopBottom - size.height,
        };
        break;
      case 4:
        point = {
          X: pageWidth / 2 - size.width / 2,
          Y: pageHeight - marginTopBottom - size.height,
        };
        break;
      case 5:
        point = {
          X: pageWidth - marginLeftRight - size.width,
          Y: pageHeight - marginTopBottom - size.height,
        };
        break;
    }
    const annot = new Annotations.FreeTextAnnotation(
      Annotations.FreeTextAnnotation.Intent.FreeText,
      {
        PageNumber: pageNo,
        X: point.X,
        Y: point.Y,
        Width: size.width,
        Height: size.height + 5,
        FontSize: pageNumberingInfo.fontSize?.toString() || "12",
        Font: pageNumberingInfo.fontName || "Arial",
        TextAlign: "left",
        TextVerticalAlign: "top",
        TextColor: new Annotations.Color(255, 0, 0, 1),
        StrokeColor: new Annotations.Color(51, 51, 51, 1),
        StrokeThickness: AppCache.DocumentInfo?.pageNumberingInfo.addFrame
          ? 1
          : 0,
        NoMove: true,
        NoResize: true,
        LockedContents: true,
        Subject: AnnoSubject.pagenumber,
      }
    );

    annot.setContents(pageNo.toString());
    annotationManager.addAnnotation(annot);
    annotationManager.redrawAnnotation(annot);
  };
  const SetCurrentPageNoOnServer = async (pageNo: number) => {
    AppCache.CurrentPageNo = pageNo;
    const res = await ViewApi.apiLegalxtractSetCurrentPageNoGet(pageNo);
    AppCache.DataCurrentPage = res.data || null;
    DrawAnnotationOnPage(pageNo);
  };
  const ChooseWordForTag = async (
    word: Api.LxWordResponse | null,
    pageNo: number
  ) => {
    if (
      AppCache.WordTagsFocus.find((o) => o == word) ||
      !AppCache?.DataCurrentPage?.words
    )
      return;
    if (word) {
      if (AppCache.WordTagsFocus) {
        if (AppCache.WordTagsFocus.length == 0) {
          RemoveAllChooseTagWord(pageNo);
        }
        const re1 = AppCache.WordTagsFocus.find((o) => {
          if (!o || !word.id) return false;
          return (
            (o.id && o.id + 1 === word.id) || (o.id && o.id - 1 === word.id)
          );
        });
        let re2: Api.LxWordResponse | null = null;
        var specialChar: Api.LxWordResponse | null = null;
        if (!re1) {
          re2 =
            AppCache.WordTagsFocus.find((o) => {
              if (!o || !word.id) return false;
              return o.id === word.id + 2 || o.id === word.id - 2;
            }) || null;
          if (re2) {
            if ((re2.id || 0) > (word.id || 0)) {
              specialChar = AppCache?.DataCurrentPage?.words[(re2.id || 0) - 1];
            } else {
              specialChar = AppCache?.DataCurrentPage?.words[(re2.id || 0) - 1];
            }
            if (SpecialChars.includes(specialChar.text || "")) {
              AppCache.WordTagsFocus = [...AppCache.WordTagsFocus, specialChar];
              CreateFocusWordTagAnnotation(specialChar, pageNo);
            }
          }
        }

        if (
          ((!re2 && (!re1 || re1 === undefined)) ||
            (re2 && !SpecialChars.includes(specialChar?.text || ""))) &&
          AppCache.WordTagsFocus.length > 0
        ) {
          RemoveAllChooseTagWord(pageNo);
          AppCache.WordTagsFocus = [];
        }
        AppCache.WordTagsFocus = [...AppCache.WordTagsFocus, word];
        CreateFocusWordTagAnnotation(word, pageNo);
      } else {
        AppCache.WordTagsFocus = [...AppCache.WordTagsFocus, word];
        CreateFocusWordTagAnnotation(word, pageNo);
      }
    } else {
      AppCache.WordTagsFocus = [];
    }
    if (AppCache.WordTagsFocus.length === 0) {
      RemoveAllChooseTagWord(pageNo);
    }
  };
  const RemoveAllChooseTagWord = async (pageNo: number) => {
    if (!AppCache.WebViewerInstance?.Core) return;
    const { annotationManager, Annotations, Math } =
      AppCache.WebViewerInstance?.Core;
    const annos = GetAnnotationsList(pageNo);
    const annotagtemp = annos?.filter((o) => o.Subject === AnnoSubject.tagtemp);
    annotationManager.deleteAnnotations(annotagtemp);
  };
  const CreateFocusWordTagAnnotation = async (
    word: Api.LxWordResponse | null,
    pageNo: number
  ) => {
    if (!word) {
      return;
    }
    if (!AppCache.WebViewerInstance?.Core || !word) return;
    const { annotationManager, Annotations, Math, documentViewer } =
      AppCache.WebViewerInstance?.Core;
    DeleteHighlighttemp(pageNo);
    if (!word || !AppCache.WebViewerInstance?.Core) {
      return;
    }
    const annot = new Annotations.TextHighlightAnnotation({
      PageNumber: pageNo,
      StrokeColor: new Annotations.Color(117, 174, 255, 1),
      Subject: AnnoSubject.tagtemp,
    });
    const doc = documentViewer.getDocument();
    const quads = [] as Core.Math.Quad[];
    word.rects?.forEach((r) => {
      if (!r) return;
      const newRect = RectConvertToViewerCoordinates(
        {
          bottom: r.bottom || 0,
          left: r.left || 0,
          right: r.right || 0,
          top: r.top || 0,
        },
        pageNo
      );
      const rect = new Math.Rect(
        newRect?.left,
        newRect?.top,
        newRect?.right,
        newRect?.bottom
      );
      quads.push(rect.toQuad());
    });
    annot.Quads = quads;
    annot.NoResize = true;
    annotationManager.addAnnotation(annot);
    annotationManager.redrawAnnotation(annot);
  };
  const DrawReplaceToolTipAnnotation = (
    pageNo: number,
    x: number,
    y: number
  ) => {
    if (!AppCache.WebViewerInstance?.Core) return;
    const { annotationManager, Annotations } = AppCache.WebViewerInstance?.Core;
    const anno = GetAnnotationByMousePosition(pageNo, x, y);
    const replaceObjId = anno?.getCustomData("replaceObjId");
    if (!replaceObjId) return false;
    var replaceObj = AppCache.ReplaceObjects.replaceObjs?.find(
      (o) => o.id === replaceObjId
    );
    if (!replaceObj) return false;
    DeleteHighlighttemp(pageNo);
    const textColor = ColorHelper.colorStringToRGB(
      replaceObj?.fontColor || "black"
    );
    const extractColor = ColorHelper.colorStringToRGB(
      replaceObj?.extractColor || "black"
    );
    const annot = new Annotations.FreeTextAnnotation(
      Annotations.FreeTextAnnotation.Intent.FreeText,
      {
        PageNumber: pageNo,
        X: anno?.X,
        Y: (anno?.Y || 0) - (anno?.Height || 0),
        Width: anno?.Width,
        Height: anno?.Height,
        FontSize: (replaceObj?.fontSize?.toString() || "10") + "px",
        Font: replaceObj?.fontName || "Arial",
        TextAlign: "center",
        TextVerticalAlign: "center",
        TextColor: new Annotations.Color(
          textColor?.r || 0,
          textColor?.g || 0,
          textColor?.b || 0,
          1
        ),
        FillColor: new Annotations.Color(
          extractColor?.r || 0,
          extractColor?.g || 0,
          extractColor?.b || 0,
          1
        ),
        StrokeThickness: 0,
        NoMove: true,
        NoResize: true,
        LockedContents: true,
        Subject: AnnoSubject.replacetooltip,
      }
    );
    annot.setContents(replaceObj?.replaceBy || "");
    annotationManager.addAnnotation(annot);
    annotationManager.redrawAnnotation(annot);
    return true;
  };

  const DrawNoteAnnotationOnPage = (pageNo: number) => {
    if (!AppCache.WebViewerInstance?.Core) return;
    const { annotationManager, documentViewer, Annotations, Math } =
      AppCache.WebViewerInstance?.Core;
    const noteAnnotations = [] as Core.Annotations.FreeTextAnnotation[];

    AppCache.DataCurrentPage?.noteTextObjs?.forEach((o) => {
      const textColor = ColorHelper.colorStringToRGB(o.foreColor || "yellow");
      const backColor = ColorHelper.colorStringToRGB(o.backColor || "yellow");
      const size = GetTextSite(o?.noteText || "", {
        fontName: o.fontName || "Arial",
        fontSize: o.fontSize?.toString() || "12",
      });
      const leftTop = documentViewer
        .getDocument()
        .getViewerCoordinates(
          pageNo,
          o?.rectangleF?.x || 0,
          o?.rectangleF?.y || 0
        );
      const annot = new Annotations.FreeTextAnnotation(
        Annotations.FreeTextAnnotation.Intent.FreeText,
        {
          PageNumber: pageNo,
          X: leftTop?.x,
          Y: leftTop?.y,
          Height: o.rectangleF?.height || size.height,
          Width: o.rectangleF?.width || size.width,
          FontSize: o?.fontSize?.toString() || "12",
          Font: o?.fontName || "Arial",
          TextAlign: "left",
          TextVerticalAlign: "top",
          TextColor: new Annotations.Color(
            textColor?.r || 0,
            textColor?.g || 0,
            textColor?.b || 0,
            1
          ),
          FillColor: new Annotations.Color(
            backColor?.r || 0,
            backColor?.g || 0,
            backColor?.b || 0,
            (o.opacity || 0) / 100
          ),
          StrokeThickness: 1,
          LockedContents: true,
          Subject: AnnoSubject.note,
        }
      );
      annot.setCustomData("noteId", o.id || "");
      annot.setContents(o?.noteText || "");
      annot.setPadding(new Math.Rect(0.5, 0.5, 0.5, 0.5));
      noteAnnotations.push(annot);
    });
    annotationManager.addAnnotations(noteAnnotations);
    noteAnnotations.forEach(async (o) => {
      annotationManager.redrawAnnotation(o);
      console.log(o.getPadding());
    });
    return true;
  };
  const DeleteHighlighttemp = (pageNo: number) => {
    if (!AppCache.WebViewerInstance?.Core) return;
    const { annotationManager, Annotations, Math, documentViewer } =
      AppCache.WebViewerInstance?.Core;
    const annos = GetAnnotationsList(pageNo);
    const annotagtemp = annos.filter(
      (o) =>
        o.Subject === AnnoSubject.focushighlighttemp ||
        o.Subject === AnnoSubject.highlighttemp ||
        o.Subject === AnnoSubject.replacetooltip
    );
    annotationManager.deleteAnnotations(annotagtemp);
  };
  const InsertBlankPage = async (
    pageNo: number,
    width: number,
    height: number
  ) => {
    if (!AppCache.WebViewerInstance?.Core) return;
    const { documentViewer } = AppCache.WebViewerInstance?.Core;
    const doc = documentViewer.getDocument();
    // Insert blank pages
    await doc.insertBlankPages([pageNo], width, height);
  };
  const InsertPages = async (
    sourceDocument: Core.Document,
    pageArray?: number[],
    insertBeforeThisPage?: number
  ) => {
    if (!AppCache.WebViewerInstance?.Core) return;
    const { documentViewer } = AppCache.WebViewerInstance?.Core;
    const doc = documentViewer.getDocument();
    // Insert blank pages
    await doc.insertPages(sourceDocument, pageArray, insertBeforeThisPage);
  };
  const MergeDocument = async (
    source: string | File | ArrayBuffer | Blob,
    position?: number
  ) => {
    if (!AppCache.WebViewerInstance?.Core) return;
    const { documentViewer } = AppCache.WebViewerInstance?.Core;
    const doc = documentViewer.getDocument();
    // Insert blank pages
    await doc.mergeDocument(source, position);
  };
  const SetDataCurrentPage = (data: Api.LxPageData, pageNo: number) => {
    if (!data) return;
    AppCache.DataCurrentPage = data || null;
    AppCache.CurrentPageNo = pageNo;
    DrawAnnotationOnPage(pageNo || 0);
  };

  const RotatePagesOnView = () => {
    const pages = AppCache.DocumentInfo?.tabDetails?.flatMap((o) => o.pages);
    const page_180 =
      pages
        ?.filter((o) => o?.rotationAngles === 180)
        .map((o) => o?.viewerPageNo || 0) || [];
    const page_90 =
      pages
        ?.filter((o) => o?.rotationAngles === 90)
        .map((o) => o?.viewerPageNo || 0) || [];
    const page_270 =
      pages
        ?.filter((o) => o?.rotationAngles === 270)
        .map((o) => o?.viewerPageNo || 0) || [];
    if (page_180) RotatePages(page_180, 180);
    if (page_90) RotatePages(page_90, 90);
    if (page_270) RotatePages(page_270, 270);
  };
  return {
    GetDataCurrentPage,
    GetWordFocus,
    RemoveAllChooseWordTag,
    DrawHighlightTextAnnotation,
    DrawFocusWord,
    DrawAnnotationOnPage,
    DrawStamp,
    DrawPageNumbering,
    SetCurrentPageNoOnServer,
    ChooseWordForTag,
    DrawLineAnnotation,
    DrawFocusWordSearchResults,
    DrawReplaceToolTipAnnotation,
    InsertBlankPage,
    SetDataCurrentPage,
    DrawRectangleFocusWord,
    RotatePagesOnView,
    InsertPages,
    MergeDocument,
  };
};

import Cookies from "js-cookie";
import globalAxios from "axios";
import { userService } from "../Services/NotHook/UserService";
import { AppConstants, ConfigSettings } from "../Constant/AppContant";
import axios from "axios";
const globalAxiosConfig = () => {
  const { getUser } = userService();
  const requestHandler = (request: any) => {
    // @ts-ignore
    request.headers["IdentifiedKey"] = localStorage.IdentifiedKey?.toString();
    const TokenNovaLegalXtract = Cookies.get(AppConstants.TokenThirdParty);
    // @ts-ignore
    request.headers["TokenThirdParty"] = TokenNovaLegalXtract || "";
    // @ts-ignore
    const user = getUser();
    if (user) {
      request.headers["Authorization"] = "Bearer " + user.token;
      request.headers["LicenseKey"] = user.userDataLogin?.result?.user_info?.licenseKey || "";
      request.headers["UserInfo"] = encodeURI(JSON.stringify({email:user.userDataLogin?.result?.user_info?.email || "", userName:user.userDataLogin?.result?.user_info?.fullName || ""}));
    }
    return request;
  };
  const responseHandler = (response: any) => {
    return response;
  };
  globalAxios.defaults.baseURL = ConfigSettings.REACT_APP_API_ENDPOINT;
  globalAxios.interceptors.request.use((request) => requestHandler(request));
  globalAxios.interceptors.response.use(
    (response) => responseHandler(response),
    (err) => {
      console.log(err);
      if (err.response.status === 401) {
        window.location.href = "/login";
      }
      return err;
    }
  );
};
export const axiosInstance = axios.create({
  baseURL: `${ConfigSettings.REACT_APP_API_ENDPOINT}`,
});
export default globalAxiosConfig;

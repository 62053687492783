import { Button, Checkbox, Col, Radio, Row } from "antd";
import React, { useRef, useState } from "react";
import { useLang } from "../../../i18n/useLang";
import "react-data-grid/lib/styles.css";
import DataGrid, { SelectColumn } from "react-data-grid";
import { SearchResultService } from "../../../Services/Hook/Search/SearchResultService";
import { StringFormat } from "../../../Common/CommonFunction";
import InfiniteScroll from "react-infinite-scroll-component";
import LoaderSearch from "../../../Loadding/loading-search";
let timeOutLoadMore: any = null;
export const SearchResult = () => {
  const { tr } = useLang();
  // Usage
  const [arr, setArr] = React.useState<any[]>([]);
  let initCheck = useRef(false);
  React.useEffect(() => {
    if (!initCheck.current) {
      for (let i = 0; i < 1000; i++) {
        setArr((prevArr) => [...prevArr, { number: i, name: "uwu" }]);
      }
    }
    initCheck.current = true;
  }, []);

  const {
    SearchClick,
    SelectAllClick,
    DeselectAllClick,
    ReplaceSelectedClick,
    ReplaceAllClick,
    SearchTabState,
    SearchResultData,
    SetSearchTabStore,
    CheckBoxItemClick,
    DrawFocusWordSearchResults,
    FetchMoreSearchData,
    CancelSearch,
    lastScrollTop,
  } = SearchResultService();
  return (
    <>
      <Row gutter={[8, 8]} style={{ marginTop: 8 }}>
        <Col md={12}>
          <Radio
            checked={SearchTabState.SearchTabVm?.allPages == false}
            onClick={(value) => {
              SetSearchTabStore({
                ...SearchTabState,
                SearchTabVm: {
                  ...SearchTabState.SearchTabVm,
                  allPages: false,
                },
              });
            }}
          >
            {tr("currentPage")}
          </Radio>
        </Col>
        <Col md={12}>
          <Radio
            checked={SearchTabState.SearchTabVm?.allPages}
            onClick={() => {
              SetSearchTabStore({
                ...SearchTabState,
                SearchTabVm: {
                  ...SearchTabState.SearchTabVm,
                  allPages: true,
                },
              });
            }}
          >
            {tr("allPages")}
          </Radio>
        </Col>

        <Col md={12}>
          <Checkbox
            checked={SearchTabState.SearchTabVm?.accuracy}
            onChange={(value) => {
              SetSearchTabStore({
                ...SearchTabState,
                SearchTabVm: {
                  ...SearchTabState.SearchTabVm,
                  accuracy: value.target.checked,
                },
              });
            }}
          >
            {tr("accuracy")}
          </Checkbox>
        </Col>
        <Col md={12}>
          <Checkbox
            checked={SearchTabState.SearchTabVm?.autoSearch}
            onChange={(value) => {
              SetSearchTabStore({
                ...SearchTabState,
                SearchTabVm: {
                  ...SearchTabState.SearchTabVm,
                  autoSearch: value.target.checked,
                },
              });
            }}
          >
            {tr("autoSearch")}
          </Checkbox>
        </Col>

        <Col md={24}>
          <Button
            type="primary"
            onClick={() => {
              SearchClick();
            }}
            style={{ width: "100%" }}
          >
            {tr("search")}
          </Button>
        </Col>

        <Col md={12}>
          <Button
            onClick={() => {
              SelectAllClick();
            }}
            type="primary"
            style={{ width: "100%" }}
          >
            {tr("selectAll")}
          </Button>
        </Col>
        <Col md={12}>
          <Button
            onClick={() => {
              DeselectAllClick();
            }}
            type="primary"
            style={{ width: "100%" }}
          >
            {tr("deselectAll")}
          </Button>
        </Col>
        <Col md={24}>
          {StringFormat(
            tr("found0MatchesIn1PageS"),
            `${SearchResultData?.totalSearchResult || 0}`,
            `${SearchResultData?.totalPage || 0}`
          )}
        </Col>
        <Col md={24}>
          <LoaderSearch
            showCancelLoader={true}
            showLoader={SearchResultData?.loadding || false}
            onClickCancel={() => {
              CancelSearch();
            }}
          ></LoaderSearch>
          <DataGrid
            className={`rdg-light`}
            style={{ width: "100%" }}
            onScroll={(e) => {
              const nativeEvent = e.nativeEvent as any;
              const computedStyle = getComputedStyle(
                nativeEvent.srcElement,
                "::before"
              );
              const heightContent = parseFloat(
                computedStyle.getPropertyValue("height").replace("px", "")
              );
              if (
                nativeEvent.srcElement.scrollTop +
                  nativeEvent.srcElement.offsetHeight >
                  heightContent - 100 &&
                lastScrollTop.current < nativeEvent.srcElement.scrollTop
              ) {
                if (timeOutLoadMore) clearTimeout(timeOutLoadMore);
                timeOutLoadMore = setTimeout(() => {
                  FetchMoreSearchData();
                }, 300);
              }
              lastScrollTop.current = nativeEvent.srcElement.scrollTop;
            }}
            columns={[
              {
                key: "name",
                name: "",
                renderCell: (item) => {
                  if (!item.row.phrase || !item.row.highLightLength)
                    return null;
                  const phrase = item.row.phrase;
                  const indexWordResult = phrase.indexOf(item.row.word || "");
                  const start = phrase
                    .substring(0, indexWordResult)
                    .replace(/</g, "&lt;")
                    .replace(/>/g, "&gt;");
                  const mid = phrase
                    .substring(
                      item.row.highLightFromIdx || 0,
                      (item.row.highLightFromIdx || 0) +
                        item.row.highLightLength
                    )
                    .replace(/</g, "&lt;")
                    .replace(/>/g, "&gt;");
                  const end = phrase
                    .substring(
                      (item.row.highLightFromIdx || 0) +
                        item.row.highLightLength
                    )
                    .replace(/</g, "&lt;")
                    .replace(/>/g, "&gt;");
                  const result = `${start}<mark>${mid}</mark>${end}`;
                  return (
                    <>
                      <Checkbox
                        checked={item.row.checked}
                        onChange={(e) => {
                          CheckBoxItemClick(item.row, e.target.checked);
                        }}
                        className="search-result-checkbox"
                        title={item.row.phrase || ""}
                      >
                        <label
                          title={item.row.phrase || ""}
                          style={{
                            flex: 1,
                            display: "inline-block",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          dangerouslySetInnerHTML={{ __html: result }}
                        />
                      </Checkbox>
                    </>
                  );
                },
              },
            ]}
            rows={SearchResultData?.searchData || []}
            headerRowHeight={0}
            onCellClick={(cell) => {
              DrawFocusWordSearchResults(cell.row);
            }}
          ></DataGrid>
        </Col>
        <Col md={12}>
          <Button
            onClick={() => {
              ReplaceSelectedClick();
            }}
            type="primary"
            style={{ width: "100%" }}
          >
            {tr("replace") + " " + tr("selected")}
          </Button>
        </Col>
        <Col md={12}>
          <Button
            onClick={() => {
              ReplaceAllClick();
            }}
            type="primary"
            style={{ width: "100%" }}
          >
            {tr("replaceAll")}
          </Button>
        </Col>
      </Row>
    </>
  );
};

import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  ColorPicker,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Slider,
  Space,
} from "antd";
import { useLang } from "../../i18n/useLang";
import {
  DeleteFilled,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  MDStampSettingsProps,
  MDStampSettingsService,
} from "../../Services/Hook/Modal/Stamp/MDStamSettingService";

const MDStampSetting = ({ open, onClose, editItem }: MDStampSettingsProps) => {
  const { tr } = useLang();
  const {
    formik,
    showErrors,
    fontList,
    onSelectedFile,
    fileInputRef,
    ChooseFile,
    DeleteFile,
    ResetForm,
  } = MDStampSettingsService({
    onClose,
    open,
    editItem,
  });
  return (
    <>
      <Modal
        title={tr("stamp")}
        footer={
          <>
            <Button
              style={{ minWidth: 100 }}
              type="default"
              onClick={() => {
                onClose && onClose();
              }}
            >
              {tr("close")}
            </Button>
            <Button
              style={{ minWidth: 100 }}
              type="default"
              onClick={ResetForm}
            >
              {tr("clear")}
            </Button>
            <Button
              style={{ minWidth: 100 }}
              type="primary"
              onClick={() => {
                formik.setFieldValue("actionType", "ApplyStampAll");
                formik.submitForm();
              }}
            >
              {tr("applyAll")}
            </Button>
            {editItem && (
              <Button
                style={{ minWidth: 100 }}
                hidden={true}
                type="primary"
                onClick={() => {
                  formik.setFieldValue("actionType", "ApplyStampCurrent");
                  formik.submitForm();
                }}
              >
                {tr("applyCurrent")}
              </Button>
            )}
          </>
        }
        centered
        open={open}
        maskClosable={false}
        width={550}
        onCancel={() => {
          onClose && onClose();
        }}
      >
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <label>{tr("title")}</label>
            <Input
              name="title"
              value={formik.values.title || ""}
              onChange={formik.handleChange}
              width={"100%"}
            ></Input>
          </Col>

          <Col span={12}>
            <div style={{ width: "100%" }}>
              <label>{tr("index")}</label>
            </div>
            <Radio
              disabled={!!editItem}
              name="automaticCount"
              onChange={(value) => {
                formik.setFieldValue("automaticCount", true);
              }}
              checked={formik.values.automaticCount === true}
              value={true}
            >
              {tr("fromTOC")}
            </Radio>
          </Col>
          <Col span={12}>
            <div style={{ width: "100%" }}>
              <label style={{ color: "transparent" }}>{"sss"}</label>
            </div>
            <Radio
              disabled={!!editItem}
              name="indexFromToc"
              checked={formik.values.automaticCount === false}
              onChange={(value) => {
                formik.setFieldValue("index", "");
                formik.setFieldValue("automaticCount", false);
              }}
              value={false}
            >
              {tr("manual")}
            </Radio>
          </Col>
          {formik.values.automaticCount === true && (
            <Col span={24}>
              <label>{tr("indexFormat")}</label>
              <Input
                disabled={!!editItem}
                name="index"
                onChange={formik.handleChange}
                value={formik.values.index || "1"}
                width={"100%"}
              ></Input>
            </Col>
          )}

          <Col span={12}>
            <label>{tr("font")}</label>
            <Select
              size="middle"
              value={formik.values.fontName}
              style={{ width: "100%" }}
              options={fontList}
              onChange={(value) => {
                formik.setFieldValue("fontName", value);
              }}
            />
          </Col>
          <Col span={6}>
            <label>{tr("size")}</label>
            <InputNumber
              type="number"
              name="fontSize"
              value={formik.values.fontSize}
              onChange={(value) => {
                formik.setFieldValue("fontSize", value);
              }}
              min={1}
              step={0.5}
              style={{ width: "100%" }}
            ></InputNumber>
          </Col>
          <Col span={6}>
            <label>{tr("fontColor")}</label>
            <ColorPicker
              style={{ width: "100%" }}
              value={formik.values.fontColor}
              onChange={(color) => {
                formik.setFieldValue("fontColor", color.toHexString());
              }}
            />
          </Col>
          <Col span={12}>
            <label>{tr("transparant")}</label>
            <Slider
              value={formik.values.transparent}
              onChange={(value) => {
                formik.setFieldValue("transparent", value);
              }}
            />
          </Col>
          <Col span={6}>
            <label style={{ color: "transparent" }}>{"sss"}</label>
            <Checkbox
              name="addFrame"
              value={formik.values.addFrame}
              onChange={formik.handleChange}
              style={{ width: "100%" }}
            >
              {tr("addFrame")}
            </Checkbox>
          </Col>
          <Col span={12}>
            <label>{tr("logo")}</label>
            <div
              style={{
                height: 150,
                width: "100%",
                border: "1px solid #d9d9d9",
                borderRadius: 8,
                display: "flex",
              }}
            >
              <input
                style={{ display: "none" }}
                multiple={false}
                ref={(file) => (fileInputRef.current = file)}
                type="file"
                onChange={(e) => {
                  onSelectedFile(e.target.files);
                  e.target.value = "";
                }}
                accept="image/x-png,image/gif,image/jpeg"
              />
              {(formik.values.logoBase64 && (
                <img
                  style={{
                    height: "100%",
                    maxWidth: "100%",
                    margin: "auto",
                  }}
                  src={
                    (formik.values.logoBase64.indexOf("base64") < 0
                      ? "data:image/png;base64, "
                      : "") + formik.values.logoBase64
                  }
                  alt={""}
                />
              )) || <div style={{ margin: "auto" }}>{tr("noImageData")}</div>}
            </div>
          </Col>
          <Col span={12}>
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <label>{tr("height")}</label>
                <InputNumber
                  type="number"
                  min={1}
                  name="location.height"
                  value={formik.values.location?.height}
                  onChange={(value) => {
                    formik.setFieldValue("location.height", value);
                  }}
                  style={{ width: "100%" }}
                  step={0.5}
                ></InputNumber>
              </Col>
              <Col span={24}>
                <label>{tr("width")}</label>
                <InputNumber
                  min={1}
                  type="number"
                  name="location.width"
                  value={formik.values.location?.width}
                  onChange={(value) => {
                    formik.setFieldValue("location.width", value);
                  }}
                  style={{ width: "100%" }}
                  step={0.5}
                ></InputNumber>
              </Col>
              <Col span={24}>
                <Checkbox
                  name="showFromToc"
                  disabled={!!editItem}
                  checked={formik.values.showFromToc}
                  onChange={formik.handleChange}
                  style={{ width: "100%" }}
                >
                  {tr("showFromTableOfContent")}
                </Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox
                  name="autoScale"
                  checked={formik.values.autoScale}
                  onChange={formik.handleChange}
                  style={{ width: "100%" }}
                >
                  {tr("automaticallyScaleStampBasedOnPageSize")}
                </Checkbox>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Space>
              <Button
                icon={<UploadOutlined />}
                onClick={() => {
                  ChooseFile();
                }}
              />
              <Button
                icon={<DeleteOutlined />}
                onClick={() => {
                  DeleteFile();
                }}
              />
            </Space>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default MDStampSetting;

import UndoRedoManagerObj from "../UndoRedomanager";
import * as Api from "../../Api/src/index";
import { AppCache } from "../../Cache/AppCache";
import { PageService } from "../../Services/NotHook/PageService";
import { UndoHightLight } from "./HightLightCommand";
import { ReplaceService } from "../../Services/NotHook/ReplaceService";
import { ReplacePanelService } from "../../Services/Hook/LeftPanel/ReplacePanelService";
import useReplaceStore from "../../ZustandStore/ReplaceStore";
const ReplaceApi = new Api.ReplaceApi();
export const DeleteReplaceOneWordCommand = () => {
  const { SetDataCurrentPage } = PageService();
  const { SetReplaceStore } = useReplaceStore();
  const DeleteReplace = async (param: {
    x?: number;
    y?: number;
    pageNo?: number;
  }) => {
    if (!param) return null;
    const res = await ReplaceApi.apiLegalxtractDeleteReplaceFromWordGet(
      param.x,
      param.y,
      param.pageNo
    );
    SetDataCurrentPage(res.data.pageData!, AppCache.CurrentPageNo || 0);
    ReplaceService().GetReplaceObjects();
    if (AppCache.LeftTabActive == "pnReplace") {
      const data = await ReplaceApi.apiLegalxtractCalculateReplaceGet();
      SetReplaceStore({ ReplaceObjDisplays: data.data });
    }
    return res;
  };
  const Do = async (param: { x?: number; y?: number; pageNo?: number }) => {
    const res = await DeleteReplace(param);
    UndoRedoManagerObj.add({
      Redo: () => {
        Redo(param);
      },
      Undo: () => {
        Undo({
          preState: JSON.stringify(res?.data.data),
          viewerPageNo: AppCache.CurrentPageNo,
        });
      },
    });
    return res;
  };
  const Redo = (param: { x?: number; y?: number; pageNo?: number }) => {
    DeleteReplace(param);
  };
  const Undo = async (param: Api.UndoHightLightParam) => {
    await UndoHightLight(param);
  };
  return { Do };
};

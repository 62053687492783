import { Space, TableProps } from "antd";
import * as Api from "../../../Api/src";
import { useLang } from "../../../i18n/useLang";
import { AppCache } from "../../../Cache/AppCache";
import { LeftPanelService } from "./LeftPanelService";
import useLogStore from "../../../ZustandStore/LogStore";
import useTabFileInfoStore from "../../../ZustandStore/TabFileInfoStore";
import { useEffect, useState } from "react";
import FileSaver from "file-saver";
const HistoryApi = new Api.HistoryApi();
export const LogPanelService = () => {
  const { tr } = useLang();
  const { LogState, SetLogStore } = useLogStore();
  const { TabFileInfoState } = useTabFileInfoStore();
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const { LoadLogsData } = LeftPanelService();
  const columns: TableProps<Api.HistoryViewItemWeb>["columns"] = [
    {
      title: tr("wordsReplaceBy"),
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <div
          style={
            record.children?.length! > 0
              ? {}
              : {
                  background: record.color || "transparent",
                  position: record.children?.length! > 0 ? "unset" : "absolute",
                  width: "100%",
                  height: "100%",
                  top: "0",
                  left: "0",
                  alignContent: "center",
                  padding: 8,
                  boxSizing: "border-box",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }
          }
          title={record.text || ""}
        >
          {record.text}
        </div>
      ),
    },
    {
      width: 140,
      title: tr("color"),
      key: "dateTime",
      dataIndex: "dateTime",
      render: (text, record) => text,
    },
    {
      width: 50,
      title: tr("count"),
      key: "count",
      render: (_, record) => <>{record.pageNo}</>,
    },
  ];
  const tadList = () => {
    const viewAll = [
      { value: "0000000-0000-0000-0000-000000000000", label: tr("viewAll") },
    ];
    const tabs =
      TabFileInfoState.TabDetails?.map((item) => {
        return {
          value: item.uniqueId,
          label: item.fileName,
        };
      }) || [];
    return [...viewAll, ...tabs];
  };
  const LoadData = async (uniqueId: string) => {
    LoadLogsData(uniqueId);
    SetLogStore({ TabID: uniqueId });
  };
  useEffect(() => {
    setExpandedRowKeys(
      LogState.HistoryViewItemWebs?.filter((o) => o.children?.length! > 0).map(
        (x) => x.id || ""
      ) || []
    );
  }, [LogState.HistoryViewItemWebs]);
  const exportExcel = async () => {
    const tabDetail = AppCache.DocumentInfo?.tabDetails?.find(
      (o) => o.uniqueId == LogState.TabID
    );
    const response = (
      await HistoryApi.apiLegalxtractExportExcelGet({ responseType: "blob" })
    ).data;
    FileSaver(response, (tabDetail?.fileName || "Log file") + ".xlsx");
  };
  return {
    tadList,
    columns,
    LoadData,
    LogState,
    expandedRowKeys,
    setExpandedRowKeys,
    exportExcel
  };
};

import { useState } from "react";
import * as Api from "../../../../Api/src/index";
import { v4 } from "uuid";
import useTagStore from "../../../../ZustandStore/TagStore";
import { FormikErrors, useFormik } from "formik";
import * as yup from "yup";
import { useLang } from "../../../../i18n/useLang";
import UseConfirmDialogStore from "../../../../ZustandStore/ConfirmDialogStore";
import { AddTagNameParam } from "../../../../Api/src/index";
const TagApi = new Api.TagApi();
export interface MDTagDetailProps {
  tagCategoryId: string;
  tagNameId: string;
  color: string;
  keyWord: string;
  tagId: string | null;
  open?: boolean;
  onClose?: () => void;
}
export const MDTagDetailService = ({
  onClose,
  tagCategoryId,
  tagNameId,
  color,
  keyWord,
  tagId,
}: MDTagDetailProps) => {
  const { TagState, SetTagStore } = useTagStore();
  const { SetConfirmDialogState } = UseConfirmDialogStore();
  const { tr, i18n } = useLang();
  const validationTagNameSchema = yup.object({
    keyWord: yup.string().required(tr("pleaseSetKeyword")),
    tagNameId: yup.string().required(tr("pleaseSetTagName")),
  });
  const showErrors = (error: FormikErrors<Api.SaveTagParam>) => {
    return (
      <>
        {error.keyWord ? <div>{error.keyWord}</div> : <></>}
        {error.keyWord ? <div>{error.tagNameId}</div> : <></>}
      </>
    );
  };
  const formik = useFormik({
    initialValues: {
      categoryID: tagCategoryId,
      tagNameId: tagNameId,
      keyWord: keyWord,
      backColor: color,
      comma: false,
      line: false,
      semicolon: false,
      slash: false,
      space: false,
      isDefault: false,
      tagId: tagId,
    } as Api.SaveTagParam,
    validationSchema: validationTagNameSchema,
    onSubmit: async (values) => {
      TagApi.apiLegalxtractSaveTagPost(values).then((res) => {
        if (res && res.data.isSuccess) {
          TagApi.apiLegalxtractLoadTagDictionaryForClientGet().then((res) => {
            var data = res.data;
            if (data) {
              data.tagCategories?.forEach((item) => {
                item.keyword =
                  i18n.language == "da"
                    ? item.keywordDa || item.keyword
                    : item.keyword;
              });
              data.tagNames?.forEach((item) => {
                item.keyword =
                  i18n.language == "da"
                    ? item.keywordDa || item.keyword
                    : item.keyword;
              });
              SetTagStore({ Tags: data });
            } else {
              SetTagStore({ Tags: null });
            }
          });
          onClose && onClose();
        } else {
          if ((res.data.errorMessage || "").indexOf("AlreadyExists") > 0)
            SetConfirmDialogState({
              Show: true,
              Type: "Alert",
              CancelText: tr("no"),
              Msg: `[${res.data.errorMessage?.replace(
                "&&AlreadyExists",
                ""
              )}] ${tr("alreadyExists")}`,
            });
        }
      });
    },
  });
  const GetDataSelectCategory = () => {
    return (
      TagState.Tags?.tagCategories?.map((o) => {
        return { value: o.id, label: o.keyword };
      }) || []
    );
  };
  const GetDataSelectTagName = (categoryId: string) => {
    var tagNames =
      TagState.Tags?.tagNames?.filter((o) => o.tagCategoryId == categoryId) ||
      [];

    return (
      tagNames.map((o) => {
        return { value: o.id, label: o.keyword };
      }) || []
    );
  };
  const SelectCategoryChange = (id: string) => {
    formik.setFieldValue("categoryID", id);
    var tagNames =
      TagState.Tags?.tagNames?.filter((o) => o.tagCategoryId == id) || [];
    if (tagNames.length > 0) {
      formik.setFieldValue("tagNameId", tagNames[0].id);
    } else {
      formik.setFieldValue("tagNameId", "");
    }
  };
  return {
    formik,
    showErrors,
    GetDataSelectCategory,
    GetDataSelectTagName,
    SelectCategoryChange,
  };
};

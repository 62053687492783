import { Core } from "@pdftron/webviewer";
import * as Api from "../../Api/src/index";
import { App } from "antd";
import { AppCache } from "../../Cache/AppCache";
import { PdfTronService } from "./PdfTronService";
import { PageService } from "./PageService";
const NoteApi = new Api.NoteApi();
export const NoteService = () => {
  const UpdateNote = async (
    noteAnno: Core.Annotations.Annotation | undefined
  ) => {
    if (!noteAnno) return;
    const pdfTronService = PdfTronService();
    const pageService = PageService();
    var noteId = noteAnno.getCustomData("noteId");
    var rect = noteAnno.getRect();

    if (!noteId) return;
    var noteObj = AppCache.DataCurrentPage?.noteTextObjs?.find(
      (o) => o.id == noteId
    );
    const rectonPdfServer = pdfTronService.RectConvertToPDFCoordinates(
      {
        bottom: rect.getBottom(),
        left: rect.getLeft(),
        right: rect.getRight(),
        top: rect.getTop(),
      },
      AppCache.CurrentPageNo
    );
    const res = await NoteApi.apiLegalxtractEditNotePost({
      backColor: noteObj?.backColor,
      fontName: noteObj?.fontName,
      fontSize: noteObj?.fontSize,
      foreColor: noteObj?.foreColor,
      id: noteObj?.id,
      noteText: noteObj?.noteText,
      opacity: noteObj?.opacity,
      visibleOnExtract: noteObj?.visibleOnExtract,
      pageNo: AppCache.CurrentPageNo,
      rectangleF: {
        x: rectonPdfServer?.left,
        y: rectonPdfServer?.bottom,
        width: rectonPdfServer?.width,
        height: rectonPdfServer?.height,
      },
    });
    pageService.SetDataCurrentPage(
      res.data.result?.pageData!,
      AppCache.CurrentPageNo
    );
  };
  const DeleteNote = async (noteId: string) => {
    const res = await NoteApi.apiLegalxtractDeleteNotePost(noteId);
    const pageService = PageService();
    pageService.SetDataCurrentPage(
      res.data.result?.pageData!,
      AppCache.CurrentPageNo
    );
  };
  const UpdateVisibleOnExtract = async (noteObj: Api.NoteTextObj) => {
    const res = await NoteApi.apiLegalxtractEditNotePost({
      backColor: noteObj?.backColor,
      fontName: noteObj?.fontName,
      fontSize: noteObj?.fontSize,
      foreColor: noteObj?.foreColor,
      id: noteObj?.id,
      noteText: noteObj?.noteText,
      opacity: noteObj?.opacity,
      visibleOnExtract: !noteObj?.visibleOnExtract,
      pageNo: AppCache.CurrentPageNo,
      rectangleF: noteObj?.rectangleF,
    });
  };
  return { UpdateNote, DeleteNote, UpdateVisibleOnExtract };
};

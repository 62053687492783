import { create } from "zustand";
import { persist } from "zustand/middleware";
import * as Api from "../Api/src/index";
import { AppLocalStorage } from "../Constant/AppContant";
export type ReplaceParamWeb = Api.ReplaceParamWeb & {
  ReplaceType?: "ReplaceWord" | "ReplaceAllSearch" | "ReplaceAllItemSelected";
  cacheKey?: string | null;
  disableReplaceOne?: boolean;
};
interface ReplaceState {
  ParamReplaceWord?: ReplaceParamWeb;
  ReplaceObjDisplays?: Api.ReplaceObjDisplay[];
}
interface ReplaceStore {
  ReplaceState: ReplaceState;
  SetReplaceStore: (replaceState: ReplaceState) => void;
  GetReplaceState: () => ReplaceState;
}
const useReplaceStore = create<ReplaceStore>()((set, get) => ({
  ReplaceState: {
    ReplaceObjDisplays: [],
    ParamReplaceWord: {
      fontColor: "#e74c3c",
      extractColor: "#ffffff",
      fontName: 'Arial',
      fontSize: 10,
      isReplaceFromSearch: false,
    } as Api.ReplaceParamWeb
  } as ReplaceState,
  SetReplaceStore: (replaceState) =>
    set((state) => ({
      ...state,
      ReplaceState: { ...state.ReplaceState, ...replaceState },
    })),
  GetReplaceState: () => {
    const state = get().ReplaceState;
    return state;
  },
}));

export default useReplaceStore;

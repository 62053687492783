import { create } from "zustand";
interface LoaddingAppState {
  Show?: boolean;
}
interface LoaddingAppStore {
  LoaddingAppState: LoaddingAppState;
  SetLoaddingAppStore: (LoaddingAppState: LoaddingAppState) => void;
}
const useLoaddingAppStore = create<LoaddingAppStore>()((set) => ({
  LoaddingAppState: { Show: false },
  SetLoaddingAppStore: (LoaddingAppState) =>
    set((state) => ({
      ...state,
      LoaddingAppState: { ...state.LoaddingAppState, ...LoaddingAppState },
    })),
}));

export default useLoaddingAppStore;

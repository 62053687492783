import React, { useState } from "react";
import type { RadioChangeEvent } from "antd";
import { Radio, Space } from "antd";
import pnFind from "../../Assets/IconApp/npFind.svg";
import pnLog from "../../Assets/IconApp/npLog.svg";
import pnNote from "../../Assets/IconApp/npNote.svg";
import pnReplace from "../../Assets/IconApp/npReplace.svg";
import pnThumbnail from "../../Assets/IconApp/npThumbnail.svg";
import pnTOC from "../../Assets/IconApp/npTOC.svg";
import pnWatchDog from "../../Assets/IconApp/watchdog.svg";
import pnComment from "../../Assets/IconApp/npComment.svg";
import pnBookmark from "../../Assets/IconApp/npBookmark.svg";
import "../../Assets/Styles/LeftPanels/LeftPanel.scss";
import { useLang } from "../../i18n/useLang";
import FindPanel from "./Find/FindPanel";
import Tabs from "rc-tabs";
import "rc-tabs/assets/index.css";
import { TocPanel } from "./Toc/TocPanel";
import useTocStore from "../../ZustandStore/TocStore";
import { TocPanelService } from "../../Services/Hook/LeftPanel/TocPanelService";
import { ReplacePanel } from "./Replace/ReplacePanel";
import { ReplacePanelService } from "../../Services/Hook/LeftPanel/ReplacePanelService";
import { AppCache } from "../../Cache/AppCache";
import { WatchdogPanel } from "./Watchdog/WatchdogPanel";
import { WatchDogPanelService } from "../../Services/Hook/LeftPanel/WatchDogPanelService";
import useLeftPanelStore, {
  LeftTabName,
} from "../../ZustandStore/LeftPanelStore";
import useWatchdogStore from "../../ZustandStore/WatchdogStore";
import { SearchService } from "../../Services/NotHook/SearchService";
import * as Api from "../../Api/src";
import BookmarkPanel from "./Bookmark/BookmarkPanel";
import { LeftPanelService } from "../../Services/Hook/LeftPanel/LeftPanelService";
import { NotePanel } from "./Note/NotePanel";
import { LogPanel } from "./Log/LogPanel";
import { CommentPanel } from "./Comment/CommentPanel";
import { ThumbnailPanel } from "./Thumbnail/ThumbnailPanel";
import { ThumbnailPanelService } from "../../Services/Hook/LeftPanel/ThumbnailPanelService";
const WatchDogApi = new Api.WatchDogApi();
const LeftPanel: React.FC = () => {
  const { tr } = useLang();
  const { LoadToc } = TocPanelService();
  const { LoadReplaceObjDisplays } = ReplacePanelService();
  const { getThumbnails, generateThumbnail } = ThumbnailPanelService();
  const { LoadBookmark, LoadNoteData, LoadLogsData, LoadCommentData } =
    LeftPanelService();
  const { SetLeftPanelStore, LeftPanelState } = useLeftPanelStore();
  const {
    SetWatchDogParamWeb,
    SetWatchdogDatas,
    WatchDogParamWeb,
    WatchdogDatas,
  } = useWatchdogStore();
  const [tabItems, setTabItems] = useState([
    {
      label: ``,
      key: LeftTabName.pnFind,
      children: <FindPanel></FindPanel>,
      icon: <img title={tr("find")} src={pnFind} width={25} />,
    },

    {
      label: ``,
      key: LeftTabName.pnThumbnail,
      children: <ThumbnailPanel></ThumbnailPanel>,
      icon: <img title={tr("thumbnail")} src={pnThumbnail} width={25} />,
    },
    {
      label: ``,
      key: LeftTabName.pnReplace,
      children: <ReplacePanel></ReplacePanel>,
      icon: <img title={tr("replace")} src={pnReplace} width={25} />,
    },
    {
      label: ``,
      key: LeftTabName.pnWatchDog,
      children: <WatchdogPanel></WatchdogPanel>,
      icon: <img title={tr("watchdog")} src={pnWatchDog} width={25} />,
    },
    {
      label: ``,
      key: LeftTabName.pnBookmark,
      children: <BookmarkPanel></BookmarkPanel>,
      icon: <img title={tr("bookMark")} src={pnBookmark} width={25} />,
    },
    {
      label: ``,
      key: LeftTabName.pnNote,
      children: <NotePanel></NotePanel>,
      icon: <img title={tr("note")} src={pnNote} width={25} />,
    },
    {
      label: ``,
      key: LeftTabName.pnTOC,
      children: <TocPanel></TocPanel>,
      icon: <img title={tr("toc")} src={pnTOC} width={25} />,
    },
    {
      label: ``,
      key: LeftTabName.pnComment,
      children: <CommentPanel></CommentPanel>,
      icon: <img title={tr("comment")} src={pnComment} width={25} />,
    },
    {
      label: ``,
      key: LeftTabName.pnLog,
      children: <LogPanel></LogPanel>,
      icon: <img title={tr("log")} src={pnLog} width={25} />,
    },
  ]);
  return (
    <div className="left-pn">
      <Tabs
        style={{ height: "100%" }}
        tabPosition={"left"}
        tabBarGutter={10}
        items={tabItems}
        activeKey={LeftPanelState.LeftTabActive || LeftTabName.pnFind}
        onChange={async (key) => {
          AppCache.LeftTabActive = key as LeftTabName;
          SetLeftPanelStore({ LeftTabActive: key as LeftTabName });
          if (key === "pnTOC") {
            LoadToc();
          } else if (key === LeftTabName.pnReplace) {
            LoadReplaceObjDisplays();
          } else if (key === LeftTabName.pnWatchDog) {
            const data = await WatchDogApi.apiLegalxtractCalculateWatchDogPost({
              param: WatchDogParamWeb,
              searchTemplateParam: SearchService().GetSearchTemplateParam(),
            });
            SetWatchdogDatas(data.data);
          } else if (key === LeftTabName.pnBookmark) {
            LoadBookmark();
          } else if (key === LeftTabName.pnNote) {
            LoadNoteData();
          } else if (key === LeftTabName.pnComment) {
            LoadCommentData();
          } else if (key === LeftTabName.pnLog) {
            LoadLogsData();
          } else if (key === LeftTabName.pnThumbnail) {
            const ItemCount = Math.round(
              (document.body.clientHeight - 164) / 150
            );
            getThumbnails(
              AppCache.CurrentPageNo - ItemCount,
              AppCache.CurrentPageNo + ItemCount,
              true
            );
          }
        }}
      ></Tabs>
    </div>
  );
};

export default LeftPanel;

import React from "react";
import { UseMenuIconService } from "../../Services/Hook/TopMenu/MenuIconService";
import { useLang } from "../../i18n/useLang";
import { Button } from "antd";
import { IconButtonTopMenu } from "../../Controls/IconButtonTopMenu";
import OpenIcon from "../../Assets/IconApp/folder_open.svg";
import save from "../../Assets/IconApp/save.svg";
import ocr from "../../Assets/IconApp/scan_text.svg";
import find_duplicate from "../../Assets/IconApp/find_duplicate.svg";
import print from "../../Assets/IconApp/print.svg";
import document_arrow_down from "../../Assets/IconApp/document_arrow_down.svg";
import tab_add_box from "../../Assets/IconApp/tab_add_box.svg";
import line from "../../Assets/IconApp/line.svg";
import expand_up_right from "../../Assets/IconApp/expand_up_right.svg";
import cursor_click from "../../Assets/IconApp/cursor_click.svg";
import rename from "../../Assets/IconApp/rename.svg";
import undo from "../../Assets/IconApp/undo.svg";
import redo from "../../Assets/IconApp/redo.svg";
import tag from "../../Assets/IconApp/tag.svg";
import desktop from "../../Assets/IconApp/desktop.svg";
import "../../Assets/Styles/TopMenu/MenuIcon.css";
import useMenuIconStore from "../../ZustandStore/MenuIconStore";
import { ToolMode } from "../../Constant/AppEnum";
import { MDTagSelect } from "../../Modal/Tag/MDTagSelect";
import { MDFindDuplicates } from "../../Modal/Finduplicate/MDFindDuplicates";
const MenuIcon = () => {
  const { MenuIconState } = useMenuIconStore();
  const {
    OpenClick,
    BoxClick,
    SectionClick,
    MaskClick,
    SelectClick,
    TagClick,
    LineClick,
    ShowTagSelect,
    SaveClick,
    CloseFindDuplicate,
    ShowMDFindDuplicate,
    FindDuplicateClick,
  } = UseMenuIconService();
  const { tr } = useLang();
  return (
    <div className="menu-icon">
      <IconButtonTopMenu
        onClick={OpenClick}
        icon={OpenIcon}
        text={tr("open")}
      ></IconButtonTopMenu>
      <IconButtonTopMenu
        onClick={SaveClick}
        icon={save}
        text={tr("save")}
      ></IconButtonTopMenu>
      <IconButtonTopMenu
        onClick={() => {}}
        disabled={true}
        icon={ocr}
        text={tr("ocr")}
      ></IconButtonTopMenu>
      <IconButtonTopMenu
        onClick={() => {
          FindDuplicateClick();
        }}
        icon={find_duplicate}
        text={tr("duplicates")}
      ></IconButtonTopMenu>
      <IconButtonTopMenu
        onClick={OpenClick}
        icon={print}
        disabled={true}
        text={tr("print")}
      ></IconButtonTopMenu>
      <IconButtonTopMenu
        onClick={() => {}}
        icon={document_arrow_down}
        disabled={true}
        text={tr("publish")}
      ></IconButtonTopMenu>
      <IconButtonTopMenu
        onClick={BoxClick}
        icon={tab_add_box}
        className={MenuIconState.ToolMode === ToolMode.Box ? "selected" : ""}
        text={tr("box")}
      ></IconButtonTopMenu>
      <IconButtonTopMenu
        onClick={LineClick}
        className={MenuIconState.ToolMode === ToolMode.Line ? "selected" : ""}
        icon={line}
        text={tr("line")}
      ></IconButtonTopMenu>
      <IconButtonTopMenu
        onClick={SectionClick}
        className={
          MenuIconState.ToolMode === ToolMode.Section ? "selected" : ""
        }
        icon={expand_up_right}
        text={tr("section")}
      ></IconButtonTopMenu>
      <IconButtonTopMenu
        onClick={SelectClick}
        className={MenuIconState.ToolMode === ToolMode.Select ? "selected" : ""}
        icon={cursor_click}
        text={tr("select")}
      ></IconButtonTopMenu>
      <IconButtonTopMenu
        onClick={MaskClick}
        className={MenuIconState.ToolMode === ToolMode.Mask ? "selected" : ""}
        icon={rename}
        text={tr("mask")}
      ></IconButtonTopMenu>
      <div className="separator"></div>
      <IconButtonTopMenu
        onClick={() => {}}
        icon={undo}
        disabled={true}
        text={tr("undo")}
      ></IconButtonTopMenu>
      <IconButtonTopMenu
        onClick={() => {}}
        icon={redo}
        disabled={true}
        text={tr("redo")}
      ></IconButtonTopMenu>
      <div className="separator"></div>
      <IconButtonTopMenu
        className={MenuIconState.ToolMode === ToolMode.Tag ? "selected" : ""}
        onClick={TagClick}
        icon={tag}
        text={tr("tag")}
      ></IconButtonTopMenu>
      <IconButtonTopMenu
        onClick={() => {}}
        icon={desktop}
        disabled={true}
        text={tr("preview")}
      ></IconButtonTopMenu>
      <MDFindDuplicates
        Show={ShowMDFindDuplicate.open}
        onClose={() => {
          CloseFindDuplicate();
        }}
      ></MDFindDuplicates>
    </div>
  );
};
export default MenuIcon;

import React, { useEffect, useRef, useState } from "react";
import * as Api from "../../Api/src";
import { useLang } from "../../i18n/useLang";
import { Button, Col, Modal, Row, Space } from "antd";
import useLoaddingAppStore from "../../ZustandStore/LoaddingAppStore";
import { LeftPanelService } from "../../Services/Hook/LeftPanel/LeftPanelService";
import Thumbnail from "./Thumbnail";
import WebViewer from "@pdftron/webviewer";
import { Core, WebViewerInstance } from "@pdftron/webviewer";
import { Item, Menu, useContextMenu } from "react-contexify";
import { AppCache } from "../../Cache/AppCache";
import useTabFileInfoStore from "../../ZustandStore/TabFileInfoStore";
import { PdfTronService } from "../../Services/NotHook/PdfTronService";
import usePageStore from "../../ZustandStore/PageStore";
const CheckDuplicateApi = new Api.CheckDuplicateApi();
interface PreviewCheckDuplicateModalProps {
  isShow: boolean;
  DuplicatePages: { [key: string]: Array<Api.PageStatus> } | null;
  onClose: () => void;
}
export type ThumbnailDuplicatePreview = {
  img: any;
  group: string;
  pageNoPreview: number;
} & Api.PageStatus;
const DocumentApi = new Api.DocumentApi();
export let webViewerInstanceCheckDuplicate: WebViewerInstance | null = null;

export default (props: PreviewCheckDuplicateModalProps) => {
  const { tr } = useLang();
  const [reloadView, SetReloadView] = useState<number>(0);
  const [reloadThumnail, SetReloadThumnail] = useState<number>(1);
  const { SetTabFileInfoState } = useTabFileInfoStore();
  const { SetPageStore } = usePageStore();
  const { SetLoaddingAppStore } = useLoaddingAppStore();
  const { LoadLeftPanelData } = LeftPanelService();
  const [selectPages, SetSelectPages] = useState<number[]>([1]);
  const { DeletePages } = PdfTronService();
  const [modalSize, SetModalSize] = useState<{ width: number; height: number }>(
    { height: window.innerHeight - 80, width: window.innerWidth - 80 }
  );
  useEffect(() => {
    if (props.isShow) {
      // setTimeout(() => {
      //   GetDocument();
      // }, 500);
    }
  }, [props.isShow]);
  useEffect(() => {
    SetReloadThumnail(reloadThumnail + 1);
  }, [props.DuplicatePages]);

  const onCloseModal = () => {
    CheckDuplicateApi.apiLegalxtractCloseDuplicateGet();
    props.onClose();
  };
  const apply = async () => {
    SetLoaddingAppStore({ Show: true });
    const param = ListDuplicatePage();
    const data =
      await CheckDuplicateApi.apiLegalxtractApplyStateForPageDuplicatePost(
        param
      );
    SetLoaddingAppStore({ Show: false });
    if (data.data) {
      if (data.data.result) {
        AppCache.DocumentInfo = data.data.result;
        SetPageStore({ TotalPages: data.data.result.totalPage || 0 });
        SetTabFileInfoState({ TabDetails: data.data.result.tabDetails || [] });
        if (data.data.result.totalPage == 0) {
          AppCache.WebViewerInstance?.Core.documentViewer.closeDocument();
        } else {
          DeletePages(
            param
              .filter((o) => o.status === Api.HiddenWithDeleteEnum.Delete)
              .map((o) => o.viewerPageNo || 0)
          ).then(() => {
            LoadLeftPanelData([]);
          });
        }
      }
    }
    props.onClose();
  };
  const ListDuplicatePage = () => {
    let pages: Api.PageStatus[] = [];
    for (const key in props.DuplicatePages) {
      const element = props.DuplicatePages[key];
      pages = [...pages, ...element];
    }
    return pages;
  };
  const changeStatus = (status: Api.HiddenWithDeleteEnum) => {
    for (const key in props.DuplicatePages) {
      const element = props.DuplicatePages[key];
      element.forEach((o) => {
        if (selectPages.includes(o.previewPageNo || 0)) {
          o.status = status;
        }
      });
    }
    SetReloadThumnail(reloadThumnail + 1);
  };
  const checkdisableButton = (status: Api.HiddenWithDeleteEnum) => {
    for (const key in props.DuplicatePages) {
      const element = props.DuplicatePages[key];
      const item = element.find(
        (o) => selectPages.includes(o.previewPageNo || 0) && o.status != status
      );
      if (item) {
        return false;
      }
    }
    return true;
  };

  return (
    <Modal
      title={tr("findDuplicates")}
      footer={
        <div style={{ width: "100%", display: "flex" }}>
          {reloadThumnail > 0 && (
            <>
              <Space>
                <Button
                  style={{ minWidth: 100 }}
                  disabled={checkdisableButton(Api.HiddenWithDeleteEnum.Show)}
                  onClick={() => {
                    changeStatus(Api.HiddenWithDeleteEnum.Show);
                  }}
                  type="default"
                >
                  {tr("show")}
                </Button>

                <Button
                  style={{ minWidth: 100 }}
                  disabled={checkdisableButton(Api.HiddenWithDeleteEnum.Hide)}
                  onClick={() => {
                    changeStatus(Api.HiddenWithDeleteEnum.Hide);
                  }}
                  type="default"
                >
                  {tr("hide")}
                </Button>

                <Button
                  style={{ minWidth: 100 }}
                  disabled={checkdisableButton(
                    Api.HiddenWithDeleteEnum.HideOnExtract
                  )}
                  onClick={() => {
                    changeStatus(Api.HiddenWithDeleteEnum.HideOnExtract);
                  }}
                  type="default"
                >
                  {tr("hideOnExtract")}
                </Button>

                <Button
                  style={{ minWidth: 100 }}
                  disabled={checkdisableButton(Api.HiddenWithDeleteEnum.Delete)}
                  onClick={() => {
                    changeStatus(Api.HiddenWithDeleteEnum.Delete);
                  }}
                  type="default"
                >
                  {tr("delete")}
                </Button>
              </Space>
              <div style={{ flex: 1 }}></div>
              <Space>
                <Button
                  style={{ minWidth: 100 }}
                  onClick={() => {
                    onCloseModal && onCloseModal();
                  }}
                  type="default"
                >
                  {tr("cancel")}
                </Button>
                <Button
                  style={{ minWidth: 100 }}
                  onClick={() => {
                    apply();
                  }}
                  type="primary"
                >
                  {tr("apply")}
                </Button>
              </Space>
            </>
          )}
        </div>
      }
      centered
      open={props.isShow}
      width={modalSize.width}
      styles={{ content: {}, body: { height: modalSize.height - 100 } }}
      onCancel={() => {
        onCloseModal && onCloseModal();
      }}
      maskClosable={false}
    >
      <Row style={{ height: "calc(100% - 18px)" }} gutter={[8, 8]}>
        <Col span={24}></Col>
        <ViewDocument
          DuplicatePages={props.DuplicatePages}
          reloadView={reloadView}
          changeStatus={(thumbnails) => {
            // props.DuplicatePages.thumbnails = [...thumbnails];
            SetReloadThumnail(reloadThumnail + 1);
          }}
          onSelectPages={(pages) => {
            SetSelectPages(pages);
            SetReloadThumnail(reloadThumnail + 1);
          }}
        ></ViewDocument>
      </Row>
    </Modal>
  );
};

const ViewDocument = (props: {
  DuplicatePages: {
    [key: string]: Api.PageStatus[];
  } | null;
  onContextMenu?: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  changeStatus: (thumbnails: Api.PageStatus[]) => void;
  onSelectPages?: (pages: number[]) => void;
  reloadView: number;
}) => {
  const getWidthLeft = () => {
    let max = 0;
    for (const key in props.DuplicatePages) {
      const element = props.DuplicatePages[key];
      if (element.length > max) {
        max = element.length;
      }
    }
    if (max >= 3) {
      return 500;
    }
    return 340;
  };
  const [width, setWidth] = useState<number>(getWidthLeft());
  useEffect(() => {
    if (props.reloadView > 0) {
    }
  }, [props.reloadView]);
  const [currentPage, setCurentPage] = useState<number>(1);
  const { SetLoaddingAppStore } = useLoaddingAppStore();
  const [ThumbnailDuplicatePreviews, SetThumbnailDuplicatePreviews] = useState<
    ThumbnailDuplicatePreview[]
  >([]);
  const initCheck = useRef(false);
  const pageNumberUpdatedTimeOut = useRef<any>(null);

  const viewer = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!initCheck.current) {
      initCheck.current = true;
      if (viewer.current != null) {
        WebViewer(
          {
            path: "/lib/pdftron",
            licenseKey:
              "demo:1712804211613:7f1522ce03000000003c9d6c10d25ea63f46b884bdfc5877de41976cd5",
            css: "/viewer.css",
            fullAPI: true,
            disabledElements: [
              "contextMenuPopup",
              "viewControlsButton",
              "viewControlsOverlay",
              "header",
              "toolsHeader",
              "pageNavOverlay",
              "textPopup",
            ],
          },
          viewer?.current
        ).then(async (instance) => {
          // PDFNet is only available with full API enabled
          const { PDFNet } = instance.Core;
          // Start the full workers
          await PDFNet.initialize(
            "demo:1712804211613:7f1522ce03000000003c9d6c10d25ea63f46b884bdfc5877de41976cd5"
          );
          webViewerInstanceCheckDuplicate = instance;
          instance.UI.setFitMode(instance.UI.FitMode.FitWidth);
          DocumentLoaded();
          PageNumberUpdated();
          GetDocument();
        });
      }
    }
  }, []);
  const GetDocument = () => {
    SetLoaddingAppStore({ Show: true });
    CheckDuplicateApi.apiLegalxtractShowDuplicateGet({
      responseType: "blob",
    }).then((res) => {
      if (res.status === 200 && res.data.size > 0) {
        webViewerInstanceCheckDuplicate?.Core.documentViewer.loadDocument(
          res.data
        );
        SetLoaddingAppStore({ Show: false });
      } else {
        SetLoaddingAppStore({ Show: false });
      }
    });
  };
  const PageNumberUpdated = () => {
    if (!webViewerInstanceCheckDuplicate?.Core) return;
    const { documentViewer } = webViewerInstanceCheckDuplicate?.Core;
    documentViewer.addEventListener(
      "pageNumberUpdated",
      (pageNumber: number) => {
        if (pageNumberUpdatedTimeOut.current) {
          clearTimeout(pageNumberUpdatedTimeOut.current);
        }
        pageNumberUpdatedTimeOut.current = setTimeout(async () => {
          setCurentPage(pageNumber);
        }, 300);
      }
    );
  };
  const DocumentLoaded = () => {
    if (!webViewerInstanceCheckDuplicate?.Core) return;
    const { documentViewer, PDFNet } = webViewerInstanceCheckDuplicate?.Core;
    documentViewer.addEventListener("documentLoaded", async () => {
      if (documentViewer.getPageCount() > 0) {
        buildThumbnails();
      }
      SetLoaddingAppStore({ Show: false });
    });
  };

  const buildThumbnails = () => {
    SetLoaddingAppStore({ Show: true });
    var tmpThumbnailDuplicatePreviews = [] as ThumbnailDuplicatePreview[];
    const DuplicatePages = props.DuplicatePages;
    let groupid = 1;
    for (const key in DuplicatePages) {
      const element = DuplicatePages[key];
      element.forEach((y, index) => {
        tmpThumbnailDuplicatePreviews.push({
          group: groupid.toString(),
          img: "",
          pageNoPreview: y.previewPageNo || 0,
          status: y.status,
          viewerPageNo: y.viewerPageNo,
        });
      });
      groupid = groupid + 1;
    }
    if (!webViewerInstanceCheckDuplicate?.Core) return;
    const { documentViewer } = webViewerInstanceCheckDuplicate?.Core;
    const doc = documentViewer.getDocument();
    tmpThumbnailDuplicatePreviews.forEach(
      (element: ThumbnailDuplicatePreview, index: number) => {
        doc.loadThumbnail(element.pageNoPreview || 0, (thumbnail) => {
          element.img = thumbnail;
          if (
            tmpThumbnailDuplicatePreviews.length > 0 &&
            index == tmpThumbnailDuplicatePreviews.length - 1
          ) {
            SetThumbnailDuplicatePreviews(tmpThumbnailDuplicatePreviews); // Draw thumbnail
            SetLoaddingAppStore({ Show: false });
          }
        });
      }
    );
  };

  return (
    <div
      style={{
        display: "flex",
        marginTop: 10,
        border: "solid 1px#b2b2b2",
        boxSizing: "border-box",
        height: "100%",
        width: "100%",
      }}
    >
      <div
        className="container split"
        style={{ display: "flex", width: "100%" }}
      >
        <div className="split__left" style={{ minWidth: width }}>
          {ThumbnailDuplicatePreviews?.length > 0 && (
            <Thumbnail
              DuplicatePages={props.DuplicatePages}
              ThumbnailDuplicatePreviews={ThumbnailDuplicatePreviews}
              currentPage={currentPage}
              onClickItem={(page) => {
                setCurentPage(page);
                if (!webViewerInstanceCheckDuplicate?.Core) return;
                const { documentViewer } =
                  webViewerInstanceCheckDuplicate?.Core;
                documentViewer.setCurrentPage(page, true);
              }}
              onSelectPages={(pages) => {
                props.onSelectPages && props.onSelectPages(pages);
              }}
              changedStatus={(thumbnails) => {
                props.changeStatus(thumbnails);
              }}
            ></Thumbnail>
          )}
        </div>
        <div className="split__bar"></div>
        <div
          className="split__right"
          ref={viewer}
          style={{ width: "100%" }}
        ></div>
      </div>
    </div>
  );
};

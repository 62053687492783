import { useEffect, useRef, useState } from "react";
import * as Api from "../../../Api/src/index";
import { AppLocalStorage } from "../../../Constant/AppContant";
import { SearchService } from "../../NotHook/SearchService";
import useTagStore from "../../../ZustandStore/TagStore";
import { Tag, TreeDataNode, TreeProps } from "antd";
import useSearchTabStore from "../../../ZustandStore/SearchTabStore";
export const TagSearchPanelService = () => {
  const { GetSearchTagParams } = SearchService();
  const { TagState } = useTagStore();
  const { SearchTabState, SetSearchTabStore } = useSearchTabStore();
  const [TreeTag, SetTreeTag] = useState<TreeDataNode[]>();
  const initCheck = useRef<boolean>(false);
  const getTreeTag = async () => {
    const tree = [] as TreeDataNode[];
    TagState.Tags?.tagCategories?.map(async (item) => {
      const children = [] as TreeDataNode[];
      const tagNames = TagState.Tags?.tagNames?.filter(
        (o) => o.tagCategoryId === item.id
      );
      if (tagNames?.length === 0) return;
      tagNames?.map((child) => {
        children.push({
          title: child.keyword,
          key: child.id!.toString(),
        });
      });
      tree.push({
        title: item.keyword,
        key: item.id!.toString(),
        children: children,
      });
    });
    SetTreeTag(tree);
  };
  useEffect(() => {
    getTreeTag();
  }, [TagState]);
  // useEffect(() => {
  //   if (!initCheck.current) {
  //     const obj = GetSearchTagParams();
  //     if (obj) SetTagSearchParam(obj);
  //     getTreeTag();
  //     initCheck.current = true;
  //   }
  // }, []);
  const onCheckTagNode: TreeProps["onCheck"] = (checkedKeys, info) => {
    console.log(
      "onCheck",
      TagState.Tags?.tagNames?.length,
      (checkedKeys as string[]).length
    );
    const checkedTagNameIds = info.checkedNodes
      ?.filter((o) => !o.children)
      .map((o) => o.key);
    SetSearchTabStore({
      ...SearchTabState,
      SearchTabVm: {
        ...SearchTabState.SearchTabVm,
        searchTagParam: {
          ...SearchTabState.SearchTabVm?.searchTagParam,
          checkAll:
            checkedTagNameIds.length === TagState.Tags?.tagNames?.length,
          selectedTags: (checkedTagNameIds as string[]) || [],
        },
      },
    });
    console.log("onCheck", checkedKeys, info);
  };
  const CheckAll = (checked: boolean) => {
    if (!checked) {
      SetSearchTabStore({
        ...SearchTabState,
        SearchTabVm: {
          ...SearchTabState.SearchTabVm,
          searchTagParam: {
            ...SearchTabState.SearchTabVm?.searchTagParam,
            selectedTags: [],
          },
        },
      });
    } else {
      SetSearchTabStore({
        ...SearchTabState,
        SearchTabVm: {
          ...SearchTabState.SearchTabVm,
          searchTagParam: {
            ...SearchTabState.SearchTabVm?.searchTagParam,
            selectedTags: TagState.Tags?.tagNames?.map((o) => o.id || "") || [],
          },
        },
      });
    }
  };
  return {
    SearchTabState,
    SetSearchTabStore,
    TreeTag,
    onCheckTagNode,
    CheckAll,
  };
};

import { create } from "zustand";
import { persist } from "zustand/middleware";
import * as Api from "./../Api/src/index";
import { AppCache } from "../Cache/AppCache";
interface TabFileInfoState {
  TabDetails?: Api.TabDetail[];
  CurrentTab?: Api.TabDetail | null;
}
interface TabFileInfoStore {
  TabFileInfoState: TabFileInfoState;
  SetTabFileInfoState: (tabFileInfoState: TabFileInfoState) => void;
  GetTabInfoById: (id: string) => Api.TabDetail | null;
  GetTabFileInfoState: () => TabFileInfoState;
}
const useTabFileInfoStore = create<TabFileInfoStore>()((set, get) => ({
  TabFileInfoState: {
    TabDetails: [],
    CurrentTab: null,
  } as TabFileInfoState,
  SetTabFileInfoState: (tabFileInfoState) =>
    set((state) => {
      AppCache.CurrentTabId = tabFileInfoState.CurrentTab?.uniqueId || null;
      return {
        ...state,
        TabFileInfoState: { ...state.TabFileInfoState, ...tabFileInfoState },
      };
    }),
  GetTabInfoById: (id) => {
    var tab = get().TabFileInfoState.TabDetails?.find((x) => x.uniqueId === id);
    return tab ? tab : null;
  },
  GetTabFileInfoState: () => {
    return get().TabFileInfoState!;
  },
}));
export default useTabFileInfoStore;

import { create } from "zustand";
import * as Api from "../Api/src/index";
import { TreeDataNode } from "antd";
interface BookmarkState {
  BookmarkRows?: Api.BookmarkObj[];
  BookmarkTree?: TreeDataNode[];
  BookmarkSelectedKeys?: string[];
}
interface BookmarkStore {
  BookmarkState: BookmarkState;
  SetBookmarkStore: (BookmarkState: BookmarkState) => void;
}
const useBookmarkStore = create<BookmarkStore>()((set) => ({
  BookmarkState: {
    BookmarkTree: [],
    BookmarkRows: [],
    BookmarkSelectedKeys: [],
  },
  SetBookmarkStore: (BookmarkState) =>
    set((state) => ({
      ...state,
      BookmarkState: { ...state.BookmarkState, ...BookmarkState },
    })),
}));
export default useBookmarkStore;

import React from "react";
import {
  Tree,
  TreeItem,
  TreeItemRenderContext,
  TreeInformation,
  TreeItemIndex,
  DraggingPosition,
  ControlledTreeEnvironment,
} from "react-complex-tree";
import "react-complex-tree/lib/style-modern.css";
import { useLang } from "../../../i18n/useLang";
import * as Api from "../../../Api/src/index";
import { Input } from "antd";
import { TocPanelService } from "../../../Services/Hook/LeftPanel/TocPanelService";
import MDAddBlankPage from "../../../Modal/MDAddBlankPage";
const TocApi = new Api.TocApi();
const TreeToc = () => {
  const { tr } = useLang();
  const {
    TocState,
    SetTocStore,
    reOrderToc,
    DrawMenuItem,
    UpdateTOC,
    dropNodes,
    selectedItems,
    setSelectedItems,
    expandedItems,
    setExpandedItems,
    itemTrees,
    tree,
    maxLevel,
    goToDocument,
    SetTocFocus,
    ShowMenu,
    SetShowMdAddBlankPage,
    ShowMdAddBlankPage,
  } = TocPanelService();
  return (
    <>
      <div className="header border-left border-bottom">
        <div
          className="cell"
          style={{
            minWidth: (maxLevel - 1) * 10 + 58,
            display: "flex",
            alignItems: "center",
            boxSizing: "border-box",
          }}
        >
          {tr("index")}
        </div>
        <div className=" border-left border-right" style={{ flex: 1 }}>
          <div className="cell border-bottom" style={{ height: 21 }}>
            {tr("filename")}
          </div>
          <div style={{ display: "flex" }}>
            <div className=" cell border-right" style={{ flex: 1 }}>
              {tr("date")}
            </div>
            <div className="cell border-right" style={{ flex: 1 }}>
              {tr("appendix")}
            </div>
            <div className="cell" style={{ flex: 1, height: 22 }}>
              {tr("text")}
            </div>
          </div>
        </div>
        <div className=" cell page" style={{ width: 75 }}>
          {tr("page")}
        </div>
      </div>
      <div
        onContextMenu={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          ShowMenu(ev);
        }}
      >
        <ControlledTreeEnvironment<Api.TocRow>
          canDropOnFolder
          canSearch={false}
          canDragAndDrop={true}
          canReorderItems={true}
          onDrop={(items: TreeItem<Api.TocRow>[], target: DraggingPosition) => {
            const treeData = dropNodes(items, target);
            let parentid = (target as any).parentItem;
            if (parentid === "root") {
              parentid = "00000000-0000-0000-0000-000000000000";
            }
            if (target.targetType === "item") {
              parentid = (target as any).targetItem;
            }
            reOrderToc(items, parentid, treeData);
          }}
          items={itemTrees}
          getItemTitle={(item: TreeItem<Api.TocRow>) => item.data.id || ""}
          onSelectItems={(items: TreeItemIndex[]) => {
            setSelectedItems(items);
          }}
          onExpandItem={(item: TreeItem<Api.TocRow>) =>
            setExpandedItems([...expandedItems, item.index])
          }
          onCollapseItem={(item: TreeItem<Api.TocRow>) =>
            setExpandedItems(expandedItems.filter((o) => o !== item.index))
          }
          viewState={{
            "tree-1": { selectedItems, expandedItems },
          }}
          renderItem={(props: {
            item: TreeItem<Api.TocRow>;
            depth: number;
            children: React.ReactNode;
            title: React.ReactNode;
            arrow: React.ReactNode;
            context: TreeItemRenderContext<never>;
            info: TreeInformation;
          }) => {
            props.context.interactiveElementProps.onClick = (ev: any) => {
              if (ev.ctrlKey) {
                const id = (ev.target as any).parentNode.getAttribute(
                  "data-rct-item-id"
                );
                if (selectedItems.find((o) => o == id)) {
                  props.context.unselectItem();
                } else {
                  props.context.addToSelectedItems();
                }
              } else {
                props.context.selectItem();
              }
            };
            props.context.interactiveElementProps.onFocus = (ev) => {};
            props.context.interactiveElementProps.onContextMenu = () => {
              const tocItem = TocState.TocRows?.find(
                (o) => (o.id || "") === props.item.index
              );
              if (selectedItems.length == 1 || selectedItems.length == 0) {
                props.context.selectItem();
              }
              SetTocFocus(tocItem);
            };
            return (
              <li {...props.context.itemContainerWithChildrenProps}>
                <div
                  {...props.context.itemContainerWithoutChildrenProps}
                  {...(props.context.interactiveElementProps as any)}
                  onDoubleClick={() => {
                    goToDocument(props.item.data);
                  }}
                  style={{
                    display: "flex",
                    borderTop: "1px solid #d9d9d9",
                    borderBottom: "1px solid #d9d9d9",
                    borderLeft: "1px solid #d9d9d9",
                    height: 43,
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {props.item && (props.item.children?.length || 0) > 0
                      ? props.arrow
                      : ""}
                  </div>
                  <div
                    className="toccol"
                    style={{
                      display: "flex",
                      padding: "0 10px",
                      minWidth:
                        (maxLevel -
                          (props.item.data.indexView?.split(".")?.length ||
                            1)) *
                          10 +
                        50 +
                        ((props.item.children?.length || 0) > 0 ? 2 : 8),
                      boxSizing: "border-box",
                    }}
                  >
                    {props.item.data.indexView}
                  </div>
                  <div className="toccol" style={{ flex: "1" }}>
                    <div className="" style={{ width: "100%" }}>
                      <Input
                        style={{ width: "100%" }}
                        value={props.item.data.fileName || ""}
                        onChange={(e) => {
                          let item = (TocState.TocRows || []).find(
                            (o) => o.id == props.item.index
                          );
                          if (item && item.fileName != e.target.value) {
                            item = {
                              ...item,
                              fileName: e.target.value,
                            };
                            console.log(TocState.TocRows);
                            const newarrItem = TocState.TocRows?.filter(
                              (o) => o.uniqueId != item?.uniqueId
                            );
                            const curItem = TocState.TocRows?.find(
                              (o) => o.uniqueId == item?.uniqueId
                            );
                            newarrItem!.splice(
                              TocState.TocRows!?.indexOf(curItem || {}),
                              0,
                              item || {}
                            );
                            SetTocStore({ TocRows: newarrItem });
                            UpdateTOC({
                              ...item,
                              fileName: e.target.value,
                            });
                          }
                        }}
                      ></Input>
                    </div>
                    <div style={{ width: "100%", display: "flex" }}>
                      <div
                        className="border-right border-top"
                        style={{ width: "100%" }}
                      >
                        <Input
                          value={props.item.data.date || ""}
                          onChange={(e) => {
                            let item = (TocState.TocRows || []).find(
                              (o) => o.id == props.item.index
                            );
                            if (item && item.date != e.target.value) {
                              item = {
                                ...item,
                                date: e.target.value,
                              };
                              const newarrItem = TocState.TocRows?.filter(
                                (o) => o.uniqueId != item?.uniqueId
                              );
                              const curItem = TocState.TocRows?.find(
                                (o) => o.uniqueId == item?.uniqueId
                              );
                              newarrItem!.splice(
                                TocState.TocRows!?.indexOf(curItem || {}),
                                0,
                                item || {}
                              );
                              SetTocStore({ TocRows: newarrItem });
                              UpdateTOC({
                                ...item,
                                date: e.target.value,
                              });
                            }
                          }}
                          style={{ width: "100%" }}
                        ></Input>
                      </div>
                      <div
                        className="border-right border-top"
                        style={{ width: "100%" }}
                      >
                        <Input
                          style={{ width: "100%" }}
                          value={props.item.data.appendix || ""}
                          onChange={(e) => {
                            let item = (TocState.TocRows || []).find(
                              (o) => o.id == props.item.index
                            );
                            if (item && item.appendix != e.target.value) {
                              item = {
                                ...item,
                                appendix: e.target.value,
                              };
                              const newarrItem = TocState.TocRows?.filter(
                                (o) => o.uniqueId != item?.uniqueId
                              );
                              const curItem = TocState.TocRows?.find(
                                (o) => o.uniqueId == item?.uniqueId
                              );
                              newarrItem!.splice(
                                TocState.TocRows!?.indexOf(curItem || {}),
                                0,
                                item || {}
                              );
                              SetTocStore({ TocRows: newarrItem });
                              UpdateTOC({
                                ...item,
                                appendix: e.target.value,
                              });
                            }
                          }}
                        ></Input>
                      </div>
                      <div className="border-top" style={{ width: "100%" }}>
                        <Input
                          value={props.item.data.text || ""}
                          onChange={(e) => {
                            let item = (TocState.TocRows || []).find(
                              (o) => o.id == props.item.index
                            );
                            if (item && item.text != e.target.value) {
                              item = {
                                ...item,
                                text: e.target.value,
                              };
                              const newarrItem = TocState.TocRows?.filter(
                                (o) => o.uniqueId != item?.uniqueId
                              );
                              const curItem = TocState.TocRows?.find(
                                (o) => o.uniqueId == item?.uniqueId
                              );
                              newarrItem!.splice(
                                TocState.TocRows!?.indexOf(curItem || {}),
                                0,
                                item || {}
                              );
                              SetTocStore({ TocRows: newarrItem });
                              UpdateTOC({
                                ...item,
                                text: e.target.value,
                              });
                            }
                          }}
                          style={{ width: "100%" }}
                        ></Input>
                      </div>
                    </div>
                  </div>
                  <div
                    className="toccol"
                    style={{ display: "flex", padding: "0 10px", width: 55 }}
                  >
                    {props.item.data.page}
                  </div>
                </div>
                {props.children}
              </li>
            );
          }}
        >
          <Tree treeId="tree-1" rootItem="root" ref={tree} />
        </ControlledTreeEnvironment>
      </div>

      {DrawMenuItem()}
      {ShowMdAddBlankPage.open && (
        <MDAddBlankPage
          open={ShowMdAddBlankPage.open}
          name={ShowMdAddBlankPage.name}
          onClose={() => {
            SetShowMdAddBlankPage({ open: false, name: "", tocIDs: [] });
          }}
          selectedTabIds={ShowMdAddBlankPage.tocIDs}
        ></MDAddBlankPage>
      )}
    </>
  );
};
export default TreeToc;

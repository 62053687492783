import UndoRedoManagerObj from "../../UndoRedomanager";
import * as Api from "../../../Api/src/index";
import { AppCache } from "../../../Cache/AppCache";
import { PageService } from "../../../Services/NotHook/PageService";
const BoxApi = new Api.BoxApi();
export const EditBoxCommand = () => {
  const { SetDataCurrentPage } = PageService();
  const EditBox = async (pageNo: number, param: Api.AddBoxParam) => {
    var res = await BoxApi.apiLegalxtractEditBoxPost(pageNo, param);
    var box = AppCache.DataCurrentPage?.boxObjs?.find((o) => o.id === param.id);
    if (AppCache.CurrentPageNo === pageNo) {
      SetDataCurrentPage(res.data.result?.pageData! || null, pageNo || 0);
    }
    return { box, pageNo: AppCache.CurrentPageNo };
  };
  const Do = async (pageNo: number, param: Api.AddBoxParam) => {
    const res = await EditBox(pageNo, param);
    UndoRedoManagerObj.add({
      Redo: () => {
        RedoEdit(pageNo, param);
      },
      Undo: () => {
        UndoEdit(res.pageNo, {
          height: res.box?.rect?.bottom,
          id: res.box?.id,
          left: res.box?.rect?.left,
          top: res.box?.rect?.top,
          width: res.box?.rect?.width,
        });
      },
    });
  };

  const RedoEdit = (pageNo: number, param: Api.AddBoxParam) => {
    EditBox(pageNo, param);
  };
  const UndoEdit = (pageNo: number, addBoxParam: Api.AddBoxParam) => {
    Do(pageNo, addBoxParam);
  };
  return { Do };
};

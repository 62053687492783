import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  ColorPicker,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
} from "antd";
import { useLang } from "../../i18n/useLang";
import { MDReplaceBoxService } from "../../Services/Hook/Modal/Replace/MDReplaceBoxService";
import loadding from "../../Assets/IconApp/loading.gif";
const MDReplaceBox = () => {
  const { tr } = useLang();
  const { formik, fontList, Close, ModalState, imageState } =
    MDReplaceBoxService();
  return (
    <>
      <Modal
        title={tr("replace")}
        footer={
          <>
            <Button
              style={{ minWidth: 100 }}
              type="default"
              onClick={() => {
                Close();
              }}
            >
              {tr("close")}
            </Button>
            <Button
              style={{ minWidth: 100 }}
              onClick={() => {
                formik.handleSubmit();
              }}
              type="primary"
            >
              {tr("replace")}
            </Button>
          </>
        }
        centered
        open={ModalState.Show}
        maskClosable={false}
        width={550}
        onCancel={() => {
          Close();
        }}
      >
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <label>{tr("originalWords")}</label>
            <div
              style={{
                width: "100%",
                height: 150,
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                background: "#ddd",
                border: "1px solid #ddd",
              }}
            >
              {(imageState && (
                <img
                  style={{ maxHeight: "100%" }}
                  src={imageState}
                ></img>
              )) || (
                <img
                  width="40"
                  src={loadding}
                ></img>
              )}
            </div>
          </Col>
          <Col span={24}>
            <label>{tr("replaceBy")}</label>
            <Input
              value={formik.values.replaceBy || ""}
              name="replaceBy"
              onChange={formik.handleChange}
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={12}>
            <label>{tr("font")}</label>
            <Select
              value={formik.values.fontName}
              onChange={(value) => {
                formik.setFieldValue("fontName", value);
              }}
              style={{ width: "100%" }}
              options={fontList}
            />
          </Col>
          <Col span={12}>
            <label>{tr("size")}</label>
            <InputNumber
              min={1}
              step={0.5}
              value={formik.values.fontSize}
              onChange={(value) => {
                formik.setFieldValue("fontSize", value);
              }}
              style={{ width: "100%" }}
            ></InputNumber>
          </Col>
          <Col span={12}>
            <label>{tr("backgroundColor")}</label>
            <ColorPicker
              value={formik.values.extractColor}
              onChange={(value) => {
                formik.setFieldValue("extractColor", value.toHexString());
              }}
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={12}>
            <label>{tr("textColor")}</label>
            <ColorPicker
              value={formik.values.fontColor}
              onChange={(value) => {
                formik.setFieldValue("fontColor", value.toHexString());
              }}
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default MDReplaceBox;

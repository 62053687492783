import { AppCache, AppTools } from "../../Cache/AppCache";
import { ColorHelper } from "../../Common/AppHelpers";
import { ToolMode } from "../../Constant/AppEnum";
import { HightLightMaskCommand } from "../../UndoRedo/Commands/HightLightMaskCommand";
import { HightLightSectionCommand } from "../../UndoRedo/Commands/HightLightSectionCommand";
import * as Api from "../../Api/src/index";
import { Core } from "@pdftron/webviewer";
import { PdfTronService } from "./PdfTronService";
import { LineCommand } from "../../UndoRedo/Commands/Line/LineCommand";
import { v4 } from "uuid";
import { number } from "yup";
import { BoxCommand } from "../../UndoRedo/Commands/Box/BoxCommand";
import { AppLocalStorage } from "../../Constant/AppContant";
import { App } from "antd";
import { useContextMenu } from "react-contexify";
import { MENU_ID } from "../../Components/MainPanel/MenuContext";
export const ToolService = () => {
  const addBoxCommand = BoxCommand();
  const lineCommand = LineCommand();
  const hightLightSectionCommand = HightLightSectionCommand();
  const hightLightMaskCommand = HightLightMaskCommand();
  const pdfTronService = PdfTronService();
  const BoxTool = () => {
    if (AppCache.WebViewerInstance?.Core) {
      AppCache.ToolMode = ToolMode.Box;
      const { documentViewer, Tools, Annotations, annotationManager } =
        AppCache.WebViewerInstance?.Core;
      const tool = documentViewer.getTool(Tools.ToolNames.RECTANGLE);
      const color = ColorHelper.colorStringToRGB("Yellow");
      if (!color) return;
      tool.setStyles({
        StrokeColor: new Annotations.Color(color?.r, color?.g, color?.b, 0.5),
        FillColor: new Annotations.Color(color?.r, color?.g, color?.b, 0.5),
        StrokeThickness: 0.1,
      });
      tool.addEventListener("annotationAdded", async (annotation) => {
        var rect = annotation.getRect() as Core.Math.Rect;
        const newRect = pdfTronService.RectConvertToPDFCoordinates(
          {
            left: rect.getLeft(),
            top: rect.getTop(),
            right: rect.getRight(),
            bottom: rect.getBottom(),
          },
          documentViewer.getCurrentPage()
        );
        await addBoxCommand.DoAdd(documentViewer.getCurrentPage(), {
          height: newRect?.height || 0,
          left: newRect?.left || 0,
          top: newRect?.top || 0,
          width: newRect?.width || 0,
        });
        annotationManager.deleteAnnotation(annotation);
      });
      documentViewer.setToolMode(tool);
    }
  };
  const SectionTool = () => {
    if (!AppCache.WebViewerInstance?.Core) return;
    AppCache.ToolMode = ToolMode.Section;
    const { documentViewer, Tools, Annotations, annotationManager } =
      AppCache.WebViewerInstance?.Core;
    const tool = documentViewer.getTool(Tools.ToolNames.RECTANGLE3);
    const color = ColorHelper.colorStringToRGB("black");
    if (!color) return;
    tool.setStyles({
      StrokeColor: new Annotations.Color(color?.r, color?.g, color?.b, 1),
      FillColor: new Annotations.Color(0, 0, 0, 0),
      StrokeThickness: 0.5,
    });
    tool.addEventListener("annotationAdded", (annotation) => {
      try {
        if (annotation.PageNumber !== documentViewer.getCurrentPage()) return;
        const newRect = pdfTronService.RectConvertToPDFCoordinates(
          {
            left: annotation.X,
            top: annotation.Y,
            right: annotation.X + annotation.Width,
            bottom: annotation.Y + annotation.Height,
          },
          AppCache.CurrentPageNo
        );
        hightLightSectionCommand.Do(documentViewer.getCurrentPage(), {
          left: newRect?.left,
          top: newRect?.top,
          height: newRect?.height,
          width: newRect?.width,
        });
      } finally {
        setTimeout(() => {
          annotationManager.deleteAnnotation(annotation);
        }, 250);
      }
    });
    documentViewer.setToolMode(tool);
  };
  const SelectTool = () => {
    if (!AppCache.WebViewerInstance?.Core) return;
    AppCache.ToolMode = ToolMode.Select;
    const { documentViewer, Tools } = AppCache.WebViewerInstance?.Core;
    const tool = documentViewer.getTool(Tools.ToolNames.PAN);
    documentViewer.setToolMode(tool);
  };
  const MaskTool = () => {
    if (!AppCache.WebViewerInstance?.Core) return;
    AppCache.ToolMode = ToolMode.Mask;
    const { documentViewer, Tools, Math } = AppCache.WebViewerInstance?.Core;
    if (!AppTools.MaskTool) {
      const tool = documentViewer.getTool(Tools.ToolNames.TEXT_SELECT);
      tool.removeEventListener("selectionComplete.Tools");
      tool.addEventListener(
        "selectionComplete",
        (startLocation: any, allQuads: Core.Math.Quad[]) => {
          const rectangleSelections: Api.RectangleSelection[] | null = [];
          (
            allQuads[startLocation.pageNumber] as any as Core.Math.Quad[]
          ).forEach((quad: Core.Math.Quad) => {
            var newQuad = new Math.Quad(
              quad.x1,
              quad.y1,
              quad.x2,
              quad.y2,
              quad.x3,
              quad.y3,
              quad.x4,
              quad.y4
            );
            const rect = newQuad.toRect();
            const newRect = pdfTronService.RectConvertToPDFCoordinates(
              {
                left: rect.getLeft(),
                top: rect.getTop(),
                right: rect.getRight(),
                bottom: rect.getBottom(),
              },
              AppCache.CurrentPageNo
            );
            rectangleSelections.push({
              height: window.Math.abs(newRect?.height || 0),
              left: newRect?.left,
              top: newRect?.top,
              width: newRect?.width,
            });
          });
          const lxSetting = AppLocalStorage.GetItem(
            AppLocalStorage.LxSetting
          ) as Api.LxSetting;
          if (lxSetting.autoHighlightMask) {
            hightLightMaskCommand.Do(
              Api.HighLightMode.None,
              AppCache.CurrentPageNo,
              rectangleSelections
            );
          }else{

          }
        }
      );
      AppTools.MaskTool = tool;
    }
    documentViewer.setToolMode(AppTools.MaskTool);
  };
  const { show, hideAll } = useContextMenu({
    id: MENU_ID,
  });
  function ShowContextMenu(event: any) {
    hideAll();
    setTimeout(() => {
      event.preventDefault();
      event.stopPropagation();
      show({
        event,
        position: { x: event.screenX, y: event.screenY - 100 },
      });
    }, 200);
  }
  const LineTool = () => {
    if (!AppCache.WebViewerInstance?.Core) return;
    AppCache.ToolMode = ToolMode.Line;
    const { documentViewer, Annotations, annotationManager, Tools } =
      AppCache.WebViewerInstance?.Core;
    const tool = documentViewer.getTool(Tools.ToolNames.LINE);
    documentViewer.setToolMode(tool);
    const color = ColorHelper.colorStringToRGB(
      AppCache.LineBorderWidth?.colorAdd!
    );
    if (!color) return;
    tool.setStyles({
      StrokeColor: new Annotations.Color(color?.r, color?.g, color?.b, 1),
      StrokeThickness: AppCache.LineBorderWidth?.borderAdd!,
    });
    tool.addEventListener("annotationAdded", async (annotation) => {
      try {
        const newRect = pdfTronService.RectConvertToPDFCoordinates(
          {
            left: annotation.X,
            top: annotation.Y,
            right: annotation.X + annotation.Width,
            bottom: annotation.Y + annotation.Height,
          },
          AppCache.CurrentPageNo
        );
        let width = newRect?.width || 0;
        let height = newRect?.height || 0;
        if (width > height) {
          height = 0;
        } else {
          width = 0;
        }
        lineCommand.Do(documentViewer.getCurrentPage(), {
          borderWidth: AppCache.LineBorderWidth?.borderAdd || 0.02 * 72,
          color: AppCache.LineBorderWidth?.colorAdd || "#323130",
          height: height,
          left: newRect?.left,
          top: newRect?.top,
          width: width,
          id: v4(),
        });
      } finally {
        annotationManager.deleteAnnotation(annotation);
      }
    });
  };
  return { BoxTool, SectionTool, SelectTool, MaskTool, LineTool };
};

import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  ColorPicker,
  InputNumber,
  Modal,
  Row,
  Select,
  Slider,
} from "antd";
import { useLang } from "../i18n/useLang";
import TextArea from "antd/es/input/TextArea";
import {
  MDNoteProps,
  MDNoteService,
} from "../Services/Hook/Modal/MDNoteService";
const MDNote = ({ onClose, open, editItem }: MDNoteProps) => {
  const { tr } = useLang();
  const { formik, fontList } = MDNoteService({
    onClose,
    editItem,
  });
  return (
    <>
      <Modal
        title={tr("note")}
        footer={
          <>
            <Button
              style={{ minWidth: 100 }}
              type="default"
              onClick={() => {
                onClose && onClose();
              }}
            >
              {tr("close")}
            </Button>
            <Button
              style={{ minWidth: 100 }}
              onClick={() => {
                formik.handleSubmit();
              }}
              type="primary"
            >
              {tr("save")}
            </Button>
          </>
        }
        centered
        open={open}
        maskClosable={false}
        width={550}
        onCancel={() => {
          onClose && onClose();
        }}
      >
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <label>{tr("note")}</label>
            <TextArea
              value={formik.values.noteText || ""}
              name="noteText"
              onChange={formik.handleChange}
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={12}>
            <label>{tr("fontColor")}</label>
            <ColorPicker
              value={formik.values.foreColor}
              onChange={(value) => {
                formik.setFieldValue("foreColor", value.toHexString());
              }}
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={12}>
            <label>{tr("backColor")}</label>
            <ColorPicker
              value={formik.values.backColor}
              onChange={(value) => {
                formik.setFieldValue("backColor", value.toHexString());
              }}
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={12}>
            <label>{tr("font")}</label>
            <Select
              value={formik.values.fontName}
              onChange={(value) => {
                formik.setFieldValue("fontName", value);
              }}
              style={{ width: "100%" }}
              options={fontList}
            />
          </Col>
          <Col span={12}>
            <label>{tr("size")}</label>
            <InputNumber
              min={1}
              step={0.5}
              value={formik.values.fontSize}
              onChange={(value) => {
                formik.setFieldValue("fontSize", value);
              }}
              style={{ width: "100%" }}
            ></InputNumber>
          </Col>
          <Col span={24}>
            <label>{tr("tranparent")}</label>
            <Slider
              min={0}
              max={100}
              value={formik.values.opacity}
              onChange={(value) => {
                formik.setFieldValue("opacity", value);
              }}
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={24}>
            <Checkbox
              checked={formik.values.visibleOnExtract}
              name="visibleOnExtract"
              onChange={formik.handleChange}
              style={{ width: "100%" }}
            >
              {tr("showOnExtract")}
            </Checkbox>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default MDNote;

import React from "react";
import { Avatar, Dropdown, Space } from "antd";
import { useLang } from "../../i18n/useLang";
import { ItemType } from "antd/es/menu/hooks/useItems";
import "../../Assets/Styles/TopMenu/TopMenuStrip.css";
import { MDTagList } from "../../Modal/Tag/MDTagList";
import { TopMenuStripService } from "../../Services/Hook/TopMenu/TopMenuStripService";
import MDStampSetting from "../../Modal/Stamp/MDStampSetting";
import MDSettings from "../../Modal/MDSettings";
import MDPageNumbering from "../../Modal/MDPageNumbering";
import { CheckOutlined } from "@ant-design/icons";
import { AppCache } from "../../Cache/AppCache";
import { PageToolbarService } from "../../Services/Hook/TopMenu/PageToolbarService";
import {
  abbreviateName,
  generateColorFromName,
} from "../../Common/CommonFunction";
export const TopMenuStrip = () => {
  const { tr } = useLang();
  const {
    FitPage,
    FitWidth,
    PageState,
    SetPageNo,
    ZoomIn,
    ZoomOut,
    RotateLeft,
    RotateRight,
  } = PageToolbarService();
  const {
    TagOptionsClick,
    TagOptionsClose,
    ShowStampSetting,
    SetShowStampSetting,
    ShowAppSetting,
    SetShowAppSetting,
    ShowPageNumbering,
    SetShowPageNumbering,
    ShowStampClick,
    TagState,
    AddBlankPage,
    MenuStripState,
    ShowPageNumberingClick,
    CloseClick,
    AddClick,
    OpenClick,
    LogoutClick,
    getUser,
    CreateFrontPage,
    UploadFrontPage,
  } = TopMenuStripService();
  const itemFiles: ItemType[] = [
    {
      key: "addFile",
      onClick: AddClick,
      label: (
        <div className="topmenuitem">
          <div className="label">{tr("addFile")}</div>
        </div>
      ),
    },
    {
      key: "open",
      onClick: OpenClick,
      label: (
        <div className="topmenuitem">
          <div className="label">{tr("open")}</div>
          <div>{"Ctrl+O"}</div>
        </div>
      ),
    },
    {
      key: "close",
      onClick: CloseClick,
      label: tr("close"),
    },
    {
      key: "save",
      label: (
        <div className="topmenuitem">
          <div className="label">{tr("save")}</div>
          <div>{"Ctrl+S"}</div>
        </div>
      ),
    },
    {
      key: "savePdf",
      label: tr("savePdf"),
    },
    {
      key: "saveAsZip",
      label: tr("saveAsZip"),
    },
    {
      key: "combinePdf",
      label: tr("combinePdf"),
    },
    {
      key: "print",
      label: tr("print"),
      children: [
        {
          key: "printExtract",
          label: tr("printExtract"),
        },
        {
          key: "printPdf",
          label: tr("printPdf"),
        },
        {
          key: "printComment",
          label: tr("printComment"),
        },
      ],
    },
    {
      key: "puslish",
      label: tr("publish"),
    },
  ];
  const itemEdits: ItemType[] = [
    {
      key: "addFile",
      label: (
        <div className="topmenuitem">
          <div className="label">{tr("undo")}</div>
          <div>{"Ctrl+Z"}</div>
        </div>
      ),
    },
    {
      key: "open",
      label: (
        <div className="topmenuitem">
          <div className="label">{tr("redo")}</div>
          <div>{"Ctrl+Y"}</div>
        </div>
      ),
    },
  ];
  const itemNavigations: ItemType[] = [
    {
      key: "firstPage",
      label: tr("firstPage"),
      disabled:
        (PageState.CurrentPageNo || 0) <= 1 || PageState.CurrentPageNo == 0,
      onClick: () => {
        SetPageNo(1);
      },
    },
    {
      key: "previousPage",
      label: tr("previousPage"),
      disabled: (PageState.CurrentPageNo || 0) <= 1,
      onClick: () => {
        SetPageNo((PageState.CurrentPageNo || 0) - 1);
      },
    },
    {
      key: "nextPage",
      label: tr("nextPage"),
      disabled:
        (PageState.CurrentPageNo || 0) >= (PageState.TotalPages || 0) ||
        PageState.CurrentPageNo == 0,
      onClick: () => {
        SetPageNo((PageState.CurrentPageNo || 0) + 1);
      },
    },
    {
      key: "lastPage",
      label: tr("lastPage"),
      disabled:
        (PageState.CurrentPageNo || 0) >= (PageState.TotalPages || 0) ||
        PageState.CurrentPageNo == 0,
      onClick: () => {
        SetPageNo(PageState.TotalPages || 0);
      },
    },
  ];
  const itemZooms: ItemType[] = [
    {
      key: "zoomIn",
      label: tr("zoomIn"),
      onClick: ZoomIn,
    },
    {
      key: "zoomOut",
      label: tr("zoomOut"),
      onClick: ZoomOut,
    },
    {
      key: "fitPage",
      label: tr("fitPage"),
      onClick: FitPage,
    },
    {
      key: "fitWidth",
      label: tr("fitWidth"),
      onClick: FitWidth,
    },
  ];
  const itemOptions: ItemType[] = [
    {
      key: "stamp",
      label: tr("stamp"),
      icon: MenuStripState.ShowStamp ? <CheckOutlined /> : null,
      children: [
        {
          key: "showStamp",
          onClick: ShowStampClick,
          label: tr("show"),
          icon: MenuStripState.ShowStamp ? <CheckOutlined /> : null,
        },
        {
          key: "settingStamp",
          label: tr("settings"),
          onClick: () => {
            SetShowStampSetting({ ...ShowStampSetting, open: true });
          },
        },
      ],
    },
    {
      key: "blankPage",
      icon: MenuStripState.AddBlankPage ? <CheckOutlined /> : null,
      label: tr("blankPage"),
      onClick: AddBlankPage,
    },
    {
      key: "pagenumbering",
      icon: MenuStripState.ShowPageNumbering ? <CheckOutlined /> : null,
      label: tr("pageNumbering"),
      children: [
        {
          key: "showPageNumbering",
          icon: MenuStripState.ShowPageNumbering ? <CheckOutlined /> : null,
          onClick: () => {
            ShowPageNumberingClick();
          },
          label: tr("show"),
        },
        {
          key: "settingPageNumbering",
          label: tr("settings"),
          onClick: () => {
            SetShowPageNumbering({ ...ShowPageNumbering, open: true });
          },
        },
      ],
    },
    {
      key: "toc",
      label: tr("toc"),
      children: [
        {
          key: "showToc",
          label: tr("show"),
        },
        {
          key: "settingsToc",
          label: tr("settings"),
        },
      ],
    },
    {
      key: "tagOptions",
      onClick: TagOptionsClick,
      label: tr("tagOptions"),
    },
    {
      key: "findDuplicates",
      label: tr("findDuplicates"),
    },
    {
      key: "frontPage",
      label: tr("frontPage"),
      children: [
        {
          key: "uploadFrontPage",
          onClick: () => {
            UploadFrontPage(
              !(AppCache.DocumentInfo?.isUploadFrontPage || false)
            );
          },
          icon: MenuStripState.UploadFrontPage ? <CheckOutlined /> : null,
          label: tr("uploadFrontPage"),
        },
        {
          key: "createFrontPage",
          onClick: () => {
            CreateFrontPage(
              !(AppCache.DocumentInfo?.isCreateFrontPage || false)
            );
          },
          icon: MenuStripState.CreateFrontPage ? <CheckOutlined /> : null,
          label: tr("createFrontPage"),
        },
      ],
    },
    {
      key: "language",
      label: tr("language"),
      children: [
        {
          key: "english",
          label: tr("english"),
        },
        {
          key: "danish",
          label: tr("danish"),
        },
        {
          key: "german",
          label: tr("german"),
        },
      ],
    },
    {
      key: "settings",
      label: tr("settings"),
      onClick: () => {
        SetShowAppSetting({ ...ShowAppSetting, open: true });
      },
    },
  ];
  const itemHelps: ItemType[] = [
    {
      key: "userGuide",
      label: tr("userGuide"),
    },
    {
      key: "keyboardShortcuts",
      label: tr("keyboardShortcuts"),
    },
  ];
  const itemUsers: ItemType[] = [
    // {
    //   key: "changePassword",
    //   label: tr("changePassword"),
    // },
    {
      key: "logout",
      onClick: LogoutClick,
      label: tr("logout"),
    },
  ];
  return (
    <div className="top-menu-strip">
      <Space direction="horizontal" size={"large"}>
        <Dropdown
          menu={{
            items: itemFiles,
          }}
          trigger={["click"]}
        >
          <a onClick={(e) => e.preventDefault()}>
            <Space>{tr("file")}</Space>
          </a>
        </Dropdown>
        <Dropdown
          menu={{
            items: itemEdits,
          }}
          trigger={["click"]}
        >
          <a onClick={(e) => e.preventDefault()}>
            <Space>{tr("edit")}</Space>
          </a>
        </Dropdown>
        <Dropdown
          menu={{
            items: itemNavigations,
          }}
          trigger={["click"]}
        >
          <a onClick={(e) => e.preventDefault()}>
            <Space>{tr("navigation")}</Space>
          </a>
        </Dropdown>
        <Dropdown
          menu={{
            items: itemZooms,
          }}
          trigger={["click"]}
        >
          <a onClick={(e) => e.preventDefault()}>
            <Space>{tr("zoom")}</Space>
          </a>
        </Dropdown>
        <Dropdown menu={{ items: itemOptions }} trigger={["click"]}>
          <a onClick={(e) => e.preventDefault()}>
            <Space>{tr("options")}</Space>
          </a>
        </Dropdown>
        <Dropdown
          menu={{
            items: itemHelps,
          }}
          trigger={["click"]}
        >
          <a onClick={(e) => e.preventDefault()}>
            <Space>{tr("help")}</Space>
          </a>
        </Dropdown>
      </Space>
      <Dropdown
        menu={{
          items: itemUsers,
        }}
        trigger={["click"]}
      >
        <Avatar
          style={{
            backgroundColor: generateColorFromName(
              abbreviateName(
                getUser()?.userDataLogin?.result?.user_info?.fullName || ""
              )
            ),
          }}
          size={34}
        >
          {abbreviateName(
            getUser()?.userDataLogin?.result?.user_info?.fullName || ""
          )}
        </Avatar>
      </Dropdown>
      {TagState.ShowTagList && (
        <MDTagList
          open={TagState.ShowTagList}
          onClose={TagOptionsClose}
        ></MDTagList>
      )}
      {ShowStampSetting.open && (
        <MDStampSetting
          open={ShowStampSetting.open}
          editItem={null}
          onClose={() => {
            SetShowStampSetting({ ...ShowStampSetting, open: false });
          }}
        ></MDStampSetting>
      )}
      {ShowAppSetting.open && (
        <MDSettings
          open={ShowAppSetting.open}
          onClose={() => {
            SetShowAppSetting({ ...ShowAppSetting, open: false });
          }}
        ></MDSettings>
      )}
      {ShowPageNumbering.open && (
        <MDPageNumbering
          open={ShowPageNumbering.open}
          onClose={() => {
            SetShowPageNumbering({ ...ShowPageNumbering, open: false });
          }}
        ></MDPageNumbering>
      )}
    </div>
  );
};

import "../../Assets/Styles/TabFiles/Tab.scss";
import React, { useState } from "react";
import { closestCenter, DndContext, DragOverlay } from "@dnd-kit/core";
import {
  SortableContext,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import { SortableTabItem, TabItem } from "./TabItem";
import { DownOutlined, PlusOutlined } from "@ant-design/icons";
import { TabFileInfoService } from "../../Services/Hook/TabFileInfoService";
import VTabs from "./VTabs";
import MDRenameTab from "../../Modal/MDRenameTab";
import * as Api from "../../Api/src/index";
export const Tabs = () => {
  const {
    handleDragCancel,
    TabDetails,
    GetTabFileInfoState,
    ShowVTabs,
    isVTabShow,
    CloseOneTab,
    OnClickTab,
    AppendFile,
    getHeight,
    activeId,
    sensors,
    handleDragEnd,
    handleDragStart,
    ShowMDRenameTab,
    SetShowMDRenameTab,
    DrawMenuItem,
    ShowMenuContext,
  } = TabFileInfoService();

  return (
    <div
      style={{ display: "flex", height: 40, borderBottom: "1px solid #d9d9d9" }}
    >
      <div
        style={{
          padding: "0 8px",
          cursor: "pointer",
          height: "100%",
          width: "40px",
          display: "flex",
          boxSizing: "border-box",
          borderRight: "1px solid #d9d9d9",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => {
          AppendFile();
        }}
      >
        <PlusOutlined />
      </div>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
      >
        <div id="hTabFiles" className="tabfile">
          {TabDetails.filter(
            (o) => o.tabFileType !== Api.TabFileType.Normal
          ).map((o) => (
            <TabItem
              tabType="Htab"
              key={o.id}
              id={o.id}
              TabDetail={o}
              selected={
                o.uniqueId === GetTabFileInfoState().CurrentTab?.uniqueId
              }
              height={getHeight()}
              onClick={() => {
                OnClickTab(o);
              }}
            />
          ))}
          <SortableContext
            items={TabDetails}
            strategy={horizontalListSortingStrategy}
          >
            {TabDetails.filter(
              (o) => o.tabFileType === Api.TabFileType.Normal
            ).map((o) => (
              <SortableTabItem
                tabType="Htab"
                key={o.id}
                id={o.id}
                TabDetail={o}
                selected={
                  o.uniqueId === GetTabFileInfoState().CurrentTab?.uniqueId
                }
                height={getHeight()}
                onCloseTab={(id) => {
                  CloseOneTab(id);
                }}
                onClick={() => {
                  OnClickTab(o);
                }}
                showMenu={(e, tab) => {
                  ShowMenuContext(e, tab || null);
                }}
              />
            ))}
          </SortableContext>
          <DragOverlay>
            {activeId ? (
              <TabItem
                tabType="Htab"
                id={activeId}
                selected={
                  activeId === GetTabFileInfoState().CurrentTab?.uniqueId
                }
                height={getHeight()}
                TabDetail={TabDetails.find((o) => o.id == activeId)}
                isDragging={true}
              />
            ) : null}
          </DragOverlay>
        </div>
      </DndContext>
      <div
        style={{
          padding: "0 8px",
          cursor: "pointer",
          height: "100%",
          display: "flex",
          width: "40px",
          justifyContent: "center",
          borderLeft: "1px solid #d9d9d9",
          boxSizing: "border-box",
          alignItems: "center",
          position: "relative",
        }}
        onMouseUp={() => {
          ShowVTabs();
        }}
      >
        <DownOutlined />
      </div>
      {isVTabShow ? <VTabs></VTabs> : <></>}
      {DrawMenuItem()}
      {ShowMDRenameTab.open && (
        <MDRenameTab
          name={ShowMDRenameTab.name}
          tabId={ShowMDRenameTab.tabId}
          onClose={() => {
            SetShowMDRenameTab({ ...ShowMDRenameTab, open: false });
          }}
          open={ShowMDRenameTab.open}
        ></MDRenameTab>
      )}
    </div>
  );
};

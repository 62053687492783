import React, { useState } from "react";
import type { RadioChangeEvent } from "antd";
import { Radio, Space, Tabs } from "antd";
import { useLang } from "../../../i18n/useLang";
import "../../../Assets/Styles/LeftPanels/FindPanel.scss";
import { KeyWordSearchPanel } from "./KeyWordSearchPanel";
import { TemplateSearchPanel } from "./TemplateSearchPanel";
import { SearchResult } from "./SearchResult";
import { FindPanelService } from "../../../Services/Hook/Search/FindPanelService";
import * as Api from "../../../Api/src";
import { TagSearchPanel } from "./TagSearchPanel";
const FindPanel: React.FC = () => {
  const { SetSearchTabStore, SearchTabState } = FindPanelService();
  const { tr } = useLang();
  const items = [
    {
      label: tr("keywordSearch"),
      key: Api.SearchTabType.Keyword.toString(),
      children: <KeyWordSearchPanel></KeyWordSearchPanel>,
    },

    {
      label: tr("templateSearch"),
      key: Api.SearchTabType.Template.toString(),
      children: <TemplateSearchPanel></TemplateSearchPanel>,
    },
    {
      label: tr("tagSearch"),
      key: Api.SearchTabType.Tag.toString(),
      children: <TagSearchPanel></TagSearchPanel>,
    },
  ];
  return (
    <div className="search-pn">
      <div className="header">{tr("find")}</div>
      <Tabs
        activeKey={SearchTabState.SearchTabVm?.searchTabType?.toString()}
        onChange={(key) => {
          SetSearchTabStore({
            ...SearchTabState,
            SearchTabVm: {
              ...SearchTabState.SearchTabVm,
              searchTabType: parseInt(key) as Api.SearchTabType,
            },
          });
        }}
        items={items}
      />
      <SearchResult></SearchResult>
    </div>
  );
};

export default FindPanel;

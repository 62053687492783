import { Button, Space, Table, TableColumnsType, TableProps } from "antd";
import Draggable from "react-draggable";
import "../../Assets/Styles/Modal/MDTagSelect.scss";
import { useLang } from "../../i18n/useLang";
interface TreeTagNode{
    key: string;
    color: string;
    label: string;
    children: { key: string; label: string }[];
}
import {
  CloseOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { MDTagSelectService } from "../../Services/Hook/Modal/Tags/MDTagSelectService";
export interface MDTagSelectProps {
  onShowTagList: () => void;
  onClose: () => void;
}
export const MDTagSelect = ({onClose, onShowTagList} : MDTagSelectProps) => {
  const {columns,expandedRowKeys,getNodes,onAddTag, setExpandedRowKeys} = MDTagSelectService()
  const { tr } = useLang();
  return (
    <Draggable bounds="parent" handle="strong">
      <div
        style={{
          position: "absolute",
          top: 325,
          right: "10rem",
          borderRadius: 4,
        }}
        className={"box no-cursor tag-form"}
      >
        <strong className="cursor header">
          <span>{tr("tag")}</span>
          <div style={{ flex: 1, textAlign: "end" }}>
            <Space>
              <Button  icon={<SettingOutlined />} onClick={() => {
                onShowTagList()
              }} />
              <Button onClick={() => {
                onClose()
              }} icon={<CloseOutlined />} />
            </Space>
          </div>
        </strong>
        <Table
          columns={columns}
          dataSource={getNodes()}
          pagination={false}
          scroll={{ y: 350 }}
          showHeader={false}
          style={{ marginBottom: 7 }}
          size="small"
          expandable={{
            expandedRowKeys: expandedRowKeys,
            onExpand: (expanded, record) => {
              if (expanded) {
                setExpandedRowKeys([...expandedRowKeys, record.key]);
              } else {
                setExpandedRowKeys(
                  expandedRowKeys.filter((o) => o !== record.key)
                );
              }
            },
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (!record.children || record.children.length == 0) {
                  onAddTag(record);
                }
              }, // click row
            };
          }}
          rowKey={"key"}
        />
      </div>
    </Draggable>
  );
};

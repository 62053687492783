import { create } from "zustand";
import { persist } from "zustand/middleware";
interface ConfirmDialogState {
  Show: boolean;
  Type?: "Confirm" | "ConfirmYesNoCancel" | "Alert";
  Icon?: "Info" | "Warning" | "Question";
  Title?: string;
  Msg?: string;
  YesText?: string;
  NoText?: string;
  CancelText?: string;
  OnYes?: () => void;
  OnCancel?: () => void;
  OnNo?: () => void;
}
interface ConfirmDialogStore {
  ConfirmDialogState: ConfirmDialogState;
  SetConfirmDialogState: (pageState: ConfirmDialogState) => void;
}
const UseConfirmDialogStore = create<ConfirmDialogStore>()((set) => ({
  ConfirmDialogState: { Show: false, Type: "Confirm", Title: "", Msg: "", Icon: "Info"},
  SetConfirmDialogState: (confirmDialogState) =>
    set((state) => ({
      ...state,
      ConfirmDialogState: {
        ...state.ConfirmDialogState,
        ...confirmDialogState,
      },
    })),
}));

export default UseConfirmDialogStore;

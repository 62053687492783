import { create } from "zustand";
import { persist } from "zustand/middleware";
import * as Api from "../Api/src/index";
interface NoteState {
  NoteTextObjs?: Api.NoteTextObj[];
}
interface NoteStore {
  NoteState: NoteState;
  SetNoteStore: (NoteState: NoteState) => void;
  GetNoteState: () => NoteState;
}
const useNoteStore = create<NoteStore>()((set, get) => ({
  NoteState: {
    NoteTextObjs: [],
  },
  SetNoteStore: (NoteState) =>
    set((state) => ({
      ...state,
      NoteState: { ...state.NoteState, ...NoteState },
    })),
  GetNoteState: () => {
    const state = get().NoteState;
    return state;
  },
}));

export default useNoteStore;

import { create } from "zustand";
import { persist } from "zustand/middleware";
import { AppCache } from "../Cache/AppCache";
export const LeftTabName = {
  pnFind: "pnFind",
  pnLog: "pnLog",
  pnNote: "pnNote",
  pnReplace: "pnReplace",
  pnThumbnail: "pnThumbnail",
  pnTOC: "pnTOC",
  pnWatchDog: "pnWatchDog",
  pnComment: "pnComment",
  pnBookmark: "pnBookmark",
} as const;
export type LeftTabName = (typeof LeftTabName)[keyof typeof LeftTabName];
interface LeftPanelState {
  LeftTabActive?: LeftTabName;
}
interface LeftPanelStore {
  LeftPanelState: LeftPanelState;
  SetLeftPanelStore: (LeftPanelState: LeftPanelState) => void;
}
const useLeftPanelStore = create<LeftPanelStore>()((set, get) => ({
  LeftPanelState: { LeftTabActive: LeftTabName.pnFind } as LeftPanelState,
  SetLeftPanelStore: (LeftPanelState) => {
    AppCache.LeftTabActive = LeftPanelState.LeftTabActive || LeftTabName.pnFind;
    set((state) => ({
      ...state,
      LeftPanelState: { ...state.LeftPanelState, ...LeftPanelState },
    }));
  },
}));

export default useLeftPanelStore;

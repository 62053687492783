import { useEffect, useRef, useState } from "react";
import * as Api from "../../../Api/src/index";
import { TreeDataNode, TreeProps } from "antd";
import useBookmarkStore from "../../../ZustandStore/BookmarkStore";
import { LeftPanelService } from "./LeftPanelService";
import { useContextMenu } from "react-contexify";
import { EventDataNode } from "antd/es/tree";
import { PdfTronService } from "../../NotHook/PdfTronService";
import { AppCache } from "../../../Cache/AppCache";
import { Core } from "@pdftron/webviewer";
import UseConfirmDialogStore from "../../../ZustandStore/ConfirmDialogStore";
import { useLang } from "../../../i18n/useLang";
export const MenuID = "BookmarkMenu";
const BookMarkApi = new Api.BookMarkApi();
export const BookmarkPanelService = () => {
  const { tr } = useLang();
  const { ConfirmDialogState, SetConfirmDialogState } = UseConfirmDialogStore();
  const { BookmarkState, SetBookmarkStore } = useBookmarkStore();
  const { LoadBookmark } = LeftPanelService();
  const [ShowAddBookMark, SetShowAddBookMark] = useState<{
    open: boolean;
    editItem?: Api.BookmarkObj | null;
  }>({ open: false, editItem: null });
  const { SetCurrentPageNo } = PdfTronService();
  const onRowClick = (event: any, record: EventDataNode<TreeDataNode>) => {
    SetBookmarkStore({
      BookmarkSelectedKeys: [record.key.toString()],
    });
    GoToBookMark(record.key.toString());
  };
  const { show, hideAll } = useContextMenu({
    id: MenuID,
  });
  function RightClick(event: any, record: EventDataNode<TreeDataNode>) {
    SetBookmarkStore({
      BookmarkSelectedKeys: [record.key.toString()],
    });
    GoToBookMark(record.key.toString());
    if (
      BookmarkState.BookmarkRows?.find(
        (o) => o.id === record.key.toString() && o.parentId !== null
      )
    ) {
      show({
        event,
      });
    }
  }
  const GoToBookMark = (key: string) => {
    if (
      BookmarkState.BookmarkRows?.find(
        (o) => o.id === key && o.parentId === null
      )
    ) {
      const tab = AppCache.DocumentInfo?.tabDetails?.find(
        (o) => o.uniqueId === key
      );
      if (tab) {
        SetCurrentPageNo(tab.startPage || 0);
      }
    } else {
      const bookmark = BookmarkState.BookmarkRows?.find((o) => o.id === key);
      if (bookmark) {
        if (!AppCache.WebViewerInstance?.Core) return;
        const { documentViewer, Annotations, annotationManager, Math } =
          AppCache.WebViewerInstance?.Core;
        const page = AppCache.DocumentInfo?.tabDetails
          ?.flatMap((o) => o.pages)
          .find((o) => o?.bookMarkIds?.includes(bookmark.id!));
        if (!page) return;
        const doc = documentViewer.getDocument();
        const newPos = doc.getViewerCoordinates(
          page?.viewerPageNo || 0,
          bookmark.position?.x || 0,
          bookmark.position?.y || 0
        );
        documentViewer.displayPageLocation(
          page?.viewerPageNo || 0,
          newPos.x,
          newPos.y
        );
      }
    }
  };
  const DeleteBookMark = () => {
    const Delete = async () => {
      var res = await BookMarkApi.apiLegalxtractDeleteBookMarkDelete(
        BookmarkState.BookmarkSelectedKeys?.[0] || ""
      );
      if (res && res.data.isSuccess) {
        AppCache.DocumentInfo?.tabDetails
          ?.flatMap((o) => o.pages)
          .forEach((p) => {
            if (p && p?.id === res.data?.result?.id) {
              p.bookMarkIds = res.data?.result?.bookMarkIds || [];
            }
          });
        if (AppCache.LeftTabActive === "pnBookmark") {
          LoadBookmark();
        }
      }
    };
    const bookmark = BookmarkState.BookmarkRows?.find(
      (o) => o.id === BookmarkState.BookmarkSelectedKeys?.[0] || ""
    );
    SetConfirmDialogState({
      Show: true,
      Type: "Confirm",
      Icon: "Question",
      CancelText: tr("no"),
      YesText: tr("yes"),
      Msg: tr("doYouWantToDelete") + ` [${bookmark?.title}]?`,
      OnYes: () => {
        Delete();
      },
    });
  };
  const EditBookMark = () => {
    const bookmark = BookmarkState.BookmarkRows?.find(
      (o) => o.id === BookmarkState.BookmarkSelectedKeys?.[0] || ""
    );
    SetShowAddBookMark({
      open: true,
      editItem: bookmark,
    });
  };
  const CloseMDAddBookMark = () => {
    SetShowAddBookMark({
      open: false,
      editItem: null,
    });
  };
  return {
    BookmarkState,
    LoadBookmark,
    onRowClick,
    RightClick,
    DeleteBookMark,
    EditBookMark,
    ShowAddBookMark,
    CloseMDAddBookMark,
  };
};
export const buildBookmarkTree = (
  flatList: Api.BookmarkObj[]
): TreeDataNode[] => {
  // Tạo một đối tượng để lưu trữ các nút theo id
  const nodes: { [key: string]: TreeDataNode } = {};

  // Tạo một đối tượng để lưu trữ cây
  const tree = [] as TreeDataNode[];

  // Khởi tạo các node từ danh sách phẳng

  flatList.forEach((item) => {
    nodes[item.id || ""] = {
      key: item.id,
      children: [],
      title: item.title,
    } as TreeDataNode;
  });
  // Xây dựng cây
  flatList.forEach((item) => {
    if (
      item.parentId === null ||
      item.parentId === "00000000-0000-0000-0000-000000000000"
    ) {
      // Nếu node không có parent, thêm vào gốc của cây
      tree.push(nodes[item.id || ""]);
    } else {
      // Nếu node có parent, thêm nó vào mảng children của parent
      nodes[item.parentId || ""].children?.push(nodes[item.id || ""]);
    }
  });
  return tree;
};

import * as Api from "../../Api/src/index";
import { AppLocalStorage } from "../../Constant/AppContant";
export const userService = () => {
  const getUser = (): Api.LoginResponse | null => {
    const userInfoCache = localStorage.getItem(AppLocalStorage.UserInfo);
    let userinfo: Api.LoginResponse | null = null;
    if (userInfoCache) {
      userinfo = JSON.parse(userInfoCache) as Api.LoginResponse;
    }
    return userinfo;
  };
  return { getUser };
};

import { useEffect, useRef, useState } from "react";
import * as Api from "../../../../Api/src/index";
import { v4 } from "uuid";
import { FormikErrors, useFormik } from "formik";
import * as yup from "yup";
import { useLang } from "../../../../i18n/useLang";
import { PageNumberingCommand } from "../../../../UndoRedo/Commands/PageNumberingCommand";
import { AppLocalStorage } from "../../../../Constant/AppContant";
import { AppCache } from "../../../../Cache/AppCache";
import UseModalStore, { ModaType } from "../../../../ZustandStore/ModalStore";
import useReplaceStore, {
  ReplaceParamWeb,
} from "../../../../ZustandStore/ReplaceStore";
import { on } from "events";
import { ReplaceWordCommand } from "../../../../UndoRedo/Commands/ReplaceWordCommand";
import { ReplaceAllSearchCommand } from "../../../../UndoRedo/Commands/ReplaceAllSearchCommand";
import { ReplaceAllItemSelectedCommand } from "../../../../UndoRedo/Commands/ReplaceAllItemSelectedCommand";
import { ReplacePanelService } from "../../LeftPanel/ReplacePanelService";
const FontApi = new Api.FontApi();
const ReplaceApi = new Api.ReplaceApi();

export interface MDReplaceWordProps {
  open?: boolean;
  onClose?: () => void;
}
export const MDReplaceWordService = () => {
  const { ModalState, GetModalState, SetModalState } = UseModalStore();
  const { GetReplaceState, SetReplaceStore } = useReplaceStore();
  const replaceWordCommand = ReplaceWordCommand();
  const replaceAllSearchCommand = ReplaceAllSearchCommand();
  const replaceAllItemSelectedCommand = ReplaceAllItemSelectedCommand();
  const [fontList, setFontList] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);
  const lxSetting = AppLocalStorage.GetItem(AppLocalStorage.LxSetting);
  const InitCheck = useRef<boolean>(false);
  useEffect(() => {
    if (InitCheck.current) return;
    GetFontList();
    InitCheck.current = true;
  }, []);
  const GetInitDataForm = (): ReplaceParamWeb => {
    if (GetModalState().Status === "Add") {
      return {
        ...GetReplaceState().ParamReplaceWord,
        fontName:
          GetReplaceState().ParamReplaceWord?.fontName ||
          lxSetting.replaceFontName,
        fontSize:
          GetReplaceState().ParamReplaceWord?.fontSize ||
          lxSetting.replaceFontSize,
      };
    } else {
      return (
        GetReplaceState().ParamReplaceWord || {
          fontColor: "#e74c3c",
          extractColor: "#ffffff",
          fontName: lxSetting.replaceFontName,
          fontSize: lxSetting.replaceFontSize,
          ReplaceType: "ReplaceWord",
        }
      );
    }
  };
  const formik = useFormik({
    initialValues: GetInitDataForm(),
    onSubmit: async (values) => {
      if (!values.id) {
        values.id = v4();
      }
      if (values.ReplaceType === "ReplaceWord")
        await replaceWordCommand.Do(values);
      if (values.ReplaceType === "ReplaceAllSearch")
        await replaceAllSearchCommand.Do(values);
      if (values.ReplaceType === "ReplaceAllItemSelected")
        await replaceAllItemSelectedCommand.Do(values);
      SetModalState({ Show: false, Type: ModaType.None });
      SetReplaceStore({
        ParamReplaceWord: {
          ...GetReplaceState().ParamReplaceWord,
          originalWords: "",
          replaceBy: "",
          id: "",
          matchCase: false,
          wholeWord: false,
        },
      });
    },
  });
  const GetFontList = () => {
    FontApi.apiLegalxtractGetListFontGet().then((res) => {
      if (res && res.data) {
        setFontList(res.data.map((item) => ({ value: item, label: item })));
      }
    });
  };
  const Close = () => {
    SetModalState({ Show: false, Type: ModaType.None });
  };
  return { formik, fontList, GetModalState, Close, ModalState };
};

import { Button, Col, Row, Space, Table, TableProps, Tag } from "antd";
import { useLang } from "../../../i18n/useLang";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { ReplacePanelService } from "../../../Services/Hook/LeftPanel/ReplacePanelService";
import { NotePanelService } from "../../../Services/Hook/LeftPanel/NotePanelService";
import MDNote from "../../../Modal/MDNote";
interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}
export const NotePanel = () => {
  const { tr } = useLang();
  const { columns, NoteState, ShowAddNote, SetShowAddNote } = NotePanelService();
  return (
    <div className="left-panel-container">
      <Row gutter={[8, 8]}>
        <Col span={24} className="header-panel">
          {tr("note")}
        </Col>
        <Col span={24}>
          <Table
            size="small"
            columns={columns}
            dataSource={NoteState.NoteTextObjs}
            pagination={false}
          />
          {ShowAddNote.open && (
        <MDNote
          open={ShowAddNote.open}
          onClose={() => {
            SetShowAddNote({ open: false, editItem: null });
          }}
          editItem={ShowAddNote.editItem}
        ></MDNote>
      )}
        </Col>
      </Row>
    </div>
  );
};

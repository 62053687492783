import { Alert, Button, Col, Input, Modal, Radio, Row } from "antd";
import {
  MDAddBookmarkProps,
  MDAddBookmarkService,
} from "../Services/Hook/Modal/MDAddBookmarkService";
import { useLang } from "../i18n/useLang";

export const MDAddBookmark = ({
  onClose,
  open,
  EditItem,
}: MDAddBookmarkProps) => {
  const { tr } = useLang();
  const { formik, showErrors, BookmarkState } = MDAddBookmarkService({
    onClose,
    EditItem,
  });
  return (
    <Modal
      title={tr("Bookmark")}
      footer={
        <>
          <Button
            style={{ minWidth: 100 }}
            onClick={() => {
              onClose && onClose();
            }}
            type="default"
          >
            {tr("close")}
          </Button>
          <Button
            style={{ minWidth: 100 }}
            onClick={() => {
              formik.submitForm();
            }}
            type="primary"
          >
            {tr("save")}
          </Button>
        </>
      }
      centered
      open={open}
      width={550}
      onCancel={() => {
        onClose && onClose();
      }}
      maskClosable={false}
    >
      <Row gutter={[8, 8]}>
        <Col span={24}>
          {!formik.isValid && (
            <Alert
              description={showErrors(formik.errors)}
              type="warning"
              showIcon
            />
          )}
        </Col>
        <Col span={24}>
          <label>{tr("name")}</label>
          <Input
            style={{ width: "100%" }}
            name="title"
            value={formik.values.title || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleChange}
          ></Input>
        </Col>
        <Col span={24}>
          <label>{tr("bookmarkPosition")}</label>
        </Col>
        <Col md={12}>
          <Radio
            checked={formik.values.IsCurrentMousePosition == false}
            disabled={EditItem != null}
            onClick={(value) => {
              formik.setFieldValue("IsCurrentMousePosition", false);
            }}
          >
            {tr("currentPage")}
          </Radio>
        </Col>
        <Col md={12}>
          <label style={{ color: "transparent" }}>asA</label>
          <Radio
            checked={formik.values.IsCurrentMousePosition == true}
            disabled={EditItem != null}
            onClick={() => {
              formik.setFieldValue("IsCurrentMousePosition", true);
            }}
          >
            {tr("currentMousePosition")}
          </Radio>
        </Col>
        <Col span={24}>
          <label>{tr("bookmarkWillBeAddedTo0").replaceAll("{0}", "")}</label>
          <label>
            {formik.values?.parentId &&
              BookmarkState.BookmarkRows?.find(
                (o) => o.id == formik.values?.parentId
              )?.title}
          </label>
        </Col>
      </Row>
    </Modal>
  );
};

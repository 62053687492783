import { useSortable } from "@dnd-kit/sortable";
import React, { forwardRef, HTMLAttributes, CSSProperties, FC } from "react";
import { CSS } from "@dnd-kit/utilities";
import { CloseOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";
import * as Api from "../../Api/src/index";
import { useLang } from "../../i18n/useLang";
import { StringFormat } from "../../Common/CommonFunction";
export type ItemProps = HTMLAttributes<HTMLDivElement> & {
  id: string;
  selected?: boolean;
  tabType: "Vtab" | "Htab";
  height?: number;
  TabDetail?: Api.TabDetail;
  withOpacity?: boolean;
  isDragging?: boolean;
  placement?: TooltipPlacement;
  onCloseTab?: (id: string) => void;
  onClick?: () => void;
  showMenu?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    tabDetail?: Api.TabDetail
  ) => void;
};

export const TabItem = forwardRef(
  (
    {
      id,
      withOpacity,
      height,
      isDragging,
      tabType,
      style,
      selected,
      TabDetail,
      onCloseTab,
      onClick,
      showMenu,
      placement,
      ...props
    }: ItemProps,
    ref: any
  ) => {
    const inlineStyles: CSSProperties = {
      opacity: withOpacity ? "0.5" : "1",
      transformOrigin: "50% 50%",
      height: height || 34,
      maxWidth: "180px",
      minWidth: "180px",
      backgroundColor: "#ffffff",
      cursor: isDragging ? "grabbing" : "grab",
      boxShadow: isDragging
        ? "rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px"
        : "rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px",
      transform: isDragging ? "scale(1.05)" : "scale(1)",
      ...style,
    };
    const { tr } = useLang();
    return (
      <Popover
        placement={!placement ? "bottom" : placement}
        content={
          <>
            <div>
              <div>
                <b>{TabDetail?.fileName}</b>
              </div>
              <div>{tr("totalPages") + ": " + TabDetail?.pageCount}</div>
              {(TabDetail?.numberPageOCRDone || 0) > 0 && (
                <div>{`${tr("ocr")} : ${TabDetail?.numberPageOCRDone || 0} ${tr(
                  "page"
                )}`}</div>
              )}
              <div>
                {StringFormat(
                  `${tr("needOcr0Pages")}`,
                  (TabDetail?.numberPageNeedOCR || 0).toString()
                )}
              </div>
            </div>
          </>
        }
      >
        <div {...props} style={inlineStyles} ref={ref}>
          <div
            id={tabType + TabDetail?.uniqueId}
            style={{
              height:
                TabDetail?.tabFileType === Api.TabFileType.Normal
                  ? "calc(100% - 6px)"
                  : "100%",
            }}
            className={`tabfile-content ${selected ? "selected" : ""}`}
          >
            <div
              className="tabfile-name"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onClick && onClick();
              }}
              onContextMenu={(e) => {
                e.preventDefault();
                e.stopPropagation();
                showMenu && showMenu(e, TabDetail);
              }}
            >
              {TabDetail?.fileName}
            </div>
            {TabDetail?.tabFileType === Api.TabFileType.Normal && (
              <div
                className="tab-close"
                onMouseUp={(e) => {
                  onCloseTab && onCloseTab(TabDetail?.uniqueId || "");
                }}
                onMouseDown={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <CloseOutlined />
              </div>
            )}
          </div>
          {TabDetail?.tabFileType === Api.TabFileType.Normal && (
            <div className="ocr-progress-bar">
              <div className="blue-progress-bar"></div>
              <div className="red-progress-bar"></div>
            </div>
          )}
        </div>
      </Popover>
    );
  }
);

export const SortableTabItem = (props: ItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <TabItem
      ref={setNodeRef}
      style={style}
      withOpacity={props.isDragging}
      onClick={() => {
        console.log("click");
      }}
      {...props}
      {...attributes}
      {...listeners}
    ></TabItem>
  );
};

import UndoRedoManagerObj from "../UndoRedomanager";
import * as Api from "../../Api/src/index";
import { AppCache } from "../../Cache/AppCache";
import { PageService } from "../../Services/NotHook/PageService";
import { UndoHightLight } from "./HightLightCommand";
import { ReplaceService } from "../../Services/NotHook/ReplaceService";
import { LeftPanelService } from "../../Services/Hook/LeftPanel/LeftPanelService";
const ReplaceApi = new Api.ReplaceApi();
export const ReplaceAllItemSelectedCommand = () => {
  const { SetDataCurrentPage } = PageService();
  const { LoadLeftPanelData } = LeftPanelService();
  const ReplaceWord = async (param: Api.ReplaceParamWeb) => {
    if (!param) return null;
    const res = await ReplaceApi.apiLegalxtractAddReplaceAllItemSelectedsPost(param);
    SetDataCurrentPage(res.data.pageData!, AppCache.CurrentPageNo || 0);
    ReplaceService().GetReplaceObjects();
    LoadLeftPanelData(["pnFind", "pnWatchDog", "pnReplace", "pnLog"]);
    return res;
  };
  const Do = async (param: Api.ReplaceParamWeb) => {
    const res = await ReplaceWord(param);
    UndoRedoManagerObj.add({
      Redo: () => {
        Redo(param);
      },
      Undo: () => {
        Undo({
          preState: JSON.stringify(res?.data.data),
          viewerPageNo: AppCache.CurrentPageNo,
        });
      },
    });
    return res;
  };
  const Redo = async (param: Api.ReplaceParamWeb) => {
   await ReplaceWord(param);
  };
  const Undo = async (param: Api.UndoHightLightParam) => {
   await UndoHightLight(param);
  };
  return { Do };
};

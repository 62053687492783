import { useState } from "react";
import * as Api from "../../../../Api/src/index";
import { v4 } from "uuid";
import useTagStore from "../../../../ZustandStore/TagStore";
import { FormikErrors, useFormik } from "formik";
import * as yup from "yup";
import { useLang } from "../../../../i18n/useLang";
import UseConfirmDialogStore from "../../../../ZustandStore/ConfirmDialogStore";
import { AddTagNameParam } from "../../../../Api/src/index";
const TagApi = new Api.TagApi();
export interface MDTagNameProps {
  tagCategoryId: string;
  open?: boolean;
  onClose?: (tagName: Api.TagName | null) => void;
}
export const MDTagNameService = ({
  onClose,
  tagCategoryId,
}: MDTagNameProps) => {
  const { TagState, SetTagStore } = useTagStore();
  const { SetConfirmDialogState } = UseConfirmDialogStore();
  const { tr } = useLang();
  const validationTagNameSchema = yup.object({
    tagName: yup.string().required(tr("pleaseSetName")),
  });
  const showErrors = (error: FormikErrors<AddTagNameParam>) => {
    if (error.tagName) {
      return <p>{error.tagName}</p>;
    }
  };
  const formik = useFormik({
    initialValues: {
      tagCategoryId: "",
      tagName: "",
      isDefault: false,
      id: v4(),
    } as Api.AddTagNameParam,
    validationSchema: validationTagNameSchema,
    onSubmit: async (values) => {
      values.tagCategoryId = tagCategoryId;
      TagApi.apiLegalxtractAddTagNamePost(values).then((res) => {
        if (res && res.data.isSuccess) {
          SetTagStore({
            Tags: { ...TagState.Tags, tagNames: res.data.result },
          });
          onClose &&
            onClose(
              res.data.result?.find((o) => o.id === values?.id || "") || null
            );
        } else {
          SetConfirmDialogState({
            Show: true,
            Type: "Alert",
            CancelText: tr("no"),
            Msg: `${tr("tagName")} ${values.tagName} ${tr("alreadyExists")}`,
          });
        }
      });
    },
  });
  return { formik, showErrors };
};

import { FileDialog } from "../../../Common/FileDialog";
import * as Api from "../../../Api/src/index";
import { AppCache } from "../../../Cache/AppCache";
import { PageService } from "../../NotHook/PageService";
import { Core } from "@pdftron/webviewer";
import { ColorHelper } from "../../../Common/AppHelpers";
import { ToolService } from "../../NotHook/ToolService";
import { OpenFileService } from "../OpenFileService";
import useMenuIconStore from "../../../ZustandStore/MenuIconStore";
import { ToolMode } from "../../../Constant/AppEnum";
import { useState } from "react";
import UseModalStore, { ModaType } from "../../../ZustandStore/ModalStore";
import { SaveMode } from "../../../Modal/MDSaveFile";
const OpenApi = new Api.OpenApi();
export const UseMenuIconService = () => {
  //create useState
  const [ShowTagSelect, SetShowTagSelect] = useState<boolean>(false);

  const { BoxTool, SectionTool, SelectTool, MaskTool, LineTool } =
    ToolService();
  const [ShowMDFindDuplicate, SetShowMDFindDuplicate] = useState<{
    open: boolean;
  }>({
    open: false,
  });
  const { SetMenuIconStore } = useMenuIconStore();
  const { SetModalState } = UseModalStore();
  const { OpenFiles } = OpenFileService();
  const OpenClick = () => {
    OpenFiles(true);
  };
  const BoxClick = () => {
    SetMenuIconStore({ ToolMode: ToolMode.Box });
    BoxTool();
  };
  const SectionClick = () => {
    SetMenuIconStore({ ToolMode: ToolMode.Section });
    SectionTool();
  };
  const SelectClick = () => {
    SetMenuIconStore({ ToolMode: ToolMode.Select });
    SelectTool();
  };
  const MaskClick = () => {
    SetMenuIconStore({ ToolMode: ToolMode.Mask });
    MaskTool();
  };
  const TagClick = () => {
    SetMenuIconStore({ ToolMode: ToolMode.Tag });
    SetShowTagSelect(true);
  };
  const LineClick = () => {
    SetMenuIconStore({ ToolMode: ToolMode.Line });
    LineTool();
  };
  const SaveClick = () => {
    AppCache.SaveParam.saveMode = SaveMode.SaveFileXtract;
    SetModalState({
      Show: true,
      Type: ModaType.MDSaveFile,
      Status: "Edit",
    });
  };
  const FindDuplicateClick = () => {
    SetShowMDFindDuplicate({ open: true });
  };
  const CloseFindDuplicate = () => {
    SetShowMDFindDuplicate({ open: false });
  };
  return {
    FindDuplicateClick,
    CloseFindDuplicate,
    OpenClick,
    BoxClick,
    SectionClick,
    SelectClick,
    MaskClick,
    ShowTagSelect,
    SetShowTagSelect,
    TagClick,
    LineClick,
    SaveClick,
    ShowMDFindDuplicate,
  };
};

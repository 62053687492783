import { useState } from "react";
import * as Api from "../../../Api/src/index";
import { v4 } from "uuid";
import useTagStore from "../../../ZustandStore/TagStore";
import { FormikErrors, useFormik } from "formik";
import * as yup from "yup";
import { useLang } from "../../../i18n/useLang";
import UseConfirmDialogStore from "../../../ZustandStore/ConfirmDialogStore";
import { AddTagNameParam } from "../../../Api/src/index";
import { AppCache } from "../../../Cache/AppCache";
import useTabFileInfoStore from "../../../ZustandStore/TabFileInfoStore";
import useTocStore from "../../../ZustandStore/TocStore";
import { PageService } from "../../NotHook/PageService";
import usePageStore from "../../../ZustandStore/PageStore";
const TabApi = new Api.TabApi();
export interface MDRenameTabProps {
  name: string;
  tabId: string;
  open?: boolean;
  onClose?: () => void;
}
export const MDRenameTabService = ({
  onClose,
  name,
  tabId,
}: MDRenameTabProps) => {
  const { SetTabFileInfoState } = useTabFileInfoStore();
  const { tr } = useLang();
  const validationTagNameSchema = yup.object({
    name: yup.string().required(tr("pleaseSetName")),
  });
  const showErrors = (error: FormikErrors<{ name: string }>) => {
    if (error.name) {
      return <p>{error.name}</p>;
    }
  };
  const formik = useFormik({
    initialValues: {
      name: name,
    },
    validationSchema: validationTagNameSchema,
    onSubmit: async (values) => {
      TabApi.apiLegalxtractRenameTabGet(tabId, values.name).then((res) => {
        if (res && res.data) {
          const tab = AppCache.DocumentInfo?.tabDetails?.find((o) => o.uniqueId === tabId);
          if (tab) {
            tab.fileName = values.name;
          }
          console.log("tab", tab);
          console.log("AppCache.DocumentInfo", AppCache.DocumentInfo);
          console.log("AppCache.DocumentInfo?.tabDetails", AppCache.DocumentInfo?.tabDetails);
          AppCache.DocumentInfo = AppCache.DocumentInfo;
          SetTabFileInfoState({
            TabDetails: [...AppCache.DocumentInfo?.tabDetails || []],
          });
        }
        onClose && onClose();
      });
    },
  });
  return { formik, showErrors };
};

import {
  Button,
  Col,
  ColorPicker,
  Modal,
  Row,
  Select,
  Space,
  Table,
  TableColumnsType,
} from "antd";
import { useLang } from "../../i18n/useLang";
import {
  DeleteOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  MDTagListProps,
  TagListService,
} from "../../Services/Hook/Modal/Tags/TagListService";
import MDTagCategory from "./MDTagCategory";
import MDTagName from "./MDTagName";
import MDTagDetail from "./MDTagDetail";

export const MDTagList = ({ open, onClose }: MDTagListProps) => {
  const { tr } = useLang();
  const {
    columns,
    rowSelection,
    GetDataSelectTagName,
    GetDataSelectCategory,
    selectedCategoryId,
    selectedTagNameId,
    categoryColor,
    SelectCategoryChange,
    SelectTagNameChange,
    GetDataTagInfos,
    categoryColorChange,
    DeleteTagName,
    DeleteCategory,
    showMDCategory,
    setShowMDCategory,
    closeCategoryTag,
    showMDTagName,
    setShowMDTagName,
    showMDTagDetail,
    setShowMDTagDetail,
    handleExport,
    fileInputRef,
    onSelectedImportFile,
    handleImport,
  } = TagListService();

  return (
    <>
      <Modal
        title={tr("tagOptions")}
        footer={
          <div style={{ display: "flex" }}>
            <Button
              onClick={handleExport}
              style={{ minWidth: 100, marginRight: 8 }}
              type="default"
            >
              {tr("export")}
            </Button>
            <Button
              onClick={handleImport}
              style={{ minWidth: 100 }}
              type="default"
            >
              {tr("import")}
            </Button>
            <div style={{ flex: 1 }}></div>
            <Button onClick={onClose} style={{ minWidth: 100 }} type="default">
              {tr("close")}
            </Button>
          </div>
        }
        centered
        maskClosable={false}
        open={open}
        width={650}
        onCancel={onClose}
      >
        <Row gutter={[8, 8]}>
          <Col span={24} style={{ textAlign: "end" }}>
            <Button
              onClick={() => {
                setShowMDTagDetail({
                  open: true,
                  tagId: null,
                  editItem: null,
                });
              }}
              style={{ minWidth: 100 }}
              type="primary"
            >
              {tr("newTag")}
            </Button>
          </Col>
          <Col span={13}>
            <label>{tr("category")}</label>
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1, marginRight: 8 }}>
                <Select
                  value={selectedCategoryId}
                  style={{ width: 202 }}
                  options={GetDataSelectCategory()}
                  onChange={(value) => {
                    SelectCategoryChange(value);
                  }}
                />
              </div>
              <div style={{ width: 130 }}>
                <Space>
                  <ColorPicker
                    value={categoryColor}
                    onChange={(value) => {
                      categoryColorChange(
                        selectedCategoryId || "",
                        value.toHexString()
                      );
                    }}
                  />
                  <Button
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setShowMDCategory(!showMDCategory);
                    }}
                  />
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      DeleteCategory(selectedCategoryId || "");
                    }}
                  />
                </Space>
              </div>
            </div>
          </Col>
          <Col span={11}>
            <label>{tr("name")}</label>
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1, marginRight: 8 }}>
                <Select
                  value={selectedTagNameId}
                  style={{ width: 191 }}
                  options={GetDataSelectTagName(selectedCategoryId || "")}
                  onChange={(value) => {
                    SelectTagNameChange(value);
                  }}
                />
              </div>
              <div>
                <Space>
                  <Button
                    onClick={() => {
                      setShowMDTagName(true);
                    }}
                    icon={<PlusOutlined />}
                  />
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      DeleteTagName(selectedTagNameId || "");
                    }}
                  />
                </Space>
              </div>
            </div>
          </Col>
          <Col span={24}>
            <Table
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              size="small"
              scroll={{ y: 500 }}
              pagination={false}
              columns={columns}
              rowKey={"id"}
              dataSource={GetDataTagInfos(selectedTagNameId || "")}
            />
          </Col>
        </Row>
      </Modal>
      {showMDCategory && (
        <MDTagCategory
          open={showMDCategory}
          onClose={(value) => {
            setShowMDCategory(false);
            if (value) closeCategoryTag(value);
          }}
        ></MDTagCategory>
      )}
      {showMDTagName && selectedCategoryId && (
        <MDTagName
          tagCategoryId={selectedCategoryId}
          open={showMDTagName}
          onClose={(value) => {
            setShowMDTagName(false);
            if (value) SelectTagNameChange(value.id || "");
          }}
        ></MDTagName>
      )}
      {showMDTagDetail.open && selectedCategoryId && (
        <MDTagDetail
          tagCategoryId={selectedCategoryId}
          tagNameId={selectedTagNameId || ""}
          open={showMDTagDetail.open}
          color={
            showMDTagDetail.editItem
              ? showMDTagDetail.editItem.backColor || categoryColor
              : categoryColor
          }
          tagId={showMDTagDetail.tagId}
          keyWord={showMDTagDetail.editItem?.keyWord || ""}
          onClose={() => {
            setShowMDTagDetail({ open: false, tagId: null, editItem: null });
          }}
        ></MDTagDetail>
      )}
      <input
        style={{ display: "none" }}
        multiple={false}
        ref={fileInputRef}
        type="file"
        id="uploadtag"
        accept={".dat"}
        onChange={(e: any) => {
          onSelectedImportFile(e);
          e.target.value = "";
        }}
      />
    </>
  );
};

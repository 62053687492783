import * as React from "react";
import { useLang } from "../../i18n/useLang";
import * as Api from "../../Api/src";
import { Button, Checkbox, Col, Modal, Radio, Row } from "antd";
import PreviewCheckDuplicateModal from "./PreviewCheckDuplicateModal";
import { AppCache } from "../../Cache/AppCache";
import useLoaddingAppStore from "../../ZustandStore/LoaddingAppStore";
import UseConfirmDialogStore from "../../ZustandStore/ConfirmDialogStore";
import "../../Assets/Styles/Modal/MDFindDuplicates.scss";
import { WebViewerInstance } from "@pdftron/webviewer";
const CheckDuplicateApi = new Api.CheckDuplicateApi();
interface FindDuplicatesProps {
  Show: boolean;
  onClose: () => void;
}
export let webViewerInstanceCheckDuplicate: WebViewerInstance | null = null;
export const MDFindDuplicates = (props: FindDuplicatesProps) => {
  const { tr } = useLang();
  const [checkDuplicateSettings, SetCheckDuplicateSettings] =
    React.useState<Api.CheckDuplicateSettings>({
      isCurrentDocument: false,
      includeHideOnExtract: false,
    });
  const { SetLoaddingAppStore } = useLoaddingAppStore();
  const { SetConfirmDialogState } = UseConfirmDialogStore();
  const [showViewDuplicate, SetShowDuplicate] = React.useState<{
    isShow: boolean;
    DuplicatePages: { [key: string]: Array<Api.PageStatus> } | null;
  }>({ isShow: false, DuplicatePages: null });

  const onCloseModal = () => props.onClose();
  const onSubmit = async () => {
    SetLoaddingAppStore({ Show: true });
    CheckDuplicateApi.apiLegalxtractCheckDuplicatePost(
      checkDuplicateSettings
    ).then((data) => {
      SetLoaddingAppStore({ Show: false });
      if (!data.data.result) {
        SetConfirmDialogState({
          Show: true,
          Type: "Alert",
          CancelText: tr("no"),
          Msg: `${tr("thereAreNoDuplicatePagesAccordingToYourFilterCriteria")}`,
        });
        props.onClose();
      } else {
        SetShowDuplicate({
          isShow: true,
          DuplicatePages: data.data.result || null,
        });
      }
    });
  };
  const checkAllTabOcrDone = (): boolean => {
    const tabs = AppCache.DocumentInfo?.tabDetails?.filter((tab) => {
      return tab.numberPageNeedOCR == 0;
    });
    if (tabs?.length == AppCache.DocumentInfo?.tabDetails?.length) {
      return true;
    }
    return false;
  };
  return (
    <Modal
      title={tr("findDuplicates")}
      footer={
        <>
          <Button
            style={{ minWidth: 100 }}
            onClick={() => {
              onCloseModal && onCloseModal();
            }}
            type="default"
          >
            {tr("close")}
          </Button>
          <Button
            style={{ minWidth: 100 }}
            onClick={() => {
              onSubmit();
            }}
            type="primary"
          >
            {tr("continue")}
          </Button>
        </>
      }
      centered
      open={props.Show}
      width={550}
      onCancel={() => {
        onCloseModal && onCloseModal();
      }}
      maskClosable={false}
    >
      <Row gutter={[8, 8]}>
        <Col span={12}>
          <Radio
            name="automaticCount"
            onChange={(value) => {
              SetCheckDuplicateSettings({
                ...checkDuplicateSettings,
                isCurrentDocument: true,
              });
            }}
            checked={checkDuplicateSettings.isCurrentDocument}
            value={true}
          >
            {tr("currentDocument")}
          </Radio>
        </Col>
        <Col span={12}>
          <Radio
            name="indexFromToc"
            checked={!checkDuplicateSettings.isCurrentDocument}
            onChange={(value) => {
              SetCheckDuplicateSettings({
                ...checkDuplicateSettings,
                isCurrentDocument: false,
              });
            }}
            value={false}
          >
            {tr("allDocuments")}
          </Radio>
        </Col>
        <Col span={24}>
          {!checkAllTabOcrDone() && (
            <p style={{ color: "red" }}>
              {tr("someDocumentsHaveNotUndergoneOCRProcessing")}
            </p>
          )}
        </Col>
        <Col span={24}>
          <Checkbox
            checked={checkDuplicateSettings?.includeHideOnExtract}
            onChange={(e) => {
              SetCheckDuplicateSettings({
                ...checkDuplicateSettings,
                includeHideOnExtract: e.target.checked,
              });
            }}
          >
            {tr("includingHiddenPagesWhenPublishing")}
          </Checkbox>
        </Col>
        <Col span={24}>
          {showViewDuplicate.isShow && (
            <PreviewCheckDuplicateModal
              isShow={showViewDuplicate.isShow}
              onClose={() => {
                SetShowDuplicate({ isShow: false, DuplicatePages: null });
                setTimeout(() => {
                onCloseModal && onCloseModal();
                }, 100);
              }}
              DuplicatePages={showViewDuplicate.DuplicatePages || null}
            ></PreviewCheckDuplicateModal>
          )}
        </Col>
      </Row>
    </Modal>
  );
};

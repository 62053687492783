import React, { useContext, useEffect } from "react";
import { useLang } from "../../i18n/useLang";
import * as Api from "../../Api/src";
import loading from "../../Assets/IconApp/loading.gif";
import { ThumbnailDuplicatePreview } from "./PreviewCheckDuplicateModal";

interface PageThumbnailDupliProps {
  Item: ThumbnailDuplicatePreview;
  selectedPage: number[];
  pageNo: number | undefined;
  thumbnailStatus: number;
  active: boolean;
  onMouseDown?: (
    pageIndex: number,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  onClick: (
    pageIndex: number,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  onContextMenu: (
    pageIndex: number,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
}

export const PageThumbnailDupli = ({
  active,
  selectedPage,
  Item,
  pageNo,
  thumbnailStatus,
  onMouseDown,
  onContextMenu,
  onClick,
}: PageThumbnailDupliProps) => {
  const { tr } = useLang();
  const getTextPage = () => {
    switch (thumbnailStatus) {
      case 1 as any:
        return tr("hide");
      case 2 as any:
        return tr("hideOnExtract");
      case 3 as any:
        return tr("delete");
      default:
        return "";
    }
  };
  const pageRef = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    const div = pageRef.current;
    if (div && Item && Item.img) {
      div.appendChild(Item.img);
      // Cleanup function to remove the canvas on component unmount
      return () => {
        div.removeChild(Item.img);
      };
    } else {
      //   <img
      //   src={loading || ""}
      //   style={{ width: "30px", margin: "auto", maxHeight: "100%" }}
      //   alt=""
      // />
    }
  }, [Item]);
  return (
    <React.Fragment>
      <div
        onMouseDown={(e) => {
          onMouseDown && onMouseDown(pageNo || 0, e);
        }}
        onClick={(e) => {
          onClick(pageNo || 0, e);
        }}
        onContextMenu={(e) => {
          onContextMenu(pageNo || 0, e);
        }}
        itemType={""}
        id={`thumbnail-duplicate-${pageNo}`}
        className={`item-thumbnail-duplicate ${
          selectedPage.indexOf(Item.pageNoPreview!) >= 0 ? "selected" : ""
        } ${active ? "active" : ""}`}
      >
        <div ref={pageRef} className={`thumnail-img`}></div>
        <label>
          {tr("page")} {Item.viewerPageNo} {getTextPage()}
        </label>
      </div>
    </React.Fragment>
  );
};

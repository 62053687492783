import { useEffect, useRef, useState } from "react";
import * as Api from "../../../Api/src/index";
import { TreeDataNode } from "antd";
import useTocStore from "../../../ZustandStore/TocStore";
import { Item, Menu, useContextMenu } from "react-contexify";
import { useLang } from "../../../i18n/useLang";
import {
  DraggingPosition,
  TreeItem,
  TreeItemIndex,
  TreeRef,
} from "react-complex-tree";
import { PdfTronService } from "../../NotHook/PdfTronService";
import { PageService } from "../../NotHook/PageService";
import UseConfirmDialogStore from "../../../ZustandStore/ConfirmDialogStore";
import { StringFormat } from "../../../Common/CommonFunction";
import useTabFileInfoStore from "../../../ZustandStore/TabFileInfoStore";
import { AppCache } from "../../../Cache/AppCache";
import usePageStore from "../../../ZustandStore/PageStore";
let timeOut: any = null;
const TocApi = new Api.TocApi();
const JoinDocumentApi = new Api.JoinDocumentApi();

export const MenuID = "TOCMenu";
export const TocPanelService = () => {
  const { TocState, SetTocStore } = useTocStore();
  const { TabFileInfoState, SetTabFileInfoState } = useTabFileInfoStore();
  const { SetCurrentPageNo } = PdfTronService();
  const [TocFocus, SetTocFocus] = useState<Api.TocRow | null>();
  const { tr } = useLang();
  const [maxLevel, setMaxLevel] = useState<number>(0);
  const { SetConfirmDialogState } = UseConfirmDialogStore();
  const [selectedItems, setSelectedItems] = useState<TreeItemIndex[]>([]);
  const [expandedItems, setExpandedItems] = useState<TreeItemIndex[]>([]);
  const [ShowMdAddBlankPage, SetShowMdAddBlankPage] = useState<{
    open: boolean;
    tocIDs: string[];
    name: string;
  }>({ open: false, tocIDs: [], name: "" });
  const [itemTrees, setItemTrees] = useState<any>({
    root: {
      index: "root",
      canMove: true,
      isFolder: false,
      children: [],
      canRename: true,
      data: { id: "00000000-0000-0000-0000-000000000000" },
    },
  });
  const tree = useRef<TreeRef>(null);
  // const initCheck = useRef<boolean>(false);
  // useEffect(() => {
  //   if (!initCheck.current) {
  //     LoadToc();
  //     initCheck.current = true;
  //   }
  // }, []);
  const LoadToc = () => {
    TocApi.apiLegalxtractGetTocRowGet().then((response) => {
      if (response.data.result) {
        SetTocStore({ TocRows: response.data.result });
      }
    });
  };
  const GetAllId = (item: string, treeData: any): string[] => {
    let allIds: string[] = [];
    (treeData[item].children as string[]).forEach((element) => {
      allIds.push(element);
      allIds = [...allIds, ...GetAllId(element.toString(), treeData)];
    });
    return allIds;
  };
  const getOrderIDs = (treeData: any) => {
    const arrId = GetAllId("root", treeData);
    return arrId;
  };
  const reOrderToc = async (
    items: any[],
    parentId: string | null,
    treeData: any
  ) => {
    const arrId: string[] = getOrderIDs(treeData);
    TocApi.apiLegalxtractReOrderTOCPost({
      tocIds: items.map((o) => o.index),
      parentId: parentId || "00000000-0000-0000-0000-000000000000",
      newOrderIds: arrId,
    }).then((res) => {
      const data = res.data;
      if (!data) return;
      SetTocStore({ TocRows: res.data.result?.content || [] });
    });
  };
  const { show, hideAll } = useContextMenu({
    id: MenuID,
  });
  function ShowMenu(event: any) {
    show({
      event,
    });
  }
  // kiem tra xem cac phan tu co lien ke nhau khong
  const isAdjacent = (arr: number[]) => {
    if (arr.length < 2) {
      return false;
    }
    for (let i = 1; i < arr.length; i++) {
      if (arr[i] - arr[i - 1] !== 1) {
        return false;
      }
    }
    return true;
  };

  const DrawMenuItem = () => {
    const TocSelecteds =
      TocState.TocRows?.filter((o) =>
        (selectedItems as string[]).includes(o.id || "")
      ) || [];
    const indexTocSelecteds = TocSelecteds.map(
      (o) => TocState.TocRows?.indexOf(o) || -1
    );
    const menu = {
      removePageBreak: false,
      pageBreak: false,
      addBlankPage: false,
      joinDocuments: false,
    };
    if (TocSelecteds.length === 1) {
      if (TocSelecteds[0].isTocPageBreak) {
        menu.removePageBreak = true;
      } else {
        menu.pageBreak = true;
      }
    }
    if (indexTocSelecteds.length === 1 || isAdjacent(indexTocSelecteds)) {
      menu.addBlankPage = true;
    }
    if (indexTocSelecteds.length > 1 && isAdjacent(indexTocSelecteds)) {
      menu.joinDocuments = true;
    }
    return (
      <Menu id={MenuID}>
        <Item
          onClick={() => {
            goToDocument(TocFocus || {});
          }}
        >
          {tr("goToDocument")}
        </Item>
        {menu.removePageBreak && (
          <Item
            onClick={() => {
              AddRemovePageBreak(true);
            }}
          >
            {tr("removePageBreak")}
          </Item>
        )}
        {menu.pageBreak && (
          <Item
            onClick={() => {
              AddRemovePageBreak(true);
            }}
          >
            {tr("pageBreak")}
          </Item>
        )}
        {menu.addBlankPage && (
          <Item
            onClick={() => {
              AddBlankFile();
            }}
          >
            {tr("addBlankPage")}
          </Item>
        )}
        {menu.joinDocuments && (
          <Item onClick={() => {
            JoinDocument();
          }}>{tr("joinDocuments")}</Item>
        )}
      </Menu>
    );
  };
  useEffect(() => {
    const timout = setTimeout(() => {
      if (tree.current) {
        tree.current.expandAll();
      }
    }, 500);
    return () => clearTimeout(timout);
  }, []);
  useEffect(() => {
    const items = [];
    for (const field in itemTrees) {
      if (field != "root" && itemTrees[field].children.length > 0) {
        items.push(field);
      }
      setExpandedItems(items);
    }
  }, [itemTrees]);
  useEffect(() => {
    setItemTrees(BuildDataTree(TocState.TocRows || []));
    const allLevel = (TocState.TocRows || []).map(
      (o) => (o.indexView || "").split(".").length || 0
    );
    setMaxLevel(Math.max(...allLevel));
  }, [TocState.TocRows || []]);
  const dropNodes = (items: TreeItem<any>[], target: DraggingPosition): any => {
    let ids = items.map((o) => o.index);
    if (target.targetType == "between-items") {
      const idViTriLonHons: string[] = [];
      const idViTriNhoHons: string[] = [];
      let linearIndex = target.childIndex;
      if (target.parentItem != "root") {
        linearIndex = target.childIndex;
      }
      ((itemTrees[target.parentItem].children as string[]) || []).forEach(
        (o, index) => {
          if (index < linearIndex) {
            if (!ids.includes(o)) {
              idViTriNhoHons.push(o);
            }
          } else {
            if (!ids.includes(o)) {
              idViTriLonHons.push(o);
            }
          }
        }
      );
      itemTrees[target.parentItem].children = [
        ...idViTriNhoHons,
        ...ids,
        ...idViTriLonHons,
      ];
      // }
      for (const field in itemTrees) {
        if (field != target.parentItem)
          itemTrees[field].children = (
            (itemTrees[field].children as string[]) || []
          ).filter((o) => !ids.includes(o));
      }
    } else if (target.targetType == "item") {
      // var idSelects = (Items.root.children || []).filter((o: string) =>
      //   ids.includes(o)
      // );
      const item = itemTrees[target.targetItem];

      ids = ids.filter(
        (o) => !(item.children as string[]).includes(o as string)
      );
      if (ids.length == 0) return itemTrees;
      const idUnSelects = (itemTrees.root.children || []).filter(
        (o: string) => !ids.includes(o)
      );
      itemTrees.root.children = idUnSelects;
      item.children = [...item.children, ...ids];
      for (const field in itemTrees) {
        if (field != "root" && field != target.targetItem)
          itemTrees[field].children = (
            (itemTrees[field].children as string[]) || []
          ).filter((o) => !ids.includes(o));
      }
    }
    setItemTrees({ ...itemTrees });
    return itemTrees;
  };
  function BuildDataTree(tocs: Api.TocRow[]) {
    const dataResult: any = {
      root: {
        index: "root",
        canMove: true,
        isFolder: false,
        children: tocs
          .filter((o) => o.parentId == "00000000-0000-0000-0000-000000000000")
          .map((o) => o.id),
        canRename: true,
        data: { id: "00000000-0000-0000-0000-000000000000" },
      },
    };
    tocs.forEach((element) => {
      const childs = tocs
        .filter((o) => o.parentId == element.id)
        .map((o) => o.id);
      const a = {
        index: element.id,
        name: element.fileName,
        canMove: true,
        isFolder: true,
        children: childs || [],
        data: element,
        canRename: true,
      };
      dataResult[element.id || ""] = a;
    });
    return dataResult;
  }
  const UpdateTOC = async (tocRow: Api.TocRow) => {
    if (timeOut) clearTimeout(timeOut);
    timeOut = setTimeout(async () => {
      timeOut = null;
      const res = await TocApi.apiLegalxtractEditTocRowPost(tocRow);
      const newarrItem = TocState.TocRows?.filter(
        (o) => o.uniqueId != tocRow.uniqueId
      );
      const curItem = TocState.TocRows?.find(
        (o) => o.uniqueId == tocRow.uniqueId
      );
      newarrItem?.splice(TocState.TocRows!?.indexOf(curItem || {}), 0, tocRow);
      SetTocStore({ TocRows: newarrItem || [] });
    }, 300);
  };
  const GetAllSelected = (item: string): string[] => {
    let allitemSelecteds: string[] = [];
    allitemSelecteds = [...(itemTrees[item].children as string[])];
    allitemSelecteds.forEach((element) => {
      allitemSelecteds = [
        ...allitemSelecteds,
        ...GetAllSelected(element.toString()),
      ];
    });
    return allitemSelecteds;
  };
  const goToDocument = (item: Api.TocRow) => {
    SetCurrentPageNo(parseInt((item?.page || "").split("‐")[0].trim()));
  };
  const AddRemovePageBreak = (isTocPageBreak: boolean) => {
    const TocSelected =
      TocState.TocRows?.find((o) => (selectedItems as string[])[0] == o.id) ||
      null;
    UpdateTOC({
      ...TocSelected,
      isTocPageBreak: isTocPageBreak,
      parentId: TocSelected?.parentId || "00000000-0000-0000-0000-000000000000",
    });
  };
  const AddBlankFile = () => {
    SetShowMdAddBlankPage({
      open: true,
      tocIDs: selectedItems as string[],
      name: "Blank page",
    });
  };
  useEffect(() => {
    const allLevel =
      TocState.TocRows?.map(
        (o) => (o.indexView || "").split(".").length || 0
      ) || [];
    let max = 0;
    if (allLevel.length > 0) {
      max = Math.max(...allLevel);
    }
    setMaxLevel(max || 0);
  }, [TocState.TocRows]);
  const JoinDocument = () => {
    const Join = async () => {
      const res =  await JoinDocumentApi.apiLegalxtractJoinDocumentsPost({
        tabIds: selectedItems as string[],
      });
      if (res.data.isSuccess) {
        SetTabFileInfoState({
          TabDetails: res.data.result?.tabDetails || [],
          CurrentTab: res.data.result?.tabDetails?.find((o) => o.pages?.some((o) => o.viewerPageNo === AppCache.CurrentPageNo)),
        });
        LoadToc();
      }
    };
    SetConfirmDialogState({
      Show: true,
      Type: "Confirm",
      Icon: "Question",
      CancelText: tr("no"),
      YesText: tr("yes"),
      Msg: StringFormat(
        tr("areYouSureToJoin0Documents"),
        selectedItems.length.toString()
      ),
      OnYes: () => {
        Join();
      },
    });
  };
  return {
    TocState,
    LoadToc,
    SetTocStore,
    reOrderToc,
    DrawMenuItem,
    ShowMenu,
    UpdateTOC,
    dropNodes,
    selectedItems,
    setSelectedItems,
    expandedItems,
    setExpandedItems,
    itemTrees,
    tree,
    maxLevel,
    goToDocument,
    TocFocus,
    SetTocFocus,
    SetShowMdAddBlankPage,
    ShowMdAddBlankPage,
  };
};

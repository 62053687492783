import UndoRedoManagerObj from "../../UndoRedomanager";
import * as Api from "../../../Api/src/index";
import { AppCache } from "../../../Cache/AppCache";
import { PageService } from "../../../Services/NotHook/PageService";
const LineApi = new Api.LineApi();
export const DeleteLineCommand = () => {
  const { DrawLineAnnotation } = PageService();
  const DrawLine = async (pageNo: number, param: Api.AddLineParam | null) => {
    if (!param) return;
    param.borderWidth = AppCache.LineBorderWidth?.borderAdd || 0.02 * 72;
    param.color = AppCache.LineBorderWidth?.colorAdd || "#323130";
    var res = await LineApi.apiLegalxtractAddLinePost(pageNo, param);
    if (AppCache.CurrentPageNo === pageNo) {
      AppCache.DataCurrentPage = res.data.result?.pageData || null;
      DrawLineAnnotation(pageNo || 0);
    }
    return res;
  };
  const DeleteLine = async (id: string | null, pageNo: number) => {
    const res = await LineApi.apiLegalxtractRemoveLinePost(id || "", pageNo);
    const line = AppCache.DataCurrentPage?.lineAnnotations?.find(
      (o) => o.id === id
    );
    if (AppCache.CurrentPageNo === pageNo) {
      AppCache.DataCurrentPage = res.data.result?.pageData || null;
      DrawLineAnnotation(pageNo || 0);
    }
    return { line, pageNo: AppCache.CurrentPageNo };
  };
  const Do = async (id: string | null, pageNo: number) => {
    var res = await DeleteLine(id, pageNo);
    UndoRedoManagerObj.add({
      Redo: () => {
        Redo(id, pageNo);
      },
      Undo: () => { 
        Undo(res?.pageNo, {
          borderWidth: res?.line?.borderWidth,
          color: res?.line?.color,
          height: res?.line?.height,
          id: res?.line?.id,
          left: res?.line?.left,
          top: res?.line?.top,
          width: res?.line?.width,
        });
      },
    }); //add undo redo
  };
  const Redo = (id: string | null, pageNo: number) => {
    DeleteLine(id, pageNo);
  };
  const Undo = async (pageNo: number, param: Api.AddLineParam | null) => {
    DrawLine(pageNo, param);
  };
  return { Do };
};

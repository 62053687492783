import { Space, TableProps } from "antd";
import * as Api from "../../../Api/src";
import { useLang } from "../../../i18n/useLang";
import {
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import useReplaceStore from "../../../ZustandStore/ReplaceStore";
import UseModalStore, { ModaType } from "../../../ZustandStore/ModalStore";
import UseConfirmDialogStore from "../../../ZustandStore/ConfirmDialogStore";
import { AppCache } from "../../../Cache/AppCache";
import { DeleteReplaceCommand } from "../../../UndoRedo/Commands/DeleteReplaceCommand";
import { useEffect, useState } from "react";
import { AppLocalStorage } from "../../../Constant/AppContant";
import useWatchdogStore from "../../../ZustandStore/WatchdogStore";
import { SearchService } from "../../NotHook/SearchService";
import useSearchTabStore from "../../../ZustandStore/SearchTabStore";
import useLeftPanelStore from "../../../ZustandStore/LeftPanelStore";
import { WatchdogUnHightLightAllCommand } from "../../../UndoRedo/Commands/WatchdogUnHightLightAllCommand";
import { WatchdogHightLightCommand } from "../../../UndoRedo/Commands/WatchdogHightLightCommand";
import { useContextMenu } from "react-contexify";
export const MenuID = "WatchdogPanelMenu";
const ReplaceApi = new Api.ReplaceApi();
const WatchDogApi = new Api.WatchDogApi();
export const WatchDogPanelService = () => {
  const { tr } = useLang();
  const { SetModalState } = UseModalStore();
  const {
    SetWatchDogParamWeb,
    SetWatchdogDatas,
    WatchDogParamWeb,
    WatchdogDatas,
    setExpandedRowKeys,
    expandedRowKeys,
  } = useWatchdogStore();
  const { SetConfirmDialogState } = UseConfirmDialogStore();
  const columns: TableProps<Api.WatchDogNode>["columns"] = [
    {
      title: tr("word"),
      dataIndex: "word",
      key: "word",
      render: (text, record) => (
        <>
          {watchDogChecked.includes(record) && (
            <div
              style={{
                height: "100%",
                width: `100%`,
                background: "rgb(148 197 255 / 22%)",
                position: "absolute",
                textAlign: "right",
                alignContent: "center",
                top: "0",
                left: "0",
                boxSizing: "border-box",
                zIndex: 1,
              }}
            ></div>
          )}

          <div
            style={
              record.children?.length! > 0
                ? {}
                : {
                    background:
                      (record?.selectedCount || 0) === (record?.totalCount || 0)
                        ? "tranparent"
                        : "#ffddd1",
                    position:
                      record.children?.length! > 0 ? "unset" : "absolute",
                    width: "100%",
                    height: "100%",
                    top: "0",
                    left: "0",
                    alignContent: "center",
                    padding: 8,
                    boxSizing: "border-box",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }
            }
            title={
              record.children?.length! > 0
                ? record.word + `(${record.totalCount})`
                : record.word || ""
            }
          >
            {record.children?.length! > 0
              ? record.word + `(${record.totalCount})`
              : record.word}
          </div>
        </>
      ),
    },
    {
      width: 50,
      title: tr("selected"),
      key: "selectedCount",
      dataIndex: "selectedCount",
      render: (text, record) => (
        <>
          <div
            style={{
              height: "100%",
              width: `${record.replacePercent}%`,
              background: "rgb(153 199 255)",
              position: "absolute",
              textAlign: "right",
              alignContent: "center",
              top: "0",
              left: "0",
              boxSizing: "border-box",
            }}
          ></div>
          <div
            style={{
              zIndex: 10,
              position: "absolute",
              height: "100%",
              right: 8,
              top: "0",
              alignContent: "center",
            }}
          >
            {record.children?.length! > 0 ? "" : record.selectedCount}
          </div>
        </>
      ),
    },
    {
      width: 85,
      title: tr("count"),
      key: "totalCount",
      dataIndex: "totalCount",
      render: (text, record) => (
        <div style={{ textAlign: "right" }}>
          {record.children?.length! > 0 ? "" : record.totalCount}
        </div>
      ),
    },
    {
      width: 50,
      key: "action",
      render: (_, record, i) => {
        return (
          <>
            {record.children?.length! > 0 ? (
              ""
            ) : (
              <Space>
                <a
                  onClick={() => {
                    SearchClick(record);
                  }}
                >
                  <SearchOutlined />
                </a>
              </Space>
            )}
          </>
        );
      },
    },
  ];
  const { SearchTabState, SetSearchTabStore } = useSearchTabStore();
  const { LeftPanelState, SetLeftPanelStore } = useLeftPanelStore();
  const watchdogUnHightLightAllCommand = WatchdogUnHightLightAllCommand();
  const watchdogHightLightCommand = WatchdogHightLightCommand();
  const [watchDogChecked, SetWatchDogChecked] = useState<Api.WatchDogNode[]>(
    []
  );
  useEffect(() => {
    setExpandedRowKeys(WatchdogDatas?.map((x) => x.id!) || []);
  }, [WatchdogDatas]);
  const LoadWatchDogData = async (watchDogParamWeb?: Api.WatchDogParamWeb) => {
    const data = await WatchDogApi.apiLegalxtractCalculateWatchDogPost({
      param: { ...WatchDogParamWeb, ...(watchDogParamWeb || {}) },
      searchTemplateParam: SearchService().GetSearchTemplateParam(),
    });
    if (watchDogParamWeb) {
      SetWatchDogParamWeb(watchDogParamWeb);
    }
    SetWatchdogDatas(data.data);
    setExpandedRowKeys(data.data.map((x) => x.id!));
  };
  const SearchClick = (item: Api.WatchDogNode) => {
    SetSearchTabStore({
      ...SearchTabState,
      SearchTabVm: {
        ...SearchTabState.SearchTabVm,
        searchKeywordParam: {
          ...SearchTabState.SearchTabVm?.searchKeywordParam,
          keyword: item.word || "",
        },
        searchTabType: Api.SearchTabType.Keyword,
      },
      runSearch: true,
    });
    setTimeout(() => {
      SetLeftPanelStore({
        LeftTabActive: "pnFind",
      });
    }, 300);
  };
  const DeSelectAll = () => {
    SetConfirmDialogState({
      Show: true,
      Type: "Confirm",
      Icon: "Question",
      CancelText: tr("no"),
      YesText: tr("yes"),
      Msg: tr("youAreAboutToDeselectAllSelections"),
      OnYes: () => {
        watchdogUnHightLightAllCommand.Do({
          param: WatchDogParamWeb,
          watchDogObjs: [],
        });
      },
    });
  };
  const SelectAll = () => {
    SetConfirmDialogState({
      Show: true,
      Type: "Confirm",
      Icon: "Question",
      CancelText: tr("no"),
      YesText: tr("yes"),
      Msg: tr("youAreAboutToSelectAllSelections"),
      OnYes: () => {
        watchdogHightLightCommand.Do({
          param: WatchDogParamWeb,
          watchDogObjs: [],
        });
      },
    });
  };
  const { show, hideAll } = useContextMenu({
    id: MenuID,
  });
  function ShowContextMenu(event: any, record: Api.WatchDogNode) {
    if (!watchDogChecked.includes(record)) {
      SetWatchDogChecked([record]);
    }
    show({
      event,
    });
  }
  const onRowClick = (record: Api.WatchDogNode, e: any) => {
    if (e.ctrlKey) {
      watchDogChecked.includes(record)
        ? SetWatchDogChecked(watchDogChecked.filter((o) => o !== record))
        : SetWatchDogChecked([...watchDogChecked, record]);
    } else {
      SetWatchDogChecked([record]);
    }
  };
  const MenuDeSelectAllClick = () => {
    watchdogUnHightLightAllCommand.Do({
      param: WatchDogParamWeb,
      watchDogObjs: watchDogChecked as Api.WatchDogObj[],
    });
  };
  const MenuSelectAllClick = () => {
    watchdogHightLightCommand.Do({
      param: WatchDogParamWeb,
      watchDogObjs: watchDogChecked as Api.WatchDogObj[],
    });
  };
  return {
    columns,
    LoadWatchDogData,
    setExpandedRowKeys,
    expandedRowKeys,
    SetWatchDogParamWeb,
    SetWatchdogDatas,
    WatchDogParamWeb,
    WatchdogDatas,
    DeSelectAll,
    SelectAll,
    ShowContextMenu,
    onRowClick,
    MenuDeSelectAllClick,
    MenuSelectAllClick,
    watchDogChecked,
  };
};

import html2canvas from "html2canvas";
import * as Api from "../Api/src/index";
export function triggerClick(id: string) {
  const inputRef = document.getElementById(id);
  if (inputRef) {
    inputRef.click();
  }
}
export const isPointInRectangle = (
  pointX: number,
  pointY: number,
  rectX: number,
  rectY: number,
  rectWidth: number,
  rectHeight: number
) => {
  return (
    pointX >= rectX &&
    pointX <= rectX + rectWidth &&
    pointY >= rectY &&
    pointY <= rectY + rectHeight
  );
};
export const downloadFileFromStream = async (
  fileName: string | undefined,
  contentStreamReference: any
) => {
  const arrayBuffer = await contentStreamReference.arrayBuffer();
  const blob = new Blob([arrayBuffer]);
  const url = URL.createObjectURL(blob);
  const anchorElement = document.createElement("a");
  anchorElement.href = url;
  anchorElement.download = fileName ?? "";
  anchorElement.click();
  anchorElement.remove();
  URL.revokeObjectURL(url);
};
export function dragElement(id: string) {
  const elmnt = document.getElementById(id);
  if (!elmnt) return;
  let pos1 = 0,
    pos2 = 0,
    pos3 = 0,
    pos4 = 0;
  const header = document.getElementById(elmnt.id + "header");
  if (header) {
    /* if present, the header is where you move the DIV from:*/
    header.onmousedown = dragMouseDown;
  } else {
    /* otherwise, move the DIV from anywhere inside the DIV:*/
    elmnt.onmousedown = dragMouseDown;
  }

  function dragMouseDown(e: any) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  function elementDrag(e: any) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    if (!elmnt) return;
    elmnt.style.top = elmnt.offsetTop - pos2 + "px";
    elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
  }

  function closeDragElement() {
    /* stop moving when mouse button is released:*/
    document.onmouseup = null;
    document.onmousemove = null;
  }
}
export function isShowInViewport(el: any, container: any) {
  const rect = el.getBoundingClientRect();
  const parentRect = container.getBoundingClientRect();
  return (
    (rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= parentRect.bottom &&
      rect.right <= parentRect.right) ||
    (rect.top >= 0 &&
      rect.left >= 0 &&
      rect.top <= parentRect.bottom &&
      rect.right <= parentRect.right) ||
    (rect.top <= parentRect.top &&
      rect.bottom > parentRect.top &&
      rect.right <= parentRect.right)
  );
}

export function base64ToBlob(base64: string, mimeType: string) {
  // Decode base64
  const byteCharacters = window.atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  // Create blob
  const blob = new Blob([byteArray], { type: mimeType });
  return blob;
}
17;

export const StringFormat = (str: string, ...args: string[]) =>
  str.replace(/{(\d+)}/g, (match, index) => args[index] || "");
export const createArrayNumber = (
  fromIndex: number,
  toIndex: number
): number[] => {
  const array = [];
  for (let i = fromIndex; i <= toIndex; i++) {
    array.push(i);
  }
  return array;
};

export function resizeImageToBase64(
  file: any,
  maxWidth: number,
  maxHeight: number
): Promise<string> {
  return new Promise((resolve) => {
    const img = new Image();
    const canvas: any = document.createElement("canvas");
    const ctx = canvas?.getContext("2d");
    img.onload = () => {
      const iw = img.width;
      const ih = img.height;
      const scale = Math.min(maxWidth / iw, maxHeight / ih);
      const iwScaled = iw * scale;
      const ihScaled = ih * scale;
      canvas.width = iwScaled;
      canvas.height = ihScaled;
      ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
      resolve(canvas.toDataURL("image/png", 0.8));
    };
    img.src = URL.createObjectURL(file);
  });
}
export const GetTextSite = (
  text: string,
  settings: {
    fontSize: string;
    fontName: string;
  }
) => {
  const spanElement = document.createElement("span");
  spanElement.style.display = "inline-block";
  spanElement.style.padding = "1px";
  spanElement.style.border = "solid 1px #333";
  spanElement.style.fontSize = settings.fontSize;
  spanElement.style.fontFamily = settings.fontName;
  spanElement.innerHTML = text;
  document.body.appendChild(spanElement);
  const spanWidth = spanElement.offsetWidth;
  const offsetHeight = spanElement.offsetHeight;
  document.body.removeChild(spanElement);
  return { height: offsetHeight, width: spanWidth };
};
export const getCenterPointOfRect = (
  rect: Api.RectangleF | undefined | null
) => {
  if (!rect) return null;
  const centerX = rect.x! + rect.width! / 2;
  const centerY = rect.y! + rect.height! / 2;
  return { x: centerX, y: centerY };
};

export function generateBase64ImageFromText(text: string, height: number) {
  // Create a new canvas element
  const canvas = document.createElement("canvas");

  // Get the 2D context of the canvas
  const ctx = canvas.getContext("2d");
  if (ctx == null) return;
  // Set font and text alignment
  ctx.font = "20px Arial";

  // Measure the width of the text
  const textWidth = ctx.measureText(text).width;

  // Set canvas width based on text width
  canvas.width = textWidth;
  canvas.height = height;

  // Set text alignment after setting canvas width
  ctx.textAlign = "center";
  ctx.textBaseline = "middle";

  // Fill the canvas with a white background
  ctx.fillStyle = "#ffffff";
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  // Set text color
  ctx.fillStyle = "#000000";

  // Draw the text onto the canvas
  ctx.fillText(text, canvas.width / 2, canvas.height / 2);

  // Convert the canvas to a base64-encoded image data URL
  const base64ImageData = canvas.toDataURL("image/png");

  // Return the base64-encoded image data
  return base64ImageData;
}

export const CheckVisibleElement = (el: any, container: any): boolean => {
  if (!container || !el) return false;
  const elementRect = el.getBoundingClientRect();
  const parentRect = container.getBoundingClientRect();

  return (
    (elementRect.top >= parentRect.top &&
      elementRect.top + elementRect.height <= parentRect.bottom &&
      elementRect.right >= parentRect.left &&
      elementRect.left + elementRect.width <= parentRect.right) ||
    (elementRect.top >= parentRect.top &&
      elementRect.right >= parentRect.left &&
      elementRect.left + elementRect.width <= parentRect.right &&
      parentRect.bottom > elementRect.top)
  );
};
export const generateColorFromName = (name: string) => {
  const colors = [
    "#009900",
    "#339900",
    "#669900",
    "#999900",
    "#CC9900",
    "#FF9900",
    "#00CC00",
    "#33CC00",
    "#66CC00",
    "#99CC00",
    "#CCCC00",
    "#FFCC00",
    "#00FF00",
    "#33FF00",
    "#66FF00",
    "#99FF00",
    "#CCFF00",
    "#FFFF00",
    "#0033FF",
    "#3333FF",
    "#6633FF",
    "#9933FF",
    "#CC33FF",
    "#FF33FF",
    "#0066FF",
    "#3366FF",
    "#6666FF",
    "#9966FF",
    "#CC66FF",
    "#FF66FF",
    "#0099FF",
    "#3399FF",
    "#6699FF",
    "#9999FF",
    "#CC99FF",
    "#FF99FF",
    "#00CCFF",
    "#33CCFF",
    "#66CCFF",
    "#99CCFF",
    "#CCCCFF",
    "#FFCCF",
  ];

  // Hash the name using a simple hash function
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Select a color from the list using the hash value
  const colorIndex = Math.abs(hash) % colors.length;
  return colors[colorIndex];
};
export function abbreviateName(name: string) {
  const parts = name.trim().split(/\s+/);
  if (parts.length >= 2) {
    const firstInitial = parts[0].charAt(0).toUpperCase();
    const lastInitial = parts[parts.length - 1].charAt(0).toUpperCase();
    return firstInitial + lastInitial;
  } else if (parts.length === 1) {
    return parts[0].charAt(0).toUpperCase();
  }
  return "";
}
export function FileToArrayBuffer(file: File): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.result instanceof ArrayBuffer) {
        resolve(reader.result);
      } else {
        reject(new Error("Error converting file to ArrayBuffer"));
      }
    };

    reader.onerror = () => {
      reject(new Error("Error reading file"));
    };

    reader.readAsArrayBuffer(file);
  });
}

import UndoRedoManagerObj from "../UndoRedomanager";
import * as Api from "../../Api/src/index";
import { AppCache } from "../../Cache/AppCache";
import { PageService } from "../../Services/NotHook/PageService";
import { UndoHightLight } from "./HightLightCommand";
const HighlightApi = new Api.HighlightApi();
export const HightLightSearchResultCommand = () => {
  const { SetDataCurrentPage } = PageService();
  const HightLight = async (param: Api.HighlightSearchResultsParam) => {
    if (!param) return null;
    const res =
      await HighlightApi.apiLegalxtractHighLightUnHightSearchResultsPost(param);
    SetDataCurrentPage(res.data.lxPageData!, AppCache.CurrentPageNo || 0);
    return res;
  };
  const Do = async (param: Api.HighlightSearchResultsParam) => {
    const res = await HightLight(param);
    UndoRedoManagerObj.add({
      Redo: () => {
        Redo(param);
      },
      Undo: () => {
        Undo({
          preState: JSON.stringify(res?.data.dicPreState),
          viewerPageNo: AppCache.CurrentPageNo,
        });
      },
    });
    return res;
  };
  const Redo = (param: Api.HighlightSearchResultsParam) => {
    HightLight(param);
  };
  const Undo = async (param: Api.UndoHightLightParam) => {
    await UndoHightLight(param);
  };
  return { Do };
};

import { Button, Col, Row, Select, Space, Table, TableProps, Tag } from "antd";
import { useLang } from "../../../i18n/useLang";
import { LogPanelService } from "../../../Services/Hook/LeftPanel/LogPanelService";
interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}
export const LogPanel = () => {
  const { tr } = useLang();
  const {
    columns,
    LoadData,
    LogState,
    tadList,
    expandedRowKeys,
    setExpandedRowKeys,
    exportExcel,
  } = LogPanelService();
  return (
    <div className="left-panel-container">
      <Row style={{ height: "100%" }} gutter={[8, 8]}>
        <Col span={24} className="header-panel">
          {tr("Log")}
        </Col>
        <Col span={24}>
          <div style={{ display: "flex" }}>
            <label style={{ marginRight: "8px", alignContent: "center" }}>
              {tr("logFileFor")}
            </label>
            <div style={{ flex: "1" }}>
              <Select
                value={LogState.TabID}
                onChange={(value) => {
                  LoadData(value);
                }}
                style={{ width: "100%" }}
                options={tadList()}
              />
            </div>
            <div
              style={{ marginLeft: "8px", alignContent: "center", width: 70 }}
            >
              <Button
                style={{ height: 28 }}
                onClick={() => {
                  exportExcel();
                }}
              >
                {tr("export")}
              </Button>
            </div>
          </div>
        </Col>
        <Col
          style={{
            height: "calc(100% - 82px)",
            overflow: "auto",
          }}
          span={24}
        >
          <Table
            pagination={false}
            showHeader={false}
            expandable={{
              expandedRowKeys: expandedRowKeys,
              onExpand: (expanded, record) => {
                if (expanded) {
                  setExpandedRowKeys([...expandedRowKeys, record.id!]);
                } else {
                  setExpandedRowKeys(
                    expandedRowKeys.filter((o) => o !== record.id)
                  );
                }
              },
            }}
            rowKey={"id"}
            size="small"
            columns={columns}
            dataSource={LogState.HistoryViewItemWebs}
          />
        </Col>
      </Row>
    </div>
  );
};

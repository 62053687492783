import { Checkbox, Col, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";
import { useLang } from "../../../i18n/useLang";
import { KeyWordSearchPanelService } from "../../../Services/Hook/Search/KeyWordSearchPanelService";
export const KeyWordSearchPanel = () => {
  const { tr } = useLang();
  const { SearchTabState, SetSearchTabStore } = KeyWordSearchPanelService();
  return (
    <>
      <Row gutter={[8, 8]}>
        <Col md={24}>
          <TextArea
            value={
              SearchTabState.SearchTabVm?.searchKeywordParam?.keyword || ""
            }
            onChange={(e) => {
              SetSearchTabStore({
                ...SearchTabState,
                SearchTabVm: {
                  ...SearchTabState.SearchTabVm,
                  searchKeywordParam: {
                    ...SearchTabState.SearchTabVm?.searchKeywordParam,
                    keyword: e.target.value,
                  },
                },
              });
            }}
          ></TextArea>
        </Col>
        <Col md={12}>
          <Checkbox
            checked={SearchTabState.SearchTabVm?.searchKeywordParam?.wholeWord}
            onChange={(e) => {
              SetSearchTabStore({
                ...SearchTabState,
                SearchTabVm: {
                  ...SearchTabState.SearchTabVm,
                  searchKeywordParam: {
                    ...SearchTabState.SearchTabVm?.searchKeywordParam,
                    wholeWord: e.target.checked,
                  },
                },
              });
            }}
          >
            {tr("matchWholeWord")}
          </Checkbox>
        </Col>
        <Col md={12}>
          <Checkbox
            checked={SearchTabState.SearchTabVm?.searchKeywordParam?.matchCase}
            onChange={(e) => {
              SetSearchTabStore({
                ...SearchTabState,
                SearchTabVm: {
                  ...SearchTabState.SearchTabVm,
                  searchKeywordParam: {
                    ...SearchTabState.SearchTabVm?.searchKeywordParam,
                    matchCase: e.target.checked,
                  },
                },
              });
            }}
          >
            {tr("matchCase")}
          </Checkbox>
        </Col>
        <Col md={12}>
          <Checkbox
            checked={
              SearchTabState.SearchTabVm?.searchKeywordParam?.highlightWholeWord
            }
            onChange={(e) => {
              SetSearchTabStore({
                ...SearchTabState,
                SearchTabVm: {
                  ...SearchTabState.SearchTabVm,
                  searchKeywordParam: {
                    ...SearchTabState.SearchTabVm?.searchKeywordParam,
                    highlightWholeWord: e.target.checked,
                  },
                },
              });
            }}
          >
            {tr("selectEntireWord")}
          </Checkbox>
        </Col>
        <Col md={12}>
          <Checkbox
            checked={SearchTabState.SearchTabVm?.searchKeywordParam?.wildCard}
            onChange={(e) => {
              SetSearchTabStore({
                ...SearchTabState,
                SearchTabVm: {
                  ...SearchTabState.SearchTabVm,
                  searchKeywordParam: {
                    ...SearchTabState.SearchTabVm?.searchKeywordParam,
                    wildCard: e.target.checked,
                  },
                },
              });
            }}
          >
            {tr("useWildcard")}
          </Checkbox>
        </Col>
        <Col md={24}>
          <Checkbox
            checked={SearchTabState.SearchTabVm?.searchKeywordParam?.searchList}
            onChange={(e) => {
              SetSearchTabStore({
                ...SearchTabState,
                SearchTabVm: {
                  ...SearchTabState.SearchTabVm,
                  searchKeywordParam: {
                    ...SearchTabState.SearchTabVm?.searchKeywordParam,
                    searchList: e.target.checked,
                  },
                },
              });
            }}
          >
            {tr("allowMultiKeywordsSeparateBy")}
          </Checkbox>
        </Col>
      </Row>
      {SearchTabState.SearchTabVm?.searchKeywordParam?.searchList && (
        <div style={{ marginLeft: 16 }}>
          <Row gutter={[8, 8]}>
            <Col md={12}>
              <Checkbox
                checked={
                  SearchTabState.SearchTabVm?.searchKeywordParam?.byComma
                }
                onChange={(e) => {
                  SetSearchTabStore({
                    ...SearchTabState,
                    SearchTabVm: {
                      ...SearchTabState.SearchTabVm,
                      searchKeywordParam: {
                        ...SearchTabState.SearchTabVm?.searchKeywordParam,
                        byComma: e.target.checked,
                      },
                    },
                  });
                }}
              >
                {tr("comma")}
              </Checkbox>
            </Col>
            <Col md={12}>
              <Checkbox
                checked={
                  SearchTabState.SearchTabVm?.searchKeywordParam?.bySpace
                }
                onChange={(e) => {
                  SetSearchTabStore({
                    ...SearchTabState,
                    SearchTabVm: {
                      ...SearchTabState.SearchTabVm,
                      searchKeywordParam: {
                        ...SearchTabState.SearchTabVm?.searchKeywordParam,
                        bySpace: e.target.checked,
                      },
                    },
                  });
                }}
              >
                {tr("space")}
              </Checkbox>
            </Col>

            <Col md={12}>
              <Checkbox
                checked={
                  SearchTabState.SearchTabVm?.searchKeywordParam?.bySemicolon
                }
                onChange={(e) => {
                  SetSearchTabStore({
                    ...SearchTabState,
                    SearchTabVm: {
                      ...SearchTabState.SearchTabVm,
                      searchKeywordParam: {
                        ...SearchTabState.SearchTabVm?.searchKeywordParam,
                        bySemicolon: e.target.checked,
                      },
                    },
                  });
                }}
              >
                {tr("semicolon")}
              </Checkbox>
            </Col>
            <Col md={12}>
              <Checkbox
                checked={
                  SearchTabState.SearchTabVm?.searchKeywordParam?.bySplash
                }
                onChange={(e) => {
                  SetSearchTabStore({
                    ...SearchTabState,
                    SearchTabVm: {
                      ...SearchTabState.SearchTabVm,
                      searchKeywordParam: {
                        ...SearchTabState.SearchTabVm?.searchKeywordParam,
                        bySplash: e.target.checked,
                      },
                    },
                  });
                }}
              >
                {tr("slash")}
              </Checkbox>
            </Col>
            <Col md={24}>
              <Checkbox
                checked={SearchTabState.SearchTabVm?.searchKeywordParam?.byLine}
                onChange={(e) => {
                  SetSearchTabStore({
                    ...SearchTabState,
                    SearchTabVm: {
                      ...SearchTabState.SearchTabVm,
                      searchKeywordParam: {
                        ...SearchTabState.SearchTabVm?.searchKeywordParam,
                        byLine: e.target.checked,
                      },
                    },
                  });
                }}
              >
                {tr("line")}
              </Checkbox>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import MenuIcon from "./Components/TopMenu/MenuIcon";
import { MainView } from "./Components/MainPanel/MainView";
import UseAppService from "./Services/Hook/AppService";
import SplitPane, { Pane } from "split-pane-react";
import "split-pane-react/esm/themes/default.css";
import { TopMenuStrip } from "./Components/TopMenu/TopMenuStrip";
import LeftPanel from "./Components/LeftPanels/LeftPanel";
import MDPageNumbering from "./Modal/MDPageNumbering";
import MDTOCSettings from "./Modal/MDTOCSettings";
import MDSettings from "./Modal/MDSettings";
import PageToolbar from "./Components/TopMenu/PageToolbar";
import { Tabs } from "./Components/TabFile/Tabs";
import { MDTagList } from "./Modal/Tag/MDTagList";
import { ConfirmDialog } from "./MsgDialog/ConfirmDialog";
import { MDTagSelect } from "./Modal/Tag/MDTagSelect";
import MenuIconStore from "./ZustandStore/MenuIconStore";
import { ToolMode } from "./Constant/AppEnum";
import useTagStore from "./ZustandStore/TagStore";
import UseModalStore from "./ZustandStore/ModalStore";
import { ModalContainer } from "./Modal/ModaContainer";
import { userService } from "./Services/NotHook/UserService";
import { AppConstants, ConfigSettings } from "./Constant/AppContant";
import globalAxios from "axios";
import * as Api from "./Api/src/index";
import UseConfirmDialogStore from "./ZustandStore/ConfirmDialogStore";
import { AppLoadding } from "./Loadding/Loading";
function App() {
  let initCheck = useRef(false);
  const {} = UseAppService();
  const [sizes, setSizes] = useState([425, "auto"]);
  const [isShow, SetShow] = useState<boolean>(false);
  const { MenuIconState, SetMenuIconStore } = MenuIconStore();
  const { TagState, SetTagStore } = useTagStore();
  const { GetModalState } = UseModalStore();
  const { SetConfirmDialogState } = UseConfirmDialogStore();
  const { getUser } = userService();
  useEffect(() => {
    if (initCheck.current) {
      return;
    }
    initCheck.current = true;
    const user = getUser();
    if (user) {
      if (!ConfigSettings.REACT_APP_CompanyCode) {
        globalAxios
          .post(
            ConfigSettings.REACT_APP_API_ENDPOINT +
              "/api/legalxtract/CheckUserStatus",
            {
              token: user.userDataLogin?.result?.access_token,
            }
          )
          .then(async (res) => {
            const a = res.data.result as Api.UserStatusResult;
            if (a.status != (1 as any)) {
              window.location.href = "/login";
            } else {
              let msg = "";
              if (a.licenseData?.mode == "invalid") {
                if (a.licenseData.description == "key expired") {
                  msg = "Your time to use the software is over.";
                } else {
                  msg = "You no longer have the right to use the software";
                }
              }
              if (msg) {
                SetConfirmDialogState({
                  Show: true,
                  Msg: msg,
                  OnYes: () => {
                    window.location.href = "/login";
                  },
                  Type: "Alert",
                });
              }
            }
          });
      }
    }
  }, []);
  return (
    <>
      <div className="App" style={{ height: "100%", width: "100%" }}>
        <TopMenuStrip></TopMenuStrip>
        <MenuIcon></MenuIcon>
        <div style={{ height: "calc(100% - 110px)", width: "100%" }}>
          <SplitPane
            split="vertical"
            sizes={sizes}
            onChange={(sizes) => {
              setSizes(sizes);
            }}
            sashRender={(sashProps) => {
              return (
                <div
                  style={{
                    backgroundColor: "red",
                    width: 3,
                    cursor: "col-resize",
                    height: "100%",
                  }}
                  onMouseDown={() => {
                    SetShow(true);
                  }}
                  onMouseUp={() => {
                    SetShow(false);
                  }}
                />
              );
            }}
          >
            <Pane minSize={45} maxSize="50%">
              <LeftPanel></LeftPanel>
            </Pane>
            <div style={{ height: "calc(100% - 22px)" }}>
              <div
                onMouseUp={() => {
                  SetShow(false);
                }}
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                  display: isShow ? "block" : "none",
                }}
              ></div>
              <Tabs></Tabs>
              <PageToolbar></PageToolbar>
              <MainView></MainView>
              <ConfirmDialog></ConfirmDialog>
              {MenuIconState.ToolMode === ToolMode.Tag && (
                <MDTagSelect
                  onClose={() => {
                    SetMenuIconStore({ ToolMode: ToolMode.Select });
                  }}
                  onShowTagList={() => {
                    SetTagStore({ ShowTagList: true });
                  }}
                ></MDTagSelect>
              )}
            </div>
          </SplitPane>
        </div>
      </div>
      <ModalContainer></ModalContainer>
      <AppLoadding></AppLoadding>
    </>
  );
}

export default App;

import React from "react";
import { LoginService } from "../../Services/Hook/Login/LoginService";
import { useLang } from "../../i18n/useLang";
import { ConfigSettings } from "../../Constant/AppContant";
import { Button, Col, Form, Input, Row } from "antd";
import Title from "antd/es/skeleton/Title";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Link from "antd/es/typography/Link";
const Login = () => {
  const { tr } = useLang();
  const { formikLogin, handleSnackbarClose, msgState } = LoginService();
  const contentRender = () => {
    return (
      <div className="flex-box">
        <span>You have a new message &nbsp;</span>
        <i className="dx-icon-email icon-style"></i>
      </div>
    );
  };
  return (
    <>
      <div
        style={{
          height: "100vh",
          width: "100%",
          padding: "0 10px",
          display: "flex",
          background:
            "linear-gradient(155deg, rgb(51 108 248) 0%, rgb(125 184 254) 49%, rgb(200 212 255) 77%)",
        }}
        onKeyDown={(e) => {
          if (e.code && e.code.toLocaleLowerCase() === "enter") {
            formikLogin.submitForm();
          }
        }}
      >
        <div
          style={{
            margin: "auto",
            maxWidth: 400,
            background: "#fff",
            borderRadius: 10,
            padding: 20,
          }}
        >
          <Row gutter={[16, 16]}>
            <Col sm={24}>
              <label>{tr("email")}</label>
              <Input
                style={{ width: "100%" }}
                prefix={<UserOutlined />}
                name="userName"
                value={formikLogin.values?.userName}
                onChange={(e) => {
                  formikLogin.handleChange(e);
                }}
                onBlur={(e) => {
                  formikLogin.handleBlur(e);
                }}
              />
            </Col>
            <Col md={24} xs={24}>
              <label>{tr("password")}</label>
              <Input.Password
                style={{ width: "100%" }}
                name="passWord"
                value={formikLogin.values?.passWord}
                onChange={(e) => {
                  formikLogin.handleChange(e);
                }}
                onBlur={(e) => {
                  formikLogin.handleBlur(e);
                }}
                prefix={<LockOutlined />}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Col>
            <Col md={24} xs={24}>
              <Button
                type="primary"
                style={{ width: "100%" }}
                onClick={() => {
                  formikLogin.submitForm();
                }}
              >
                {tr("login")}
              </Button>
            </Col>
            <Col style={{ textAlign: "center" }} xs={24} md={24}>
              {tr("notAMember")}{" "}
              <Link href={ConfigSettings.RegisterLink}>
                {tr("registerNow")}
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default Login;

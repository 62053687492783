import { AppCache } from "../../../Cache/AppCache";
import usePageStore from "../../../ZustandStore/PageStore";
import { PdfTronService } from "../../NotHook/PdfTronService";
import * as Api from "../../../Api/src/index";
const RotateApi = new Api.RotateApi();
export const PageToolbarService = () => {
  const { PageState, SetPageStore } = usePageStore();
  const { SetCurrentPageNo, RotatePages } = PdfTronService();
  const SetPageNo = (pageNo: number | null) => {
    if (pageNo == null) return;
    if (pageNo < 1) {
      pageNo = 1;
    }
    if (pageNo > (PageState.TotalPages || 0)) {
      pageNo = PageState.TotalPages || 0;
    }
    // SetPageStore({ CurrentPageNo: pageNo });
    SetCurrentPageNo(pageNo);
  };
  const FitWidth = () => {
    if (AppCache.WebViewerInstance?.UI)
      AppCache.WebViewerInstance?.UI.setFitMode(
        AppCache.WebViewerInstance?.UI.FitMode.FitWidth
      );
  };
  const FitPage = () => {
    if (AppCache.WebViewerInstance?.UI)
      AppCache.WebViewerInstance?.UI.setFitMode(
        AppCache.WebViewerInstance?.UI.FitMode.FitPage
      );
  };
  const ZoomIn = () => {
    if (AppCache.WebViewerInstance?.UI) {
      const zoomLevel = AppCache.WebViewerInstance?.UI.getZoomLevel();
      AppCache.WebViewerInstance?.UI.setZoomLevel(zoomLevel + 0.1);
    }
  };
  const ZoomOut = () => {
    if (AppCache.WebViewerInstance?.UI) {
      let zoomLevel = AppCache.WebViewerInstance?.UI.getZoomLevel();
      zoomLevel = zoomLevel - 0.1;
      AppCache.WebViewerInstance?.UI.setZoomLevel(
        zoomLevel < 0.05 ? 0.05 : zoomLevel
      );
    }
  };

  const RotateLeft = async () => {
    const res = await RotateApi.apiLegalxtractRotateLeftPost({
      viewerPageNos: [AppCache.CurrentPageNo || 0],
    });
    if (res && res.data.isSuccess) {
      ProcessAfterRotate("left", res.data.result);
    }
  };
  const RotateRight = async () => {
    const res = await RotateApi.apiLegalxtractRotateRightPost({
      viewerPageNos: [AppCache.CurrentPageNo || 0],
    });
    if (res && res.data.isSuccess) {
      ProcessAfterRotate("right", res.data.result);
    }
  };
  const ProcessAfterRotate = (
    rotate: "left" | "right",
    data: Api.DocumentInfoPageDataResponse | undefined
  ) => {
    AppCache.DocumentInfo = data?.data || null;
    if (rotate == "left") {
      RotatePages([AppCache.CurrentPageNo || 0], 270);
    } else {
      RotatePages([AppCache.CurrentPageNo || 0], 90);
    }
    if (!AppCache.WebViewerInstance?.Core) return;
    const { documentViewer } = AppCache.WebViewerInstance?.Core;
    documentViewer.refreshPage(AppCache.CurrentPageNo || 0);
  };
  return {
    SetPageNo,
    PageState,
    FitWidth,
    FitPage,
    ZoomIn,
    ZoomOut,
    RotateLeft,
    RotateRight,
  };
};

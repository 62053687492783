import React, { useState } from "react";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  ColorPicker,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
} from "antd";
import { useLang } from "../i18n/useLang";
import {
  MDCustomTemplaceSearchProps,
  MDCustomTemplaceSearchService,
} from "../Services/Hook/Modal/MDCustomTemplaceSearchService";

const MDCustomTemplaceSearch = ({
  onClose,
  open,
  editItem,
}: MDCustomTemplaceSearchProps) => {
  const { tr } = useLang();
  const { formik, showErrors } = MDCustomTemplaceSearchService({
    onClose: onClose,
    editItem: editItem,
  });

  return (
    <>
      <Modal
        title={tr("template")}
        footer={
          <>
            <Button
              style={{ minWidth: 100 }}
              onClick={() => {
                onClose && onClose(null);
              }}
              type="default"
            >
              {tr("close")}
            </Button>
            <Button
              style={{ minWidth: 100 }}
              onClick={() => {
                formik.submitForm();
              }}
              type="primary"
            >
              {tr("save")}
            </Button>
          </>
        }
        centered
        open={open}
        width={550}
        onCancel={() => {
          onClose && onClose(null);
        }}
        maskClosable={false}
      >
        <Row gutter={[8, 8]}>
          <Col span={24}>
            {!formik.isValid && (
              <Alert
                description={showErrors(formik.errors)}
                type="warning"
                showIcon
              />
            )}
          </Col>
          <Col span={24}>{tr("customSeachHelpsYouToFindVariblePatterns")}</Col>
          <Col span={24}>
            <label>{tr("insertPattern")}</label>
            <Input
              style={{ width: "100%" }}
              name="value"
              value={formik.values.value || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleChange}
            ></Input>
          </Col>
          <Col span={24}>
            <Checkbox
              checked={formik.values.isRegex}
              onChange={(e) => {
                formik.setFieldValue("isRegex", e.target.checked);
              }}
            >
              {tr("regexForComplexPattern")}
            </Checkbox>
          </Col>
          <Col span={24}>
            <b>{tr("customPattern")}</b>
          </Col>
          <Col span={8} style={{ textAlign: "center" }}>
            <Button
              onClick={() => {
                formik.setFieldValue("value", formik.values.value + "#");
              }}
              style={{ minWidth: "100%" }}
              type="default"
            >
              #
            </Button>
            <p>{tr("number")}</p>
          </Col>
          <Col span={8} style={{ textAlign: "center" }}>
            <Button
              onClick={() => {
                formik.setFieldValue("value", formik.values.value + "&");
              }}
              style={{ minWidth: "100%" }}
              type="default"
            >
              &
            </Button>
            <p>{tr("letter")}</p>
          </Col>
          <Col span={8} style={{ textAlign: "center" }}>
            <Button
              onClick={() => {
                formik.setFieldValue("value", formik.values.value + "%");
              }}
              style={{ minWidth: "100%" }}
              type="default"
            >
              %
            </Button>
            <p>{tr("symbol")}</p>
          </Col>
          <Col span={24}>{tr("example")}</Col>
          <Col span={24}>
            <div style={{ display: "flex" }}>
              <div>{tr("journalNo")}</div>
              <div style={{ marginLeft: 40 }}>
                <div>&nbsp;&nbsp;K2002-00140 </div>
                <div>&nbsp;&nbsp;&####%#####</div>
              </div>
            </div>
          </Col>
          <Col span={24}>
            <label>{tr("namePattern")}</label>
            <Input
              style={{ width: "100%" }}
              name="name"
              value={formik.values.name || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleChange}
            ></Input>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default MDCustomTemplaceSearch;

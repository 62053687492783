import { create } from "zustand";
import { persist } from "zustand/middleware";
interface ThumbnailState {
  LoadThumbnail?: boolean;
}
interface ThumbnailStore {
  ThumbnailState: ThumbnailState;
  SetThumbnailStore: (ThumbnailState: ThumbnailState) => void;
}
const useThumbnailStore = create<ThumbnailStore>()((set) => ({
  ThumbnailState: { LoadThumbnail: false },
  SetThumbnailStore: (ThumbnailState) =>
    set((state) => ({
      ...state,
      ThumbnailState: { ...state.ThumbnailState, ...ThumbnailState },
    })),
}));

export default useThumbnailStore;

import * as Api from "../../Api/src/index";
import { AppCache } from "../../Cache/AppCache";
const ReplaceApi = new Api.ReplaceApi();
export const ReplaceService = () => {
  const GetReplaceObjects = () => {
    ReplaceApi.apiLegalxtractGetReplaceObjectsGet().then((res) => {
      AppCache.ReplaceObjects = res.data;
    });
  };
  return { GetReplaceObjects };
};

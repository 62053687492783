import loadding from "../Assets/IconApp/loading.gif";
import useLoaddingAppStore from "../ZustandStore/LoaddingAppStore";
export const AppLoadding = () => {
  const { LoaddingAppState } = useLoaddingAppStore();
  return (
    <>
      {LoaddingAppState.Show && (
        <div className="loadding-app">
          <img src={loadding} alt="loadding" />
        </div>
      )}
    </>
  );
};

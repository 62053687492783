import { create } from "zustand";
import { persist } from "zustand/middleware";
import * as Api from "../Api/src/index";
interface LogState {
  HistoryViewItemWebs?: Api.HistoryViewItemWeb[];
  TabID?: string;
}
interface LogStore {
  LogState: LogState;
  SetLogStore: (LogState: LogState) => void;
  GetLogState: () => LogState;
}
const useLogStore = create<LogStore>()((set, get) => ({
  LogState: {
    HistoryViewItemWebs: [],
    TabID: "0000000-0000-0000-0000-000000000000",
  },
  SetLogStore: (LogState) =>
    set((state) => ({
      ...state,
      LogState: { ...state.LogState, ...LogState },
    })),
  GetLogState: () => {
    const state = get().LogState;
    return state;
  },
}));

export default useLogStore;

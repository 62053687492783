import { Checkbox, Col, Row, Space, Table, TableColumnsType } from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";
import { useLang } from "../../../i18n/useLang";
import { SearchResult } from "./SearchResult";
import Link from "antd/es/typography/Link";
import { TemplateSearchPanelService } from "../../../Services/Hook/Search/TemplateSearchPanelService";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import MDCustomTemplaceSearch from "../../../Modal/MDCustomTemplaceSearch";
import UseConfirmDialogStore from "../../../ZustandStore/ConfirmDialogStore";
import { CustomSearchTemplate } from "../../../Services/NotHook/SearchService";
export const TemplateSearchPanel = () => {
  const { tr } = useLang();
  const {
    SearchTemplateParam,
    SetSearchTemplateParam,
    AddCustomTemplate,
    EditCustomTemplate,
    RemoveCustomTemplate,
    MDCustomeTemplaceState,
    SetMDCustomeTemplaceState,
    rowCustomTemplaceSelection,
  } = TemplateSearchPanelService();

  const columns: TableColumnsType<CustomSearchTemplate> = [
    {
      title: "selected",
      width: 30,
      dataIndex: "selected",
      render: (_, record, i) => (
        <Checkbox
          checked={record.selected}
          onChange={(e) => {
            const customTemplateItems =
              SearchTemplateParam.customTemplateItems?.map((o) => {
                return o.id === record.id
                  ? {
                      ...o,
                      selected: e.target.checked,
                    }
                  : o;
              });
            SetSearchTemplateParam({
              ...SearchTemplateParam,
              customTemplateItems: customTemplateItems,
            });
          }}
        ></Checkbox>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text: string) => text,
    },
    {
      width: 60,
      render: (_, record, i) => {
        return (
          <Space>
            <a
              onClick={() => {
                EditCustomTemplate(record);
              }}
            >
              <EditOutlined />
            </a>
            <a
              onClick={() => {
                RemoveCustomTemplate(record);
              }}
            >
              <DeleteOutlined />
            </a>
          </Space>
        );
      },
    },
  ];
  // rowSelection object indicates the need for row selection

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col md={12}>
          <Checkbox
            checked={SearchTemplateParam?.templateItem?.name?.checked}
            onChange={(e) =>
              SetSearchTemplateParam({
                ...SearchTemplateParam,
                templateItem: {
                  ...SearchTemplateParam?.templateItem,
                  name: {
                    ...SearchTemplateParam?.templateItem?.name,
                    checked: e.target.checked,
                  },
                },
              })
            }
          >
            {tr("fullName")}
          </Checkbox>
          <Checkbox
            checked={SearchTemplateParam?.templateItem?.name?.isFull}
            onChange={(e) =>
              SetSearchTemplateParam({
                ...SearchTemplateParam,
                templateItem: {
                  ...SearchTemplateParam?.templateItem,
                  name: {
                    ...SearchTemplateParam?.templateItem?.name,
                    isFull: e.target.checked,
                  },
                },
              })
            }
          >
            {tr("firstName")}
          </Checkbox>
        </Col>
        <Col md={12}>
          <Checkbox
            checked={SearchTemplateParam?.templateItem?.phoneNumber?.checked}
            onChange={(e) =>
              SetSearchTemplateParam({
                ...SearchTemplateParam,
                templateItem: {
                  ...SearchTemplateParam?.templateItem,
                  phoneNumber: {
                    ...SearchTemplateParam?.templateItem?.phoneNumber,
                    checked: e.target.checked,
                  },
                },
              })
            }
          >
            {tr("phoneNumber")}
          </Checkbox>
        </Col>
        <Col md={12}>
          <Checkbox
            checked={SearchTemplateParam?.templateItem?.cpr?.checked}
            onChange={(e) =>
              SetSearchTemplateParam({
                ...SearchTemplateParam,
                templateItem: {
                  ...SearchTemplateParam?.templateItem,
                  cpr: {
                    ...SearchTemplateParam?.templateItem?.cpr,
                    checked: e.target.checked,
                  },
                },
              })
            }
          >
            {tr("cpr")}
          </Checkbox>
          {SearchTemplateParam?.templateItem?.cpr?.checked && (
            <Checkbox
              checked={SearchTemplateParam?.templateItem?.cpr?.isFull}
              onChange={(e) =>
                SetSearchTemplateParam({
                  ...SearchTemplateParam,
                  templateItem: {
                    ...SearchTemplateParam?.templateItem,
                    cpr: {
                      ...SearchTemplateParam?.templateItem?.cpr,
                      isFull: e.target.checked,
                    },
                  },
                })
              }
            >
              {tr("module11")}
            </Checkbox>
          )}
        </Col>
        <Col md={12}>
          <Checkbox
            checked={SearchTemplateParam.templateItem?.email?.checked}
            onChange={(e) =>
              SetSearchTemplateParam({
                ...SearchTemplateParam,
                templateItem: {
                  ...SearchTemplateParam?.templateItem,
                  email: {
                    ...SearchTemplateParam?.templateItem?.email,
                    templateKey: "Email",
                    checked: e.target.checked,
                  },
                },
              })
            }
          >
            {tr("email")}
          </Checkbox>
        </Col>
        <Col md={12}>
          <Checkbox
            checked={SearchTemplateParam?.templateItem?.cvr?.checked}
            onChange={(e) =>
              SetSearchTemplateParam({
                ...SearchTemplateParam,
                templateItem: {
                  ...SearchTemplateParam?.templateItem,
                  cvr: {
                    ...SearchTemplateParam?.templateItem?.cvr,
                    checked: e.target.checked,
                  },
                },
              })
            }
          >
            {tr("cvr")}
          </Checkbox>
        </Col>
        <Col md={12}>
          <Checkbox
            checked={SearchTemplateParam?.templateItem?.creditCard?.checked}
            onChange={(e) =>
              SetSearchTemplateParam({
                ...SearchTemplateParam,
                templateItem: {
                  ...SearchTemplateParam?.templateItem,
                  creditCard: {
                    ...SearchTemplateParam?.templateItem?.creditCard,
                    checked: e.target.checked,
                  },
                },
              })
            }
          >
            {tr("creditCard")}
          </Checkbox>
        </Col>
        <Col md={12}>
          <Checkbox
            checked={SearchTemplateParam?.templateItem?.address?.checked}
            onChange={(e) =>
              SetSearchTemplateParam({
                ...SearchTemplateParam,
                templateItem: {
                  ...SearchTemplateParam?.templateItem,
                  address: {
                    ...SearchTemplateParam?.templateItem?.address,
                    checked: e.target.checked,
                  },
                },
              })
            }
          >
            {tr("address")}
          </Checkbox>
          {SearchTemplateParam?.templateItem?.address?.checked && (
            <Checkbox
              checked={SearchTemplateParam?.templateItem?.address?.isFull}
              onChange={(e) =>
                SetSearchTemplateParam({
                  ...SearchTemplateParam,
                  templateItem: {
                    ...SearchTemplateParam?.templateItem,
                    address: {
                      ...SearchTemplateParam?.templateItem?.address,
                      isFull: e.target.checked,
                    },
                  },
                })
              }
            >
              {tr("fullAddress")}
            </Checkbox>
          )}
        </Col>
        <Col md={12}>
          <Checkbox
            checked={SearchTemplateParam?.templateItem?.currency?.checked}
            onChange={(e) =>
              SetSearchTemplateParam({
                ...SearchTemplateParam,
                templateItem: {
                  ...SearchTemplateParam?.templateItem,
                  currency: {
                    ...SearchTemplateParam?.templateItem?.currency,
                    checked: e.target.checked,
                  },
                },
              })
            }
          >
            {tr("prices")}
          </Checkbox>
        </Col>
        <Col md={12}>
          <Checkbox
            checked={SearchTemplateParam?.templateItem?.webAddress?.checked}
            onChange={(e) =>
              SetSearchTemplateParam({
                ...SearchTemplateParam,
                templateItem: {
                  ...SearchTemplateParam?.templateItem,
                  webAddress: {
                    ...SearchTemplateParam?.templateItem?.webAddress,
                    checked: e.target.checked,
                  },
                },
              })
            }
          >
            {tr("webAddress")}
          </Checkbox>
        </Col>
        <Col md={12}>
          <Checkbox
            checked={SearchTemplateParam?.templateItem?.number?.checked}
            onChange={(e) =>
              SetSearchTemplateParam({
                ...SearchTemplateParam,
                templateItem: {
                  ...SearchTemplateParam?.templateItem,
                  number: {
                    ...SearchTemplateParam?.templateItem?.number,
                    checked: e.target.checked,
                  },
                },
              })
            }
          >
            {tr("number")}
          </Checkbox>
        </Col>
        <Col md={12}>
          <Checkbox
            checked={SearchTemplateParam?.templateItem?.date?.checked}
            onChange={(e) =>
              SetSearchTemplateParam({
                ...SearchTemplateParam,
                templateItem: {
                  ...SearchTemplateParam?.templateItem,
                  date: {
                    ...SearchTemplateParam?.templateItem?.date,
                    checked: e.target.checked,
                  },
                },
              })
            }
          >
            {tr("date")}
          </Checkbox>
        </Col>
        <Col md={12}>
          <Checkbox
            checked={
              SearchTemplateParam?.templateItem?.assistiveProduct?.checked
            }
            onChange={(e) =>
              SetSearchTemplateParam({
                ...SearchTemplateParam,
                templateItem: {
                  ...SearchTemplateParam?.templateItem,
                  assistiveProduct: {
                    ...SearchTemplateParam?.templateItem?.assistiveProduct,
                    checked: e.target.checked,
                  },
                },
              })
            }
          >
            {tr("assistiveProduct")}
          </Checkbox>
        </Col>
        <Col md={12}>
          <Checkbox
            checked={SearchTemplateParam?.templateItem?.diseases?.checked}
            onChange={(e) =>
              SetSearchTemplateParam({
                ...SearchTemplateParam,
                templateItem: {
                  ...SearchTemplateParam?.templateItem,
                  diseases: {
                    ...SearchTemplateParam?.templateItem?.diseases,
                    checked: e.target.checked,
                  },
                },
              })
            }
          >
            {tr("diseases")}
          </Checkbox>
        </Col>
        <Col md={12}>
          <Checkbox
            checked={SearchTemplateParam?.templateItem?.medication?.checked}
            onChange={(e) =>
              SetSearchTemplateParam({
                ...SearchTemplateParam,
                templateItem: {
                  ...SearchTemplateParam?.templateItem,
                  medication: {
                    ...SearchTemplateParam?.templateItem?.medication,
                    checked: e.target.checked,
                  },
                },
              })
            }
          >
            {tr("medications")}
          </Checkbox>
        </Col>
        <Col md={24}>
          <Checkbox
            checked={SearchTemplateParam?.templateItem?.customTemplate?.checked}
            onChange={(e) => {
              SetSearchTemplateParam({
                ...SearchTemplateParam,
                templateItem: {
                  ...SearchTemplateParam?.templateItem,
                  customTemplate: {
                    ...SearchTemplateParam?.templateItem?.customTemplate,
                    checked: e.target.checked,
                  },
                },
              });
            }}
          >
            {tr("customTemplate")}
          </Checkbox>
        </Col>
        {SearchTemplateParam?.templateItem?.customTemplate?.checked && (
          <>
            <Col md={24}>
              <Link
                onClick={(o) => {
                  AddCustomTemplate();
                }}
              >
                {tr("addCustomTemplate")}
              </Link>
            </Col>
            <Col md={24}>
              <Table
                scroll={{ y: 350 }}
                size="small"
                columns={columns}
                dataSource={SearchTemplateParam.customTemplateItems || []}
                pagination={false}
                showHeader={false}
              />
            </Col>
          </>
        )}
        {MDCustomeTemplaceState.open && (
          <MDCustomTemplaceSearch
            open={MDCustomeTemplaceState.open}
            editItem={MDCustomeTemplaceState.EditItem}
            onClose={(item) => {
              if (item) {
                if (MDCustomeTemplaceState.EditItem) {
                  var customTemplateItems =
                    SearchTemplateParam.customTemplateItems?.map((o) => {
                      return o.id === item.id
                        ? {
                            ...o,
                            ...item,
                          }
                        : o;
                    });
                  SetSearchTemplateParam({
                    ...SearchTemplateParam,
                    customTemplateItems: customTemplateItems,
                  });
                } else {
                  SetSearchTemplateParam({
                    ...SearchTemplateParam,
                    customTemplateItems: [
                      ...(SearchTemplateParam.customTemplateItems || []),
                      ...[item],
                    ],
                  });
                }
              }
              SetMDCustomeTemplaceState({
                open: false,
                EditItem: null,
              });
            }}
          ></MDCustomTemplaceSearch>
        )}
      </Row>
    </>
  );
};

import MDReplaceWord from "./Replace/MDReplaceWord";
import UseModalStore, { ModaType } from "../ZustandStore/ModalStore";
import MDReplaceBox from "./Replace/MDReplaceBox";
import { MDSaveFile } from "./MDSaveFile";

export const ModalContainer = () => {
  const { ModalState, SetModalState } = UseModalStore();
  return (
    <>
      {ModalState.Type === ModaType.MDReplaceWord && ModalState.Show && (
        <MDReplaceWord></MDReplaceWord>
      )}
      {ModalState.Type === ModaType.MDReplaceBox && ModalState.Show && (
        <MDReplaceBox></MDReplaceBox>
      )}
      {ModalState.Type === ModaType.MDSaveFile && ModalState.Show && (
        <MDSaveFile></MDSaveFile>
      )}
    </>
  );
};

import React, { useContext, useEffect, useRef } from "react";
import { useLang } from "../../../i18n/useLang";
import loading from "../../../Assets/IconApp/loading.gif";
import { AppCache } from "../../../Cache/AppCache";
import * as Api from "../../../Api/src";
import { GetLxSetting } from "../../../Services/Hook/Modal/MDAppSettingsService";
import { AppConstants } from "../../../Constant/AppContant";
import { generateBase64ImageFromText } from "../../../Common/CommonFunction";
interface PageThumbnailProps {
  selectedPage: number[];
  imagePage: any;
  pageNo: number | undefined;
  active: boolean;
  onMouseDown?: (
    pageNo: number,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  onClick: (
    pageNo: number,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  onContextMenu: (
    pageNo: number,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
}

export const PageThumbnail = ({
  active,
  selectedPage,
  pageNo,
  imagePage,
  onMouseDown,
  onContextMenu,
  onClick,
}: PageThumbnailProps) => {
  const { tr } = useLang();
  const pageRef = React.useRef<HTMLDivElement>(null);
  const page = AppCache.GetLxPage(pageNo || 0);
  const InitCheck = useRef<boolean>(false);
  const getTextPage = () => {
    // thiếu trang thái OCR
    let s =
      page?.isHidden !== (0 as any)
        ? `...${
            page?.isHidden === (1 as any) ? tr("hide") : tr("hideOnExtract")
          }`
        : "";
    if (!s && page?.tabFileType ==  Api.TabFileType.TOC) {
      return `...TOC`;
    } else if (!s && page?.isBlankPage) {
      return `...Blank`;
    } else if (!s && page?.tabFileType == Api.TabFileType.TOC) {
      return `...FrontPage`;
    } else {
      // thiếu trang thái OCR
      // if (!s && imageDetail.needOCR) {
      //   s = tr("needOcr");
      // }
      s += page?.splitOnExtract ? " " + tr("splitFilesWhenPublishing") : "";
      return s;
    }
  };
  // let image = imagePage;
  // if (!image) {
  //   image = <div></div>;
  // } else {
  //   image = `data:image/png;base64, ${imagePage}`;
  // }
  useEffect(() => {
    if (InitCheck.current) return;
    // if (pageRef.current && imagePage) {
    //   pageRef.current.appendChild(imagePage);
    // }
    InitCheck.current = true;
  }, []);
  useEffect(() => {
    const div = pageRef.current;
    if (div && imagePage && page?.isHidden === (0 as any)) {
      div.appendChild(imagePage);

      // Cleanup function to remove the canvas on component unmount
      return () => {
        div.removeChild(imagePage);
      };
    }
  }, [imagePage]);
  const lxSetting = GetLxSetting();
  const DrawHidenPage = () => {
    return (
      <div
        className={`placeholder ${
          page?.rotationAngles == 0 || page?.rotationAngles == 180
            ? "vertical"
            : ""
        }`}
        style={{
          display: "flex",
          alignItems: "center",
          background: "white",
        }}
      >
        <div
          style={{
            width: "fit-content",
            height: "fit-content",
            margin: "auto",
          }}
        >
          {lxSetting.hiddenPageImage && (
            <img
              src={
                "data:image/png;base64, " +
                (lxSetting.hiddenPageImage || AppConstants.hiddenImg)
              }
              style={{
                width: 60,
                margin: "auto",
                maxHeight: "200px",
                fontSize: 66,
                display: "block",
              }}
              alt=""
            />
          )}
          {lxSetting.hiddenPageTitle && (
            <img
              src={generateBase64ImageFromText(
                lxSetting.hiddenPageTitle || "",
                40
              )}
              style={{
                margin: "auto",
                width: 80,
                maxHeight: "200px",
                fontSize: 66,
              }}
              alt=""
            />
          )}
        </div>
      </div>
    );
  };
  return (
    <React.Fragment>
      <div
        onMouseDown={(e) => {
          onMouseDown && onMouseDown(pageNo || 0, e);
        }}
        onClick={(e) => {
          onClick(pageNo || 0, e);
        }}
        onContextMenu={(e) => {
          onContextMenu(pageNo || 0, e);
        }}
        itemType={
          page?.tabFileType == Api.TabFileType.FrontPage ||
          page?.tabFileType == Api.TabFileType.TOC
            ? "NotSelect"
            : ""
        }
        id={`thumbnail-${pageNo}`}
        className={
          "item-thumbnail " +
          (active ? "active" : "") +
          " " +
          (selectedPage.includes(pageNo || 0) ? "selected" : "")
        }
      >
        <div className="image" ref={pageRef}>
          {page?.isHidden === (0 as any) && !imagePage && (
            <img className="loading-img" src={loading} alt="loading" />
          )}
          {page?.isHidden !== (0 as any) && DrawHidenPage()}
        </div>
        <p style={{ textAlign: "center" }}>
          {tr("page")} {pageNo} {getTextPage()}
        </p>
      </div>
    </React.Fragment>
  );
};

import * as Api from "../../Api/src/index";
const CommentApi = new Api.CommentApi();
export const CommentService = () => {
  const editComment = async (
    comment: Api.CommentParam,
    pageNo: number
  ): Promise<Api.CommentObjDisplayWeb> => {
    const res = await CommentApi.apiLegalxtractEditCommentPost(pageNo, comment);
    return {
      commentObjDisplay: res.data.result?.commentObjDisplay,
      rectangleFs: res.data.result?.rectangleFs,
    };
  };
  const deleteComment = async (id: string, pageNo: number) => {
    const res = await CommentApi.apiLegalxtractDeleteCommentDelete(id, pageNo );
    return res.data;
  };
  return { editComment, deleteComment };
};

import { create } from "zustand";
import { persist } from "zustand/middleware";
import { SearchTabVm, SearchResultData } from "../Api/src";
import { SearchService } from "../Services/NotHook/SearchService";
import { get } from "http";
interface SearchTabState {
  SearchTabVm?: SearchTabVm;
  runSearch?: boolean;
  clearSearch?: boolean;
  runSelect?: "selectAll" | "deSelectAll" | "none";
}
interface SearchTabStore {
  SearchTabState: SearchTabState;
  SetSearchTabStore: (searchTabState: SearchTabState) => void;
}
const {
  GetSearchTabVm,
  GetSearchKeywordParam,
  GetSearchTemplateParam,
  GetSearchTagParams,
} = SearchService();
const useSearchTabStore = create<SearchTabStore>()(
  persist(
    (set) => ({
      SearchTabState: {
        SearchTabVm: {
          ...GetSearchTabVm(),
          searchKeywordParam: GetSearchKeywordParam(),
          searchTagParam: GetSearchTagParams(),
        },
      },
      SetSearchTabStore: (searchTabState) =>
        set((state) => ({
          ...state,
          SearchTabState: { ...state.SearchTabState, ...searchTabState },
        })),
    }),
    {
      name: "SearchTabStore",
    }
  )
);

export default useSearchTabStore;

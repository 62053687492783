import * as React from "react";
import * as Api from "../Api/src";
import { Button, Col, Input, Modal, Row } from "antd";
import { AppCache } from "../Cache/AppCache";
import useWatchdogStore from "../ZustandStore/WatchdogStore";
import useSearchTabStore from "../ZustandStore/SearchTabStore";
import UseConfirmDialogStore from "../ZustandStore/ConfirmDialogStore";
import { useLang } from "../i18n/useLang";
import { SaveService } from "../Services/NotHook/SaveService";
import UseModalStore, { ModaType } from "../ZustandStore/ModalStore";
import useLoaddingAppStore from "../ZustandStore/LoaddingAppStore";
const SaveApi = new Api.SaveApi();
// const NovaApi = new Api.NovaApi();

export enum SaveMode {
  Extract = 1,
  SavePDF = 2,
  SaveAsZip = 3,
  SaveFileXtract = 4,
  SaveCombine = 5,
  SaveXtractPdfLC = 6,
  SaveFileXtractLC = 7,
}
export enum SaveType {
  SaveNova = 10,
  SaveExtract = 11,
}
export const GoToNova = () => {
  // NovaApi.apiLegalxtractGetReturnUrlGet(
  //   localStorage.getItem(LocalStorageKey.IdentifiedKey) || ""
  // ).then((res) => {
  //   if (res) {
  //     window.location.href = res.data;
  //   } else {
  //     window.location.reload();
  //   }
  // });
};
export const MDSaveFile = () => {
  const { tr } = useLang();
  const { SetLoaddingAppStore } = useLoaddingAppStore();
  const { SetConfirmDialogState } = UseConfirmDialogStore();
  const { WatchDogParamWeb } = useWatchdogStore();
  const { ModalState, SetModalState } = UseModalStore();
  const { SearchTabState } = useSearchTabStore();
  function generateSavingFileName(mode: Number = 1) {
    let fileName = "ExtractFile";
    if (
      AppCache?.DocumentInfo?.tabDetails &&
      (AppCache?.DocumentInfo?.tabDetails || []).length > 0
    ) {
      const arrFile = AppCache?.DocumentInfo?.tabDetails.filter(
        (tab) => tab.tabFileType == (0 as any)
      );
      if (arrFile && arrFile.length > 0) {
        if (mode == 1) {
          fileName = "X_" + arrFile[0].fileName?.trim();
        } else if (mode == 2) {
          fileName = "Document_" + arrFile[0].fileName?.trim();
        } else if (mode == 3) {
          fileName = "LegalXtract_" + arrFile[0].fileName?.trim();
        } else {
          fileName = arrFile[0].fileName?.trim() || "ExtractFile";
        }
      }
    }
    return fileName;
  }
  const getFileName = (): string => {
    switch (AppCache.SaveParam.saveMode) {
      case SaveMode.Extract:
        switch (AppCache.SaveParam.saveType) {
          case SaveType.SaveNova:
            return generateSavingFileName(4);
            break;
          default:
            return generateSavingFileName();
            break;
        }
        break;
      case SaveMode.SaveFileXtractLC:
      case SaveMode.SaveFileXtract:
        switch (AppCache.SaveParam.saveType) {
          case SaveType.SaveNova:
            return generateSavingFileName(4);
            break;
          default:
            return generateSavingFileName(4);
            break;
        }
        break;
      case SaveMode.SaveAsZip:
        return generateSavingFileName(3);
        break;
      case SaveMode.SavePDF:
        return generateSavingFileName(2);
        break;
      case SaveMode.SaveCombine:
        return generateSavingFileName(1);
      default:
        return generateSavingFileName(1);
    }
  };
  const [name, setName] = React.useState(getFileName());
  const [show, setShow] = React.useState<boolean>();
  const onSubmit = async () => {
    SetLoaddingAppStore({ Show: true });
    if (AppCache.SaveParam.saveMode != SaveMode.SaveCombine) {
      // setTrackingState({
      //   ...tracking,
      //   openingLoadingState: {
      //     ...tracking.openingLoadingState,
      //     isOpen: true,
      //     label: ``,
      //   },
      // });
    }
    switch (AppCache.SaveParam.saveMode) {
      case SaveMode.Extract:
        switch (AppCache.SaveParam.saveType) {
          case SaveType.SaveNova:
            // saveService.saveRedactedNova(name, docuviewareMain).then((res) => {
            //   setTrackingState({
            //     ...tracking,
            //     openingLoadingState: {
            //       ...tracking.openingLoadingState,
            //       isOpen: false,
            //       label: ``,
            //     },
            //   });
            //   if (res.toString() == "true") {
            //     GoToNova();
            //   } else {
            //     setDialogState({
            //       titleDialog: "",
            //       MsgDialog: tr("errorSavingFile"),
            //       CallBackConfirmDialog: null,
            //       Type: "AlertDialog",
            //     });
            //   }
            // });
            break;
          default:
            // saveService
            //   .onExtractToPDF(
            //     {
            //       pdfFileType: AppCache.SaveParam.saveType as Api.PdfFileType,
            //       fileName: name,
            //       fixedSplitInPages: "",
            //       highlightedSelections: false,
            //     },
            //     docuviewareMain
            //   )
            //   .then(() => {
            //     setTrackingState({
            //       ...tracking,
            //       openingLoadingState: {
            //         ...tracking.openingLoadingState,
            //         isOpen: false,
            //         label: ``,
            //       },
            //     });
            //   });
            break;
        }
        break;
      case SaveMode.SaveFileXtract:
        switch (AppCache.SaveParam.saveType) {
          case SaveType.SaveNova:
            // saveService.saveXtractNova(name, docuviewareMain).then((res) => {
            //   setTrackingState({
            //     ...tracking,
            //     openingLoadingState: {
            //       ...tracking.openingLoadingState,
            //       isOpen: false,
            //       label: ``,
            //     },
            //   });
            //   if (res.toString() == "true") {
            //     GoToNova();
            //   } else {
            //     setDialogState({
            //       titleDialog: "",
            //       MsgDialog: tr("errorSavingFile"),
            //       CallBackConfirmDialog: null,
            //       Type: "AlertDialog",
            //     });
            //   }
            // });
            break;
          default:
            SaveService.SaveXtract(name).then((res) => {
              SetLoaddingAppStore({ Show: false });
              if (AppCache.SaveParam.afterSave) AppCache.SaveParam.afterSave();
              AppCache.SaveParam = { ...AppCache.SaveParam, afterSave: null };
            });
            break;
        }
        break;
      case SaveMode.SaveAsZip:
        // saveService.saveZipFile(name, docuviewareMain).then(() => {
        //   setTrackingState({
        //     ...tracking,
        //     openingLoadingState: {
        //       ...tracking.openingLoadingState,
        //       isOpen: false,
        //       label: ``,
        //     },
        //   });
        // });
        break;
      case SaveMode.SavePDF:
        // saveService.savePdfFile(name, docuviewareMain).then(() => {
        //   setTrackingState({
        //     ...tracking,
        //     openingLoadingState: {
        //       ...tracking.openingLoadingState,
        //       isOpen: false,
        //       label: ``,
        //     },
        //   });
        // });
        break;
      case SaveMode.SaveCombine:
        // setDialogState({
        //   titleDialog: "",
        //   MsgDialog: tr(
        //     "ifYouCombineThePdfFilesPleaseBeAwareThatTheStampFrontPageTocAndBlankPageIsNotIncludedAreYouSureYouWantToCreateCombinedFile"
        //   ),
        //   CallBackConfirmDialog: {
        //     action: () => {
        //       setTrackingState({
        //         ...tracking,
        //         openingLoadingState: {
        //           ...tracking.openingLoadingState,
        //           isOpen: true,
        //           label: ``,
        //         },
        //       });
        //       saveService.combinePdf(name, docuviewareMain).then(() => {
        //         setTrackingState({
        //           ...tracking,
        //           openingLoadingState: {
        //             ...tracking.openingLoadingState,
        //             isOpen: false,
        //             label: ``,
        //           },
        //         });
        //       });
        //     },
        //     param: null,
        //   },
        //   Type: "ConfirmationDialog",
        // });

        break;
      case SaveMode.SaveFileXtractLC: {
        // saveService
        //   .saveExtractFileToThirdParty(name, docuviewareMain)
        //   .then((res) => {
        //     if (res && res.isSuccess) {
        //       setTrackingState({
        //         ...tracking,
        //         openingLoadingState: {
        //           ...tracking.openingLoadingState,
        //           isOpen: false,
        //           label: ``,
        //         },
        //       });
        //       setDialogState({
        //         titleDialog: "",
        //         MsgDialog: tr("Save file success"),
        //         CallBackConfirmDialog: null,
        //         Type: "AlertDialog",
        //       });
        //       if (AppCache.SaveParam.afterSave) AppCache.SaveParam.afterSave();
        //     }
        //   });
      }
    }
    onCloseModal();
  };
  const onCloseModal = () => {
    SetModalState({
      Show: false,
      Type: ModaType.None,
    });
  };
  React.useEffect(() => {
    if (ModalState.Show && ModalState.Type == ModaType.MDSaveFile) {
      // setTrackingState({
      //   ...tracking,
      //   openingLoadingState: {
      //     ...tracking.openingLoadingState,
      //     isOpen: true,
      //     label: ``,
      //   },
      // });
      if (
        AppCache.SaveParam.saveMode == SaveMode.Extract ||
        AppCache.SaveParam.saveMode == SaveMode.SaveXtractPdfLC
      ) {
        SaveApi.apiLegalxtractCheckSettingWatchdogPost({
          param: WatchDogParamWeb,
          searchTemplateParam: {
            customTemplateItems:
              SearchTabState.SearchTabVm?.searchTemplateParam
                ?.customTemplateItems,
            customTemplate: true,
            templateItems:
              SearchTabState.SearchTabVm?.searchTemplateParam?.templateItems,
          },
        }).then((res) => {
          // setTrackingState({
          //   ...tracking,
          //   openingLoadingState: {
          //     ...tracking.openingLoadingState,
          //     isOpen: false,
          //     label: ``,
          //   },
          // });

          if (res.data && res.data.result && res.data.result?.length > 0) {
            const data = res.data.result;
            SetConfirmDialogState({
              Title: "",
              Msg: data[0],
              OnCancel: () => {
                onCloseModal();
              },
              OnYes: () => {
                if (!data || !data[1]) {
                  setShow(ModalState.Show);
                } else {
                  SetConfirmDialogState({
                    Title: "",
                    Msg: data[1],
                    Show: true,
                    OnYes: () => {
                      if (!data || !data[2]) {
                        setShow(ModalState.Show);
                      } else {
                        SetConfirmDialogState({
                          Title: "",
                          Msg: data[2],
                          OnYes: () => {
                            setShow(ModalState.Show);
                          },
                          OnCancel: () => {
                            onCloseModal();
                          },
                          Show: true,
                          Type: "Confirm",
                        });
                      }
                    },
                    Type: "Confirm",
                  });
                }
              },
              Show: true,
              Type: "Confirm",
            });
          } else {
            setShow(ModalState.Show);
          }
        });
      } else {
        // setTrackingState({
        //   ...tracking,
        //   openingLoadingState: {
        //     ...tracking.openingLoadingState,
        //     isOpen: false,
        //     label: ``,
        //   },
        // });

        setShow(ModalState.Show);
      }
    } else {
      setShow(ModalState.Show);
    }
  }, [ModalState.Show]);
  return (
    <Modal
      title={tr("filename")}
      footer={
        <>
          <Button
            style={{ minWidth: 100 }}
            onClick={() => {
              onCloseModal && onCloseModal();
              if (AppCache.SaveParam.afterSave) AppCache.SaveParam.afterSave();
              AppCache.SaveParam = { ...AppCache.SaveParam, afterSave: null };
            }}
            type="default"
          >
            {tr("cancel")}
          </Button>
          <Button
            style={{ minWidth: 100 }}
            onClick={() => {
              onSubmit();
            }}
            type="primary"
          >
            {tr("save")}
          </Button>
        </>
      }
      centered
      open={show}
      width={550}
      onCancel={() => {
        onCloseModal && onCloseModal();
        if (AppCache.SaveParam.afterSave) AppCache.SaveParam.afterSave();
        AppCache.SaveParam = { ...AppCache.SaveParam, afterSave: null };
      }}
      maskClosable={false}
    >
      <Row gutter={[8, 8]}>
        {/* <Col span={24}>
        {!formik.isValid && (
          <Alert
            description={showErrors(formik.errors)}
            type="warning"
            showIcon
          />
        )}
      </Col> */}
        <Col span={24}>
          <Input
            value={name as any}
            autoFocus={true}
            onChange={(e: any) => setName(e.target.value)}
            onKeyUp={(e) => {
              if (e.code.toLowerCase() == "enter") {
                onSubmit();
              }
            }}
          ></Input>
        </Col>
      </Row>
    </Modal>
  );
};

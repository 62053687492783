import { FormikErrors, useFormik } from "formik";
import UseConfirmDialogStore from "../../../ZustandStore/ConfirmDialogStore";
import useBookmarkStore from "../../../ZustandStore/BookmarkStore";
import { useLang } from "../../../i18n/useLang";
import * as yup from "yup";
import * as Api from "../../../Api/src/index";
import { v4 } from "uuid";
import { AppCache } from "../../../Cache/AppCache";
import { LeftPanelService } from "../LeftPanel/LeftPanelService";
export interface MDAddBookmarkProps {
  open?: boolean;
  EditItem?: Api.BookmarkObj | null;
  onClose?: () => void;
}
interface MDBookmarkParam extends Api.BookmarkObj {
  IsCurrentMousePosition: boolean;
}
const BookMarkApi = new Api.BookMarkApi();
export const MDAddBookmarkService = ({
  onClose,
  EditItem,
}: MDAddBookmarkProps) => {
  const { SetConfirmDialogState } = UseConfirmDialogStore();
  const { LoadBookmark } = LeftPanelService();
  const { BookmarkState, SetBookmarkStore } = useBookmarkStore();
  const { tr } = useLang();
  const validationTagNameSchema = yup.object({
    title: yup.string().required(tr("titleIsRequired")),
  });
  const showErrors = (error: FormikErrors<Api.BookmarkObj>) => {
    if (error.title) {
      return <p>{error.title}</p>;
    }
  };
  const formik = useFormik({
    initialValues: EditItem
      ? {
          ...EditItem,
          IsCurrentMousePosition: !(
            EditItem.position?.x === 0 && EditItem.position?.y === 0
          ),
        }
      : ({
          title: "",
          parentId: BookmarkState.BookmarkSelectedKeys?.[0] || null,
          IsCurrentMousePosition: true,
          position: {
            x: AppCache.LastMousePositionServer.x,
            y: AppCache.LastMousePositionServer.y,
          },
          id: v4(),
        } as MDBookmarkParam),
    validationSchema: validationTagNameSchema,
    onSubmit: async (values) => {
      if (!values.IsCurrentMousePosition) {
        values.position = {
          x: 0,
          y: 0,
          isEmpty: true,
        };
      }
      const page = AppCache.DocumentInfo?.tabDetails
        ?.flatMap((o) => o.pages)
        .find((o) => o?.viewerPageNo === AppCache.CurrentPageNo);
      if (EditItem) {
        BookMarkApi.apiLegalxtractUpdateBookMarkPut(
          values.id,
          values.title! 
        ).then((res) => {
          if (res && res.data.isSuccess) {
            if (AppCache.LeftTabActive === "pnBookmark") {
              LoadBookmark();
            }
            onClose && onClose();
          }
        });
      } else {
        BookMarkApi.apiLegalxtractAddPageToBookMarkPut(page?.id, values).then(
          (res) => {
            if (res && res.data.isSuccess) {
              AppCache.DocumentInfo?.tabDetails
                ?.flatMap((o) => o.pages)
                .forEach((p) => {
                  if (p && p?.id === res.data?.result?.id) {
                    p.bookMarkIds = res.data?.result?.bookMarkIds || [];
                  }
                });
              if (AppCache.LeftTabActive === "pnBookmark") {
                LoadBookmark();
              }
              onClose && onClose();
            }
          }
        );
      }
    },
  });
  return { formik, showErrors, BookmarkState };
};

import React, { useEffect, useRef, useState } from "react";
import { AppCache } from "../../../Cache/AppCache";
import { PageThumbnail } from "../../../Components/LeftPanels/Thumbnail/PageThumbnail";
import useThumbnailStore from "../../../ZustandStore/ThumbnailStore";
import { PdfTronService } from "../../NotHook/PdfTronService";
import usePageStore from "../../../ZustandStore/PageStore";
import { CheckVisibleElement } from "../../../Common/CommonFunction";
import { Item, Menu, useContextMenu } from "react-contexify";
import { useLang } from "../../../i18n/useLang";
import * as Api from "../../../Api/src";
import { PageService } from "../../NotHook/PageService";
import useTabFileInfoStore from "../../../ZustandStore/TabFileInfoStore";
import UseConfirmDialogStore from "../../../ZustandStore/ConfirmDialogStore";
export interface ThumbnailPage {
  pageNo: number;
  thumbnail: any | null;
}
export const MenuID = "ThumbnailMenu";
interface Position {
  bottom: number;
  height: number;
  left: number;
  right: number;
  top: number;
  width: number;
  x: number;
  y: number;
}
let timeOutGetThumbnail: any;
const ThumbnailApi = new Api.ThumbnailApi();
const RotateApi = new Api.RotateApi();
const PageApi = new Api.PageApi();
const JoinDocumentApi = new Api.JoinDocumentApi();
export const ThumbnailPanelService = () => {
  const { tr } = useLang();
  const { ThumbnailState, SetThumbnailStore } = useThumbnailStore();
  const { SetTabFileInfoState } = useTabFileInfoStore();
  const { SetDataCurrentPage } = PageService();
  const { RotatePages, DeletePages } = PdfTronService();
  const [selectedPages, setSelectedPages] = useState<number[]>([
    AppCache.CurrentPageNo,
  ]);
  const [ShowSplitDocument, SetShowSplitDocument] = useState<{
    open: boolean;
    pageNo: number;
    name: string;
  }>({ open: false, pageNo: 0, name: "" });
  const { SetCurrentPageNo } = PdfTronService();
  const { PageState, SetPageStore } = usePageStore();
  const thumbnail_container = useRef<HTMLDivElement>(null);
  const scrollPosition = useRef(0);
  useEffect(() => {
    const pageNos = GetPagesShow();
    if (pageNos && pageNos.indexOf(PageState.CurrentPageNo || 0) < 0) {
      const a = document.getElementById(`thumbnail-${PageState.CurrentPageNo}`);
      const container = document.getElementById(`thumbnailSelectionContainer`);
      container?.scrollTo(0, a?.offsetTop || 0);
    }
    if (PageState.CurrentPageNo && selectedPages.length === 1) {
      setSelectedPages([PageState.CurrentPageNo]);
    }
  }, [PageState.CurrentPageNo]);

  const getThumbnails = (
    fromPage: number,
    toPage: number,
    clickFromLeftTab?: boolean
  ) => {
    if (
      !AppCache.WebViewerInstance?.Core ||
      (AppCache.DocumentInfo?.tabDetails?.length || 0) === 0
    )
      return;
    SetThumbnailStore({ LoadThumbnail: false });
    const { documentViewer } = AppCache.WebViewerInstance?.Core;
    var pageCount = documentViewer.getPageCount();
    if (AppCache.ThumbnailPages.length != pageCount) {
      AppCache.ThumbnailPages = [];
      for (let i = 1; i <= pageCount; i++) {
        AppCache.ThumbnailPages.push({ pageNo: i, thumbnail: null });
      }
    }
    fromPage = fromPage < 1 ? 1 : fromPage;
    toPage = toPage > pageCount ? pageCount : toPage;
    const doc = documentViewer.getDocument();
    for (let i = fromPage; i <= toPage; i++) {
      doc.loadThumbnail(i, (thumbnail) => {
        AppCache.ThumbnailPages[i - 1].thumbnail = thumbnail;
        if (
          AppCache.ThumbnailPages.filter(
            (o) => o.pageNo >= fromPage && o.pageNo <= toPage
          ).every((o) => o.thumbnail)
        ) {
          SetThumbnailStore({ LoadThumbnail: true });
        }
      });
    }
    if (clickFromLeftTab) {
      setTimeout(() => {
        const a = document.getElementById(
          `thumbnail-${PageState.CurrentPageNo}`
        );
        if (a) {
          const container = document.getElementById(
            `thumbnailSelectionContainer`
          );
          container?.scrollTo(0, a?.offsetTop || 0);
        }
      }, 500);
    }
  };
  const addPageSelectShifKey = (shifKey: boolean, pageSelect: number) => {
    let pageOK: number[] = [];
    if (shifKey) {
      pageOK = selectedPages;
    }
    let minPage = Math.min(...pageOK);
    let maxPage = Math.max(...pageOK);
    if (pageSelect > maxPage) {
      minPage = maxPage;
      maxPage = pageSelect;
    }
    if (pageSelect < maxPage) {
      minPage = pageSelect;
    }
    const pageSelecteds = AppCache.ThumbnailPages.filter(
      (o) => (o.pageNo || 0) <= maxPage && (o.pageNo || 0) >= minPage
    );
    setSelectedPages(pageSelecteds.map((o) => o.pageNo || 0));
  };
  const addPageSelect = (ctrlKey: boolean, pageSelects: number[]) => {
    let pageOK: number[] = [];
    if (ctrlKey) {
      pageOK = selectedPages;
    }
    (pageSelects || []).forEach((pageNo) => {
      if (ctrlKey) {
        const page = pageOK.find((o) => o == pageNo);
        if (page) {
          pageOK = pageOK.filter((o) => o != pageNo);
        } else {
          pageOK.push(pageNo);
        }
      } else {
        pageOK.push(pageNo);
      }
    });
    setSelectedPages([...pageOK]);
  };
  const generateThumbnail = () => {
    const thumbnails: React.JSX.Element[] = [];
    AppCache.DocumentInfo?.tabDetails?.forEach((tab) => {
      var a = (
        <div key={tab.uniqueId}>
          <p style={{ textAlign: "center" }}>
            <strong>{tab.fileName}</strong>
          </p>
          {tab.pages?.map((page, index) => {
            return (
              <PageThumbnail
                onClick={(pageNo, e) => {
                  if (e.shiftKey) {
                    addPageSelectShifKey(e.shiftKey, pageNo);
                  } else {
                    addPageSelect(e.ctrlKey, [pageNo]);
                  }
                  SetCurrentPageNo(pageNo);
                }}
                onMouseDown={(pageNo, e) => {
                  //   if (e.button == 2) {
                  //     if (
                  //       thumbnailCurrentState.selectPages.indexOf(
                  //         pageNo
                  //       ) < 0
                  //     ) {
                  //       addPageSelect(e.ctrlKey, [pageNo]);
                  //       documentService.GotoPage(
                  //         DataCache.docuViewareID(),
                  //         pageNo || 1
                  //       );
                  //     }
                  //   }
                }}
                onContextMenu={(pageNo, e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  ShowMenuContext(e, pageNo);
                }}
                imagePage={
                  AppCache.ThumbnailPages[(page.viewerPageNo || 0) - 1]
                    ?.thumbnail || null
                }
                selectedPage={selectedPages}
                active={PageState.CurrentPageNo === page.viewerPageNo}
                pageNo={page.viewerPageNo}
                key={index}
              />
            );
          })}
        </div>
      );
      thumbnails.push(a);
    });
    return thumbnails;
  };
  const checkCollisionDetected = (rect1: Position, rect2: Position) => {
    if (
      rect1.x < rect2.right &&
      rect1.right > rect2.x &&
      rect1.y < rect2.bottom &&
      rect1.bottom > rect2.y
    ) {
      return true;
    }
    return false;
  };
  const getSelectedItem = (e: any, elementRect: HTMLDivElement | null) => {
    if (!elementRect) return;
    const pageSelects: number[] = [];
    const items = document.getElementsByClassName("item-thumbnail");
    const rect1 = elementRect.getBoundingClientRect();
    for (let index = 0; index < items.length; index++) {
      const element = items[index];
      if (
        element &&
        ((element.attributes as any)["itemtype"].nodeValue || "")
          .toString()
          .indexOf("NotSelect") < 0
      ) {
        const rect2 = element.getBoundingClientRect();
        const valueCheck = checkCollisionDetected(rect1, rect2);
        const pageIndex = parseInt(element.id.replace("thumbnail-", ""));
        if (valueCheck) {
          pageSelects.push(pageIndex);
        }
      }
    }
    addPageSelect(e.ctrlKey, pageSelects);
  };
  const GetPagesShow = (): number[] => {
    const pageNos: number[] = [];
    const container = document.getElementById(`thumbnailSelectionContainer`);
    AppCache.ThumbnailPages.forEach((page) => {
      const el = document.getElementById("thumbnail-" + page.pageNo);
      if (CheckVisibleElement(el, container || undefined)) {
        if (page.pageNo) {
          pageNos.push(page.pageNo);
        }
      }
    });
    return pageNos;
  };
  const RefreshThumnails = () => {
    if (timeOutGetThumbnail) clearTimeout(timeOutGetThumbnail);
    timeOutGetThumbnail = setTimeout(async () => {
      const el = document.getElementById("thumbnailSelectionContainer");
      if (el) {
        scrollPosition.current = el.scrollTop;
        const pageNos = GetPagesShow();
        if (pageNos.length == 0) return;
        const maxPage = Math.max(...pageNos) + 2;
        const minPage = Math.min(...pageNos) - 2;
        getThumbnails(minPage, maxPage);
      }
    }, 500);
  };
  const LoadThumnailScroll = () => {
    if (timeOutGetThumbnail) clearTimeout(timeOutGetThumbnail);
    timeOutGetThumbnail = setTimeout(async () => {
      const el = document.getElementById("thumbnailSelectionContainer");
      if (el && Math.abs(scrollPosition.current - el.scrollTop) > 300) {
        scrollPosition.current = el.scrollTop;
        const pageNos = GetPagesShow();
        if (pageNos.length == 0) return;
        const maxPage = Math.max(...pageNos) + 2;
        const minPage = Math.min(...pageNos) - 2;
        getThumbnails(minPage, maxPage);
      }
    }, 500);
  };
  const { show, hideAll } = useContextMenu({
    id: MenuID,
  });
  function ShowMenuContext(event: any, pageNo: number) {
    if (!selectedPages.includes(pageNo)) {
      setSelectedPages([pageNo]);
      SetCurrentPageNo(pageNo);
    }
    show({
      event,
    });
  }
  const DrawMenuItem = () => {
    const menu = {
      splitDocument: true,
      deletePage: true,
      JoinDocuments: true,
      splitOnPublish: true,
    };
    const pages = AppCache.DocumentInfo?.tabDetails?.flatMap((o) =>
      o.pages?.map((p) => {
        return { ...p, tabFileId: o.uniqueId };
      })
    );
    const selectPageObjs = pages?.filter(
      (o) => selectedPages.indexOf(o?.viewerPageNo || 0) >= 0
    );
    const itemSplitOnExtract = selectPageObjs?.filter(
      (o) => o?.splitOnExtract == false
    );
    menu.splitOnPublish = itemSplitOnExtract!?.length > 0 || false;
    menu.splitDocument = !(
      selectedPages.length > 1 ||
      AppCache.CurrentTab()?.startPage == selectedPages[0]
    );
    const hasBlankPage = selectPageObjs?.some((o) => o?.isBlankPage == true);
    let selectAllPageInTab = false;
    AppCache.DocumentInfo?.tabDetails?.forEach((tab) => {
      if (
        tab.pages?.every(
          (o) =>
            !!selectPageObjs?.find((p) => p?.viewerPageNo == o.viewerPageNo)
        )
      ) {
        selectAllPageInTab = true;
        return;
      }
    });
    if (
      hasBlankPage ||
      selectedPages.length === pages?.length ||
      selectAllPageInTab
    ) {
      menu.deletePage = false;
    }
    const tabSelecteds = selectPageObjs?.map((o) => o?.tabFileId || "") || [];
    const tabIds = Array.from(new Set(tabSelecteds));
    const tabIndexs =
      AppCache.DocumentInfo?.tabDetails
        ?.filter((o) => tabIds.indexOf(o.uniqueId || "") >= 0)
        .map((o) => o.tabIndex || 0) || [];
    if (!isConsecutiveArray(tabIndexs.sort())) {
      menu.JoinDocuments = false;
    }
    return (
      <Menu id={MenuID}>
        <Item
          onClick={() => {
            ShowHideThumnail(Api.HiddenEnum.Show);
          }}
        >
          {tr("show")}
        </Item>
        <Item
          onClick={() => {
            ShowHideThumnail(Api.HiddenEnum.Hide);
          }}
        >
          {tr("hide")}
        </Item>
        <Item
          onClick={() => {
            ShowHideThumnail(Api.HiddenEnum.HideOnExtract);
          }}
        >
          {tr("hideOnExtract")}
        </Item>
        <Item
          onClick={() => {
            RotateLeft();
          }}
        >
          {tr("rotateLeft")}
        </Item>
        <Item
          onClick={() => {
            RotateRight();
          }}
        >
          {tr("rotateRight")}
        </Item>
        {menu.splitDocument && (
          <Item
            onClick={() => {
              SplitDocument();
            }}
          >
            {tr("splitDocument")}
          </Item>
        )}
        {menu.JoinDocuments && (
          <Item
            onClick={() => {
              JoinDocuments();
            }}
          >
            {tr("joinDocuments")}
          </Item>
        )}
        {(menu.splitOnPublish && (
          <Item
            onClick={() => {
              SetSplitOnExtract(true);
            }}
          >
            {tr("splitOnPublish")}
          </Item>
        )) || (
          <Item
            onClick={() => {
              SetSplitOnExtract(false);
            }}
          >
            {tr("removeSplit")}
          </Item>
        )}
        <Item onClick={() => {}}>{tr("deskew")}</Item>
        <Item
          onClick={() => {
            setSelectedPages(
              AppCache.ThumbnailPages?.map((o) => o.pageNo || 0) || []
            );
          }}
        >
          {tr("selectAll")}
        </Item>
        {menu.deletePage && (
          <Item
            onClick={() => {
              DeltePages();
            }}
          >
            {tr("delete")}
          </Item>
        )}
      </Menu>
    );
  };
  const isConsecutiveArray = (arr: number[]): boolean => {
    return (
      arr.length > 1 &&
      arr.every((value, index) => index === 0 || value === arr[index - 1] + 1)
    );
  };
  const ShowHideThumnail = async (status: Api.HiddenEnum) => {
    const res = await ThumbnailApi.apiLegalxtractShowHidePagesPost({
      pageNos: selectedPages,
      status: status,
    });
    if (res && res.data.isSuccess) {
      AppCache.DocumentInfo = res.data.result || null;
      const pageNos = GetPagesShow();
      if (pageNos.length == 0) return;
      const maxPage = Math.max(...pageNos) + 2;
      const minPage = Math.min(...pageNos) - 2;
      getThumbnails(minPage, maxPage);
    }
  };
  const SetSplitOnExtract = async (status: boolean) => {
    const res = await ThumbnailApi.apiLegalxtractSetSplitOnExtractPost({
      pages: selectedPages,
      splitOnExtract: status,
    });
    if (res && res.data.isSuccess) {
      AppCache.DocumentInfo = res.data.result || null;
      const pageNos = GetPagesShow();
      if (pageNos.length == 0) return;
      const maxPage = Math.max(...pageNos) + 2;
      const minPage = Math.min(...pageNos) - 2;
      getThumbnails(minPage, maxPage);
    }
  };
  const RotateLeft = async () => {
    const res = await RotateApi.apiLegalxtractRotateLeftPost({
      viewerPageNos: selectedPages,
    });
    if (res && res.data.isSuccess) {
      ProcessAfterRotate("left", res.data.result);
    }
  };
  const RotateRight = async () => {
    const res = await RotateApi.apiLegalxtractRotateRightPost({
      viewerPageNos: selectedPages,
    });
    if (res && res.data.isSuccess) {
      ProcessAfterRotate("right", res.data.result);
    }
  };
  const ProcessAfterRotate = (
    rotate: "left" | "right",
    data: Api.DocumentInfoPageDataResponse | undefined
  ) => {
    AppCache.DocumentInfo = data?.data || null;
    if (rotate == "left") {
      RotatePages(selectedPages, 270);
    } else {
      RotatePages(selectedPages, 90);
    }
    SetDataCurrentPage(data?.pageData!, AppCache.CurrentPageNo || 0);
    setTimeout(() => {
      if (!AppCache.WebViewerInstance?.Core) return;
      const { documentViewer } = AppCache.WebViewerInstance?.Core;
      selectedPages.forEach((pageNo) => {
        documentViewer.refreshPage(pageNo);
      });
      const pageNos = GetPagesShow();
      if (pageNos.length == 0) return;
      const maxPage = Math.max(...pageNos) + 2;
      const minPage = Math.min(...pageNos) - 2;
      getThumbnails(minPage, maxPage);
    }, 500);
  };
  const SplitDocument = async () => {
    const tab = AppCache.DocumentInfo?.tabDetails?.find((o) =>
      o.pages?.some((p) => p.viewerPageNo == selectedPages[0])
    );
    SetShowSplitDocument({
      open: true,
      pageNo: selectedPages[0],
      name: tab?.fileName + " (2)",
    });
  };
  const JoinDocuments = async () => {
    const pages = AppCache.DocumentInfo?.tabDetails?.flatMap((o) =>
      o.pages?.map((p) => {
        return { ...p, tabFileId: o.uniqueId };
      })
    );
    const selectPageObjs = pages?.filter(
      (o) => selectedPages.indexOf(o?.viewerPageNo || 0) >= 0
    );
    const tabSelecteds = selectPageObjs?.map((o) => o?.tabFileId || "") || [];
    const tabIds = Array.from(new Set(tabSelecteds));
    var res = await JoinDocumentApi.apiLegalxtractJoinDocumentsPost({
      tabIds: tabIds,
    });
    AppCache.DocumentInfo = res.data.result || null;
    let currentTab = AppCache.CurrentTab();
    if (selectedPages.includes(AppCache.CurrentPageNo)) {
      currentTab =
        AppCache.DocumentInfo?.tabDetails?.find((o) =>
          o.pages?.some((p) => p.viewerPageNo == AppCache.CurrentPageNo)
        ) || null;
    }
    SetTabFileInfoState({
      TabDetails: res.data.result?.tabDetails || [],
      CurrentTab: currentTab,
    });
    hideAll();
  };
  const { SetConfirmDialogState } = UseConfirmDialogStore();
  const DeltePages = async () => {
    const Delete = async () => {
      const res = await PageApi.apiLegalxtractDeletePagesPost(selectedPages);
      if (res && res.data.isSuccess) {
        AppCache.DocumentInfo = res.data.result?.data || null;
        SetPageStore({ TotalPages: res.data.result?.data?.totalPage || 0 });
        DeletePages(selectedPages);
        setTimeout(() => {
          const pageNos = GetPagesShow();
          if (pageNos.length == 0) return;
          const maxPage = Math.max(...pageNos) + 2;
          const minPage = Math.min(...pageNos) - 2;
          getThumbnails(minPage, maxPage);
        }, 500);
      }
    };
    SetConfirmDialogState({
      Show: true,
      Type: "Confirm",
      Icon: "Question",
      CancelText: tr("no"),
      YesText: tr("yes"),
      Msg: tr("doYouWantToDelete"),
      OnYes: () => {
        Delete();
      },
    });
  };

  return {
    getThumbnails,
    ThumbnailState,
    generateThumbnail,
    getSelectedItem,
    LoadThumnailScroll,
    thumbnail_container,
    ShowMenuContext,
    DrawMenuItem,
    SetSplitOnExtract,
    ShowHideThumnail,
    ShowSplitDocument,
    SetShowSplitDocument,
    RefreshThumnails,
  };
};

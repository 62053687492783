/* tslint:disable */
/* eslint-disable */
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AccessToken
 */
export interface AccessToken {
    /**
     * 
     * @type {string}
     * @memberof AccessToken
     */
    'access_token'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessToken
     */
    'refresh_token'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessToken
     */
    'token_type'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AccessToken
     */
    'expires_in'?: number;
    /**
     * 
     * @type {UserInfo}
     * @memberof AccessToken
     */
    'user_info'?: UserInfo;
}
/**
 * 
 * @export
 * @interface AccessTokenOperationResult
 */
export interface AccessTokenOperationResult {
    /**
     * 
     * @type {AccessToken}
     * @memberof AccessTokenOperationResult
     */
    'result'?: AccessToken;
    /**
     * 
     * @type {boolean}
     * @memberof AccessTokenOperationResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccessTokenOperationResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AccessTokenOperationResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccessTokenOperationResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface AddBoxParam
 */
export interface AddBoxParam {
    /**
     * 
     * @type {string}
     * @memberof AddBoxParam
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof AddBoxParam
     */
    'left'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddBoxParam
     */
    'top'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddBoxParam
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddBoxParam
     */
    'height'?: number;
}
/**
 * 
 * @export
 * @interface AddBoxParamBoxResponse
 */
export interface AddBoxParamBoxResponse {
    /**
     * 
     * @type {AddBoxParam}
     * @memberof AddBoxParamBoxResponse
     */
    'dataUndo'?: AddBoxParam;
    /**
     * 
     * @type {LxPageData}
     * @memberof AddBoxParamBoxResponse
     */
    'pageData'?: LxPageData;
}
/**
 * 
 * @export
 * @interface AddBoxParamBoxResponseApiResult
 */
export interface AddBoxParamBoxResponseApiResult {
    /**
     * 
     * @type {AddBoxParamBoxResponse}
     * @memberof AddBoxParamBoxResponseApiResult
     */
    'result'?: AddBoxParamBoxResponse;
    /**
     * 
     * @type {boolean}
     * @memberof AddBoxParamBoxResponseApiResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AddBoxParamBoxResponseApiResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AddBoxParamBoxResponseApiResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddBoxParamBoxResponseApiResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface AddLineParam
 */
export interface AddLineParam {
    /**
     * 
     * @type {string}
     * @memberof AddLineParam
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof AddLineParam
     */
    'left'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddLineParam
     */
    'top'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddLineParam
     */
    'borderWidth'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddLineParam
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddLineParam
     */
    'height'?: number;
    /**
     * 
     * @type {string}
     * @memberof AddLineParam
     */
    'color'?: string;
}
/**
 * 
 * @export
 * @interface AddLineParamPageDataResponse
 */
export interface AddLineParamPageDataResponse {
    /**
     * 
     * @type {AddLineParam}
     * @memberof AddLineParamPageDataResponse
     */
    'data'?: AddLineParam;
    /**
     * 
     * @type {LxPageData}
     * @memberof AddLineParamPageDataResponse
     */
    'pageData'?: LxPageData;
}
/**
 * 
 * @export
 * @interface AddLineParamPageDataResponseApiResult
 */
export interface AddLineParamPageDataResponseApiResult {
    /**
     * 
     * @type {AddLineParamPageDataResponse}
     * @memberof AddLineParamPageDataResponseApiResult
     */
    'result'?: AddLineParamPageDataResponse;
    /**
     * 
     * @type {boolean}
     * @memberof AddLineParamPageDataResponseApiResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AddLineParamPageDataResponseApiResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AddLineParamPageDataResponseApiResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddLineParamPageDataResponseApiResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface AddReplaceAllResponse
 */
export interface AddReplaceAllResponse {
    /**
     * 
     * @type {Array<ReplaceObj>}
     * @memberof AddReplaceAllResponse
     */
    'replaceObjs'?: Array<ReplaceObj> | null;
    /**
     * 
     * @type {{ [key: string]: Array<MarkedInfoWithId> | null; }}
     * @memberof AddReplaceAllResponse
     */
    'dicPreState'?: { [key: string]: Array<MarkedInfoWithId> | null; } | null;
}
/**
 * 
 * @export
 * @interface AddReplaceAllResponsePageDataResponse
 */
export interface AddReplaceAllResponsePageDataResponse {
    /**
     * 
     * @type {AddReplaceAllResponse}
     * @memberof AddReplaceAllResponsePageDataResponse
     */
    'data'?: AddReplaceAllResponse;
    /**
     * 
     * @type {LxPageData}
     * @memberof AddReplaceAllResponsePageDataResponse
     */
    'pageData'?: LxPageData;
}
/**
 * 
 * @export
 * @interface AddReplaceBoxParam
 */
export interface AddReplaceBoxParam {
    /**
     * 
     * @type {ReplaceParam}
     * @memberof AddReplaceBoxParam
     */
    'box'?: ReplaceParam;
    /**
     * 
     * @type {string}
     * @memberof AddReplaceBoxParam
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof AddReplaceBoxParam
     */
    'viewerPageNo'?: number;
}
/**
 * 
 * @export
 * @interface AddTagCategoryParam
 */
export interface AddTagCategoryParam {
    /**
     * 
     * @type {string}
     * @memberof AddTagCategoryParam
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddTagCategoryParam
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddTagCategoryParam
     */
    'color'?: string;
}
/**
 * 
 * @export
 * @interface AddTagNameParam
 */
export interface AddTagNameParam {
    /**
     * 
     * @type {string}
     * @memberof AddTagNameParam
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddTagNameParam
     */
    'tagName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddTagNameParam
     */
    'tagCategoryId'?: string;
}
/**
 * 
 * @export
 * @interface BlankPageInfo
 */
export interface BlankPageInfo {
    /**
     * 
     * @type {number}
     * @memberof BlankPageInfo
     */
    'pageNo'?: number;
    /**
     * 
     * @type {number}
     * @memberof BlankPageInfo
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof BlankPageInfo
     */
    'height'?: number;
}
/**
 * 
 * @export
 * @interface BlankPageProcessResponse
 */
export interface BlankPageProcessResponse {
    /**
     * 
     * @type {DocumentInfo}
     * @memberof BlankPageProcessResponse
     */
    'documentInfo'?: DocumentInfo;
    /**
     * 
     * @type {Array<number>}
     * @memberof BlankPageProcessResponse
     */
    'pageDeletes'?: Array<number> | null;
    /**
     * 
     * @type {Array<BlankPageInfo>}
     * @memberof BlankPageProcessResponse
     */
    'pageAdds'?: Array<BlankPageInfo> | null;
}
/**
 * 
 * @export
 * @interface BlankPageProcessResponseApiResult
 */
export interface BlankPageProcessResponseApiResult {
    /**
     * 
     * @type {BlankPageProcessResponse}
     * @memberof BlankPageProcessResponseApiResult
     */
    'result'?: BlankPageProcessResponse;
    /**
     * 
     * @type {boolean}
     * @memberof BlankPageProcessResponseApiResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BlankPageProcessResponseApiResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BlankPageProcessResponseApiResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BlankPageProcessResponseApiResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface BookmarkObj
 */
export interface BookmarkObj {
    /**
     * 
     * @type {string}
     * @memberof BookmarkObj
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookmarkObj
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {PointF}
     * @memberof BookmarkObj
     */
    'position'?: PointF;
    /**
     * 
     * @type {string}
     * @memberof BookmarkObj
     */
    'title'?: string | null;
}
/**
 * 
 * @export
 * @interface BookmarkObjListApiResult
 */
export interface BookmarkObjListApiResult {
    /**
     * 
     * @type {Array<BookmarkObj>}
     * @memberof BookmarkObjListApiResult
     */
    'result'?: Array<BookmarkObj> | null;
    /**
     * 
     * @type {boolean}
     * @memberof BookmarkObjListApiResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BookmarkObjListApiResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BookmarkObjListApiResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BookmarkObjListApiResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface BooleanApiResult
 */
export interface BooleanApiResult {
    /**
     * 
     * @type {boolean}
     * @memberof BooleanApiResult
     */
    'result'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BooleanApiResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BooleanApiResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BooleanApiResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BooleanApiResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface BooleanBoxResponse
 */
export interface BooleanBoxResponse {
    /**
     * 
     * @type {boolean}
     * @memberof BooleanBoxResponse
     */
    'dataUndo'?: boolean;
    /**
     * 
     * @type {LxPageData}
     * @memberof BooleanBoxResponse
     */
    'pageData'?: LxPageData;
}
/**
 * 
 * @export
 * @interface BooleanBoxResponseApiResult
 */
export interface BooleanBoxResponseApiResult {
    /**
     * 
     * @type {BooleanBoxResponse}
     * @memberof BooleanBoxResponseApiResult
     */
    'result'?: BooleanBoxResponse;
    /**
     * 
     * @type {boolean}
     * @memberof BooleanBoxResponseApiResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BooleanBoxResponseApiResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BooleanBoxResponseApiResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BooleanBoxResponseApiResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface BooleanOperationResult
 */
export interface BooleanOperationResult {
    /**
     * 
     * @type {boolean}
     * @memberof BooleanOperationResult
     */
    'result'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BooleanOperationResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BooleanOperationResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BooleanOperationResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BooleanOperationResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface BooleanPageDataResponse
 */
export interface BooleanPageDataResponse {
    /**
     * 
     * @type {boolean}
     * @memberof BooleanPageDataResponse
     */
    'data'?: boolean;
    /**
     * 
     * @type {LxPageData}
     * @memberof BooleanPageDataResponse
     */
    'pageData'?: LxPageData;
}
/**
 * 
 * @export
 * @interface BooleanPageDataResponseApiResult
 */
export interface BooleanPageDataResponseApiResult {
    /**
     * 
     * @type {BooleanPageDataResponse}
     * @memberof BooleanPageDataResponseApiResult
     */
    'result'?: BooleanPageDataResponse;
    /**
     * 
     * @type {boolean}
     * @memberof BooleanPageDataResponseApiResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BooleanPageDataResponseApiResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BooleanPageDataResponseApiResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BooleanPageDataResponseApiResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface BoxObj
 */
export interface BoxObj {
    /**
     * 
     * @type {string}
     * @memberof BoxObj
     */
    'id'?: string;
    /**
     * 
     * @type {RectangleF}
     * @memberof BoxObj
     */
    'rect'?: RectangleF;
    /**
     * 
     * @type {string}
     * @memberof BoxObj
     */
    'author'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BoxObj
     */
    'creationTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoxObj
     */
    'replaceObjId'?: string | null;
}
/**
 * 
 * @export
 * @interface BoxObjBoxResponse
 */
export interface BoxObjBoxResponse {
    /**
     * 
     * @type {BoxObj}
     * @memberof BoxObjBoxResponse
     */
    'dataUndo'?: BoxObj;
    /**
     * 
     * @type {LxPageData}
     * @memberof BoxObjBoxResponse
     */
    'pageData'?: LxPageData;
}
/**
 * 
 * @export
 * @interface BoxObjBoxResponseApiResult
 */
export interface BoxObjBoxResponseApiResult {
    /**
     * 
     * @type {BoxObjBoxResponse}
     * @memberof BoxObjBoxResponseApiResult
     */
    'result'?: BoxObjBoxResponse;
    /**
     * 
     * @type {boolean}
     * @memberof BoxObjBoxResponseApiResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BoxObjBoxResponseApiResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BoxObjBoxResponseApiResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BoxObjBoxResponseApiResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface BoxObjPageDataResponse
 */
export interface BoxObjPageDataResponse {
    /**
     * 
     * @type {BoxObj}
     * @memberof BoxObjPageDataResponse
     */
    'data'?: BoxObj;
    /**
     * 
     * @type {LxPageData}
     * @memberof BoxObjPageDataResponse
     */
    'pageData'?: LxPageData;
}
/**
 * 
 * @export
 * @interface BoxObjPageDataResponseApiResult
 */
export interface BoxObjPageDataResponseApiResult {
    /**
     * 
     * @type {BoxObjPageDataResponse}
     * @memberof BoxObjPageDataResponseApiResult
     */
    'result'?: BoxObjPageDataResponse;
    /**
     * 
     * @type {boolean}
     * @memberof BoxObjPageDataResponseApiResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BoxObjPageDataResponseApiResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BoxObjPageDataResponseApiResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BoxObjPageDataResponseApiResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface CalculateWatchDogParam
 */
export interface CalculateWatchDogParam {
    /**
     * 
     * @type {WatchDogParamWeb}
     * @memberof CalculateWatchDogParam
     */
    'param'?: WatchDogParamWeb;
    /**
     * 
     * @type {SearchTemplateParam}
     * @memberof CalculateWatchDogParam
     */
    'searchTemplateParam'?: SearchTemplateParam;
}
/**
 * 
 * @export
 * @interface ChangePassWordParam
 */
export interface ChangePassWordParam {
    /**
     * 
     * @type {string}
     * @memberof ChangePassWordParam
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangePassWordParam
     */
    'passWord'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangePassWordParam
     */
    'currentPassword'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangePassWordParam
     */
    'token'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CharType = {
    Digit: 0,
    Letter: 1,
    MixLetterAndDigit: 2,
    Special: 3,
    Space: 4
} as const;

export type CharType = typeof CharType[keyof typeof CharType];


/**
 * 
 * @export
 * @interface CheckDuplicateSettings
 */
export interface CheckDuplicateSettings {
    /**
     * 
     * @type {boolean}
     * @memberof CheckDuplicateSettings
     */
    'isCurrentDocument'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CheckDuplicateSettings
     */
    'includeHideOnExtract'?: boolean;
}
/**
 * 
 * @export
 * @interface CheckUserStatusParam
 */
export interface CheckUserStatusParam {
    /**
     * 
     * @type {string}
     * @memberof CheckUserStatusParam
     */
    'token'?: string | null;
}
/**
 * 
 * @export
 * @interface CommentObjDisplay
 */
export interface CommentObjDisplay {
    /**
     * 
     * @type {string}
     * @memberof CommentObjDisplay
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentObjDisplay
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {LxRange}
     * @memberof CommentObjDisplay
     */
    'range'?: LxRange;
    /**
     * 
     * @type {string}
     * @memberof CommentObjDisplay
     */
    'boxId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommentObjDisplay
     */
    'author'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommentObjDisplay
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentObjDisplay
     */
    'comment'?: string | null;
    /**
     * 
     * @type {CommentStatusEnum}
     * @memberof CommentObjDisplay
     */
    'status'?: CommentStatusEnum;
    /**
     * 
     * @type {CommentTypeEnum}
     * @memberof CommentObjDisplay
     */
    'commentTypeEnum'?: CommentTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CommentObjDisplay
     */
    'isSelected'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CommentObjDisplay
     */
    'isCollapse'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CommentObjDisplay
     */
    'isHidden'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CommentObjDisplay
     */
    'color'?: string;
    /**
     * 
     * @type {number}
     * @memberof CommentObjDisplay
     */
    'pageNo'?: number;
    /**
     * 
     * @type {string}
     * @memberof CommentObjDisplay
     */
    'pageNoStr'?: string | null;
}


/**
 * 
 * @export
 * @interface CommentObjDisplayWeb
 */
export interface CommentObjDisplayWeb {
    /**
     * 
     * @type {CommentObjDisplay}
     * @memberof CommentObjDisplayWeb
     */
    'commentObjDisplay'?: CommentObjDisplay;
    /**
     * 
     * @type {Array<RectangleF>}
     * @memberof CommentObjDisplayWeb
     */
    'rectangleFs'?: Array<RectangleF> | null;
}
/**
 * 
 * @export
 * @interface CommentObjDisplayWebApiResult
 */
export interface CommentObjDisplayWebApiResult {
    /**
     * 
     * @type {CommentObjDisplayWeb}
     * @memberof CommentObjDisplayWebApiResult
     */
    'result'?: CommentObjDisplayWeb;
    /**
     * 
     * @type {boolean}
     * @memberof CommentObjDisplayWebApiResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CommentObjDisplayWebApiResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommentObjDisplayWebApiResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CommentObjDisplayWebApiResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface CommentObjDisplayWebListApiResult
 */
export interface CommentObjDisplayWebListApiResult {
    /**
     * 
     * @type {Array<CommentObjDisplayWeb>}
     * @memberof CommentObjDisplayWebListApiResult
     */
    'result'?: Array<CommentObjDisplayWeb> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommentObjDisplayWebListApiResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CommentObjDisplayWebListApiResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommentObjDisplayWebListApiResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CommentObjDisplayWebListApiResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CommentOrderByEnum = {
    Page: 0,
    Author: 1,
    Date: 2
} as const;

export type CommentOrderByEnum = typeof CommentOrderByEnum[keyof typeof CommentOrderByEnum];


/**
 * 
 * @export
 * @interface CommentParam
 */
export interface CommentParam {
    /**
     * 
     * @type {string}
     * @memberof CommentParam
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommentParam
     */
    'author'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommentParam
     */
    'comment'?: string | null;
    /**
     * 
     * @type {CommentStatusEnum}
     * @memberof CommentParam
     */
    'status'?: CommentStatusEnum;
    /**
     * 
     * @type {CommentTypeEnum}
     * @memberof CommentParam
     */
    'type'?: CommentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CommentParam
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommentParam
     */
    'boxId'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CommentStatusEnum = {
    None: 0,
    Approved: 1,
    Rejected: 2
} as const;

export type CommentStatusEnum = typeof CommentStatusEnum[keyof typeof CommentStatusEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const CommentTypeEnum = {
    Box: 0,
    Text: 1
} as const;

export type CommentTypeEnum = typeof CommentTypeEnum[keyof typeof CommentTypeEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const Country = {
    Danish: 1,
    Belgium: 2,
    Germany: 4,
    Netherlands: 8,
    International: 32768
} as const;

export type Country = typeof Country[keyof typeof Country];


/**
 * 
 * @export
 * @interface CreateBlankFileParam
 */
export interface CreateBlankFileParam {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateBlankFileParam
     */
    'selectedTabIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CreateBlankFileParam
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface CustomTemplateItem
 */
export interface CustomTemplateItem {
    /**
     * 
     * @type {boolean}
     * @memberof CustomTemplateItem
     */
    'selected'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomTemplateItem
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomTemplateItem
     */
    'value'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomTemplateItem
     */
    'isRegex'?: boolean;
}
/**
 * 
 * @export
 * @interface DeleteReplaceParam
 */
export interface DeleteReplaceParam {
    /**
     * 
     * @type {string}
     * @memberof DeleteReplaceParam
     */
    'replaceId'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeleteReplaceParam
     */
    'pageNo'?: number;
}
/**
 * 
 * @export
 * @interface DocumentInfo
 */
export interface DocumentInfo {
    /**
     * 
     * @type {number}
     * @memberof DocumentInfo
     */
    'totalPage'?: number;
    /**
     * 
     * @type {Array<TabDetail>}
     * @memberof DocumentInfo
     */
    'tabDetails'?: Array<TabDetail> | null;
    /**
     * 
     * @type {Array<BlankPageInfo>}
     * @memberof DocumentInfo
     */
    'blankPages'?: Array<BlankPageInfo> | null;
    /**
     * 
     * @type {TabStampWeb}
     * @memberof DocumentInfo
     */
    'tabStampWeb'?: TabStampWeb;
    /**
     * 
     * @type {PageNumberingInfo}
     * @memberof DocumentInfo
     */
    'pageNumberingInfo'?: PageNumberingInfo;
    /**
     * 
     * @type {TocData}
     * @memberof DocumentInfo
     */
    'tocData'?: TocData;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentInfo
     */
    'isUploadFrontPage'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentInfo
     */
    'isCreateFrontPage'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentInfo
     */
    'isIncludedPageNumber'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentInfo
     */
    'showStamp'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentInfo
     */
    'isBlankPage'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentInfo
     */
    'isTOC'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentInfo
     */
    'documentHasChanged'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentInfo
     */
    'fixedSplitInPages'?: string | null;
}
/**
 * 
 * @export
 * @interface DocumentInfoApiResult
 */
export interface DocumentInfoApiResult {
    /**
     * 
     * @type {DocumentInfo}
     * @memberof DocumentInfoApiResult
     */
    'result'?: DocumentInfo;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentInfoApiResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentInfoApiResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentInfoApiResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentInfoApiResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface DocumentInfoPageDataResponse
 */
export interface DocumentInfoPageDataResponse {
    /**
     * 
     * @type {DocumentInfo}
     * @memberof DocumentInfoPageDataResponse
     */
    'data'?: DocumentInfo;
    /**
     * 
     * @type {LxPageData}
     * @memberof DocumentInfoPageDataResponse
     */
    'pageData'?: LxPageData;
}
/**
 * 
 * @export
 * @interface DocumentInfoPageDataResponseApiResult
 */
export interface DocumentInfoPageDataResponseApiResult {
    /**
     * 
     * @type {DocumentInfoPageDataResponse}
     * @memberof DocumentInfoPageDataResponseApiResult
     */
    'result'?: DocumentInfoPageDataResponse;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentInfoPageDataResponseApiResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentInfoPageDataResponseApiResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentInfoPageDataResponseApiResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentInfoPageDataResponseApiResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface EditReplaceBoxParam
 */
export interface EditReplaceBoxParam {
    /**
     * 
     * @type {ReplaceParam}
     * @memberof EditReplaceBoxParam
     */
    'replaceParam'?: ReplaceParam;
    /**
     * 
     * @type {string}
     * @memberof EditReplaceBoxParam
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof EditReplaceBoxParam
     */
    'viewerPageNo'?: number;
}
/**
 * 
 * @export
 * @interface EditReplaceBoxParamPageDataResponse
 */
export interface EditReplaceBoxParamPageDataResponse {
    /**
     * 
     * @type {EditReplaceBoxParam}
     * @memberof EditReplaceBoxParamPageDataResponse
     */
    'data'?: EditReplaceBoxParam;
    /**
     * 
     * @type {LxPageData}
     * @memberof EditReplaceBoxParamPageDataResponse
     */
    'pageData'?: LxPageData;
}
/**
 * 
 * @export
 * @interface EditReplaceBoxParamPageDataResponseApiResult
 */
export interface EditReplaceBoxParamPageDataResponseApiResult {
    /**
     * 
     * @type {EditReplaceBoxParamPageDataResponse}
     * @memberof EditReplaceBoxParamPageDataResponseApiResult
     */
    'result'?: EditReplaceBoxParamPageDataResponse;
    /**
     * 
     * @type {boolean}
     * @memberof EditReplaceBoxParamPageDataResponseApiResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EditReplaceBoxParamPageDataResponseApiResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EditReplaceBoxParamPageDataResponseApiResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditReplaceBoxParamPageDataResponseApiResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface EditTocHeaderParam
 */
export interface EditTocHeaderParam {
    /**
     * 
     * @type {string}
     * @memberof EditTocHeaderParam
     */
    'text'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EditTocHeaderParam
     */
    'editTitle'?: boolean;
    /**
     * 
     * @type {TocColumEnum}
     * @memberof EditTocHeaderParam
     */
    'tocColumEnumType'?: TocColumEnum;
}


/**
 * 
 * @export
 * @interface FrontPageContentInfo
 */
export interface FrontPageContentInfo {
    /**
     * 
     * @type {string}
     * @memberof FrontPageContentInfo
     */
    'content'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FrontPageContentInfo
     */
    'fontSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof FrontPageContentInfo
     */
    'fontName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FrontPageContentInfo
     */
    'logoBase64'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FrontPageContentInfo
     */
    'logo'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FrontPageContentInfo
     */
    'logoHeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof FrontPageContentInfo
     */
    'logoWidth'?: number;
    /**
     * 
     * @type {number}
     * @memberof FrontPageContentInfo
     */
    'visibility'?: number;
}
/**
 * 
 * @export
 * @interface GetSelectedWordContinuousParam
 */
export interface GetSelectedWordContinuousParam {
    /**
     * 
     * @type {number}
     * @memberof GetSelectedWordContinuousParam
     */
    'viewerPageNo'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetSelectedWordContinuousParam
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetSelectedWordContinuousParam
     */
    'y'?: number;
}
/**
 * 
 * @export
 * @interface GetSelectedWordMaskParam
 */
export interface GetSelectedWordMaskParam {
    /**
     * 
     * @type {number}
     * @memberof GetSelectedWordMaskParam
     */
    'pageNo'?: number;
    /**
     * 
     * @type {Array<RectangleSelection>}
     * @memberof GetSelectedWordMaskParam
     */
    'rectangleSelections'?: Array<RectangleSelection> | null;
}
/**
 * 
 * @export
 * @interface GuidMarkedInfoWithIdListDictionaryPageDataResponse
 */
export interface GuidMarkedInfoWithIdListDictionaryPageDataResponse {
    /**
     * 
     * @type {{ [key: string]: Array<MarkedInfoWithId>; }}
     * @memberof GuidMarkedInfoWithIdListDictionaryPageDataResponse
     */
    'data'?: { [key: string]: Array<MarkedInfoWithId>; } | null;
    /**
     * 
     * @type {LxPageData}
     * @memberof GuidMarkedInfoWithIdListDictionaryPageDataResponse
     */
    'pageData'?: LxPageData;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const HiddenEnum = {
    Show: 0,
    Hide: 1,
    HideOnExtract: 2
} as const;

export type HiddenEnum = typeof HiddenEnum[keyof typeof HiddenEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const HiddenWithDeleteEnum = {
    Show: 0,
    Hide: 1,
    HideOnExtract: 2,
    Delete: 3
} as const;

export type HiddenWithDeleteEnum = typeof HiddenWithDeleteEnum[keyof typeof HiddenWithDeleteEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const HighLightMode = {
    UnHightlight: 0,
    Hightlight: 1,
    None: -1
} as const;

export type HighLightMode = typeof HighLightMode[keyof typeof HighLightMode];


/**
 * 
 * @export
 * @interface HighLightOrUnHighLight
 */
export interface HighLightOrUnHighLight {
    /**
     * 
     * @type {Array<RectangleSelection>}
     * @memberof HighLightOrUnHighLight
     */
    'rectangleSelection'?: Array<RectangleSelection> | null;
    /**
     * 
     * @type {HighLightMode}
     * @memberof HighLightOrUnHighLight
     */
    'mode'?: HighLightMode;
    /**
     * 
     * @type {number}
     * @memberof HighLightOrUnHighLight
     */
    'viewerPageNo'?: number;
}


/**
 * 
 * @export
 * @interface HighLightOrUnHighLightClickWordsParam
 */
export interface HighLightOrUnHighLightClickWordsParam {
    /**
     * 
     * @type {number}
     * @memberof HighLightOrUnHighLightClickWordsParam
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof HighLightOrUnHighLightClickWordsParam
     */
    'y'?: number;
    /**
     * 
     * @type {number}
     * @memberof HighLightOrUnHighLightClickWordsParam
     */
    'viewerPageNo'?: number;
}
/**
 * 
 * @export
 * @interface HighLightResult
 */
export interface HighLightResult {
    /**
     * 
     * @type {{ [key: string]: Array<MarkedInfoWithId> | null; }}
     * @memberof HighLightResult
     */
    'dicPreState'?: { [key: string]: Array<MarkedInfoWithId> | null; } | null;
    /**
     * 
     * @type {LxPageData}
     * @memberof HighLightResult
     */
    'lxPageData'?: LxPageData;
}
/**
 * 
 * @export
 * @interface HighLightSearchResultsResponse
 */
export interface HighLightSearchResultsResponse {
    /**
     * 
     * @type {{ [key: string]: Array<MarkedInfoWithId> | null; }}
     * @memberof HighLightSearchResultsResponse
     */
    'dicPreState'?: { [key: string]: Array<MarkedInfoWithId> | null; } | null;
    /**
     * 
     * @type {SearchResultData}
     * @memberof HighLightSearchResultsResponse
     */
    'searchResultData'?: SearchResultData;
    /**
     * 
     * @type {LxPageData}
     * @memberof HighLightSearchResultsResponse
     */
    'lxPageData'?: LxPageData;
}
/**
 * 
 * @export
 * @interface HighLightSearchResultsResponseApiResult
 */
export interface HighLightSearchResultsResponseApiResult {
    /**
     * 
     * @type {HighLightSearchResultsResponse}
     * @memberof HighLightSearchResultsResponseApiResult
     */
    'result'?: HighLightSearchResultsResponse;
    /**
     * 
     * @type {boolean}
     * @memberof HighLightSearchResultsResponseApiResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof HighLightSearchResultsResponseApiResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof HighLightSearchResultsResponseApiResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HighLightSearchResultsResponseApiResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface HighLightUnHighLightLine
 */
export interface HighLightUnHighLightLine {
    /**
     * 
     * @type {number}
     * @memberof HighLightUnHighLightLine
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof HighLightUnHighLightLine
     */
    'y'?: number;
    /**
     * 
     * @type {number}
     * @memberof HighLightUnHighLightLine
     */
    'pageNo'?: number;
    /**
     * 
     * @type {HighLightMode}
     * @memberof HighLightUnHighLightLine
     */
    'mode'?: HighLightMode;
}


/**
 * 
 * @export
 * @interface HighLightUnHighLightPage
 */
export interface HighLightUnHighLightPage {
    /**
     * 
     * @type {number}
     * @memberof HighLightUnHighLightPage
     */
    'viewerPageNo'?: number;
    /**
     * 
     * @type {HighLightMode}
     * @memberof HighLightUnHighLightPage
     */
    'mode'?: HighLightMode;
}


/**
 * 
 * @export
 * @interface HighlightSearchResultsParam
 */
export interface HighlightSearchResultsParam {
    /**
     * 
     * @type {Array<number>}
     * @memberof HighlightSearchResultsParam
     */
    'sortKeys'?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof HighlightSearchResultsParam
     */
    'isSelectAll'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HighlightSearchResultsParam
     */
    'isHighlight'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof HighlightSearchResultsParam
     */
    'pageNo'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const HistoryItemType = {
    Author: 0,
    Highlight: 1,
    UnHighlight: 2,
    Other: 3,
    DeletePages: 4,
    JoinDocuments: 5,
    SplitDocument: 6,
    CloseDocument: 7
} as const;

export type HistoryItemType = typeof HistoryItemType[keyof typeof HistoryItemType];


/**
 * 
 * @export
 * @interface HistoryViewItemWeb
 */
export interface HistoryViewItemWeb {
    /**
     * 
     * @type {HistoryItemType}
     * @memberof HistoryViewItemWeb
     */
    'type'?: HistoryItemType;
    /**
     * 
     * @type {string}
     * @memberof HistoryViewItemWeb
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof HistoryViewItemWeb
     */
    'text'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HistoryViewItemWeb
     */
    'pageNo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HistoryViewItemWeb
     */
    'dateTime'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof HistoryViewItemWeb
     */
    'startIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof HistoryViewItemWeb
     */
    'count'?: number;
    /**
     * 
     * @type {number}
     * @memberof HistoryViewItemWeb
     */
    'level'?: number;
    /**
     * 
     * @type {Array<HistoryViewItemWeb>}
     * @memberof HistoryViewItemWeb
     */
    'children'?: Array<HistoryViewItemWeb> | null;
    /**
     * 
     * @type {string}
     * @memberof HistoryViewItemWeb
     */
    'id'?: string;
}


/**
 * 
 * @export
 * @interface HistoryViewItemWebListApiResult
 */
export interface HistoryViewItemWebListApiResult {
    /**
     * 
     * @type {Array<HistoryViewItemWeb>}
     * @memberof HistoryViewItemWebListApiResult
     */
    'result'?: Array<HistoryViewItemWeb> | null;
    /**
     * 
     * @type {boolean}
     * @memberof HistoryViewItemWebListApiResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof HistoryViewItemWebListApiResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof HistoryViewItemWebListApiResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HistoryViewItemWebListApiResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface JoinDocumentsParam
 */
export interface JoinDocumentsParam {
    /**
     * 
     * @type {Array<string>}
     * @memberof JoinDocumentsParam
     */
    'tabIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface LicenseConfig
 */
export interface LicenseConfig {
    /**
     * 
     * @type {number}
     * @memberof LicenseConfig
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof LicenseConfig
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof LicenseConfig
     */
    'moduleKey'?: string | null;
}
/**
 * 
 * @export
 * @interface LicenseReponseResult
 */
export interface LicenseReponseResult {
    /**
     * 
     * @type {string}
     * @memberof LicenseReponseResult
     */
    'mode'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LicenseReponseResult
     */
    'update'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LicenseReponseResult
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LicenseReponseResult
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LicenseReponseResult
     */
    'company_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LicenseReponseResult
     */
    'passwordFileXtract'?: string | null;
    /**
     * 
     * @type {Array<LicenseConfig>}
     * @memberof LicenseReponseResult
     */
    'modules'?: Array<LicenseConfig> | null;
}
/**
 * 
 * @export
 * @interface LineAnnotation
 */
export interface LineAnnotation {
    /**
     * 
     * @type {string}
     * @memberof LineAnnotation
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof LineAnnotation
     */
    'left'?: number;
    /**
     * 
     * @type {number}
     * @memberof LineAnnotation
     */
    'top'?: number;
    /**
     * 
     * @type {number}
     * @memberof LineAnnotation
     */
    'borderWidth'?: number;
    /**
     * 
     * @type {number}
     * @memberof LineAnnotation
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof LineAnnotation
     */
    'height'?: number;
    /**
     * 
     * @type {string}
     * @memberof LineAnnotation
     */
    'color'?: string;
}
/**
 * 
 * @export
 * @interface LineAnnotationPageDataResponse
 */
export interface LineAnnotationPageDataResponse {
    /**
     * 
     * @type {LineAnnotation}
     * @memberof LineAnnotationPageDataResponse
     */
    'data'?: LineAnnotation;
    /**
     * 
     * @type {LxPageData}
     * @memberof LineAnnotationPageDataResponse
     */
    'pageData'?: LxPageData;
}
/**
 * 
 * @export
 * @interface LineAnnotationPageDataResponseApiResult
 */
export interface LineAnnotationPageDataResponseApiResult {
    /**
     * 
     * @type {LineAnnotationPageDataResponse}
     * @memberof LineAnnotationPageDataResponseApiResult
     */
    'result'?: LineAnnotationPageDataResponse;
    /**
     * 
     * @type {boolean}
     * @memberof LineAnnotationPageDataResponseApiResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LineAnnotationPageDataResponseApiResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LineAnnotationPageDataResponseApiResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LineAnnotationPageDataResponseApiResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface LoginAppParam
 */
export interface LoginAppParam {
    /**
     * 
     * @type {string}
     * @memberof LoginAppParam
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginAppParam
     */
    'passWord'?: string | null;
}
/**
 * 
 * @export
 * @interface LoginNovaParam
 */
export interface LoginNovaParam {
    /**
     * 
     * @type {string}
     * @memberof LoginNovaParam
     */
    'requestId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginNovaParam
     */
    'tokenThirdParty'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginNovaParam
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginNovaParam
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginNovaParam
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginNovaParam
     */
    'licenseKey'?: string | null;
}
/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * 
     * @type {AccessTokenOperationResult}
     * @memberof LoginResponse
     */
    'userDataLogin'?: AccessTokenOperationResult;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    'token'?: string | null;
}
/**
 * 
 * @export
 * @interface LxPageData
 */
export interface LxPageData {
    /**
     * 
     * @type {Array<LxWordResponse>}
     * @memberof LxPageData
     */
    'words'?: Array<LxWordResponse> | null;
    /**
     * 
     * @type {Array<RectangeWithColor>}
     * @memberof LxPageData
     */
    'rectangeWithColors'?: Array<RectangeWithColor> | null;
    /**
     * 
     * @type {Array<BoxObj>}
     * @memberof LxPageData
     */
    'boxObjs'?: Array<BoxObj> | null;
    /**
     * 
     * @type {Array<NoteTextObj>}
     * @memberof LxPageData
     */
    'noteTextObjs'?: Array<NoteTextObj> | null;
    /**
     * 
     * @type {Array<LineAnnotation>}
     * @memberof LxPageData
     */
    'lineAnnotations'?: Array<LineAnnotation> | null;
    /**
     * 
     * @type {StampViewInfo}
     * @memberof LxPageData
     */
    'stampInfo'?: StampViewInfo;
    /**
     * 
     * @type {boolean}
     * @memberof LxPageData
     */
    'isHightLightAllPage'?: boolean;
}
/**
 * 
 * @export
 * @interface LxPageDataApiResult
 */
export interface LxPageDataApiResult {
    /**
     * 
     * @type {LxPageData}
     * @memberof LxPageDataApiResult
     */
    'result'?: LxPageData;
    /**
     * 
     * @type {boolean}
     * @memberof LxPageDataApiResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LxPageDataApiResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LxPageDataApiResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LxPageDataApiResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface LxRange
 */
export interface LxRange {
    /**
     * 
     * @type {number}
     * @memberof LxRange
     */
    'fromId'?: number;
    /**
     * 
     * @type {number}
     * @memberof LxRange
     */
    'toId'?: number;
    /**
     * 
     * @type {number}
     * @memberof LxRange
     */
    'length'?: number;
}
/**
 * 
 * @export
 * @interface LxSetting
 */
export interface LxSetting {
    /**
     * 
     * @type {string}
     * @memberof LxSetting
     */
    'licenseServiceUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LxSetting
     */
    'option_Color_selection'?: string;
    /**
     * 
     * @type {number}
     * @memberof LxSetting
     */
    'archiveFileFormat'?: number;
    /**
     * 
     * @type {string}
     * @memberof LxSetting
     */
    'drawLineWidth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LxSetting
     */
    'drawLineColor'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LxSetting
     */
    'extractCombineTab'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LxSetting
     */
    'extractMaxFileSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LxSetting
     */
    'autoHighlightMask'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LxSetting
     */
    'ocrWhenOpeningDocuments'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LxSetting
     */
    'replaceFontName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LxSetting
     */
    'replaceFontSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LxSetting
     */
    'compressExtractFiles'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LxSetting
     */
    'warningKeyword'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LxSetting
     */
    'warningTemplate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LxSetting
     */
    'warningTag'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LxSetting
     */
    'isFavorSpeed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LxSetting
     */
    'ocrDictionary'?: string | null;
    /**
     * 
     * @type {PdfFileType}
     * @memberof LxSetting
     */
    'extractPdfFileType'?: PdfFileType;
    /**
     * 
     * @type {string}
     * @memberof LxSetting
     */
    'tocIndexPattern'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LxSetting
     */
    'drawIndexInTocPages'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LxSetting
     */
    'namesWillNotBeginOrEndWith'?: string | null;
    /**
     * 
     * @type {Country}
     * @memberof LxSetting
     */
    'dictionaryName'?: Country;
    /**
     * 
     * @type {string}
     * @memberof LxSetting
     */
    'highlightPublishColor'?: string;
    /**
     * 
     * @type {number}
     * @memberof LxSetting
     */
    'dontSendOCRMailPageCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LxSetting
     */
    'sendEmailAfterPublish'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LxSetting
     */
    'sendEmailTo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LxSetting
     */
    'hiddenPageTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LxSetting
     */
    'hiddenPageImage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LxSetting
     */
    'showWaterMark'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LxSetting
     */
    'waterMarkTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LxSetting
     */
    'waterMarkImage'?: string | null;
}


/**
 * 
 * @export
 * @interface LxWordResponse
 */
export interface LxWordResponse {
    /**
     * 
     * @type {number}
     * @memberof LxWordResponse
     */
    'id'?: number;
    /**
     * 
     * @type {CharType}
     * @memberof LxWordResponse
     */
    'charType'?: CharType;
    /**
     * 
     * @type {Array<RectangleF>}
     * @memberof LxWordResponse
     */
    'rects'?: Array<RectangleF> | null;
    /**
     * 
     * @type {number}
     * @memberof LxWordResponse
     */
    'logicLineId'?: number;
    /**
     * 
     * @type {string}
     * @memberof LxWordResponse
     */
    'text'?: string | null;
}


/**
 * 
 * @export
 * @interface MarkedInfo
 */
export interface MarkedInfo {
    /**
     * 
     * @type {string}
     * @memberof MarkedInfo
     */
    'author'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MarkedInfo
     */
    'creationTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarkedInfo
     */
    'replaceObjId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MarkedInfo
     */
    'tagId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MarkedInfo
     */
    'charRangeId'?: string;
    /**
     * 
     * @type {SearchType}
     * @memberof MarkedInfo
     */
    'searchType'?: SearchType;
    /**
     * 
     * @type {string}
     * @memberof MarkedInfo
     */
    'searchTypeKey'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MarkedInfo
     */
    'isHighlightOnPublish'?: boolean;
}


/**
 * 
 * @export
 * @interface MarkedInfoWithId
 */
export interface MarkedInfoWithId {
    /**
     * 
     * @type {number}
     * @memberof MarkedInfoWithId
     */
    'lxCharId'?: number;
    /**
     * 
     * @type {MarkedInfo}
     * @memberof MarkedInfoWithId
     */
    'markedInfo'?: MarkedInfo;
}
/**
 * 
 * @export
 * @interface NoteTextObj
 */
export interface NoteTextObj {
    /**
     * 
     * @type {string}
     * @memberof NoteTextObj
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteTextObj
     */
    'noteText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NoteTextObj
     */
    'foreColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteTextObj
     */
    'backColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteTextObj
     */
    'fontName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NoteTextObj
     */
    'fontSize'?: number;
    /**
     * 
     * @type {PointF}
     * @memberof NoteTextObj
     */
    'point'?: PointF;
    /**
     * 
     * @type {number}
     * @memberof NoteTextObj
     */
    'opacity'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NoteTextObj
     */
    'visibleOnExtract'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NoteTextObj
     */
    'createDate'?: string;
    /**
     * 
     * @type {RectangleF}
     * @memberof NoteTextObj
     */
    'rectangleF'?: RectangleF;
}
/**
 * 
 * @export
 * @interface NoteTextObjListApiResult
 */
export interface NoteTextObjListApiResult {
    /**
     * 
     * @type {Array<NoteTextObj>}
     * @memberof NoteTextObjListApiResult
     */
    'result'?: Array<NoteTextObj> | null;
    /**
     * 
     * @type {boolean}
     * @memberof NoteTextObjListApiResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NoteTextObjListApiResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NoteTextObjListApiResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NoteTextObjListApiResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface NoteTextObjPageDataResponse
 */
export interface NoteTextObjPageDataResponse {
    /**
     * 
     * @type {NoteTextObj}
     * @memberof NoteTextObjPageDataResponse
     */
    'data'?: NoteTextObj;
    /**
     * 
     * @type {LxPageData}
     * @memberof NoteTextObjPageDataResponse
     */
    'pageData'?: LxPageData;
}
/**
 * 
 * @export
 * @interface NoteTextObjPageDataResponseApiResult
 */
export interface NoteTextObjPageDataResponseApiResult {
    /**
     * 
     * @type {NoteTextObjPageDataResponse}
     * @memberof NoteTextObjPageDataResponseApiResult
     */
    'result'?: NoteTextObjPageDataResponse;
    /**
     * 
     * @type {boolean}
     * @memberof NoteTextObjPageDataResponseApiResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NoteTextObjPageDataResponseApiResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NoteTextObjPageDataResponseApiResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NoteTextObjPageDataResponseApiResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface NoteTextParam
 */
export interface NoteTextParam {
    /**
     * 
     * @type {string}
     * @memberof NoteTextParam
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof NoteTextParam
     */
    'pageNo'?: number;
    /**
     * 
     * @type {string}
     * @memberof NoteTextParam
     */
    'noteText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NoteTextParam
     */
    'foreColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteTextParam
     */
    'backColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteTextParam
     */
    'fontName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NoteTextParam
     */
    'fontSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof NoteTextParam
     */
    'opacity'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NoteTextParam
     */
    'visibleOnExtract'?: boolean;
    /**
     * 
     * @type {RectangleF}
     * @memberof NoteTextParam
     */
    'rectangleF'?: RectangleF;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OcrMode = {
    None: 0,
    Normal: 1
} as const;

export type OcrMode = typeof OcrMode[keyof typeof OcrMode];


/**
 * 
 * @export
 * @interface Page
 */
export interface Page {
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    'pageId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    'viewerPageNo'?: number;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    'pageImageCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    'rotationAngles'?: number;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    'extractPageNo'?: number;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    'pageWidth'?: number;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    'pageHeight'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Page
     */
    'isBlankPage'?: boolean;
    /**
     * 
     * @type {TabFileType}
     * @memberof Page
     */
    'tabFileType'?: TabFileType;
    /**
     * 
     * @type {Array<string>}
     * @memberof Page
     */
    'bookMarkIds'?: Array<string> | null;
    /**
     * 
     * @type {HiddenEnum}
     * @memberof Page
     */
    'isHidden'?: HiddenEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Page
     */
    'splitOnExtract'?: boolean;
    /**
     * 
     * @type {OcrMode}
     * @memberof Page
     */
    'ocrMode'?: OcrMode;
}


/**
 * 
 * @export
 * @interface PageApiResult
 */
export interface PageApiResult {
    /**
     * 
     * @type {Page}
     * @memberof PageApiResult
     */
    'result'?: Page;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PageApiResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface PageNumberingInfo
 */
export interface PageNumberingInfo {
    /**
     * 
     * @type {boolean}
     * @memberof PageNumberingInfo
     */
    'included'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageNumberingInfo
     */
    'margin'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageNumberingInfo
     */
    'marginSide'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageNumberingInfo
     */
    'position'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageNumberingInfo
     */
    'showFromTOC'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageNumberingInfo
     */
    'showFromFrontPage'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageNumberingInfo
     */
    'addFrame'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PageNumberingInfo
     */
    'fontName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PageNumberingInfo
     */
    'fontSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof PageNumberingInfo
     */
    'fontColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageNumberingInfo
     */
    'backColor'?: string;
}
/**
 * 
 * @export
 * @interface PageStatus
 */
export interface PageStatus {
    /**
     * 
     * @type {number}
     * @memberof PageStatus
     */
    'previewPageNo'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageStatus
     */
    'viewerPageNo'?: number;
    /**
     * 
     * @type {HiddenWithDeleteEnum}
     * @memberof PageStatus
     */
    'status'?: HiddenWithDeleteEnum;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const PdfFileType = {
    PdfPicture: 0,
    Pdf: 1,
    PdfA: 2,
    PdfUa: 3,
    PortFolio: 4
} as const;

export type PdfFileType = typeof PdfFileType[keyof typeof PdfFileType];


/**
 * 
 * @export
 * @interface PointF
 */
export interface PointF {
    /**
     * 
     * @type {boolean}
     * @memberof PointF
     */
    'isEmpty'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PointF
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof PointF
     */
    'y'?: number;
}
/**
 * 
 * @export
 * @interface ReOrderTOCParam
 */
export interface ReOrderTOCParam {
    /**
     * 
     * @type {Array<string>}
     * @memberof ReOrderTOCParam
     */
    'tocIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ReOrderTOCParam
     */
    'parentId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReOrderTOCParam
     */
    'newOrderIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface ReOrderTOCResponse
 */
export interface ReOrderTOCResponse {
    /**
     * 
     * @type {Array<TocRow>}
     * @memberof ReOrderTOCResponse
     */
    'tocRows'?: Array<TocRow> | null;
    /**
     * 
     * @type {DocumentInfo}
     * @memberof ReOrderTOCResponse
     */
    'documentInfo'?: DocumentInfo;
}
/**
 * 
 * @export
 * @interface RectangeWithColor
 */
export interface RectangeWithColor {
    /**
     * 
     * @type {RectangleF}
     * @memberof RectangeWithColor
     */
    'rect'?: RectangleF;
    /**
     * 
     * @type {string}
     * @memberof RectangeWithColor
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof RectangeWithColor
     */
    'replaceObjId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RectangeWithColor
     */
    'tagId'?: string | null;
}
/**
 * 
 * @export
 * @interface RectangleF
 */
export interface RectangleF {
    /**
     * 
     * @type {PointF}
     * @memberof RectangleF
     */
    'location'?: PointF;
    /**
     * 
     * @type {SizeF}
     * @memberof RectangleF
     */
    'size'?: SizeF;
    /**
     * 
     * @type {number}
     * @memberof RectangleF
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof RectangleF
     */
    'y'?: number;
    /**
     * 
     * @type {number}
     * @memberof RectangleF
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof RectangleF
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof RectangleF
     */
    'left'?: number;
    /**
     * 
     * @type {number}
     * @memberof RectangleF
     */
    'top'?: number;
    /**
     * 
     * @type {number}
     * @memberof RectangleF
     */
    'right'?: number;
    /**
     * 
     * @type {number}
     * @memberof RectangleF
     */
    'bottom'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RectangleF
     */
    'isEmpty'?: boolean;
}
/**
 * 
 * @export
 * @interface RectangleSelection
 */
export interface RectangleSelection {
    /**
     * 
     * @type {number}
     * @memberof RectangleSelection
     */
    'left'?: number;
    /**
     * 
     * @type {number}
     * @memberof RectangleSelection
     */
    'top'?: number;
    /**
     * 
     * @type {number}
     * @memberof RectangleSelection
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof RectangleSelection
     */
    'height'?: number;
    /**
     * 
     * @type {RectangleF}
     * @memberof RectangleSelection
     */
    'rectangleF'?: RectangleF;
}
/**
 * 
 * @export
 * @interface ReplaceBoxParam
 */
export interface ReplaceBoxParam {
    /**
     * 
     * @type {number}
     * @memberof ReplaceBoxParam
     */
    'pageId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReplaceBoxParam
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplaceBoxParam
     */
    'originalWords'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReplaceBoxParam
     */
    'replaceBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReplaceBoxParam
     */
    'fontColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplaceBoxParam
     */
    'extractColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplaceBoxParam
     */
    'fontName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReplaceBoxParam
     */
    'fontSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ReplaceBoxParam
     */
    'wholeWord'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReplaceBoxParam
     */
    'matchCase'?: boolean;
    /**
     * 
     * @type {ReplaceType}
     * @memberof ReplaceBoxParam
     */
    'replaceType'?: ReplaceType;
    /**
     * 
     * @type {string}
     * @memberof ReplaceBoxParam
     */
    'code'?: string | null;
}


/**
 * 
 * @export
 * @interface ReplaceBoxParamPageDataResponse
 */
export interface ReplaceBoxParamPageDataResponse {
    /**
     * 
     * @type {ReplaceBoxParam}
     * @memberof ReplaceBoxParamPageDataResponse
     */
    'data'?: ReplaceBoxParam;
    /**
     * 
     * @type {LxPageData}
     * @memberof ReplaceBoxParamPageDataResponse
     */
    'pageData'?: LxPageData;
}
/**
 * 
 * @export
 * @interface ReplaceBoxParamPageDataResponseApiResult
 */
export interface ReplaceBoxParamPageDataResponseApiResult {
    /**
     * 
     * @type {ReplaceBoxParamPageDataResponse}
     * @memberof ReplaceBoxParamPageDataResponseApiResult
     */
    'result'?: ReplaceBoxParamPageDataResponse;
    /**
     * 
     * @type {boolean}
     * @memberof ReplaceBoxParamPageDataResponseApiResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReplaceBoxParamPageDataResponseApiResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReplaceBoxParamPageDataResponseApiResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReplaceBoxParamPageDataResponseApiResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface ReplaceObj
 */
export interface ReplaceObj {
    /**
     * 
     * @type {number}
     * @memberof ReplaceObj
     */
    'pageId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReplaceObj
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplaceObj
     */
    'originalWords'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReplaceObj
     */
    'replaceBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReplaceObj
     */
    'fontColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplaceObj
     */
    'extractColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplaceObj
     */
    'fontName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReplaceObj
     */
    'fontSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ReplaceObj
     */
    'wholeWord'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReplaceObj
     */
    'matchCase'?: boolean;
    /**
     * 
     * @type {ReplaceType}
     * @memberof ReplaceObj
     */
    'replaceType'?: ReplaceType;
    /**
     * 
     * @type {string}
     * @memberof ReplaceObj
     */
    'code'?: string | null;
}


/**
 * 
 * @export
 * @interface ReplaceObjDisplay
 */
export interface ReplaceObjDisplay {
    /**
     * 
     * @type {number}
     * @memberof ReplaceObjDisplay
     */
    'pageId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReplaceObjDisplay
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplaceObjDisplay
     */
    'originalWords'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReplaceObjDisplay
     */
    'replaceBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReplaceObjDisplay
     */
    'fontColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplaceObjDisplay
     */
    'extractColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplaceObjDisplay
     */
    'fontName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReplaceObjDisplay
     */
    'fontSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ReplaceObjDisplay
     */
    'wholeWord'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReplaceObjDisplay
     */
    'matchCase'?: boolean;
    /**
     * 
     * @type {ReplaceType}
     * @memberof ReplaceObjDisplay
     */
    'replaceType'?: ReplaceType;
    /**
     * 
     * @type {string}
     * @memberof ReplaceObjDisplay
     */
    'code'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReplaceObjDisplay
     */
    'replaceCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReplaceObjDisplay
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReplaceObjDisplay
     */
    'cWords'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReplaceObjDisplay
     */
    'cCount'?: string | null;
}


/**
 * 
 * @export
 * @interface ReplaceObjects
 */
export interface ReplaceObjects {
    /**
     * 
     * @type {Array<BoxObj>}
     * @memberof ReplaceObjects
     */
    'boxs'?: Array<BoxObj> | null;
    /**
     * 
     * @type {Array<ReplaceObj>}
     * @memberof ReplaceObjects
     */
    'replaceObjs'?: Array<ReplaceObj> | null;
}
/**
 * 
 * @export
 * @interface ReplaceParam
 */
export interface ReplaceParam {
    /**
     * 
     * @type {string}
     * @memberof ReplaceParam
     */
    'originalWords'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReplaceParam
     */
    'replaceBy'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReplaceParam
     */
    'fontSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReplaceParam
     */
    'fontName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReplaceParam
     */
    'fontColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplaceParam
     */
    'extractColor'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReplaceParam
     */
    'wholeWord'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReplaceParam
     */
    'matchCase'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReplaceParam
     */
    'isReplaceAll'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReplaceParam
     */
    'code'?: string | null;
}
/**
 * 
 * @export
 * @interface ReplaceParamWeb
 */
export interface ReplaceParamWeb {
    /**
     * 
     * @type {string}
     * @memberof ReplaceParamWeb
     */
    'originalWords'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReplaceParamWeb
     */
    'replaceBy'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReplaceParamWeb
     */
    'fontSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReplaceParamWeb
     */
    'fontName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReplaceParamWeb
     */
    'fontColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplaceParamWeb
     */
    'extractColor'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReplaceParamWeb
     */
    'wholeWord'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReplaceParamWeb
     */
    'matchCase'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReplaceParamWeb
     */
    'isReplaceAll'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReplaceParamWeb
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReplaceParamWeb
     */
    'id'?: string;
    /**
     * 
     * @type {Array<RectangleSelection>}
     * @memberof ReplaceParamWeb
     */
    'rectangleWords'?: Array<RectangleSelection> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ReplaceParamWeb
     */
    'sortKeys'?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof ReplaceParamWeb
     */
    'viewerPageNo'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ReplaceType = {
    Text: 0,
    Box: 1
} as const;

export type ReplaceType = typeof ReplaceType[keyof typeof ReplaceType];


/**
 * 
 * @export
 * @interface RotateParam
 */
export interface RotateParam {
    /**
     * 
     * @type {Array<number>}
     * @memberof RotateParam
     */
    'viewerPageNos'?: Array<number> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RowStatus = {
    Active: 1,
    Inactive: 2,
    Delete: 3
} as const;

export type RowStatus = typeof RowStatus[keyof typeof RowStatus];


/**
 * 
 * @export
 * @interface SaveTagParam
 */
export interface SaveTagParam {
    /**
     * 
     * @type {string}
     * @memberof SaveTagParam
     */
    'tagId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaveTagParam
     */
    'categoryID'?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveTagParam
     */
    'tagNameId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveTagParam
     */
    'keyWord'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaveTagParam
     */
    'backColor'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SaveTagParam
     */
    'comma'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaveTagParam
     */
    'line'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaveTagParam
     */
    'semicolon'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaveTagParam
     */
    'slash'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaveTagParam
     */
    'space'?: boolean;
}
/**
 * 
 * @export
 * @interface SearchKeywordApiParam
 */
export interface SearchKeywordApiParam {
    /**
     * 
     * @type {SearchTabVm}
     * @memberof SearchKeywordApiParam
     */
    'searchTabVm'?: SearchTabVm;
    /**
     * 
     * @type {number}
     * @memberof SearchKeywordApiParam
     */
    'currentPageNo'?: number;
}
/**
 * 
 * @export
 * @interface SearchKeywordParam
 */
export interface SearchKeywordParam {
    /**
     * 
     * @type {string}
     * @memberof SearchKeywordParam
     */
    'keyword'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SearchKeywordParam
     */
    'wholeWord'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchKeywordParam
     */
    'matchCase'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchKeywordParam
     */
    'wildCard'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchKeywordParam
     */
    'searchList'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchKeywordParam
     */
    'byComma'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchKeywordParam
     */
    'bySemicolon'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchKeywordParam
     */
    'byLine'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchKeywordParam
     */
    'bySpace'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchKeywordParam
     */
    'bySplash'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchKeywordParam
     */
    'highlightWholeWord'?: boolean;
}
/**
 * 
 * @export
 * @interface SearchResult
 */
export interface SearchResult {
    /**
     * 
     * @type {string}
     * @memberof SearchResult
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchResult
     */
    'pageId'?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchResult
     */
    'sortKey'?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchResult
     */
    'viewerPageNo'?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchResult
     */
    'word'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchResult
     */
    'phrase'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SearchResult
     */
    'checked'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SearchResult
     */
    'highLightFromIdx'?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchResult
     */
    'highLightLength'?: number;
    /**
     * 
     * @type {LxRange}
     * @memberof SearchResult
     */
    'range'?: LxRange;
    /**
     * 
     * @type {string}
     * @memberof SearchResult
     */
    'tagId'?: string | null;
    /**
     * 
     * @type {Array<RectangleF>}
     * @memberof SearchResult
     */
    'rectangleFs'?: Array<RectangleF> | null;
    /**
     * 
     * @type {string}
     * @memberof SearchResult
     */
    'searchTypeKey'?: string | null;
}
/**
 * 
 * @export
 * @interface SearchResultData
 */
export interface SearchResultData {
    /**
     * 
     * @type {Array<SearchResult>}
     * @memberof SearchResultData
     */
    'searchData'?: Array<SearchResult> | null;
    /**
     * 
     * @type {number}
     * @memberof SearchResultData
     */
    'totalSearchResult'?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchResultData
     */
    'totalPage'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SearchTabType = {
    Keyword: 0,
    Template: 1,
    Tag: 2
} as const;

export type SearchTabType = typeof SearchTabType[keyof typeof SearchTabType];


/**
 * 
 * @export
 * @interface SearchTabVm
 */
export interface SearchTabVm {
    /**
     * 
     * @type {SearchTabType}
     * @memberof SearchTabVm
     */
    'searchTabType'?: SearchTabType;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTabVm
     */
    'autoSearch'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTabVm
     */
    'accuracy'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTabVm
     */
    'fuzzy'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTabVm
     */
    'allPages'?: boolean;
    /**
     * 
     * @type {SearchKeywordParam}
     * @memberof SearchTabVm
     */
    'searchKeywordParam'?: SearchKeywordParam;
    /**
     * 
     * @type {SearchTemplateParam}
     * @memberof SearchTabVm
     */
    'searchTemplateParam'?: SearchTemplateParam;
    /**
     * 
     * @type {SearchTagParam}
     * @memberof SearchTabVm
     */
    'searchTagParam'?: SearchTagParam;
}


/**
 * 
 * @export
 * @interface SearchTagParam
 */
export interface SearchTagParam {
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchTagParam
     */
    'selectedTags'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTagParam
     */
    'wholeWord'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTagParam
     */
    'matchCase'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTagParam
     */
    'accuracy'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTagParam
     */
    'checkAll'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SearchTagParam
     */
    'pageNo'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTagParam
     */
    'highlightWholeWord'?: boolean;
}
/**
 * 
 * @export
 * @interface SearchTemplateApiParam
 */
export interface SearchTemplateApiParam {
    /**
     * 
     * @type {SearchTabVm}
     * @memberof SearchTemplateApiParam
     */
    'searchTabVm'?: SearchTabVm;
    /**
     * 
     * @type {number}
     * @memberof SearchTemplateApiParam
     */
    'currentPageNo'?: number;
}
/**
 * 
 * @export
 * @interface SearchTemplateParam
 */
export interface SearchTemplateParam {
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'phoneNumber'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'email'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'cpr'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'module11'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'bsn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'module97Bsn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'nrn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'module11Nrn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'cvr'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'creditCard'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'searchByName'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'searchFullName'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'address'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'searchFullAddress'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'assistiveProduct'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'belgianMunicipalities'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'policeZones'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'diseases'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'currency'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'number'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'date'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'webAddress'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'customTemplate'?: boolean;
    /**
     * 
     * @type {Array<TemplateItem>}
     * @memberof SearchTemplateParam
     */
    'templateItems'?: Array<TemplateItem> | null;
    /**
     * 
     * @type {Array<CustomTemplateItem>}
     * @memberof SearchTemplateParam
     */
    'customTemplateItems'?: Array<CustomTemplateItem> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SearchType = {
    CPR: 0,
    CVR: 1,
    CreditCard: 2,
    Name: 3,
    NameAccuracy: 4,
    PhoneNumber: 5,
    Email: 6,
    Address: 7,
    Diseases: 8,
    WebAddress: 9,
    Currency: 10,
    Number: 11,
    AssistiveProduct: 12,
    AssistiveProductFuzzy: 13,
    CustomTemplate: 14,
    Tag: 15,
    Keyword: 16,
    Bsn: 17,
    Nrn: 18,
    BelgianMunicipalities: 19,
    PoliceZones: 20,
    None: 999
} as const;

export type SearchType = typeof SearchType[keyof typeof SearchType];


/**
 * 
 * @export
 * @interface SelectedWordContinuousResponse
 */
export interface SelectedWordContinuousResponse {
    /**
     * 
     * @type {string}
     * @memberof SelectedWordContinuousResponse
     */
    'value'?: string | null;
    /**
     * 
     * @type {Array<RectangleSelection>}
     * @memberof SelectedWordContinuousResponse
     */
    'rectangleSelections'?: Array<RectangleSelection> | null;
}
/**
 * 
 * @export
 * @interface SelectionTagParam
 */
export interface SelectionTagParam {
    /**
     * 
     * @type {TagInfo}
     * @memberof SelectionTagParam
     */
    'tagInfo'?: TagInfo;
    /**
     * 
     * @type {Array<RectangleSelection>}
     * @memberof SelectionTagParam
     */
    'selectionBoxes'?: Array<RectangleSelection> | null;
    /**
     * 
     * @type {number}
     * @memberof SelectionTagParam
     */
    'viewerPageNo'?: number;
}
/**
 * 
 * @export
 * @interface SetSplitOnExtractParam
 */
export interface SetSplitOnExtractParam {
    /**
     * 
     * @type {boolean}
     * @memberof SetSplitOnExtractParam
     */
    'splitOnExtract'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof SetSplitOnExtractParam
     */
    'pages'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface ShowHidePagesParam
 */
export interface ShowHidePagesParam {
    /**
     * 
     * @type {Array<number>}
     * @memberof ShowHidePagesParam
     */
    'pageNos'?: Array<number> | null;
    /**
     * 
     * @type {HiddenEnum}
     * @memberof ShowHidePagesParam
     */
    'status'?: HiddenEnum;
}


/**
 * 
 * @export
 * @interface SizeF
 */
export interface SizeF {
    /**
     * 
     * @type {boolean}
     * @memberof SizeF
     */
    'isEmpty'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SizeF
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof SizeF
     */
    'height'?: number;
}
/**
 * 
 * @export
 * @interface StampLocation
 */
export interface StampLocation {
    /**
     * 
     * @type {number}
     * @memberof StampLocation
     */
    'marginRight'?: number;
    /**
     * 
     * @type {number}
     * @memberof StampLocation
     */
    'marginTop'?: number;
    /**
     * 
     * @type {number}
     * @memberof StampLocation
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof StampLocation
     */
    'height'?: number;
}
/**
 * 
 * @export
 * @interface StampViewInfo
 */
export interface StampViewInfo {
    /**
     * 
     * @type {string}
     * @memberof StampViewInfo
     */
    'stampImg'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StampViewInfo
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof StampViewInfo
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof StampViewInfo
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof StampViewInfo
     */
    'y'?: number;
}
/**
 * 
 * @export
 * @interface StringApiResult
 */
export interface StringApiResult {
    /**
     * 
     * @type {string}
     * @memberof StringApiResult
     */
    'result'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StringApiResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StringApiResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StringApiResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StringApiResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface StringListApiResult
 */
export interface StringListApiResult {
    /**
     * 
     * @type {Array<string>}
     * @memberof StringListApiResult
     */
    'result'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof StringListApiResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StringListApiResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StringListApiResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StringListApiResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface StringPageDataResponse
 */
export interface StringPageDataResponse {
    /**
     * 
     * @type {string}
     * @memberof StringPageDataResponse
     */
    'data'?: string | null;
    /**
     * 
     * @type {LxPageData}
     * @memberof StringPageDataResponse
     */
    'pageData'?: LxPageData;
}
/**
 * 
 * @export
 * @interface StringPageStatusListDictionaryApiResult
 */
export interface StringPageStatusListDictionaryApiResult {
    /**
     * 
     * @type {{ [key: string]: Array<PageStatus>; }}
     * @memberof StringPageStatusListDictionaryApiResult
     */
    'result'?: { [key: string]: Array<PageStatus>; } | null;
    /**
     * 
     * @type {boolean}
     * @memberof StringPageStatusListDictionaryApiResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StringPageStatusListDictionaryApiResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StringPageStatusListDictionaryApiResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StringPageStatusListDictionaryApiResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface TabDetail
 */
export interface TabDetail {
    /**
     * 
     * @type {Array<Page>}
     * @memberof TabDetail
     */
    'pages'?: Array<Page> | null;
    /**
     * 
     * @type {number}
     * @memberof TabDetail
     */
    'pageCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof TabDetail
     */
    'startPage'?: number;
    /**
     * 
     * @type {string}
     * @memberof TabDetail
     */
    'uniqueId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TabDetail
     */
    'parentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TabDetail
     */
    'fileName'?: string | null;
    /**
     * 
     * @type {TabFileType}
     * @memberof TabDetail
     */
    'tabFileType'?: TabFileType;
    /**
     * 
     * @type {number}
     * @memberof TabDetail
     */
    'numberPageNeedOCR'?: number;
    /**
     * 
     * @type {number}
     * @memberof TabDetail
     */
    'numberPageOCRDone'?: number;
    /**
     * 
     * @type {number}
     * @memberof TabDetail
     */
    'numberPageHasImage'?: number;
    /**
     * 
     * @type {number}
     * @memberof TabDetail
     */
    'tabIndex'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TabDetail
     */
    'includeStamp'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TabDetail
     */
    'stampIndex'?: string | null;
    /**
     * 
     * @type {TabStamp}
     * @memberof TabDetail
     */
    'tabStamp'?: TabStamp;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const TabFileType = {
    Normal: 0,
    FrontPage: 1,
    TOC: 3
} as const;

export type TabFileType = typeof TabFileType[keyof typeof TabFileType];


/**
 * 
 * @export
 * @interface TabStamp
 */
export interface TabStamp {
    /**
     * 
     * @type {string}
     * @memberof TabStamp
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TabStamp
     */
    'index'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TabStamp
     */
    'addFrame'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TabStamp
     */
    'fontName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TabStamp
     */
    'fontSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof TabStamp
     */
    'fontColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof TabStamp
     */
    'logoBase64'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TabStamp
     */
    'transparent'?: number;
    /**
     * 
     * @type {StampLocation}
     * @memberof TabStamp
     */
    'location'?: StampLocation;
    /**
     * 
     * @type {boolean}
     * @memberof TabStamp
     */
    'autoScale'?: boolean;
}
/**
 * 
 * @export
 * @interface TabStampWeb
 */
export interface TabStampWeb {
    /**
     * 
     * @type {string}
     * @memberof TabStampWeb
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TabStampWeb
     */
    'index'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TabStampWeb
     */
    'addFrame'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TabStampWeb
     */
    'fontName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TabStampWeb
     */
    'fontSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof TabStampWeb
     */
    'fontColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof TabStampWeb
     */
    'logoBase64'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TabStampWeb
     */
    'transparent'?: number;
    /**
     * 
     * @type {StampLocation}
     * @memberof TabStampWeb
     */
    'location'?: StampLocation;
    /**
     * 
     * @type {boolean}
     * @memberof TabStampWeb
     */
    'showStamp'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TabStampWeb
     */
    'automaticCount'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TabStampWeb
     */
    'showFromTOC'?: boolean;
}
/**
 * 
 * @export
 * @interface TagCategory
 */
export interface TagCategory {
    /**
     * 
     * @type {string}
     * @memberof TagCategory
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagCategory
     */
    'keyword'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TagCategory
     */
    'keywordDa'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TagCategory
     */
    'isDefault'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TagCategory
     */
    'color'?: string;
}
/**
 * 
 * @export
 * @interface TagCategoryListApiResult
 */
export interface TagCategoryListApiResult {
    /**
     * 
     * @type {Array<TagCategory>}
     * @memberof TagCategoryListApiResult
     */
    'result'?: Array<TagCategory> | null;
    /**
     * 
     * @type {boolean}
     * @memberof TagCategoryListApiResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TagCategoryListApiResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TagCategoryListApiResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TagCategoryListApiResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface TagData
 */
export interface TagData {
    /**
     * 
     * @type {Array<TagCategory>}
     * @memberof TagData
     */
    'tagCategories'?: Array<TagCategory> | null;
    /**
     * 
     * @type {Array<TagName>}
     * @memberof TagData
     */
    'tagNames'?: Array<TagName> | null;
    /**
     * 
     * @type {Array<TagInfo>}
     * @memberof TagData
     */
    'tagInfos'?: Array<TagInfo> | null;
}
/**
 * 
 * @export
 * @interface TagInfo
 */
export interface TagInfo {
    /**
     * 
     * @type {string}
     * @memberof TagInfo
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagInfo
     */
    'tagNameId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagInfo
     */
    'keyWord'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TagInfo
     */
    'replaceBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TagInfo
     */
    'backColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagInfo
     */
    'foreColor'?: string;
    /**
     * 
     * @type {number}
     * @memberof TagInfo
     */
    'fontSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof TagInfo
     */
    'fontName'?: string | null;
}
/**
 * 
 * @export
 * @interface TagName
 */
export interface TagName {
    /**
     * 
     * @type {string}
     * @memberof TagName
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagName
     */
    'tagCategoryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagName
     */
    'keyword'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TagName
     */
    'keywordDa'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TagName
     */
    'isDefault'?: boolean;
}
/**
 * 
 * @export
 * @interface TagNameListApiResult
 */
export interface TagNameListApiResult {
    /**
     * 
     * @type {Array<TagName>}
     * @memberof TagNameListApiResult
     */
    'result'?: Array<TagName> | null;
    /**
     * 
     * @type {boolean}
     * @memberof TagNameListApiResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TagNameListApiResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TagNameListApiResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TagNameListApiResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface TemplateItem
 */
export interface TemplateItem {
    /**
     * 
     * @type {string}
     * @memberof TemplateItem
     */
    'templateKey'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TemplateItem
     */
    'checked'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TemplateItem
     */
    'isFull'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TemplateItem
     */
    'value'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TocColumEnum = {
    Date: 0,
    Appendix: 1,
    Text: 2,
    FileName: 3,
    Page: 4,
    Index: -1
} as const;

export type TocColumEnum = typeof TocColumEnum[keyof typeof TocColumEnum];


/**
 * 
 * @export
 * @interface TocColumn
 */
export interface TocColumn {
    /**
     * 
     * @type {TocColumnType}
     * @memberof TocColumn
     */
    'columnType'?: TocColumnType;
    /**
     * 
     * @type {number}
     * @memberof TocColumn
     */
    'defaultWidth'?: number;
    /**
     * 
     * @type {string}
     * @memberof TocColumn
     */
    'headerText'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TocColumn
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof TocColumn
     */
    'left'?: number;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const TocColumnType = {
    Fixed: 0,
    Auto: 1
} as const;

export type TocColumnType = typeof TocColumnType[keyof typeof TocColumnType];


/**
 * 
 * @export
 * @interface TocData
 */
export interface TocData {
    /**
     * 
     * @type {string}
     * @memberof TocData
     */
    'title'?: string | null;
    /**
     * 
     * @type {Array<TocRow>}
     * @memberof TocData
     */
    'content'?: Array<TocRow> | null;
    /**
     * 
     * @type {{ [key: string]: TocColumn; }}
     * @memberof TocData
     */
    'columns'?: { [key: string]: TocColumn; } | null;
}
/**
 * 
 * @export
 * @interface TocDataApiResult
 */
export interface TocDataApiResult {
    /**
     * 
     * @type {TocData}
     * @memberof TocDataApiResult
     */
    'result'?: TocData;
    /**
     * 
     * @type {boolean}
     * @memberof TocDataApiResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TocDataApiResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TocDataApiResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TocDataApiResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface TocRow
 */
export interface TocRow {
    /**
     * 
     * @type {string}
     * @memberof TocRow
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TocRow
     */
    'indexView'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TocRow
     */
    'index'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TocRow
     */
    'date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TocRow
     */
    'appendix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TocRow
     */
    'text'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TocRow
     */
    'fileName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TocRow
     */
    'startPage'?: number;
    /**
     * 
     * @type {string}
     * @memberof TocRow
     */
    'page'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TocRow
     */
    'height'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TocRow
     */
    'isTocPageBreak'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TocRow
     */
    'uniqueId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TocRow
     */
    'parentId'?: string;
    /**
     * 
     * @type {number}
     * @memberof TocRow
     */
    'level'?: number;
}
/**
 * 
 * @export
 * @interface TocRowListApiResult
 */
export interface TocRowListApiResult {
    /**
     * 
     * @type {Array<TocRow>}
     * @memberof TocRowListApiResult
     */
    'result'?: Array<TocRow> | null;
    /**
     * 
     * @type {boolean}
     * @memberof TocRowListApiResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TocRowListApiResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TocRowListApiResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TocRowListApiResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface TocSettings
 */
export interface TocSettings {
    /**
     * 
     * @type {string}
     * @memberof TocSettings
     */
    'fontName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TocSettings
     */
    'titleFontSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof TocSettings
     */
    'headerFontSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof TocSettings
     */
    'documentFontSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof TocSettings
     */
    'fontSizeDecreasesEachLevel'?: number;
}
/**
 * 
 * @export
 * @interface UnSelectedAllSelectAllRowParam
 */
export interface UnSelectedAllSelectAllRowParam {
    /**
     * 
     * @type {WatchDogParamWeb}
     * @memberof UnSelectedAllSelectAllRowParam
     */
    'param'?: WatchDogParamWeb;
    /**
     * 
     * @type {Array<WatchDogObj>}
     * @memberof UnSelectedAllSelectAllRowParam
     */
    'watchDogObjs'?: Array<WatchDogObj> | null;
}
/**
 * 
 * @export
 * @interface UndoHightLightParam
 */
export interface UndoHightLightParam {
    /**
     * 
     * @type {string}
     * @memberof UndoHightLightParam
     */
    'preState'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UndoHightLightParam
     */
    'viewerPageNo'?: number;
}
/**
 * 
 * @export
 * @interface UndoRemoveReplaceBox
 */
export interface UndoRemoveReplaceBox {
    /**
     * 
     * @type {string}
     * @memberof UndoRemoveReplaceBox
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof UndoRemoveReplaceBox
     */
    'left'?: number;
    /**
     * 
     * @type {number}
     * @memberof UndoRemoveReplaceBox
     */
    'top'?: number;
    /**
     * 
     * @type {number}
     * @memberof UndoRemoveReplaceBox
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof UndoRemoveReplaceBox
     */
    'height'?: number;
    /**
     * 
     * @type {string}
     * @memberof UndoRemoveReplaceBox
     */
    'replaceBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UndoRemoveReplaceBox
     */
    'fontColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof UndoRemoveReplaceBox
     */
    'extractColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof UndoRemoveReplaceBox
     */
    'fontName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UndoRemoveReplaceBox
     */
    'fontSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof UndoRemoveReplaceBox
     */
    'replaceObjId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UndoRemoveReplaceBox
     */
    'pageId'?: number;
}
/**
 * 
 * @export
 * @interface UndoRemoveReplaceBoxPageDataResponse
 */
export interface UndoRemoveReplaceBoxPageDataResponse {
    /**
     * 
     * @type {UndoRemoveReplaceBox}
     * @memberof UndoRemoveReplaceBoxPageDataResponse
     */
    'data'?: UndoRemoveReplaceBox;
    /**
     * 
     * @type {LxPageData}
     * @memberof UndoRemoveReplaceBoxPageDataResponse
     */
    'pageData'?: LxPageData;
}
/**
 * 
 * @export
 * @interface UndoRemoveReplaceBoxPageDataResponseApiResult
 */
export interface UndoRemoveReplaceBoxPageDataResponseApiResult {
    /**
     * 
     * @type {UndoRemoveReplaceBoxPageDataResponse}
     * @memberof UndoRemoveReplaceBoxPageDataResponseApiResult
     */
    'result'?: UndoRemoveReplaceBoxPageDataResponse;
    /**
     * 
     * @type {boolean}
     * @memberof UndoRemoveReplaceBoxPageDataResponseApiResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UndoRemoveReplaceBoxPageDataResponseApiResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UndoRemoveReplaceBoxPageDataResponseApiResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UndoRemoveReplaceBoxPageDataResponseApiResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateAnnotationStampParam
 */
export interface UpdateAnnotationStampParam {
    /**
     * 
     * @type {number}
     * @memberof UpdateAnnotationStampParam
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAnnotationStampParam
     */
    'y'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAnnotationStampParam
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAnnotationStampParam
     */
    'height'?: number;
}
/**
 * 
 * @export
 * @interface UserInfo
 */
export interface UserInfo {
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'concurrencyStamp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'address'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserInfo
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserInfo
     */
    'licenseCompanyId'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserInfo
     */
    'role'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'countryCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'licenseKey'?: string | null;
}
/**
 * 
 * @export
 * @interface UserStatusResult
 */
export interface UserStatusResult {
    /**
     * 
     * @type {RowStatus}
     * @memberof UserStatusResult
     */
    'status'?: RowStatus;
    /**
     * 
     * @type {LicenseReponseResult}
     * @memberof UserStatusResult
     */
    'licenseData'?: LicenseReponseResult;
}


/**
 * 
 * @export
 * @interface UserStatusResultOperationResult
 */
export interface UserStatusResultOperationResult {
    /**
     * 
     * @type {UserStatusResult}
     * @memberof UserStatusResultOperationResult
     */
    'result'?: UserStatusResult;
    /**
     * 
     * @type {boolean}
     * @memberof UserStatusResultOperationResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserStatusResultOperationResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserStatusResultOperationResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserStatusResultOperationResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface WatchDogNode
 */
export interface WatchDogNode {
    /**
     * 
     * @type {string}
     * @memberof WatchDogNode
     */
    'searchTypePath'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WatchDogNode
     */
    'word'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WatchDogNode
     */
    'selectedCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof WatchDogNode
     */
    'replacePercent'?: number;
    /**
     * 
     * @type {number}
     * @memberof WatchDogNode
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof WatchDogNode
     */
    'lastSelectedTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof WatchDogNode
     */
    'highlightOnPublishCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof WatchDogNode
     */
    'searchTypeKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WatchDogNode
     */
    'tagId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WatchDogNode
     */
    'id'?: string;
    /**
     * 
     * @type {Array<WatchDogNode>}
     * @memberof WatchDogNode
     */
    'children'?: Array<WatchDogNode> | null;
}
/**
 * 
 * @export
 * @interface WatchDogObj
 */
export interface WatchDogObj {
    /**
     * 
     * @type {string}
     * @memberof WatchDogObj
     */
    'searchTypePath'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WatchDogObj
     */
    'word'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WatchDogObj
     */
    'selectedCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof WatchDogObj
     */
    'replacePercent'?: number;
    /**
     * 
     * @type {number}
     * @memberof WatchDogObj
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof WatchDogObj
     */
    'lastSelectedTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof WatchDogObj
     */
    'highlightOnPublishCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof WatchDogObj
     */
    'searchTypeKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WatchDogObj
     */
    'tagId'?: string | null;
}
/**
 * 
 * @export
 * @interface WatchDogParamWeb
 */
export interface WatchDogParamWeb {
    /**
     * 
     * @type {boolean}
     * @memberof WatchDogParamWeb
     */
    'wholeWord'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WatchDogParamWeb
     */
    'matchCase'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WatchDogParamWeb
     */
    'combine'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WatchDogParamWeb
     */
    'orderBy'?: string | null;
}

/**
 * BlankFileApi - axios parameter creator
 * @export
 */
export const BlankFileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateBlankFileParam} [createBlankFileParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCreateBlankFilePost: async (createBlankFileParam?: CreateBlankFileParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/CreateBlankFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBlankFileParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BlankFileApi - functional programming interface
 * @export
 */
export const BlankFileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BlankFileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateBlankFileParam} [createBlankFileParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractCreateBlankFilePost(createBlankFileParam?: CreateBlankFileParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReOrderTOCResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractCreateBlankFilePost(createBlankFileParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BlankFileApi.apiLegalxtractCreateBlankFilePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BlankFileApi - factory interface
 * @export
 */
export const BlankFileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BlankFileApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateBlankFileParam} [createBlankFileParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCreateBlankFilePost(createBlankFileParam?: CreateBlankFileParam, options?: any): AxiosPromise<ReOrderTOCResponse> {
            return localVarFp.apiLegalxtractCreateBlankFilePost(createBlankFileParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BlankFileApi - object-oriented interface
 * @export
 * @class BlankFileApi
 * @extends {BaseAPI}
 */
export class BlankFileApi extends BaseAPI {
    /**
     * 
     * @param {CreateBlankFileParam} [createBlankFileParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlankFileApi
     */
    public apiLegalxtractCreateBlankFilePost(createBlankFileParam?: CreateBlankFileParam, options?: RawAxiosRequestConfig) {
        return BlankFileApiFp(this.configuration).apiLegalxtractCreateBlankFilePost(createBlankFileParam, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BlankPageApi - axios parameter creator
 * @export
 */
export const BlankPageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {boolean} [isShowBlankPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractProcessBlankPageGet: async (isShowBlankPage?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ProcessBlankPage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (isShowBlankPage !== undefined) {
                localVarQueryParameter['isShowBlankPage'] = isShowBlankPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BlankPageApi - functional programming interface
 * @export
 */
export const BlankPageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BlankPageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {boolean} [isShowBlankPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractProcessBlankPageGet(isShowBlankPage?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlankPageProcessResponseApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractProcessBlankPageGet(isShowBlankPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BlankPageApi.apiLegalxtractProcessBlankPageGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BlankPageApi - factory interface
 * @export
 */
export const BlankPageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BlankPageApiFp(configuration)
    return {
        /**
         * 
         * @param {boolean} [isShowBlankPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractProcessBlankPageGet(isShowBlankPage?: boolean, options?: any): AxiosPromise<BlankPageProcessResponseApiResult> {
            return localVarFp.apiLegalxtractProcessBlankPageGet(isShowBlankPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BlankPageApi - object-oriented interface
 * @export
 * @class BlankPageApi
 * @extends {BaseAPI}
 */
export class BlankPageApi extends BaseAPI {
    /**
     * 
     * @param {boolean} [isShowBlankPage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlankPageApi
     */
    public apiLegalxtractProcessBlankPageGet(isShowBlankPage?: boolean, options?: RawAxiosRequestConfig) {
        return BlankPageApiFp(this.configuration).apiLegalxtractProcessBlankPageGet(isShowBlankPage, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BookMarkApi - axios parameter creator
 * @export
 */
export const BookMarkApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [pageId] 
         * @param {BookmarkObj} [bookmarkObj] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddPageToBookMarkPut: async (pageId?: string, bookmarkObj?: BookmarkObj, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/AddPageToBookMark`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageId !== undefined) {
                localVarQueryParameter['pageId'] = pageId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookmarkObj, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteBookMarkDelete: async (id?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/DeleteBookMark`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetAllBookmarkObjsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetAllBookmarkObjs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [bookmarkId] 
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractUpdateBookMarkPut: async (bookmarkId?: string, title?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/UpdateBookMark`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (bookmarkId !== undefined) {
                localVarQueryParameter['bookmarkId'] = bookmarkId;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BookMarkApi - functional programming interface
 * @export
 */
export const BookMarkApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BookMarkApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [pageId] 
         * @param {BookmarkObj} [bookmarkObj] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractAddPageToBookMarkPut(pageId?: string, bookmarkObj?: BookmarkObj, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractAddPageToBookMarkPut(pageId, bookmarkObj, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookMarkApi.apiLegalxtractAddPageToBookMarkPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractDeleteBookMarkDelete(id?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractDeleteBookMarkDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookMarkApi.apiLegalxtractDeleteBookMarkDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetAllBookmarkObjsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookmarkObjListApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetAllBookmarkObjsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookMarkApi.apiLegalxtractGetAllBookmarkObjsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [bookmarkId] 
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractUpdateBookMarkPut(bookmarkId?: string, title?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractUpdateBookMarkPut(bookmarkId, title, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookMarkApi.apiLegalxtractUpdateBookMarkPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BookMarkApi - factory interface
 * @export
 */
export const BookMarkApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BookMarkApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [pageId] 
         * @param {BookmarkObj} [bookmarkObj] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddPageToBookMarkPut(pageId?: string, bookmarkObj?: BookmarkObj, options?: any): AxiosPromise<PageApiResult> {
            return localVarFp.apiLegalxtractAddPageToBookMarkPut(pageId, bookmarkObj, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteBookMarkDelete(id?: string, options?: any): AxiosPromise<PageApiResult> {
            return localVarFp.apiLegalxtractDeleteBookMarkDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetAllBookmarkObjsGet(options?: any): AxiosPromise<BookmarkObjListApiResult> {
            return localVarFp.apiLegalxtractGetAllBookmarkObjsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [bookmarkId] 
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractUpdateBookMarkPut(bookmarkId?: string, title?: string, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.apiLegalxtractUpdateBookMarkPut(bookmarkId, title, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BookMarkApi - object-oriented interface
 * @export
 * @class BookMarkApi
 * @extends {BaseAPI}
 */
export class BookMarkApi extends BaseAPI {
    /**
     * 
     * @param {string} [pageId] 
     * @param {BookmarkObj} [bookmarkObj] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookMarkApi
     */
    public apiLegalxtractAddPageToBookMarkPut(pageId?: string, bookmarkObj?: BookmarkObj, options?: RawAxiosRequestConfig) {
        return BookMarkApiFp(this.configuration).apiLegalxtractAddPageToBookMarkPut(pageId, bookmarkObj, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookMarkApi
     */
    public apiLegalxtractDeleteBookMarkDelete(id?: string, options?: RawAxiosRequestConfig) {
        return BookMarkApiFp(this.configuration).apiLegalxtractDeleteBookMarkDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookMarkApi
     */
    public apiLegalxtractGetAllBookmarkObjsGet(options?: RawAxiosRequestConfig) {
        return BookMarkApiFp(this.configuration).apiLegalxtractGetAllBookmarkObjsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [bookmarkId] 
     * @param {string} [title] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookMarkApi
     */
    public apiLegalxtractUpdateBookMarkPut(bookmarkId?: string, title?: string, options?: RawAxiosRequestConfig) {
        return BookMarkApiFp(this.configuration).apiLegalxtractUpdateBookMarkPut(bookmarkId, title, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BoxApi - axios parameter creator
 * @export
 */
export const BoxApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {AddBoxParam} [addBoxParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddBoxPost: async (viewerPageNo?: number, addBoxParam?: AddBoxParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/AddBox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (viewerPageNo !== undefined) {
                localVarQueryParameter['viewerPageNo'] = viewerPageNo;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addBoxParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {AddBoxParam} [addBoxParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractEditBoxPost: async (viewerPageNo?: number, addBoxParam?: AddBoxParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/EditBox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (viewerPageNo !== undefined) {
                localVarQueryParameter['viewerPageNo'] = viewerPageNo;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addBoxParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [boxId] 
         * @param {number} [viewerPageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRemoveBoxPost: async (boxId?: string, viewerPageNo?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/RemoveBox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (boxId !== undefined) {
                localVarQueryParameter['boxId'] = boxId;
            }

            if (viewerPageNo !== undefined) {
                localVarQueryParameter['viewerPageNo'] = viewerPageNo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BoxApi - functional programming interface
 * @export
 */
export const BoxApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BoxApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {AddBoxParam} [addBoxParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractAddBoxPost(viewerPageNo?: number, addBoxParam?: AddBoxParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoxObjBoxResponseApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractAddBoxPost(viewerPageNo, addBoxParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BoxApi.apiLegalxtractAddBoxPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {AddBoxParam} [addBoxParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractEditBoxPost(viewerPageNo?: number, addBoxParam?: AddBoxParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanBoxResponseApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractEditBoxPost(viewerPageNo, addBoxParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BoxApi.apiLegalxtractEditBoxPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [boxId] 
         * @param {number} [viewerPageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractRemoveBoxPost(boxId?: string, viewerPageNo?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddBoxParamBoxResponseApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractRemoveBoxPost(boxId, viewerPageNo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BoxApi.apiLegalxtractRemoveBoxPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BoxApi - factory interface
 * @export
 */
export const BoxApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BoxApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {AddBoxParam} [addBoxParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddBoxPost(viewerPageNo?: number, addBoxParam?: AddBoxParam, options?: any): AxiosPromise<BoxObjBoxResponseApiResult> {
            return localVarFp.apiLegalxtractAddBoxPost(viewerPageNo, addBoxParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {AddBoxParam} [addBoxParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractEditBoxPost(viewerPageNo?: number, addBoxParam?: AddBoxParam, options?: any): AxiosPromise<BooleanBoxResponseApiResult> {
            return localVarFp.apiLegalxtractEditBoxPost(viewerPageNo, addBoxParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [boxId] 
         * @param {number} [viewerPageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRemoveBoxPost(boxId?: string, viewerPageNo?: number, options?: any): AxiosPromise<AddBoxParamBoxResponseApiResult> {
            return localVarFp.apiLegalxtractRemoveBoxPost(boxId, viewerPageNo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BoxApi - object-oriented interface
 * @export
 * @class BoxApi
 * @extends {BaseAPI}
 */
export class BoxApi extends BaseAPI {
    /**
     * 
     * @param {number} [viewerPageNo] 
     * @param {AddBoxParam} [addBoxParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoxApi
     */
    public apiLegalxtractAddBoxPost(viewerPageNo?: number, addBoxParam?: AddBoxParam, options?: RawAxiosRequestConfig) {
        return BoxApiFp(this.configuration).apiLegalxtractAddBoxPost(viewerPageNo, addBoxParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [viewerPageNo] 
     * @param {AddBoxParam} [addBoxParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoxApi
     */
    public apiLegalxtractEditBoxPost(viewerPageNo?: number, addBoxParam?: AddBoxParam, options?: RawAxiosRequestConfig) {
        return BoxApiFp(this.configuration).apiLegalxtractEditBoxPost(viewerPageNo, addBoxParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [boxId] 
     * @param {number} [viewerPageNo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoxApi
     */
    public apiLegalxtractRemoveBoxPost(boxId?: string, viewerPageNo?: number, options?: RawAxiosRequestConfig) {
        return BoxApiFp(this.configuration).apiLegalxtractRemoveBoxPost(boxId, viewerPageNo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CheckDuplicateApi - axios parameter creator
 * @export
 */
export const CheckDuplicateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<PageStatus>} [pageStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractApplyStateForPageDuplicatePost: async (pageStatus?: Array<PageStatus>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ApplyStateForPageDuplicate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pageStatus, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CheckDuplicateSettings} [checkDuplicateSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCheckDuplicatePost: async (checkDuplicateSettings?: CheckDuplicateSettings, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/CheckDuplicate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkDuplicateSettings, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCloseDuplicateGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/CloseDuplicate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractShowDuplicateGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ShowDuplicate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CheckDuplicateApi - functional programming interface
 * @export
 */
export const CheckDuplicateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CheckDuplicateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<PageStatus>} [pageStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractApplyStateForPageDuplicatePost(pageStatus?: Array<PageStatus>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentInfoApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractApplyStateForPageDuplicatePost(pageStatus, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CheckDuplicateApi.apiLegalxtractApplyStateForPageDuplicatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CheckDuplicateSettings} [checkDuplicateSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractCheckDuplicatePost(checkDuplicateSettings?: CheckDuplicateSettings, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringPageStatusListDictionaryApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractCheckDuplicatePost(checkDuplicateSettings, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CheckDuplicateApi.apiLegalxtractCheckDuplicatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractCloseDuplicateGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractCloseDuplicateGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CheckDuplicateApi.apiLegalxtractCloseDuplicateGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractShowDuplicateGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractShowDuplicateGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CheckDuplicateApi.apiLegalxtractShowDuplicateGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CheckDuplicateApi - factory interface
 * @export
 */
export const CheckDuplicateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CheckDuplicateApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<PageStatus>} [pageStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractApplyStateForPageDuplicatePost(pageStatus?: Array<PageStatus>, options?: any): AxiosPromise<DocumentInfoApiResult> {
            return localVarFp.apiLegalxtractApplyStateForPageDuplicatePost(pageStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CheckDuplicateSettings} [checkDuplicateSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCheckDuplicatePost(checkDuplicateSettings?: CheckDuplicateSettings, options?: any): AxiosPromise<StringPageStatusListDictionaryApiResult> {
            return localVarFp.apiLegalxtractCheckDuplicatePost(checkDuplicateSettings, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCloseDuplicateGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractCloseDuplicateGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractShowDuplicateGet(options?: any): AxiosPromise<File> {
            return localVarFp.apiLegalxtractShowDuplicateGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CheckDuplicateApi - object-oriented interface
 * @export
 * @class CheckDuplicateApi
 * @extends {BaseAPI}
 */
export class CheckDuplicateApi extends BaseAPI {
    /**
     * 
     * @param {Array<PageStatus>} [pageStatus] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckDuplicateApi
     */
    public apiLegalxtractApplyStateForPageDuplicatePost(pageStatus?: Array<PageStatus>, options?: RawAxiosRequestConfig) {
        return CheckDuplicateApiFp(this.configuration).apiLegalxtractApplyStateForPageDuplicatePost(pageStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CheckDuplicateSettings} [checkDuplicateSettings] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckDuplicateApi
     */
    public apiLegalxtractCheckDuplicatePost(checkDuplicateSettings?: CheckDuplicateSettings, options?: RawAxiosRequestConfig) {
        return CheckDuplicateApiFp(this.configuration).apiLegalxtractCheckDuplicatePost(checkDuplicateSettings, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckDuplicateApi
     */
    public apiLegalxtractCloseDuplicateGet(options?: RawAxiosRequestConfig) {
        return CheckDuplicateApiFp(this.configuration).apiLegalxtractCloseDuplicateGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckDuplicateApi
     */
    public apiLegalxtractShowDuplicateGet(options?: RawAxiosRequestConfig) {
        return CheckDuplicateApiFp(this.configuration).apiLegalxtractShowDuplicateGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CloseFileApi - axios parameter creator
 * @export
 */
export const CloseFileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCloseAllFileGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/CloseAllFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [tabId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCloseOneFileGet: async (tabId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/CloseOneFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tabId !== undefined) {
                localVarQueryParameter['tabId'] = tabId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CloseFileApi - functional programming interface
 * @export
 */
export const CloseFileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CloseFileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractCloseAllFileGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentInfoApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractCloseAllFileGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CloseFileApi.apiLegalxtractCloseAllFileGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [tabId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractCloseOneFileGet(tabId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentInfoApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractCloseOneFileGet(tabId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CloseFileApi.apiLegalxtractCloseOneFileGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CloseFileApi - factory interface
 * @export
 */
export const CloseFileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CloseFileApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCloseAllFileGet(options?: any): AxiosPromise<DocumentInfoApiResult> {
            return localVarFp.apiLegalxtractCloseAllFileGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [tabId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCloseOneFileGet(tabId?: string, options?: any): AxiosPromise<DocumentInfoApiResult> {
            return localVarFp.apiLegalxtractCloseOneFileGet(tabId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CloseFileApi - object-oriented interface
 * @export
 * @class CloseFileApi
 * @extends {BaseAPI}
 */
export class CloseFileApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CloseFileApi
     */
    public apiLegalxtractCloseAllFileGet(options?: RawAxiosRequestConfig) {
        return CloseFileApiFp(this.configuration).apiLegalxtractCloseAllFileGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [tabId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CloseFileApi
     */
    public apiLegalxtractCloseOneFileGet(tabId?: string, options?: RawAxiosRequestConfig) {
        return CloseFileApiFp(this.configuration).apiLegalxtractCloseOneFileGet(tabId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CommentApi - axios parameter creator
 * @export
 */
export const CommentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [boxId] 
         * @param {number} [viewerPageNo] 
         * @param {CommentParam} [commentParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddBoxCommentPut: async (boxId?: string, viewerPageNo?: number, commentParam?: CommentParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/AddBoxComment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (boxId !== undefined) {
                localVarQueryParameter['boxId'] = boxId;
            }

            if (viewerPageNo !== undefined) {
                localVarQueryParameter['viewerPageNo'] = viewerPageNo;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [parentId] 
         * @param {number} [viewerPageNo] 
         * @param {CommentParam} [commentParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddReplyCommentPut: async (parentId?: string, viewerPageNo?: number, commentParam?: CommentParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/AddReplyComment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (parentId !== undefined) {
                localVarQueryParameter['parentId'] = parentId;
            }

            if (viewerPageNo !== undefined) {
                localVarQueryParameter['viewerPageNo'] = viewerPageNo;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [x] 
         * @param {number} [y] 
         * @param {number} [viewerPageNo] 
         * @param {CommentParam} [commentParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddTextCommentPut: async (x?: number, y?: number, viewerPageNo?: number, commentParam?: CommentParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/AddTextComment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x !== undefined) {
                localVarQueryParameter['x'] = x;
            }

            if (y !== undefined) {
                localVarQueryParameter['y'] = y;
            }

            if (viewerPageNo !== undefined) {
                localVarQueryParameter['viewerPageNo'] = viewerPageNo;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [viewerPageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteCommentDelete: async (id?: string, viewerPageNo?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/DeleteComment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (viewerPageNo !== undefined) {
                localVarQueryParameter['viewerPageNo'] = viewerPageNo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {CommentParam} [commentParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractEditCommentPost: async (viewerPageNo?: number, commentParam?: CommentParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/EditComment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (viewerPageNo !== undefined) {
                localVarQueryParameter['viewerPageNo'] = viewerPageNo;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CommentOrderByEnum} [commentOrderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetListCommentGet: async (commentOrderBy?: CommentOrderByEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetListComment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (commentOrderBy !== undefined) {
                localVarQueryParameter['commentOrderBy'] = commentOrderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {CommentOrderByEnum} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractPrintCommentGet: async (fileName?: string, orderBy?: CommentOrderByEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/PrintComment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommentApi - functional programming interface
 * @export
 */
export const CommentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [boxId] 
         * @param {number} [viewerPageNo] 
         * @param {CommentParam} [commentParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractAddBoxCommentPut(boxId?: string, viewerPageNo?: number, commentParam?: CommentParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentObjDisplayWebApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractAddBoxCommentPut(boxId, viewerPageNo, commentParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommentApi.apiLegalxtractAddBoxCommentPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [parentId] 
         * @param {number} [viewerPageNo] 
         * @param {CommentParam} [commentParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractAddReplyCommentPut(parentId?: string, viewerPageNo?: number, commentParam?: CommentParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentObjDisplayWebApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractAddReplyCommentPut(parentId, viewerPageNo, commentParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommentApi.apiLegalxtractAddReplyCommentPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [x] 
         * @param {number} [y] 
         * @param {number} [viewerPageNo] 
         * @param {CommentParam} [commentParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractAddTextCommentPut(x?: number, y?: number, viewerPageNo?: number, commentParam?: CommentParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentObjDisplayWebApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractAddTextCommentPut(x, y, viewerPageNo, commentParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommentApi.apiLegalxtractAddTextCommentPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [viewerPageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractDeleteCommentDelete(id?: string, viewerPageNo?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractDeleteCommentDelete(id, viewerPageNo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommentApi.apiLegalxtractDeleteCommentDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {CommentParam} [commentParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractEditCommentPost(viewerPageNo?: number, commentParam?: CommentParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentObjDisplayWebApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractEditCommentPost(viewerPageNo, commentParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommentApi.apiLegalxtractEditCommentPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CommentOrderByEnum} [commentOrderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetListCommentGet(commentOrderBy?: CommentOrderByEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentObjDisplayWebListApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetListCommentGet(commentOrderBy, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommentApi.apiLegalxtractGetListCommentGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {CommentOrderByEnum} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractPrintCommentGet(fileName?: string, orderBy?: CommentOrderByEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractPrintCommentGet(fileName, orderBy, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommentApi.apiLegalxtractPrintCommentGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CommentApi - factory interface
 * @export
 */
export const CommentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommentApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [boxId] 
         * @param {number} [viewerPageNo] 
         * @param {CommentParam} [commentParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddBoxCommentPut(boxId?: string, viewerPageNo?: number, commentParam?: CommentParam, options?: any): AxiosPromise<CommentObjDisplayWebApiResult> {
            return localVarFp.apiLegalxtractAddBoxCommentPut(boxId, viewerPageNo, commentParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [parentId] 
         * @param {number} [viewerPageNo] 
         * @param {CommentParam} [commentParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddReplyCommentPut(parentId?: string, viewerPageNo?: number, commentParam?: CommentParam, options?: any): AxiosPromise<CommentObjDisplayWebApiResult> {
            return localVarFp.apiLegalxtractAddReplyCommentPut(parentId, viewerPageNo, commentParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [x] 
         * @param {number} [y] 
         * @param {number} [viewerPageNo] 
         * @param {CommentParam} [commentParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddTextCommentPut(x?: number, y?: number, viewerPageNo?: number, commentParam?: CommentParam, options?: any): AxiosPromise<CommentObjDisplayWebApiResult> {
            return localVarFp.apiLegalxtractAddTextCommentPut(x, y, viewerPageNo, commentParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [viewerPageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteCommentDelete(id?: string, viewerPageNo?: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLegalxtractDeleteCommentDelete(id, viewerPageNo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {CommentParam} [commentParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractEditCommentPost(viewerPageNo?: number, commentParam?: CommentParam, options?: any): AxiosPromise<CommentObjDisplayWebApiResult> {
            return localVarFp.apiLegalxtractEditCommentPost(viewerPageNo, commentParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CommentOrderByEnum} [commentOrderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetListCommentGet(commentOrderBy?: CommentOrderByEnum, options?: any): AxiosPromise<CommentObjDisplayWebListApiResult> {
            return localVarFp.apiLegalxtractGetListCommentGet(commentOrderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {CommentOrderByEnum} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractPrintCommentGet(fileName?: string, orderBy?: CommentOrderByEnum, options?: any): AxiosPromise<File> {
            return localVarFp.apiLegalxtractPrintCommentGet(fileName, orderBy, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommentApi - object-oriented interface
 * @export
 * @class CommentApi
 * @extends {BaseAPI}
 */
export class CommentApi extends BaseAPI {
    /**
     * 
     * @param {string} [boxId] 
     * @param {number} [viewerPageNo] 
     * @param {CommentParam} [commentParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public apiLegalxtractAddBoxCommentPut(boxId?: string, viewerPageNo?: number, commentParam?: CommentParam, options?: RawAxiosRequestConfig) {
        return CommentApiFp(this.configuration).apiLegalxtractAddBoxCommentPut(boxId, viewerPageNo, commentParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [parentId] 
     * @param {number} [viewerPageNo] 
     * @param {CommentParam} [commentParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public apiLegalxtractAddReplyCommentPut(parentId?: string, viewerPageNo?: number, commentParam?: CommentParam, options?: RawAxiosRequestConfig) {
        return CommentApiFp(this.configuration).apiLegalxtractAddReplyCommentPut(parentId, viewerPageNo, commentParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [x] 
     * @param {number} [y] 
     * @param {number} [viewerPageNo] 
     * @param {CommentParam} [commentParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public apiLegalxtractAddTextCommentPut(x?: number, y?: number, viewerPageNo?: number, commentParam?: CommentParam, options?: RawAxiosRequestConfig) {
        return CommentApiFp(this.configuration).apiLegalxtractAddTextCommentPut(x, y, viewerPageNo, commentParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {number} [viewerPageNo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public apiLegalxtractDeleteCommentDelete(id?: string, viewerPageNo?: number, options?: RawAxiosRequestConfig) {
        return CommentApiFp(this.configuration).apiLegalxtractDeleteCommentDelete(id, viewerPageNo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [viewerPageNo] 
     * @param {CommentParam} [commentParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public apiLegalxtractEditCommentPost(viewerPageNo?: number, commentParam?: CommentParam, options?: RawAxiosRequestConfig) {
        return CommentApiFp(this.configuration).apiLegalxtractEditCommentPost(viewerPageNo, commentParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CommentOrderByEnum} [commentOrderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public apiLegalxtractGetListCommentGet(commentOrderBy?: CommentOrderByEnum, options?: RawAxiosRequestConfig) {
        return CommentApiFp(this.configuration).apiLegalxtractGetListCommentGet(commentOrderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fileName] 
     * @param {CommentOrderByEnum} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public apiLegalxtractPrintCommentGet(fileName?: string, orderBy?: CommentOrderByEnum, options?: RawAxiosRequestConfig) {
        return CommentApiFp(this.configuration).apiLegalxtractPrintCommentGet(fileName, orderBy, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DocumentApi - axios parameter creator
 * @export
 */
export const DocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetDocumentFromCacheGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetDocumentFromCache`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetDocumentInfoGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetDocumentInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentApi - functional programming interface
 * @export
 */
export const DocumentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetDocumentFromCacheGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetDocumentFromCacheGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentApi.apiLegalxtractGetDocumentFromCacheGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetDocumentInfoGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentInfoApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetDocumentInfoGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentApi.apiLegalxtractGetDocumentInfoGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DocumentApi - factory interface
 * @export
 */
export const DocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetDocumentFromCacheGet(options?: any): AxiosPromise<File> {
            return localVarFp.apiLegalxtractGetDocumentFromCacheGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetDocumentInfoGet(options?: any): AxiosPromise<DocumentInfoApiResult> {
            return localVarFp.apiLegalxtractGetDocumentInfoGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentApi - object-oriented interface
 * @export
 * @class DocumentApi
 * @extends {BaseAPI}
 */
export class DocumentApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public apiLegalxtractGetDocumentFromCacheGet(options?: RawAxiosRequestConfig) {
        return DocumentApiFp(this.configuration).apiLegalxtractGetDocumentFromCacheGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public apiLegalxtractGetDocumentInfoGet(options?: RawAxiosRequestConfig) {
        return DocumentApiFp(this.configuration).apiLegalxtractGetDocumentInfoGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FontApi - axios parameter creator
 * @export
 */
export const FontApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetListFontGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetListFont`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FontApi - functional programming interface
 * @export
 */
export const FontApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FontApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetListFontGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetListFontGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FontApi.apiLegalxtractGetListFontGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FontApi - factory interface
 * @export
 */
export const FontApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FontApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetListFontGet(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiLegalxtractGetListFontGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FontApi - object-oriented interface
 * @export
 * @class FontApi
 * @extends {BaseAPI}
 */
export class FontApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FontApi
     */
    public apiLegalxtractGetListFontGet(options?: RawAxiosRequestConfig) {
        return FontApiFp(this.configuration).apiLegalxtractGetListFontGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FrontPageApi - axios parameter creator
 * @export
 */
export const FrontPageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {FrontPageContentInfo} [frontPageContentInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddFrontPageContentPost: async (frontPageContentInfo?: FrontPageContentInfo, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/AddFrontPageContent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(frontPageContentInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [addFrontPage] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractProcessFrontPagePost: async (addFrontPage?: boolean, file?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ProcessFrontPage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (addFrontPage !== undefined) {
                localVarQueryParameter['addFrontPage'] = addFrontPage;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRemoveAnnotFrontPagePost: async (id?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/RemoveAnnotFrontPage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FrontPageApi - functional programming interface
 * @export
 */
export const FrontPageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FrontPageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {FrontPageContentInfo} [frontPageContentInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractAddFrontPageContentPost(frontPageContentInfo?: FrontPageContentInfo, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractAddFrontPageContentPost(frontPageContentInfo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontPageApi.apiLegalxtractAddFrontPageContentPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {boolean} [addFrontPage] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractProcessFrontPagePost(addFrontPage?: boolean, file?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentInfoPageDataResponseApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractProcessFrontPagePost(addFrontPage, file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontPageApi.apiLegalxtractProcessFrontPagePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractRemoveAnnotFrontPagePost(id?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractRemoveAnnotFrontPagePost(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontPageApi.apiLegalxtractRemoveAnnotFrontPagePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FrontPageApi - factory interface
 * @export
 */
export const FrontPageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FrontPageApiFp(configuration)
    return {
        /**
         * 
         * @param {FrontPageContentInfo} [frontPageContentInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddFrontPageContentPost(frontPageContentInfo?: FrontPageContentInfo, options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractAddFrontPageContentPost(frontPageContentInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [addFrontPage] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractProcessFrontPagePost(addFrontPage?: boolean, file?: File, options?: any): AxiosPromise<DocumentInfoPageDataResponseApiResult> {
            return localVarFp.apiLegalxtractProcessFrontPagePost(addFrontPage, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRemoveAnnotFrontPagePost(id?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractRemoveAnnotFrontPagePost(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FrontPageApi - object-oriented interface
 * @export
 * @class FrontPageApi
 * @extends {BaseAPI}
 */
export class FrontPageApi extends BaseAPI {
    /**
     * 
     * @param {FrontPageContentInfo} [frontPageContentInfo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontPageApi
     */
    public apiLegalxtractAddFrontPageContentPost(frontPageContentInfo?: FrontPageContentInfo, options?: RawAxiosRequestConfig) {
        return FrontPageApiFp(this.configuration).apiLegalxtractAddFrontPageContentPost(frontPageContentInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [addFrontPage] 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontPageApi
     */
    public apiLegalxtractProcessFrontPagePost(addFrontPage?: boolean, file?: File, options?: RawAxiosRequestConfig) {
        return FrontPageApiFp(this.configuration).apiLegalxtractProcessFrontPagePost(addFrontPage, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontPageApi
     */
    public apiLegalxtractRemoveAnnotFrontPagePost(id?: string, options?: RawAxiosRequestConfig) {
        return FrontPageApiFp(this.configuration).apiLegalxtractRemoveAnnotFrontPagePost(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * HighlightApi - axios parameter creator
 * @export
 */
export const HighlightApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {HighLightUnHighLightLine} [highLightUnHighLightLine] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractHighLightLinePost: async (highLightUnHighLightLine?: HighLightUnHighLightLine, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/HighLightLine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(highLightUnHighLightLine, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {HighLightOrUnHighLightClickWordsParam} [highLightOrUnHighLightClickWordsParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractHighLightOrUnHighLightClickWordsPost: async (highLightOrUnHighLightClickWordsParam?: HighLightOrUnHighLightClickWordsParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/HighLightOrUnHighLightClickWords`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(highLightOrUnHighLightClickWordsParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {HighLightOrUnHighLight} [highLightOrUnHighLight] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractHighLightOrUnHighLightMaskPost: async (highLightOrUnHighLight?: HighLightOrUnHighLight, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/HighLightOrUnHighLightMask`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(highLightOrUnHighLight, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {HighLightOrUnHighLight} [highLightOrUnHighLight] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractHighLightOrUnHighLightSectionPost: async (highLightOrUnHighLight?: HighLightOrUnHighLight, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/HighLightOrUnHighLightSection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(highLightOrUnHighLight, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {HighLightUnHighLightPage} [highLightUnHighLightPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractHighLightPagePost: async (highLightUnHighLightPage?: HighLightUnHighLightPage, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/HighLightPage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(highLightUnHighLightPage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SelectionTagParam} [selectionTagParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractHighLightTagPost: async (selectionTagParam?: SelectionTagParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/HighLightTag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(selectionTagParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {HighlightSearchResultsParam} [highlightSearchResultsParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractHighLightUnHightSearchResultsPost: async (highlightSearchResultsParam?: HighlightSearchResultsParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/HighLightUnHightSearchResults`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(highlightSearchResultsParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UndoHightLightParam} [undoHightLightParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractUndoHightLightPost: async (undoHightLightParam?: UndoHightLightParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/UndoHightLight`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(undoHightLightParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HighlightApi - functional programming interface
 * @export
 */
export const HighlightApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HighlightApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {HighLightUnHighLightLine} [highLightUnHighLightLine] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractHighLightLinePost(highLightUnHighLightLine?: HighLightUnHighLightLine, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HighLightResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractHighLightLinePost(highLightUnHighLightLine, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HighlightApi.apiLegalxtractHighLightLinePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {HighLightOrUnHighLightClickWordsParam} [highLightOrUnHighLightClickWordsParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractHighLightOrUnHighLightClickWordsPost(highLightOrUnHighLightClickWordsParam?: HighLightOrUnHighLightClickWordsParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HighLightResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractHighLightOrUnHighLightClickWordsPost(highLightOrUnHighLightClickWordsParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HighlightApi.apiLegalxtractHighLightOrUnHighLightClickWordsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {HighLightOrUnHighLight} [highLightOrUnHighLight] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractHighLightOrUnHighLightMaskPost(highLightOrUnHighLight?: HighLightOrUnHighLight, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HighLightResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractHighLightOrUnHighLightMaskPost(highLightOrUnHighLight, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HighlightApi.apiLegalxtractHighLightOrUnHighLightMaskPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {HighLightOrUnHighLight} [highLightOrUnHighLight] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractHighLightOrUnHighLightSectionPost(highLightOrUnHighLight?: HighLightOrUnHighLight, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HighLightResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractHighLightOrUnHighLightSectionPost(highLightOrUnHighLight, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HighlightApi.apiLegalxtractHighLightOrUnHighLightSectionPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {HighLightUnHighLightPage} [highLightUnHighLightPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractHighLightPagePost(highLightUnHighLightPage?: HighLightUnHighLightPage, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HighLightResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractHighLightPagePost(highLightUnHighLightPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HighlightApi.apiLegalxtractHighLightPagePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SelectionTagParam} [selectionTagParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractHighLightTagPost(selectionTagParam?: SelectionTagParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidMarkedInfoWithIdListDictionaryPageDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractHighLightTagPost(selectionTagParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HighlightApi.apiLegalxtractHighLightTagPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {HighlightSearchResultsParam} [highlightSearchResultsParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractHighLightUnHightSearchResultsPost(highlightSearchResultsParam?: HighlightSearchResultsParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HighLightSearchResultsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractHighLightUnHightSearchResultsPost(highlightSearchResultsParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HighlightApi.apiLegalxtractHighLightUnHightSearchResultsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UndoHightLightParam} [undoHightLightParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractUndoHightLightPost(undoHightLightParam?: UndoHightLightParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LxPageData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractUndoHightLightPost(undoHightLightParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HighlightApi.apiLegalxtractUndoHightLightPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * HighlightApi - factory interface
 * @export
 */
export const HighlightApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HighlightApiFp(configuration)
    return {
        /**
         * 
         * @param {HighLightUnHighLightLine} [highLightUnHighLightLine] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractHighLightLinePost(highLightUnHighLightLine?: HighLightUnHighLightLine, options?: any): AxiosPromise<HighLightResult> {
            return localVarFp.apiLegalxtractHighLightLinePost(highLightUnHighLightLine, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {HighLightOrUnHighLightClickWordsParam} [highLightOrUnHighLightClickWordsParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractHighLightOrUnHighLightClickWordsPost(highLightOrUnHighLightClickWordsParam?: HighLightOrUnHighLightClickWordsParam, options?: any): AxiosPromise<HighLightResult> {
            return localVarFp.apiLegalxtractHighLightOrUnHighLightClickWordsPost(highLightOrUnHighLightClickWordsParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {HighLightOrUnHighLight} [highLightOrUnHighLight] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractHighLightOrUnHighLightMaskPost(highLightOrUnHighLight?: HighLightOrUnHighLight, options?: any): AxiosPromise<HighLightResult> {
            return localVarFp.apiLegalxtractHighLightOrUnHighLightMaskPost(highLightOrUnHighLight, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {HighLightOrUnHighLight} [highLightOrUnHighLight] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractHighLightOrUnHighLightSectionPost(highLightOrUnHighLight?: HighLightOrUnHighLight, options?: any): AxiosPromise<HighLightResult> {
            return localVarFp.apiLegalxtractHighLightOrUnHighLightSectionPost(highLightOrUnHighLight, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {HighLightUnHighLightPage} [highLightUnHighLightPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractHighLightPagePost(highLightUnHighLightPage?: HighLightUnHighLightPage, options?: any): AxiosPromise<HighLightResult> {
            return localVarFp.apiLegalxtractHighLightPagePost(highLightUnHighLightPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SelectionTagParam} [selectionTagParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractHighLightTagPost(selectionTagParam?: SelectionTagParam, options?: any): AxiosPromise<GuidMarkedInfoWithIdListDictionaryPageDataResponse> {
            return localVarFp.apiLegalxtractHighLightTagPost(selectionTagParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {HighlightSearchResultsParam} [highlightSearchResultsParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractHighLightUnHightSearchResultsPost(highlightSearchResultsParam?: HighlightSearchResultsParam, options?: any): AxiosPromise<HighLightSearchResultsResponse> {
            return localVarFp.apiLegalxtractHighLightUnHightSearchResultsPost(highlightSearchResultsParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UndoHightLightParam} [undoHightLightParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractUndoHightLightPost(undoHightLightParam?: UndoHightLightParam, options?: any): AxiosPromise<LxPageData> {
            return localVarFp.apiLegalxtractUndoHightLightPost(undoHightLightParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HighlightApi - object-oriented interface
 * @export
 * @class HighlightApi
 * @extends {BaseAPI}
 */
export class HighlightApi extends BaseAPI {
    /**
     * 
     * @param {HighLightUnHighLightLine} [highLightUnHighLightLine] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HighlightApi
     */
    public apiLegalxtractHighLightLinePost(highLightUnHighLightLine?: HighLightUnHighLightLine, options?: RawAxiosRequestConfig) {
        return HighlightApiFp(this.configuration).apiLegalxtractHighLightLinePost(highLightUnHighLightLine, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {HighLightOrUnHighLightClickWordsParam} [highLightOrUnHighLightClickWordsParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HighlightApi
     */
    public apiLegalxtractHighLightOrUnHighLightClickWordsPost(highLightOrUnHighLightClickWordsParam?: HighLightOrUnHighLightClickWordsParam, options?: RawAxiosRequestConfig) {
        return HighlightApiFp(this.configuration).apiLegalxtractHighLightOrUnHighLightClickWordsPost(highLightOrUnHighLightClickWordsParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {HighLightOrUnHighLight} [highLightOrUnHighLight] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HighlightApi
     */
    public apiLegalxtractHighLightOrUnHighLightMaskPost(highLightOrUnHighLight?: HighLightOrUnHighLight, options?: RawAxiosRequestConfig) {
        return HighlightApiFp(this.configuration).apiLegalxtractHighLightOrUnHighLightMaskPost(highLightOrUnHighLight, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {HighLightOrUnHighLight} [highLightOrUnHighLight] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HighlightApi
     */
    public apiLegalxtractHighLightOrUnHighLightSectionPost(highLightOrUnHighLight?: HighLightOrUnHighLight, options?: RawAxiosRequestConfig) {
        return HighlightApiFp(this.configuration).apiLegalxtractHighLightOrUnHighLightSectionPost(highLightOrUnHighLight, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {HighLightUnHighLightPage} [highLightUnHighLightPage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HighlightApi
     */
    public apiLegalxtractHighLightPagePost(highLightUnHighLightPage?: HighLightUnHighLightPage, options?: RawAxiosRequestConfig) {
        return HighlightApiFp(this.configuration).apiLegalxtractHighLightPagePost(highLightUnHighLightPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SelectionTagParam} [selectionTagParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HighlightApi
     */
    public apiLegalxtractHighLightTagPost(selectionTagParam?: SelectionTagParam, options?: RawAxiosRequestConfig) {
        return HighlightApiFp(this.configuration).apiLegalxtractHighLightTagPost(selectionTagParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {HighlightSearchResultsParam} [highlightSearchResultsParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HighlightApi
     */
    public apiLegalxtractHighLightUnHightSearchResultsPost(highlightSearchResultsParam?: HighlightSearchResultsParam, options?: RawAxiosRequestConfig) {
        return HighlightApiFp(this.configuration).apiLegalxtractHighLightUnHightSearchResultsPost(highlightSearchResultsParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UndoHightLightParam} [undoHightLightParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HighlightApi
     */
    public apiLegalxtractUndoHightLightPost(undoHightLightParam?: UndoHightLightParam, options?: RawAxiosRequestConfig) {
        return HighlightApiFp(this.configuration).apiLegalxtractUndoHightLightPost(undoHightLightParam, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * HistoryApi - axios parameter creator
 * @export
 */
export const HistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractExportExcelGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ExportExcel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetDataHistoryGet: async (id?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetDataHistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HistoryApi - functional programming interface
 * @export
 */
export const HistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractExportExcelGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractExportExcelGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HistoryApi.apiLegalxtractExportExcelGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetDataHistoryGet(id?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HistoryViewItemWebListApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetDataHistoryGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HistoryApi.apiLegalxtractGetDataHistoryGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * HistoryApi - factory interface
 * @export
 */
export const HistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HistoryApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractExportExcelGet(options?: any): AxiosPromise<File> {
            return localVarFp.apiLegalxtractExportExcelGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetDataHistoryGet(id?: string, options?: any): AxiosPromise<HistoryViewItemWebListApiResult> {
            return localVarFp.apiLegalxtractGetDataHistoryGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HistoryApi - object-oriented interface
 * @export
 * @class HistoryApi
 * @extends {BaseAPI}
 */
export class HistoryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoryApi
     */
    public apiLegalxtractExportExcelGet(options?: RawAxiosRequestConfig) {
        return HistoryApiFp(this.configuration).apiLegalxtractExportExcelGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoryApi
     */
    public apiLegalxtractGetDataHistoryGet(id?: string, options?: RawAxiosRequestConfig) {
        return HistoryApiFp(this.configuration).apiLegalxtractGetDataHistoryGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * JoinDocumentApi - axios parameter creator
 * @export
 */
export const JoinDocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {JoinDocumentsParam} [joinDocumentsParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractJoinDocumentsPost: async (joinDocumentsParam?: JoinDocumentsParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/JoinDocuments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(joinDocumentsParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JoinDocumentApi - functional programming interface
 * @export
 */
export const JoinDocumentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JoinDocumentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {JoinDocumentsParam} [joinDocumentsParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractJoinDocumentsPost(joinDocumentsParam?: JoinDocumentsParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentInfoApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractJoinDocumentsPost(joinDocumentsParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JoinDocumentApi.apiLegalxtractJoinDocumentsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * JoinDocumentApi - factory interface
 * @export
 */
export const JoinDocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JoinDocumentApiFp(configuration)
    return {
        /**
         * 
         * @param {JoinDocumentsParam} [joinDocumentsParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractJoinDocumentsPost(joinDocumentsParam?: JoinDocumentsParam, options?: any): AxiosPromise<DocumentInfoApiResult> {
            return localVarFp.apiLegalxtractJoinDocumentsPost(joinDocumentsParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * JoinDocumentApi - object-oriented interface
 * @export
 * @class JoinDocumentApi
 * @extends {BaseAPI}
 */
export class JoinDocumentApi extends BaseAPI {
    /**
     * 
     * @param {JoinDocumentsParam} [joinDocumentsParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JoinDocumentApi
     */
    public apiLegalxtractJoinDocumentsPost(joinDocumentsParam?: JoinDocumentsParam, options?: RawAxiosRequestConfig) {
        return JoinDocumentApiFp(this.configuration).apiLegalxtractJoinDocumentsPost(joinDocumentsParam, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LanguageApi - axios parameter creator
 * @export
 */
export const LanguageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetLanguagesLanguageGet: async (language: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'language' is not null or undefined
            assertParamExists('apiLegalxtractGetLanguagesLanguageGet', 'language', language)
            const localVarPath = `/api/legalxtract/GetLanguages/{language}`
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSetLanguageGet: async (language?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/SetLanguage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LanguageApi - functional programming interface
 * @export
 */
export const LanguageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LanguageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetLanguagesLanguageGet(language: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetLanguagesLanguageGet(language, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LanguageApi.apiLegalxtractGetLanguagesLanguageGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractSetLanguageGet(language?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractSetLanguageGet(language, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LanguageApi.apiLegalxtractSetLanguageGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LanguageApi - factory interface
 * @export
 */
export const LanguageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LanguageApiFp(configuration)
    return {
        /**
         * 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetLanguagesLanguageGet(language: string, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.apiLegalxtractGetLanguagesLanguageGet(language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSetLanguageGet(language?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractSetLanguageGet(language, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LanguageApi - object-oriented interface
 * @export
 * @class LanguageApi
 * @extends {BaseAPI}
 */
export class LanguageApi extends BaseAPI {
    /**
     * 
     * @param {string} language 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public apiLegalxtractGetLanguagesLanguageGet(language: string, options?: RawAxiosRequestConfig) {
        return LanguageApiFp(this.configuration).apiLegalxtractGetLanguagesLanguageGet(language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [language] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public apiLegalxtractSetLanguageGet(language?: string, options?: RawAxiosRequestConfig) {
        return LanguageApiFp(this.configuration).apiLegalxtractSetLanguageGet(language, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LineApi - axios parameter creator
 * @export
 */
export const LineApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {AddLineParam} [addLineParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddLinePost: async (viewerPageNo?: number, addLineParam?: AddLineParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/AddLine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (viewerPageNo !== undefined) {
                localVarQueryParameter['viewerPageNo'] = viewerPageNo;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addLineParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [viewerPageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRemoveLinePost: async (id?: string, viewerPageNo?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/RemoveLine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (viewerPageNo !== undefined) {
                localVarQueryParameter['viewerPageNo'] = viewerPageNo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {AddLineParam} [addLineParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractUpdateLinePost: async (viewerPageNo?: number, addLineParam?: AddLineParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/UpdateLine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (viewerPageNo !== undefined) {
                localVarQueryParameter['viewerPageNo'] = viewerPageNo;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addLineParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LineApi - functional programming interface
 * @export
 */
export const LineApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LineApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {AddLineParam} [addLineParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractAddLinePost(viewerPageNo?: number, addLineParam?: AddLineParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LineAnnotationPageDataResponseApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractAddLinePost(viewerPageNo, addLineParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LineApi.apiLegalxtractAddLinePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [viewerPageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractRemoveLinePost(id?: string, viewerPageNo?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddLineParamPageDataResponseApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractRemoveLinePost(id, viewerPageNo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LineApi.apiLegalxtractRemoveLinePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {AddLineParam} [addLineParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractUpdateLinePost(viewerPageNo?: number, addLineParam?: AddLineParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddLineParamPageDataResponseApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractUpdateLinePost(viewerPageNo, addLineParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LineApi.apiLegalxtractUpdateLinePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LineApi - factory interface
 * @export
 */
export const LineApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LineApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {AddLineParam} [addLineParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddLinePost(viewerPageNo?: number, addLineParam?: AddLineParam, options?: any): AxiosPromise<LineAnnotationPageDataResponseApiResult> {
            return localVarFp.apiLegalxtractAddLinePost(viewerPageNo, addLineParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [viewerPageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRemoveLinePost(id?: string, viewerPageNo?: number, options?: any): AxiosPromise<AddLineParamPageDataResponseApiResult> {
            return localVarFp.apiLegalxtractRemoveLinePost(id, viewerPageNo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {AddLineParam} [addLineParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractUpdateLinePost(viewerPageNo?: number, addLineParam?: AddLineParam, options?: any): AxiosPromise<AddLineParamPageDataResponseApiResult> {
            return localVarFp.apiLegalxtractUpdateLinePost(viewerPageNo, addLineParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LineApi - object-oriented interface
 * @export
 * @class LineApi
 * @extends {BaseAPI}
 */
export class LineApi extends BaseAPI {
    /**
     * 
     * @param {number} [viewerPageNo] 
     * @param {AddLineParam} [addLineParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LineApi
     */
    public apiLegalxtractAddLinePost(viewerPageNo?: number, addLineParam?: AddLineParam, options?: RawAxiosRequestConfig) {
        return LineApiFp(this.configuration).apiLegalxtractAddLinePost(viewerPageNo, addLineParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {number} [viewerPageNo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LineApi
     */
    public apiLegalxtractRemoveLinePost(id?: string, viewerPageNo?: number, options?: RawAxiosRequestConfig) {
        return LineApiFp(this.configuration).apiLegalxtractRemoveLinePost(id, viewerPageNo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [viewerPageNo] 
     * @param {AddLineParam} [addLineParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LineApi
     */
    public apiLegalxtractUpdateLinePost(viewerPageNo?: number, addLineParam?: AddLineParam, options?: RawAxiosRequestConfig) {
        return LineApiFp(this.configuration).apiLegalxtractUpdateLinePost(viewerPageNo, addLineParam, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LoginApi - axios parameter creator
 * @export
 */
export const LoginApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ChangePassWordParam} [changePassWordParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractChangePasswordPost: async (changePassWordParam?: ChangePassWordParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ChangePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePassWordParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CheckUserStatusParam} [checkUserStatusParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCheckUserStatusPost: async (checkUserStatusParam?: CheckUserStatusParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/CheckUserStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkUserStatusParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginAppParam} [loginAppParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractLoginAppPost: async (loginAppParam?: LoginAppParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/LoginApp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginAppParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractLogoutPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/Logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginNovaParam} [loginNovaParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractValidateNovaTokenPost: async (loginNovaParam?: LoginNovaParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ValidateNovaToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginNovaParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoginApi - functional programming interface
 * @export
 */
export const LoginApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoginApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ChangePassWordParam} [changePassWordParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractChangePasswordPost(changePassWordParam?: ChangePassWordParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanOperationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractChangePasswordPost(changePassWordParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LoginApi.apiLegalxtractChangePasswordPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CheckUserStatusParam} [checkUserStatusParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractCheckUserStatusPost(checkUserStatusParam?: CheckUserStatusParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserStatusResultOperationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractCheckUserStatusPost(checkUserStatusParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LoginApi.apiLegalxtractCheckUserStatusPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {LoginAppParam} [loginAppParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractLoginAppPost(loginAppParam?: LoginAppParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractLoginAppPost(loginAppParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LoginApi.apiLegalxtractLoginAppPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractLogoutPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractLogoutPost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LoginApi.apiLegalxtractLogoutPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {LoginNovaParam} [loginNovaParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractValidateNovaTokenPost(loginNovaParam?: LoginNovaParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractValidateNovaTokenPost(loginNovaParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LoginApi.apiLegalxtractValidateNovaTokenPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LoginApi - factory interface
 * @export
 */
export const LoginApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoginApiFp(configuration)
    return {
        /**
         * 
         * @param {ChangePassWordParam} [changePassWordParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractChangePasswordPost(changePassWordParam?: ChangePassWordParam, options?: any): AxiosPromise<BooleanOperationResult> {
            return localVarFp.apiLegalxtractChangePasswordPost(changePassWordParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CheckUserStatusParam} [checkUserStatusParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCheckUserStatusPost(checkUserStatusParam?: CheckUserStatusParam, options?: any): AxiosPromise<UserStatusResultOperationResult> {
            return localVarFp.apiLegalxtractCheckUserStatusPost(checkUserStatusParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginAppParam} [loginAppParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractLoginAppPost(loginAppParam?: LoginAppParam, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.apiLegalxtractLoginAppPost(loginAppParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractLogoutPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractLogoutPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginNovaParam} [loginNovaParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractValidateNovaTokenPost(loginNovaParam?: LoginNovaParam, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.apiLegalxtractValidateNovaTokenPost(loginNovaParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoginApi - object-oriented interface
 * @export
 * @class LoginApi
 * @extends {BaseAPI}
 */
export class LoginApi extends BaseAPI {
    /**
     * 
     * @param {ChangePassWordParam} [changePassWordParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public apiLegalxtractChangePasswordPost(changePassWordParam?: ChangePassWordParam, options?: RawAxiosRequestConfig) {
        return LoginApiFp(this.configuration).apiLegalxtractChangePasswordPost(changePassWordParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CheckUserStatusParam} [checkUserStatusParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public apiLegalxtractCheckUserStatusPost(checkUserStatusParam?: CheckUserStatusParam, options?: RawAxiosRequestConfig) {
        return LoginApiFp(this.configuration).apiLegalxtractCheckUserStatusPost(checkUserStatusParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginAppParam} [loginAppParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public apiLegalxtractLoginAppPost(loginAppParam?: LoginAppParam, options?: RawAxiosRequestConfig) {
        return LoginApiFp(this.configuration).apiLegalxtractLoginAppPost(loginAppParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public apiLegalxtractLogoutPost(options?: RawAxiosRequestConfig) {
        return LoginApiFp(this.configuration).apiLegalxtractLogoutPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginNovaParam} [loginNovaParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public apiLegalxtractValidateNovaTokenPost(loginNovaParam?: LoginNovaParam, options?: RawAxiosRequestConfig) {
        return LoginApiFp(this.configuration).apiLegalxtractValidateNovaTokenPost(loginNovaParam, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NoteApi - axios parameter creator
 * @export
 */
export const NoteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {NoteTextParam} [noteTextParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddNotePost: async (noteTextParam?: NoteTextParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/AddNote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noteTextParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [pageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteNotePost: async (id?: string, pageNo?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/DeleteNote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NoteTextParam} [noteTextParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractEditNotePost: async (noteTextParam?: NoteTextParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/EditNote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noteTextParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetListNoteGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetListNote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NoteApi - functional programming interface
 * @export
 */
export const NoteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NoteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {NoteTextParam} [noteTextParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractAddNotePost(noteTextParam?: NoteTextParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteTextObjPageDataResponseApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractAddNotePost(noteTextParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteApi.apiLegalxtractAddNotePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [pageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractDeleteNotePost(id?: string, pageNo?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanPageDataResponseApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractDeleteNotePost(id, pageNo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteApi.apiLegalxtractDeleteNotePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {NoteTextParam} [noteTextParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractEditNotePost(noteTextParam?: NoteTextParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteTextObjPageDataResponseApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractEditNotePost(noteTextParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteApi.apiLegalxtractEditNotePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetListNoteGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteTextObjListApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetListNoteGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteApi.apiLegalxtractGetListNoteGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NoteApi - factory interface
 * @export
 */
export const NoteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NoteApiFp(configuration)
    return {
        /**
         * 
         * @param {NoteTextParam} [noteTextParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddNotePost(noteTextParam?: NoteTextParam, options?: any): AxiosPromise<NoteTextObjPageDataResponseApiResult> {
            return localVarFp.apiLegalxtractAddNotePost(noteTextParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [pageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteNotePost(id?: string, pageNo?: number, options?: any): AxiosPromise<BooleanPageDataResponseApiResult> {
            return localVarFp.apiLegalxtractDeleteNotePost(id, pageNo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NoteTextParam} [noteTextParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractEditNotePost(noteTextParam?: NoteTextParam, options?: any): AxiosPromise<NoteTextObjPageDataResponseApiResult> {
            return localVarFp.apiLegalxtractEditNotePost(noteTextParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetListNoteGet(options?: any): AxiosPromise<NoteTextObjListApiResult> {
            return localVarFp.apiLegalxtractGetListNoteGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NoteApi - object-oriented interface
 * @export
 * @class NoteApi
 * @extends {BaseAPI}
 */
export class NoteApi extends BaseAPI {
    /**
     * 
     * @param {NoteTextParam} [noteTextParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public apiLegalxtractAddNotePost(noteTextParam?: NoteTextParam, options?: RawAxiosRequestConfig) {
        return NoteApiFp(this.configuration).apiLegalxtractAddNotePost(noteTextParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {number} [pageNo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public apiLegalxtractDeleteNotePost(id?: string, pageNo?: number, options?: RawAxiosRequestConfig) {
        return NoteApiFp(this.configuration).apiLegalxtractDeleteNotePost(id, pageNo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NoteTextParam} [noteTextParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public apiLegalxtractEditNotePost(noteTextParam?: NoteTextParam, options?: RawAxiosRequestConfig) {
        return NoteApiFp(this.configuration).apiLegalxtractEditNotePost(noteTextParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public apiLegalxtractGetListNoteGet(options?: RawAxiosRequestConfig) {
        return NoteApiFp(this.configuration).apiLegalxtractGetListNoteGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OpenApi - axios parameter creator
 * @export
 */
export const OpenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<File>} [requestFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAppendFilesPost: async (requestFiles?: Array<File>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/AppendFiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (requestFiles) {
                requestFiles.forEach((element) => {
                    localVarFormParams.append('RequestFiles', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractFileTypeSupportGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/FileTypeSupport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<File>} [requestFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractOpenFilesPost: async (requestFiles?: Array<File>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/OpenFiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (requestFiles) {
                requestFiles.forEach((element) => {
                    localVarFormParams.append('RequestFiles', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpenApi - functional programming interface
 * @export
 */
export const OpenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OpenApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<File>} [requestFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractAppendFilesPost(requestFiles?: Array<File>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractAppendFilesPost(requestFiles, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OpenApi.apiLegalxtractAppendFilesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractFileTypeSupportGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractFileTypeSupportGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OpenApi.apiLegalxtractFileTypeSupportGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<File>} [requestFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractOpenFilesPost(requestFiles?: Array<File>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractOpenFilesPost(requestFiles, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OpenApi.apiLegalxtractOpenFilesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OpenApi - factory interface
 * @export
 */
export const OpenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OpenApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<File>} [requestFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAppendFilesPost(requestFiles?: Array<File>, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.apiLegalxtractAppendFilesPost(requestFiles, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractFileTypeSupportGet(options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.apiLegalxtractFileTypeSupportGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<File>} [requestFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractOpenFilesPost(requestFiles?: Array<File>, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.apiLegalxtractOpenFilesPost(requestFiles, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OpenApi - object-oriented interface
 * @export
 * @class OpenApi
 * @extends {BaseAPI}
 */
export class OpenApi extends BaseAPI {
    /**
     * 
     * @param {Array<File>} [requestFiles] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApi
     */
    public apiLegalxtractAppendFilesPost(requestFiles?: Array<File>, options?: RawAxiosRequestConfig) {
        return OpenApiFp(this.configuration).apiLegalxtractAppendFilesPost(requestFiles, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApi
     */
    public apiLegalxtractFileTypeSupportGet(options?: RawAxiosRequestConfig) {
        return OpenApiFp(this.configuration).apiLegalxtractFileTypeSupportGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<File>} [requestFiles] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApi
     */
    public apiLegalxtractOpenFilesPost(requestFiles?: Array<File>, options?: RawAxiosRequestConfig) {
        return OpenApiFp(this.configuration).apiLegalxtractOpenFilesPost(requestFiles, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PageApi - axios parameter creator
 * @export
 */
export const PageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeletePagesPost: async (requestBody?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/DeletePages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetPageDataGet: async (page?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetPageData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PageApi - functional programming interface
 * @export
 */
export const PageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractDeletePagesPost(requestBody?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentInfoPageDataResponseApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractDeletePagesPost(requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PageApi.apiLegalxtractDeletePagesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetPageDataGet(page?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LxPageDataApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetPageDataGet(page, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PageApi.apiLegalxtractGetPageDataGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PageApi - factory interface
 * @export
 */
export const PageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PageApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeletePagesPost(requestBody?: Array<number>, options?: any): AxiosPromise<DocumentInfoPageDataResponseApiResult> {
            return localVarFp.apiLegalxtractDeletePagesPost(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetPageDataGet(page?: number, options?: any): AxiosPromise<LxPageDataApiResult> {
            return localVarFp.apiLegalxtractGetPageDataGet(page, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PageApi - object-oriented interface
 * @export
 * @class PageApi
 * @extends {BaseAPI}
 */
export class PageApi extends BaseAPI {
    /**
     * 
     * @param {Array<number>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageApi
     */
    public apiLegalxtractDeletePagesPost(requestBody?: Array<number>, options?: RawAxiosRequestConfig) {
        return PageApiFp(this.configuration).apiLegalxtractDeletePagesPost(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageApi
     */
    public apiLegalxtractGetPageDataGet(page?: number, options?: RawAxiosRequestConfig) {
        return PageApiFp(this.configuration).apiLegalxtractGetPageDataGet(page, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PageNumberingApi - axios parameter creator
 * @export
 */
export const PageNumberingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PageNumberingInfo} [pageNumberingInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractProcessPageNumberingPost: async (pageNumberingInfo?: PageNumberingInfo, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ProcessPageNumbering`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pageNumberingInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PageNumberingApi - functional programming interface
 * @export
 */
export const PageNumberingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PageNumberingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PageNumberingInfo} [pageNumberingInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractProcessPageNumberingPost(pageNumberingInfo?: PageNumberingInfo, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentInfoApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractProcessPageNumberingPost(pageNumberingInfo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PageNumberingApi.apiLegalxtractProcessPageNumberingPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PageNumberingApi - factory interface
 * @export
 */
export const PageNumberingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PageNumberingApiFp(configuration)
    return {
        /**
         * 
         * @param {PageNumberingInfo} [pageNumberingInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractProcessPageNumberingPost(pageNumberingInfo?: PageNumberingInfo, options?: any): AxiosPromise<DocumentInfoApiResult> {
            return localVarFp.apiLegalxtractProcessPageNumberingPost(pageNumberingInfo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PageNumberingApi - object-oriented interface
 * @export
 * @class PageNumberingApi
 * @extends {BaseAPI}
 */
export class PageNumberingApi extends BaseAPI {
    /**
     * 
     * @param {PageNumberingInfo} [pageNumberingInfo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageNumberingApi
     */
    public apiLegalxtractProcessPageNumberingPost(pageNumberingInfo?: PageNumberingInfo, options?: RawAxiosRequestConfig) {
        return PageNumberingApiFp(this.configuration).apiLegalxtractProcessPageNumberingPost(pageNumberingInfo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReorderApi - axios parameter creator
 * @export
 */
export const ReorderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [fromTabIndex] 
         * @param {number} [toTabIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractProcessReorderGet: async (fromTabIndex?: number, toTabIndex?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ProcessReorder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fromTabIndex !== undefined) {
                localVarQueryParameter['fromTabIndex'] = fromTabIndex;
            }

            if (toTabIndex !== undefined) {
                localVarQueryParameter['toTabIndex'] = toTabIndex;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReorderApi - functional programming interface
 * @export
 */
export const ReorderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReorderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [fromTabIndex] 
         * @param {number} [toTabIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractProcessReorderGet(fromTabIndex?: number, toTabIndex?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentInfoPageDataResponseApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractProcessReorderGet(fromTabIndex, toTabIndex, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReorderApi.apiLegalxtractProcessReorderGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ReorderApi - factory interface
 * @export
 */
export const ReorderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReorderApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [fromTabIndex] 
         * @param {number} [toTabIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractProcessReorderGet(fromTabIndex?: number, toTabIndex?: number, options?: any): AxiosPromise<DocumentInfoPageDataResponseApiResult> {
            return localVarFp.apiLegalxtractProcessReorderGet(fromTabIndex, toTabIndex, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReorderApi - object-oriented interface
 * @export
 * @class ReorderApi
 * @extends {BaseAPI}
 */
export class ReorderApi extends BaseAPI {
    /**
     * 
     * @param {number} [fromTabIndex] 
     * @param {number} [toTabIndex] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReorderApi
     */
    public apiLegalxtractProcessReorderGet(fromTabIndex?: number, toTabIndex?: number, options?: RawAxiosRequestConfig) {
        return ReorderApiFp(this.configuration).apiLegalxtractProcessReorderGet(fromTabIndex, toTabIndex, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReplaceApi - axios parameter creator
 * @export
 */
export const ReplaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ReplaceParamWeb} [replaceParamWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddReplaceAllItemSelectedsPost: async (replaceParamWeb?: ReplaceParamWeb, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/AddReplaceAllItemSelecteds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(replaceParamWeb, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReplaceParamWeb} [replaceParamWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddReplaceAllSearchResultPost: async (replaceParamWeb?: ReplaceParamWeb, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/AddReplaceAllSearchResult`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(replaceParamWeb, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReplaceParamWeb} [replaceParamWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddReplacePost: async (replaceParamWeb?: ReplaceParamWeb, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/AddReplace`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(replaceParamWeb, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCalculateReplaceGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/CalculateReplace`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteReplaceParam} [deleteReplaceParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteReplaceDelete: async (deleteReplaceParam?: DeleteReplaceParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/DeleteReplace`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteReplaceParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [x] 
         * @param {number} [y] 
         * @param {number} [pageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteReplaceFromWordGet: async (x?: number, y?: number, pageNo?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/DeleteReplaceFromWord`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x !== undefined) {
                localVarQueryParameter['X'] = x;
            }

            if (y !== undefined) {
                localVarQueryParameter['Y'] = y;
            }

            if (pageNo !== undefined) {
                localVarQueryParameter['PageNo'] = pageNo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReplaceParamWeb} [replaceParamWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractEditReplacePost: async (replaceParamWeb?: ReplaceParamWeb, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/EditReplace`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(replaceParamWeb, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetReplaceObjectsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetReplaceObjects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetSelectedWordContinuousParam} [getSelectedWordContinuousParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetSelectedWordContinuousPost: async (getSelectedWordContinuousParam?: GetSelectedWordContinuousParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetSelectedWordContinuous`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getSelectedWordContinuousParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetSelectedWordMaskParam} [getSelectedWordMaskParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetSelectedWordMaskPost: async (getSelectedWordMaskParam?: GetSelectedWordMaskParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetSelectedWordMask`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getSelectedWordMaskParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReplaceApi - functional programming interface
 * @export
 */
export const ReplaceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReplaceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ReplaceParamWeb} [replaceParamWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractAddReplaceAllItemSelectedsPost(replaceParamWeb?: ReplaceParamWeb, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddReplaceAllResponsePageDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractAddReplaceAllItemSelectedsPost(replaceParamWeb, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReplaceApi.apiLegalxtractAddReplaceAllItemSelectedsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ReplaceParamWeb} [replaceParamWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractAddReplaceAllSearchResultPost(replaceParamWeb?: ReplaceParamWeb, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddReplaceAllResponsePageDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractAddReplaceAllSearchResultPost(replaceParamWeb, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReplaceApi.apiLegalxtractAddReplaceAllSearchResultPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ReplaceParamWeb} [replaceParamWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractAddReplacePost(replaceParamWeb?: ReplaceParamWeb, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidMarkedInfoWithIdListDictionaryPageDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractAddReplacePost(replaceParamWeb, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReplaceApi.apiLegalxtractAddReplacePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractCalculateReplaceGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReplaceObjDisplay>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractCalculateReplaceGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReplaceApi.apiLegalxtractCalculateReplaceGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteReplaceParam} [deleteReplaceParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractDeleteReplaceDelete(deleteReplaceParam?: DeleteReplaceParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidMarkedInfoWithIdListDictionaryPageDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractDeleteReplaceDelete(deleteReplaceParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReplaceApi.apiLegalxtractDeleteReplaceDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [x] 
         * @param {number} [y] 
         * @param {number} [pageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractDeleteReplaceFromWordGet(x?: number, y?: number, pageNo?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidMarkedInfoWithIdListDictionaryPageDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractDeleteReplaceFromWordGet(x, y, pageNo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReplaceApi.apiLegalxtractDeleteReplaceFromWordGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ReplaceParamWeb} [replaceParamWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractEditReplacePost(replaceParamWeb?: ReplaceParamWeb, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringPageDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractEditReplacePost(replaceParamWeb, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReplaceApi.apiLegalxtractEditReplacePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetReplaceObjectsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReplaceObjects>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetReplaceObjectsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReplaceApi.apiLegalxtractGetReplaceObjectsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GetSelectedWordContinuousParam} [getSelectedWordContinuousParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetSelectedWordContinuousPost(getSelectedWordContinuousParam?: GetSelectedWordContinuousParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectedWordContinuousResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetSelectedWordContinuousPost(getSelectedWordContinuousParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReplaceApi.apiLegalxtractGetSelectedWordContinuousPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GetSelectedWordMaskParam} [getSelectedWordMaskParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetSelectedWordMaskPost(getSelectedWordMaskParam?: GetSelectedWordMaskParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectedWordContinuousResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetSelectedWordMaskPost(getSelectedWordMaskParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReplaceApi.apiLegalxtractGetSelectedWordMaskPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ReplaceApi - factory interface
 * @export
 */
export const ReplaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReplaceApiFp(configuration)
    return {
        /**
         * 
         * @param {ReplaceParamWeb} [replaceParamWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddReplaceAllItemSelectedsPost(replaceParamWeb?: ReplaceParamWeb, options?: any): AxiosPromise<AddReplaceAllResponsePageDataResponse> {
            return localVarFp.apiLegalxtractAddReplaceAllItemSelectedsPost(replaceParamWeb, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReplaceParamWeb} [replaceParamWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddReplaceAllSearchResultPost(replaceParamWeb?: ReplaceParamWeb, options?: any): AxiosPromise<AddReplaceAllResponsePageDataResponse> {
            return localVarFp.apiLegalxtractAddReplaceAllSearchResultPost(replaceParamWeb, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReplaceParamWeb} [replaceParamWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddReplacePost(replaceParamWeb?: ReplaceParamWeb, options?: any): AxiosPromise<GuidMarkedInfoWithIdListDictionaryPageDataResponse> {
            return localVarFp.apiLegalxtractAddReplacePost(replaceParamWeb, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCalculateReplaceGet(options?: any): AxiosPromise<Array<ReplaceObjDisplay>> {
            return localVarFp.apiLegalxtractCalculateReplaceGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteReplaceParam} [deleteReplaceParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteReplaceDelete(deleteReplaceParam?: DeleteReplaceParam, options?: any): AxiosPromise<GuidMarkedInfoWithIdListDictionaryPageDataResponse> {
            return localVarFp.apiLegalxtractDeleteReplaceDelete(deleteReplaceParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [x] 
         * @param {number} [y] 
         * @param {number} [pageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteReplaceFromWordGet(x?: number, y?: number, pageNo?: number, options?: any): AxiosPromise<GuidMarkedInfoWithIdListDictionaryPageDataResponse> {
            return localVarFp.apiLegalxtractDeleteReplaceFromWordGet(x, y, pageNo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReplaceParamWeb} [replaceParamWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractEditReplacePost(replaceParamWeb?: ReplaceParamWeb, options?: any): AxiosPromise<StringPageDataResponse> {
            return localVarFp.apiLegalxtractEditReplacePost(replaceParamWeb, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetReplaceObjectsGet(options?: any): AxiosPromise<ReplaceObjects> {
            return localVarFp.apiLegalxtractGetReplaceObjectsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetSelectedWordContinuousParam} [getSelectedWordContinuousParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetSelectedWordContinuousPost(getSelectedWordContinuousParam?: GetSelectedWordContinuousParam, options?: any): AxiosPromise<SelectedWordContinuousResponse> {
            return localVarFp.apiLegalxtractGetSelectedWordContinuousPost(getSelectedWordContinuousParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetSelectedWordMaskParam} [getSelectedWordMaskParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetSelectedWordMaskPost(getSelectedWordMaskParam?: GetSelectedWordMaskParam, options?: any): AxiosPromise<SelectedWordContinuousResponse> {
            return localVarFp.apiLegalxtractGetSelectedWordMaskPost(getSelectedWordMaskParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReplaceApi - object-oriented interface
 * @export
 * @class ReplaceApi
 * @extends {BaseAPI}
 */
export class ReplaceApi extends BaseAPI {
    /**
     * 
     * @param {ReplaceParamWeb} [replaceParamWeb] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReplaceApi
     */
    public apiLegalxtractAddReplaceAllItemSelectedsPost(replaceParamWeb?: ReplaceParamWeb, options?: RawAxiosRequestConfig) {
        return ReplaceApiFp(this.configuration).apiLegalxtractAddReplaceAllItemSelectedsPost(replaceParamWeb, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReplaceParamWeb} [replaceParamWeb] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReplaceApi
     */
    public apiLegalxtractAddReplaceAllSearchResultPost(replaceParamWeb?: ReplaceParamWeb, options?: RawAxiosRequestConfig) {
        return ReplaceApiFp(this.configuration).apiLegalxtractAddReplaceAllSearchResultPost(replaceParamWeb, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReplaceParamWeb} [replaceParamWeb] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReplaceApi
     */
    public apiLegalxtractAddReplacePost(replaceParamWeb?: ReplaceParamWeb, options?: RawAxiosRequestConfig) {
        return ReplaceApiFp(this.configuration).apiLegalxtractAddReplacePost(replaceParamWeb, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReplaceApi
     */
    public apiLegalxtractCalculateReplaceGet(options?: RawAxiosRequestConfig) {
        return ReplaceApiFp(this.configuration).apiLegalxtractCalculateReplaceGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteReplaceParam} [deleteReplaceParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReplaceApi
     */
    public apiLegalxtractDeleteReplaceDelete(deleteReplaceParam?: DeleteReplaceParam, options?: RawAxiosRequestConfig) {
        return ReplaceApiFp(this.configuration).apiLegalxtractDeleteReplaceDelete(deleteReplaceParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [x] 
     * @param {number} [y] 
     * @param {number} [pageNo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReplaceApi
     */
    public apiLegalxtractDeleteReplaceFromWordGet(x?: number, y?: number, pageNo?: number, options?: RawAxiosRequestConfig) {
        return ReplaceApiFp(this.configuration).apiLegalxtractDeleteReplaceFromWordGet(x, y, pageNo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReplaceParamWeb} [replaceParamWeb] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReplaceApi
     */
    public apiLegalxtractEditReplacePost(replaceParamWeb?: ReplaceParamWeb, options?: RawAxiosRequestConfig) {
        return ReplaceApiFp(this.configuration).apiLegalxtractEditReplacePost(replaceParamWeb, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReplaceApi
     */
    public apiLegalxtractGetReplaceObjectsGet(options?: RawAxiosRequestConfig) {
        return ReplaceApiFp(this.configuration).apiLegalxtractGetReplaceObjectsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetSelectedWordContinuousParam} [getSelectedWordContinuousParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReplaceApi
     */
    public apiLegalxtractGetSelectedWordContinuousPost(getSelectedWordContinuousParam?: GetSelectedWordContinuousParam, options?: RawAxiosRequestConfig) {
        return ReplaceApiFp(this.configuration).apiLegalxtractGetSelectedWordContinuousPost(getSelectedWordContinuousParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetSelectedWordMaskParam} [getSelectedWordMaskParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReplaceApi
     */
    public apiLegalxtractGetSelectedWordMaskPost(getSelectedWordMaskParam?: GetSelectedWordMaskParam, options?: RawAxiosRequestConfig) {
        return ReplaceApiFp(this.configuration).apiLegalxtractGetSelectedWordMaskPost(getSelectedWordMaskParam, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReplaceBoxApi - axios parameter creator
 * @export
 */
export const ReplaceBoxApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddReplaceBoxParam} [addReplaceBoxParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddReplaceBoxPost: async (addReplaceBoxParam?: AddReplaceBoxParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/AddReplaceBox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addReplaceBoxParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReplaceBoxParam} [replaceBoxParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractConvertReplaceBoxToBoxPost: async (replaceBoxParam?: ReplaceBoxParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ConvertReplaceBoxToBox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(replaceBoxParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditReplaceBoxParam} [editReplaceBoxParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractEditReplaceBoxPost: async (editReplaceBoxParam?: EditReplaceBoxParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/EditReplaceBox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editReplaceBoxParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [boxId] 
         * @param {number} [viewerPageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetPreviewReplateBoxGet: async (boxId?: string, viewerPageNo?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetPreviewReplateBox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (boxId !== undefined) {
                localVarQueryParameter['boxId'] = boxId;
            }

            if (viewerPageNo !== undefined) {
                localVarQueryParameter['viewerPageNo'] = viewerPageNo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [boxId] 
         * @param {number} [viewerPageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRemoveReplaceBoxGet: async (boxId?: string, viewerPageNo?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/RemoveReplaceBox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (boxId !== undefined) {
                localVarQueryParameter['boxId'] = boxId;
            }

            if (viewerPageNo !== undefined) {
                localVarQueryParameter['viewerPageNo'] = viewerPageNo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UndoRemoveReplaceBox} [undoRemoveReplaceBox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractUndoActionRemoveReplaceBoxPost: async (undoRemoveReplaceBox?: UndoRemoveReplaceBox, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/UndoActionRemoveReplaceBox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(undoRemoveReplaceBox, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReplaceBoxApi - functional programming interface
 * @export
 */
export const ReplaceBoxApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReplaceBoxApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddReplaceBoxParam} [addReplaceBoxParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractAddReplaceBoxPost(addReplaceBoxParam?: AddReplaceBoxParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReplaceBoxParamPageDataResponseApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractAddReplaceBoxPost(addReplaceBoxParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReplaceBoxApi.apiLegalxtractAddReplaceBoxPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ReplaceBoxParam} [replaceBoxParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractConvertReplaceBoxToBoxPost(replaceBoxParam?: ReplaceBoxParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoxObjPageDataResponseApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractConvertReplaceBoxToBoxPost(replaceBoxParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReplaceBoxApi.apiLegalxtractConvertReplaceBoxToBoxPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EditReplaceBoxParam} [editReplaceBoxParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractEditReplaceBoxPost(editReplaceBoxParam?: EditReplaceBoxParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditReplaceBoxParamPageDataResponseApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractEditReplaceBoxPost(editReplaceBoxParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReplaceBoxApi.apiLegalxtractEditReplaceBoxPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [boxId] 
         * @param {number} [viewerPageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetPreviewReplateBoxGet(boxId?: string, viewerPageNo?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetPreviewReplateBoxGet(boxId, viewerPageNo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReplaceBoxApi.apiLegalxtractGetPreviewReplateBoxGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [boxId] 
         * @param {number} [viewerPageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractRemoveReplaceBoxGet(boxId?: string, viewerPageNo?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UndoRemoveReplaceBoxPageDataResponseApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractRemoveReplaceBoxGet(boxId, viewerPageNo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReplaceBoxApi.apiLegalxtractRemoveReplaceBoxGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UndoRemoveReplaceBox} [undoRemoveReplaceBox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractUndoActionRemoveReplaceBoxPost(undoRemoveReplaceBox?: UndoRemoveReplaceBox, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoxObjPageDataResponseApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractUndoActionRemoveReplaceBoxPost(undoRemoveReplaceBox, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReplaceBoxApi.apiLegalxtractUndoActionRemoveReplaceBoxPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ReplaceBoxApi - factory interface
 * @export
 */
export const ReplaceBoxApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReplaceBoxApiFp(configuration)
    return {
        /**
         * 
         * @param {AddReplaceBoxParam} [addReplaceBoxParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddReplaceBoxPost(addReplaceBoxParam?: AddReplaceBoxParam, options?: any): AxiosPromise<ReplaceBoxParamPageDataResponseApiResult> {
            return localVarFp.apiLegalxtractAddReplaceBoxPost(addReplaceBoxParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReplaceBoxParam} [replaceBoxParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractConvertReplaceBoxToBoxPost(replaceBoxParam?: ReplaceBoxParam, options?: any): AxiosPromise<BoxObjPageDataResponseApiResult> {
            return localVarFp.apiLegalxtractConvertReplaceBoxToBoxPost(replaceBoxParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditReplaceBoxParam} [editReplaceBoxParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractEditReplaceBoxPost(editReplaceBoxParam?: EditReplaceBoxParam, options?: any): AxiosPromise<EditReplaceBoxParamPageDataResponseApiResult> {
            return localVarFp.apiLegalxtractEditReplaceBoxPost(editReplaceBoxParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [boxId] 
         * @param {number} [viewerPageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetPreviewReplateBoxGet(boxId?: string, viewerPageNo?: number, options?: any): AxiosPromise<string> {
            return localVarFp.apiLegalxtractGetPreviewReplateBoxGet(boxId, viewerPageNo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [boxId] 
         * @param {number} [viewerPageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRemoveReplaceBoxGet(boxId?: string, viewerPageNo?: number, options?: any): AxiosPromise<UndoRemoveReplaceBoxPageDataResponseApiResult> {
            return localVarFp.apiLegalxtractRemoveReplaceBoxGet(boxId, viewerPageNo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UndoRemoveReplaceBox} [undoRemoveReplaceBox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractUndoActionRemoveReplaceBoxPost(undoRemoveReplaceBox?: UndoRemoveReplaceBox, options?: any): AxiosPromise<BoxObjPageDataResponseApiResult> {
            return localVarFp.apiLegalxtractUndoActionRemoveReplaceBoxPost(undoRemoveReplaceBox, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReplaceBoxApi - object-oriented interface
 * @export
 * @class ReplaceBoxApi
 * @extends {BaseAPI}
 */
export class ReplaceBoxApi extends BaseAPI {
    /**
     * 
     * @param {AddReplaceBoxParam} [addReplaceBoxParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReplaceBoxApi
     */
    public apiLegalxtractAddReplaceBoxPost(addReplaceBoxParam?: AddReplaceBoxParam, options?: RawAxiosRequestConfig) {
        return ReplaceBoxApiFp(this.configuration).apiLegalxtractAddReplaceBoxPost(addReplaceBoxParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReplaceBoxParam} [replaceBoxParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReplaceBoxApi
     */
    public apiLegalxtractConvertReplaceBoxToBoxPost(replaceBoxParam?: ReplaceBoxParam, options?: RawAxiosRequestConfig) {
        return ReplaceBoxApiFp(this.configuration).apiLegalxtractConvertReplaceBoxToBoxPost(replaceBoxParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditReplaceBoxParam} [editReplaceBoxParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReplaceBoxApi
     */
    public apiLegalxtractEditReplaceBoxPost(editReplaceBoxParam?: EditReplaceBoxParam, options?: RawAxiosRequestConfig) {
        return ReplaceBoxApiFp(this.configuration).apiLegalxtractEditReplaceBoxPost(editReplaceBoxParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [boxId] 
     * @param {number} [viewerPageNo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReplaceBoxApi
     */
    public apiLegalxtractGetPreviewReplateBoxGet(boxId?: string, viewerPageNo?: number, options?: RawAxiosRequestConfig) {
        return ReplaceBoxApiFp(this.configuration).apiLegalxtractGetPreviewReplateBoxGet(boxId, viewerPageNo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [boxId] 
     * @param {number} [viewerPageNo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReplaceBoxApi
     */
    public apiLegalxtractRemoveReplaceBoxGet(boxId?: string, viewerPageNo?: number, options?: RawAxiosRequestConfig) {
        return ReplaceBoxApiFp(this.configuration).apiLegalxtractRemoveReplaceBoxGet(boxId, viewerPageNo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UndoRemoveReplaceBox} [undoRemoveReplaceBox] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReplaceBoxApi
     */
    public apiLegalxtractUndoActionRemoveReplaceBoxPost(undoRemoveReplaceBox?: UndoRemoveReplaceBox, options?: RawAxiosRequestConfig) {
        return ReplaceBoxApiFp(this.configuration).apiLegalxtractUndoActionRemoveReplaceBoxPost(undoRemoveReplaceBox, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RotateApi - axios parameter creator
 * @export
 */
export const RotateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {RotateParam} [rotateParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRotateLeftPost: async (rotateParam?: RotateParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/RotateLeft`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rotateParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RotateParam} [rotateParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRotateRightPost: async (rotateParam?: RotateParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/RotateRight`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rotateParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RotateApi - functional programming interface
 * @export
 */
export const RotateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RotateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {RotateParam} [rotateParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractRotateLeftPost(rotateParam?: RotateParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentInfoPageDataResponseApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractRotateLeftPost(rotateParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RotateApi.apiLegalxtractRotateLeftPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RotateParam} [rotateParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractRotateRightPost(rotateParam?: RotateParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentInfoPageDataResponseApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractRotateRightPost(rotateParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RotateApi.apiLegalxtractRotateRightPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RotateApi - factory interface
 * @export
 */
export const RotateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RotateApiFp(configuration)
    return {
        /**
         * 
         * @param {RotateParam} [rotateParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRotateLeftPost(rotateParam?: RotateParam, options?: any): AxiosPromise<DocumentInfoPageDataResponseApiResult> {
            return localVarFp.apiLegalxtractRotateLeftPost(rotateParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RotateParam} [rotateParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRotateRightPost(rotateParam?: RotateParam, options?: any): AxiosPromise<DocumentInfoPageDataResponseApiResult> {
            return localVarFp.apiLegalxtractRotateRightPost(rotateParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RotateApi - object-oriented interface
 * @export
 * @class RotateApi
 * @extends {BaseAPI}
 */
export class RotateApi extends BaseAPI {
    /**
     * 
     * @param {RotateParam} [rotateParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RotateApi
     */
    public apiLegalxtractRotateLeftPost(rotateParam?: RotateParam, options?: RawAxiosRequestConfig) {
        return RotateApiFp(this.configuration).apiLegalxtractRotateLeftPost(rotateParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RotateParam} [rotateParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RotateApi
     */
    public apiLegalxtractRotateRightPost(rotateParam?: RotateParam, options?: RawAxiosRequestConfig) {
        return RotateApiFp(this.configuration).apiLegalxtractRotateRightPost(rotateParam, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SaveApi - axios parameter creator
 * @export
 */
export const SaveApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CalculateWatchDogParam} [calculateWatchDogParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCheckSettingWatchdogPost: async (calculateWatchDogParam?: CalculateWatchDogParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/CheckSettingWatchdog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calculateWatchDogParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSaveXtractFileGet: async (fileName?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/SaveXtractFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SaveApi - functional programming interface
 * @export
 */
export const SaveApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SaveApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CalculateWatchDogParam} [calculateWatchDogParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractCheckSettingWatchdogPost(calculateWatchDogParam?: CalculateWatchDogParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringListApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractCheckSettingWatchdogPost(calculateWatchDogParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SaveApi.apiLegalxtractCheckSettingWatchdogPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractSaveXtractFileGet(fileName?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractSaveXtractFileGet(fileName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SaveApi.apiLegalxtractSaveXtractFileGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SaveApi - factory interface
 * @export
 */
export const SaveApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SaveApiFp(configuration)
    return {
        /**
         * 
         * @param {CalculateWatchDogParam} [calculateWatchDogParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCheckSettingWatchdogPost(calculateWatchDogParam?: CalculateWatchDogParam, options?: any): AxiosPromise<StringListApiResult> {
            return localVarFp.apiLegalxtractCheckSettingWatchdogPost(calculateWatchDogParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSaveXtractFileGet(fileName?: string, options?: any): AxiosPromise<File> {
            return localVarFp.apiLegalxtractSaveXtractFileGet(fileName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SaveApi - object-oriented interface
 * @export
 * @class SaveApi
 * @extends {BaseAPI}
 */
export class SaveApi extends BaseAPI {
    /**
     * 
     * @param {CalculateWatchDogParam} [calculateWatchDogParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaveApi
     */
    public apiLegalxtractCheckSettingWatchdogPost(calculateWatchDogParam?: CalculateWatchDogParam, options?: RawAxiosRequestConfig) {
        return SaveApiFp(this.configuration).apiLegalxtractCheckSettingWatchdogPost(calculateWatchDogParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fileName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaveApi
     */
    public apiLegalxtractSaveXtractFileGet(fileName?: string, options?: RawAxiosRequestConfig) {
        return SaveApiFp(this.configuration).apiLegalxtractSaveXtractFileGet(fileName, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCancelSearchGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/CancelSearch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [take] 
         * @param {string} [cacheKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetDataSearchResultPagingGet: async (pageNumber?: number, take?: number, cacheKey?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetDataSearchResultPaging`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (cacheKey !== undefined) {
                localVarQueryParameter['cacheKey'] = cacheKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchKeywordApiParam} [searchKeywordApiParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSearchKeywordPost: async (searchKeywordApiParam?: SearchKeywordApiParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/SearchKeyword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchKeywordApiParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchTabVm} [searchTabVm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSearchTagPost: async (searchTabVm?: SearchTabVm, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/SearchTag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchTabVm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchTemplateApiParam} [searchTemplateApiParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSearchTemplatePost: async (searchTemplateApiParam?: SearchTemplateApiParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/SearchTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchTemplateApiParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractCancelSearchGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractCancelSearchGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SearchApi.apiLegalxtractCancelSearchGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [take] 
         * @param {string} [cacheKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetDataSearchResultPagingGet(pageNumber?: number, take?: number, cacheKey?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResultData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetDataSearchResultPagingGet(pageNumber, take, cacheKey, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SearchApi.apiLegalxtractGetDataSearchResultPagingGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SearchKeywordApiParam} [searchKeywordApiParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractSearchKeywordPost(searchKeywordApiParam?: SearchKeywordApiParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResultData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractSearchKeywordPost(searchKeywordApiParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SearchApi.apiLegalxtractSearchKeywordPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SearchTabVm} [searchTabVm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractSearchTagPost(searchTabVm?: SearchTabVm, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResultData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractSearchTagPost(searchTabVm, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SearchApi.apiLegalxtractSearchTagPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SearchTemplateApiParam} [searchTemplateApiParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractSearchTemplatePost(searchTemplateApiParam?: SearchTemplateApiParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResultData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractSearchTemplatePost(searchTemplateApiParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SearchApi.apiLegalxtractSearchTemplatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCancelSearchGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractCancelSearchGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [take] 
         * @param {string} [cacheKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetDataSearchResultPagingGet(pageNumber?: number, take?: number, cacheKey?: string, options?: any): AxiosPromise<SearchResultData> {
            return localVarFp.apiLegalxtractGetDataSearchResultPagingGet(pageNumber, take, cacheKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchKeywordApiParam} [searchKeywordApiParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSearchKeywordPost(searchKeywordApiParam?: SearchKeywordApiParam, options?: any): AxiosPromise<SearchResultData> {
            return localVarFp.apiLegalxtractSearchKeywordPost(searchKeywordApiParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchTabVm} [searchTabVm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSearchTagPost(searchTabVm?: SearchTabVm, options?: any): AxiosPromise<SearchResultData> {
            return localVarFp.apiLegalxtractSearchTagPost(searchTabVm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchTemplateApiParam} [searchTemplateApiParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSearchTemplatePost(searchTemplateApiParam?: SearchTemplateApiParam, options?: any): AxiosPromise<SearchResultData> {
            return localVarFp.apiLegalxtractSearchTemplatePost(searchTemplateApiParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public apiLegalxtractCancelSearchGet(options?: RawAxiosRequestConfig) {
        return SearchApiFp(this.configuration).apiLegalxtractCancelSearchGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [pageNumber] 
     * @param {number} [take] 
     * @param {string} [cacheKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public apiLegalxtractGetDataSearchResultPagingGet(pageNumber?: number, take?: number, cacheKey?: string, options?: RawAxiosRequestConfig) {
        return SearchApiFp(this.configuration).apiLegalxtractGetDataSearchResultPagingGet(pageNumber, take, cacheKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchKeywordApiParam} [searchKeywordApiParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public apiLegalxtractSearchKeywordPost(searchKeywordApiParam?: SearchKeywordApiParam, options?: RawAxiosRequestConfig) {
        return SearchApiFp(this.configuration).apiLegalxtractSearchKeywordPost(searchKeywordApiParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchTabVm} [searchTabVm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public apiLegalxtractSearchTagPost(searchTabVm?: SearchTabVm, options?: RawAxiosRequestConfig) {
        return SearchApiFp(this.configuration).apiLegalxtractSearchTagPost(searchTabVm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchTemplateApiParam} [searchTemplateApiParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public apiLegalxtractSearchTemplatePost(searchTemplateApiParam?: SearchTemplateApiParam, options?: RawAxiosRequestConfig) {
        return SearchApiFp(this.configuration).apiLegalxtractSearchTemplatePost(searchTemplateApiParam, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SettingApi - axios parameter creator
 * @export
 */
export const SettingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {boolean} [redraw] 
         * @param {LxSetting} [lxSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractUploadSettingPost: async (redraw?: boolean, lxSetting?: LxSetting, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/UploadSetting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (redraw !== undefined) {
                localVarQueryParameter['redraw'] = redraw;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lxSetting, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingApi - functional programming interface
 * @export
 */
export const SettingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {boolean} [redraw] 
         * @param {LxSetting} [lxSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractUploadSettingPost(redraw?: boolean, lxSetting?: LxSetting, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractUploadSettingPost(redraw, lxSetting, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SettingApi.apiLegalxtractUploadSettingPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SettingApi - factory interface
 * @export
 */
export const SettingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingApiFp(configuration)
    return {
        /**
         * 
         * @param {boolean} [redraw] 
         * @param {LxSetting} [lxSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractUploadSettingPost(redraw?: boolean, lxSetting?: LxSetting, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLegalxtractUploadSettingPost(redraw, lxSetting, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingApi - object-oriented interface
 * @export
 * @class SettingApi
 * @extends {BaseAPI}
 */
export class SettingApi extends BaseAPI {
    /**
     * 
     * @param {boolean} [redraw] 
     * @param {LxSetting} [lxSetting] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingApi
     */
    public apiLegalxtractUploadSettingPost(redraw?: boolean, lxSetting?: LxSetting, options?: RawAxiosRequestConfig) {
        return SettingApiFp(this.configuration).apiLegalxtractUploadSettingPost(redraw, lxSetting, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SplitDocumentApi - axios parameter creator
 * @export
 */
export const SplitDocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [splitFromViewerPageNo] 
         * @param {string} [newTabFileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSplitDocumentGet: async (splitFromViewerPageNo?: number, newTabFileName?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/SplitDocument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (splitFromViewerPageNo !== undefined) {
                localVarQueryParameter['splitFromViewerPageNo'] = splitFromViewerPageNo;
            }

            if (newTabFileName !== undefined) {
                localVarQueryParameter['newTabFileName'] = newTabFileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SplitDocumentApi - functional programming interface
 * @export
 */
export const SplitDocumentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SplitDocumentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [splitFromViewerPageNo] 
         * @param {string} [newTabFileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractSplitDocumentGet(splitFromViewerPageNo?: number, newTabFileName?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentInfoApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractSplitDocumentGet(splitFromViewerPageNo, newTabFileName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SplitDocumentApi.apiLegalxtractSplitDocumentGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SplitDocumentApi - factory interface
 * @export
 */
export const SplitDocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SplitDocumentApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [splitFromViewerPageNo] 
         * @param {string} [newTabFileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSplitDocumentGet(splitFromViewerPageNo?: number, newTabFileName?: string, options?: any): AxiosPromise<DocumentInfoApiResult> {
            return localVarFp.apiLegalxtractSplitDocumentGet(splitFromViewerPageNo, newTabFileName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SplitDocumentApi - object-oriented interface
 * @export
 * @class SplitDocumentApi
 * @extends {BaseAPI}
 */
export class SplitDocumentApi extends BaseAPI {
    /**
     * 
     * @param {number} [splitFromViewerPageNo] 
     * @param {string} [newTabFileName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SplitDocumentApi
     */
    public apiLegalxtractSplitDocumentGet(splitFromViewerPageNo?: number, newTabFileName?: string, options?: RawAxiosRequestConfig) {
        return SplitDocumentApiFp(this.configuration).apiLegalxtractSplitDocumentGet(splitFromViewerPageNo, newTabFileName, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * StampApi - axios parameter creator
 * @export
 */
export const StampApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [tabID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddStampPut: async (tabID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/AddStamp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tabID !== undefined) {
                localVarQueryParameter['tabID'] = tabID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [tabID] 
         * @param {TabStamp} [tabStamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractApplyOneStampPost: async (tabID?: string, tabStamp?: TabStamp, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ApplyOneStamp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tabID !== undefined) {
                localVarQueryParameter['tabID'] = tabID;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tabStamp, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [showFromToc] 
         * @param {boolean} [indexFromToc] 
         * @param {string} [tocIndexPattern] 
         * @param {TabStamp} [tabStamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractApplyStampAllPagePost: async (showFromToc?: boolean, indexFromToc?: boolean, tocIndexPattern?: string, tabStamp?: TabStamp, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ApplyStampAllPage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (showFromToc !== undefined) {
                localVarQueryParameter['showFromToc'] = showFromToc;
            }

            if (indexFromToc !== undefined) {
                localVarQueryParameter['indexFromToc'] = indexFromToc;
            }

            if (tocIndexPattern !== undefined) {
                localVarQueryParameter['tocIndexPattern'] = tocIndexPattern;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tabStamp, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [tabID] 
         * @param {string} [stampTitleIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractChangeCustomStampIndexPost: async (tabID?: string, stampTitleIndex?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ChangeCustomStampIndex`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tabID !== undefined) {
                localVarQueryParameter['tabID'] = tabID;
            }

            if (stampTitleIndex !== undefined) {
                localVarQueryParameter['stampTitleIndex'] = stampTitleIndex;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [tabID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteStampPost: async (tabID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/DeleteStamp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tabID !== undefined) {
                localVarQueryParameter['tabID'] = tabID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRemoveAllStampPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/RemoveAllStamp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [tabId] 
         * @param {UpdateAnnotationStampParam} [updateAnnotationStampParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractUpdateAnnotationStampPost: async (tabId?: string, updateAnnotationStampParam?: UpdateAnnotationStampParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/UpdateAnnotationStamp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tabId !== undefined) {
                localVarQueryParameter['tabId'] = tabId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAnnotationStampParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StampApi - functional programming interface
 * @export
 */
export const StampApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StampApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [tabID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractAddStampPut(tabID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentInfoPageDataResponseApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractAddStampPut(tabID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StampApi.apiLegalxtractAddStampPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [tabID] 
         * @param {TabStamp} [tabStamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractApplyOneStampPost(tabID?: string, tabStamp?: TabStamp, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentInfoPageDataResponseApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractApplyOneStampPost(tabID, tabStamp, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StampApi.apiLegalxtractApplyOneStampPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {boolean} [showFromToc] 
         * @param {boolean} [indexFromToc] 
         * @param {string} [tocIndexPattern] 
         * @param {TabStamp} [tabStamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractApplyStampAllPagePost(showFromToc?: boolean, indexFromToc?: boolean, tocIndexPattern?: string, tabStamp?: TabStamp, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentInfoPageDataResponseApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractApplyStampAllPagePost(showFromToc, indexFromToc, tocIndexPattern, tabStamp, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StampApi.apiLegalxtractApplyStampAllPagePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [tabID] 
         * @param {string} [stampTitleIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractChangeCustomStampIndexPost(tabID?: string, stampTitleIndex?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentInfoPageDataResponseApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractChangeCustomStampIndexPost(tabID, stampTitleIndex, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StampApi.apiLegalxtractChangeCustomStampIndexPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [tabID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractDeleteStampPost(tabID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentInfoPageDataResponseApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractDeleteStampPost(tabID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StampApi.apiLegalxtractDeleteStampPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractRemoveAllStampPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentInfoPageDataResponseApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractRemoveAllStampPost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StampApi.apiLegalxtractRemoveAllStampPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [tabId] 
         * @param {UpdateAnnotationStampParam} [updateAnnotationStampParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractUpdateAnnotationStampPost(tabId?: string, updateAnnotationStampParam?: UpdateAnnotationStampParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentInfoPageDataResponseApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractUpdateAnnotationStampPost(tabId, updateAnnotationStampParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StampApi.apiLegalxtractUpdateAnnotationStampPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * StampApi - factory interface
 * @export
 */
export const StampApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StampApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [tabID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddStampPut(tabID?: string, options?: any): AxiosPromise<DocumentInfoPageDataResponseApiResult> {
            return localVarFp.apiLegalxtractAddStampPut(tabID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [tabID] 
         * @param {TabStamp} [tabStamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractApplyOneStampPost(tabID?: string, tabStamp?: TabStamp, options?: any): AxiosPromise<DocumentInfoPageDataResponseApiResult> {
            return localVarFp.apiLegalxtractApplyOneStampPost(tabID, tabStamp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [showFromToc] 
         * @param {boolean} [indexFromToc] 
         * @param {string} [tocIndexPattern] 
         * @param {TabStamp} [tabStamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractApplyStampAllPagePost(showFromToc?: boolean, indexFromToc?: boolean, tocIndexPattern?: string, tabStamp?: TabStamp, options?: any): AxiosPromise<DocumentInfoPageDataResponseApiResult> {
            return localVarFp.apiLegalxtractApplyStampAllPagePost(showFromToc, indexFromToc, tocIndexPattern, tabStamp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [tabID] 
         * @param {string} [stampTitleIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractChangeCustomStampIndexPost(tabID?: string, stampTitleIndex?: string, options?: any): AxiosPromise<DocumentInfoPageDataResponseApiResult> {
            return localVarFp.apiLegalxtractChangeCustomStampIndexPost(tabID, stampTitleIndex, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [tabID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteStampPost(tabID?: string, options?: any): AxiosPromise<DocumentInfoPageDataResponseApiResult> {
            return localVarFp.apiLegalxtractDeleteStampPost(tabID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRemoveAllStampPost(options?: any): AxiosPromise<DocumentInfoPageDataResponseApiResult> {
            return localVarFp.apiLegalxtractRemoveAllStampPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [tabId] 
         * @param {UpdateAnnotationStampParam} [updateAnnotationStampParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractUpdateAnnotationStampPost(tabId?: string, updateAnnotationStampParam?: UpdateAnnotationStampParam, options?: any): AxiosPromise<DocumentInfoPageDataResponseApiResult> {
            return localVarFp.apiLegalxtractUpdateAnnotationStampPost(tabId, updateAnnotationStampParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StampApi - object-oriented interface
 * @export
 * @class StampApi
 * @extends {BaseAPI}
 */
export class StampApi extends BaseAPI {
    /**
     * 
     * @param {string} [tabID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StampApi
     */
    public apiLegalxtractAddStampPut(tabID?: string, options?: RawAxiosRequestConfig) {
        return StampApiFp(this.configuration).apiLegalxtractAddStampPut(tabID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [tabID] 
     * @param {TabStamp} [tabStamp] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StampApi
     */
    public apiLegalxtractApplyOneStampPost(tabID?: string, tabStamp?: TabStamp, options?: RawAxiosRequestConfig) {
        return StampApiFp(this.configuration).apiLegalxtractApplyOneStampPost(tabID, tabStamp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [showFromToc] 
     * @param {boolean} [indexFromToc] 
     * @param {string} [tocIndexPattern] 
     * @param {TabStamp} [tabStamp] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StampApi
     */
    public apiLegalxtractApplyStampAllPagePost(showFromToc?: boolean, indexFromToc?: boolean, tocIndexPattern?: string, tabStamp?: TabStamp, options?: RawAxiosRequestConfig) {
        return StampApiFp(this.configuration).apiLegalxtractApplyStampAllPagePost(showFromToc, indexFromToc, tocIndexPattern, tabStamp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [tabID] 
     * @param {string} [stampTitleIndex] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StampApi
     */
    public apiLegalxtractChangeCustomStampIndexPost(tabID?: string, stampTitleIndex?: string, options?: RawAxiosRequestConfig) {
        return StampApiFp(this.configuration).apiLegalxtractChangeCustomStampIndexPost(tabID, stampTitleIndex, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [tabID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StampApi
     */
    public apiLegalxtractDeleteStampPost(tabID?: string, options?: RawAxiosRequestConfig) {
        return StampApiFp(this.configuration).apiLegalxtractDeleteStampPost(tabID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StampApi
     */
    public apiLegalxtractRemoveAllStampPost(options?: RawAxiosRequestConfig) {
        return StampApiFp(this.configuration).apiLegalxtractRemoveAllStampPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [tabId] 
     * @param {UpdateAnnotationStampParam} [updateAnnotationStampParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StampApi
     */
    public apiLegalxtractUpdateAnnotationStampPost(tabId?: string, updateAnnotationStampParam?: UpdateAnnotationStampParam, options?: RawAxiosRequestConfig) {
        return StampApiFp(this.configuration).apiLegalxtractUpdateAnnotationStampPost(tabId, updateAnnotationStampParam, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TabApi - axios parameter creator
 * @export
 */
export const TabApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [tabUniqueId] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRenameTabGet: async (tabUniqueId?: string, name?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/RenameTab`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tabUniqueId !== undefined) {
                localVarQueryParameter['tabUniqueId'] = tabUniqueId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TabApi - functional programming interface
 * @export
 */
export const TabApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TabApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [tabUniqueId] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractRenameTabGet(tabUniqueId?: string, name?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractRenameTabGet(tabUniqueId, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TabApi.apiLegalxtractRenameTabGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TabApi - factory interface
 * @export
 */
export const TabApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TabApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [tabUniqueId] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRenameTabGet(tabUniqueId?: string, name?: string, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.apiLegalxtractRenameTabGet(tabUniqueId, name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TabApi - object-oriented interface
 * @export
 * @class TabApi
 * @extends {BaseAPI}
 */
export class TabApi extends BaseAPI {
    /**
     * 
     * @param {string} [tabUniqueId] 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TabApi
     */
    public apiLegalxtractRenameTabGet(tabUniqueId?: string, name?: string, options?: RawAxiosRequestConfig) {
        return TabApiFp(this.configuration).apiLegalxtractRenameTabGet(tabUniqueId, name, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TagApi - axios parameter creator
 * @export
 */
export const TagApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddTagCategoryParam} [addTagCategoryParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddTagCategoryPost: async (addTagCategoryParam?: AddTagCategoryParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/AddTagCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addTagCategoryParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddTagNameParam} [addTagNameParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddTagNamePost: async (addTagNameParam?: AddTagNameParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/AddTagName`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addTagNameParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [tagCategoryID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteTagCategoryGet: async (tagCategoryID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/DeleteTagCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tagCategoryID !== undefined) {
                localVarQueryParameter['tagCategoryID'] = tagCategoryID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [tagId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteTagNameGet: async (tagId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/DeleteTagName`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tagId !== undefined) {
                localVarQueryParameter['tagId'] = tagId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteTagPost: async (requestBody?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/DeleteTag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TagCategory} [tagCategory] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractEditTagCategoryPost: async (tagCategory?: TagCategory, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/EditTagCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tagCategory, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractExportTagDictionaryGet: async (fileName?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ExportTagDictionary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractInportFromFilePost: async (file?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/InportFromFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractLoadTagDictionaryForClientGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/LoadTagDictionaryForClient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRemoveAllTagNotExistsPost: async (pageNo?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/RemoveAllTagNotExists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SaveTagParam} [saveTagParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSaveTagPost: async (saveTagParam?: SaveTagParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/SaveTag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveTagParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SelectionTagParam} [selectionTagParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSelectionTagPost: async (selectionTagParam?: SelectionTagParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/SelectionTag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(selectionTagParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TagApi - functional programming interface
 * @export
 */
export const TagApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TagApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddTagCategoryParam} [addTagCategoryParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractAddTagCategoryPost(addTagCategoryParam?: AddTagCategoryParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagCategoryListApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractAddTagCategoryPost(addTagCategoryParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagApi.apiLegalxtractAddTagCategoryPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AddTagNameParam} [addTagNameParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractAddTagNamePost(addTagNameParam?: AddTagNameParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagNameListApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractAddTagNamePost(addTagNameParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagApi.apiLegalxtractAddTagNamePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [tagCategoryID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractDeleteTagCategoryGet(tagCategoryID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractDeleteTagCategoryGet(tagCategoryID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagApi.apiLegalxtractDeleteTagCategoryGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [tagId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractDeleteTagNameGet(tagId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractDeleteTagNameGet(tagId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagApi.apiLegalxtractDeleteTagNameGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractDeleteTagPost(requestBody?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractDeleteTagPost(requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagApi.apiLegalxtractDeleteTagPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {TagCategory} [tagCategory] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractEditTagCategoryPost(tagCategory?: TagCategory, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagCategoryListApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractEditTagCategoryPost(tagCategory, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagApi.apiLegalxtractEditTagCategoryPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractExportTagDictionaryGet(fileName?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractExportTagDictionaryGet(fileName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagApi.apiLegalxtractExportTagDictionaryGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractInportFromFilePost(file?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractInportFromFilePost(file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagApi.apiLegalxtractInportFromFilePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractLoadTagDictionaryForClientGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractLoadTagDictionaryForClientGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagApi.apiLegalxtractLoadTagDictionaryForClientGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [pageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractRemoveAllTagNotExistsPost(pageNo?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LxPageDataApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractRemoveAllTagNotExistsPost(pageNo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagApi.apiLegalxtractRemoveAllTagNotExistsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SaveTagParam} [saveTagParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractSaveTagPost(saveTagParam?: SaveTagParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractSaveTagPost(saveTagParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagApi.apiLegalxtractSaveTagPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SelectionTagParam} [selectionTagParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractSelectionTagPost(selectionTagParam?: SelectionTagParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidMarkedInfoWithIdListDictionaryPageDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractSelectionTagPost(selectionTagParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagApi.apiLegalxtractSelectionTagPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TagApi - factory interface
 * @export
 */
export const TagApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TagApiFp(configuration)
    return {
        /**
         * 
         * @param {AddTagCategoryParam} [addTagCategoryParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddTagCategoryPost(addTagCategoryParam?: AddTagCategoryParam, options?: any): AxiosPromise<TagCategoryListApiResult> {
            return localVarFp.apiLegalxtractAddTagCategoryPost(addTagCategoryParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddTagNameParam} [addTagNameParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddTagNamePost(addTagNameParam?: AddTagNameParam, options?: any): AxiosPromise<TagNameListApiResult> {
            return localVarFp.apiLegalxtractAddTagNamePost(addTagNameParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [tagCategoryID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteTagCategoryGet(tagCategoryID?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLegalxtractDeleteTagCategoryGet(tagCategoryID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [tagId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteTagNameGet(tagId?: string, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.apiLegalxtractDeleteTagNameGet(tagId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteTagPost(requestBody?: Array<string>, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLegalxtractDeleteTagPost(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TagCategory} [tagCategory] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractEditTagCategoryPost(tagCategory?: TagCategory, options?: any): AxiosPromise<TagCategoryListApiResult> {
            return localVarFp.apiLegalxtractEditTagCategoryPost(tagCategory, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractExportTagDictionaryGet(fileName?: string, options?: any): AxiosPromise<File> {
            return localVarFp.apiLegalxtractExportTagDictionaryGet(fileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractInportFromFilePost(file?: File, options?: any): AxiosPromise<TagData> {
            return localVarFp.apiLegalxtractInportFromFilePost(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractLoadTagDictionaryForClientGet(options?: any): AxiosPromise<TagData> {
            return localVarFp.apiLegalxtractLoadTagDictionaryForClientGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRemoveAllTagNotExistsPost(pageNo?: number, options?: any): AxiosPromise<LxPageDataApiResult> {
            return localVarFp.apiLegalxtractRemoveAllTagNotExistsPost(pageNo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SaveTagParam} [saveTagParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSaveTagPost(saveTagParam?: SaveTagParam, options?: any): AxiosPromise<StringApiResult> {
            return localVarFp.apiLegalxtractSaveTagPost(saveTagParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SelectionTagParam} [selectionTagParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSelectionTagPost(selectionTagParam?: SelectionTagParam, options?: any): AxiosPromise<GuidMarkedInfoWithIdListDictionaryPageDataResponse> {
            return localVarFp.apiLegalxtractSelectionTagPost(selectionTagParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TagApi - object-oriented interface
 * @export
 * @class TagApi
 * @extends {BaseAPI}
 */
export class TagApi extends BaseAPI {
    /**
     * 
     * @param {AddTagCategoryParam} [addTagCategoryParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public apiLegalxtractAddTagCategoryPost(addTagCategoryParam?: AddTagCategoryParam, options?: RawAxiosRequestConfig) {
        return TagApiFp(this.configuration).apiLegalxtractAddTagCategoryPost(addTagCategoryParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddTagNameParam} [addTagNameParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public apiLegalxtractAddTagNamePost(addTagNameParam?: AddTagNameParam, options?: RawAxiosRequestConfig) {
        return TagApiFp(this.configuration).apiLegalxtractAddTagNamePost(addTagNameParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [tagCategoryID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public apiLegalxtractDeleteTagCategoryGet(tagCategoryID?: string, options?: RawAxiosRequestConfig) {
        return TagApiFp(this.configuration).apiLegalxtractDeleteTagCategoryGet(tagCategoryID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [tagId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public apiLegalxtractDeleteTagNameGet(tagId?: string, options?: RawAxiosRequestConfig) {
        return TagApiFp(this.configuration).apiLegalxtractDeleteTagNameGet(tagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public apiLegalxtractDeleteTagPost(requestBody?: Array<string>, options?: RawAxiosRequestConfig) {
        return TagApiFp(this.configuration).apiLegalxtractDeleteTagPost(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TagCategory} [tagCategory] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public apiLegalxtractEditTagCategoryPost(tagCategory?: TagCategory, options?: RawAxiosRequestConfig) {
        return TagApiFp(this.configuration).apiLegalxtractEditTagCategoryPost(tagCategory, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fileName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public apiLegalxtractExportTagDictionaryGet(fileName?: string, options?: RawAxiosRequestConfig) {
        return TagApiFp(this.configuration).apiLegalxtractExportTagDictionaryGet(fileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public apiLegalxtractInportFromFilePost(file?: File, options?: RawAxiosRequestConfig) {
        return TagApiFp(this.configuration).apiLegalxtractInportFromFilePost(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public apiLegalxtractLoadTagDictionaryForClientGet(options?: RawAxiosRequestConfig) {
        return TagApiFp(this.configuration).apiLegalxtractLoadTagDictionaryForClientGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [pageNo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public apiLegalxtractRemoveAllTagNotExistsPost(pageNo?: number, options?: RawAxiosRequestConfig) {
        return TagApiFp(this.configuration).apiLegalxtractRemoveAllTagNotExistsPost(pageNo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SaveTagParam} [saveTagParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public apiLegalxtractSaveTagPost(saveTagParam?: SaveTagParam, options?: RawAxiosRequestConfig) {
        return TagApiFp(this.configuration).apiLegalxtractSaveTagPost(saveTagParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SelectionTagParam} [selectionTagParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public apiLegalxtractSelectionTagPost(selectionTagParam?: SelectionTagParam, options?: RawAxiosRequestConfig) {
        return TagApiFp(this.configuration).apiLegalxtractSelectionTagPost(selectionTagParam, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ThumbnailApi - axios parameter creator
 * @export
 */
export const ThumbnailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SetSplitOnExtractParam} [setSplitOnExtractParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSetSplitOnExtractPost: async (setSplitOnExtractParam?: SetSplitOnExtractParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/SetSplitOnExtract`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setSplitOnExtractParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ShowHidePagesParam} [showHidePagesParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractShowHidePagesPost: async (showHidePagesParam?: ShowHidePagesParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ShowHidePages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(showHidePagesParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ThumbnailApi - functional programming interface
 * @export
 */
export const ThumbnailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ThumbnailApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SetSplitOnExtractParam} [setSplitOnExtractParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractSetSplitOnExtractPost(setSplitOnExtractParam?: SetSplitOnExtractParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentInfoApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractSetSplitOnExtractPost(setSplitOnExtractParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ThumbnailApi.apiLegalxtractSetSplitOnExtractPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ShowHidePagesParam} [showHidePagesParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractShowHidePagesPost(showHidePagesParam?: ShowHidePagesParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentInfoApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractShowHidePagesPost(showHidePagesParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ThumbnailApi.apiLegalxtractShowHidePagesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ThumbnailApi - factory interface
 * @export
 */
export const ThumbnailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ThumbnailApiFp(configuration)
    return {
        /**
         * 
         * @param {SetSplitOnExtractParam} [setSplitOnExtractParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSetSplitOnExtractPost(setSplitOnExtractParam?: SetSplitOnExtractParam, options?: any): AxiosPromise<DocumentInfoApiResult> {
            return localVarFp.apiLegalxtractSetSplitOnExtractPost(setSplitOnExtractParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ShowHidePagesParam} [showHidePagesParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractShowHidePagesPost(showHidePagesParam?: ShowHidePagesParam, options?: any): AxiosPromise<DocumentInfoApiResult> {
            return localVarFp.apiLegalxtractShowHidePagesPost(showHidePagesParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ThumbnailApi - object-oriented interface
 * @export
 * @class ThumbnailApi
 * @extends {BaseAPI}
 */
export class ThumbnailApi extends BaseAPI {
    /**
     * 
     * @param {SetSplitOnExtractParam} [setSplitOnExtractParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThumbnailApi
     */
    public apiLegalxtractSetSplitOnExtractPost(setSplitOnExtractParam?: SetSplitOnExtractParam, options?: RawAxiosRequestConfig) {
        return ThumbnailApiFp(this.configuration).apiLegalxtractSetSplitOnExtractPost(setSplitOnExtractParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ShowHidePagesParam} [showHidePagesParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThumbnailApi
     */
    public apiLegalxtractShowHidePagesPost(showHidePagesParam?: ShowHidePagesParam, options?: RawAxiosRequestConfig) {
        return ThumbnailApiFp(this.configuration).apiLegalxtractShowHidePagesPost(showHidePagesParam, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TocApi - axios parameter creator
 * @export
 */
export const TocApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {EditTocHeaderParam} [editTocHeaderParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractEditTocHeaderPost: async (editTocHeaderParam?: EditTocHeaderParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/EditTocHeader`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editTocHeaderParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TocRow} [tocRow] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractEditTocRowPost: async (tocRow?: TocRow, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/EditTocRow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tocRow, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetTocRowGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetTocRow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [isShowToc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractProcessTocGet: async (isShowToc?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ProcessToc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (isShowToc !== undefined) {
                localVarQueryParameter['isShowToc'] = isShowToc;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReOrderTOCParam} [reOrderTOCParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractReOrderTOCPost: async (reOrderTOCParam?: ReOrderTOCParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ReOrderTOC`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reOrderTOCParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRecalculatePagesGet: async (pageCount?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/RecalculatePages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageCount !== undefined) {
                localVarQueryParameter['pageCount'] = pageCount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TocSettings} [tocSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSaveTocSettingsPost: async (tocSettings?: TocSettings, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/SaveTocSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tocSettings, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TocApi - functional programming interface
 * @export
 */
export const TocApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TocApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {EditTocHeaderParam} [editTocHeaderParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractEditTocHeaderPost(editTocHeaderParam?: EditTocHeaderParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TocDataApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractEditTocHeaderPost(editTocHeaderParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TocApi.apiLegalxtractEditTocHeaderPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {TocRow} [tocRow] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractEditTocRowPost(tocRow?: TocRow, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TocDataApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractEditTocRowPost(tocRow, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TocApi.apiLegalxtractEditTocRowPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetTocRowGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TocRowListApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetTocRowGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TocApi.apiLegalxtractGetTocRowGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {boolean} [isShowToc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractProcessTocGet(isShowToc?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentInfoApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractProcessTocGet(isShowToc, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TocApi.apiLegalxtractProcessTocGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ReOrderTOCParam} [reOrderTOCParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractReOrderTOCPost(reOrderTOCParam?: ReOrderTOCParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TocDataApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractReOrderTOCPost(reOrderTOCParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TocApi.apiLegalxtractReOrderTOCPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [pageCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractRecalculatePagesGet(pageCount?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentInfoApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractRecalculatePagesGet(pageCount, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TocApi.apiLegalxtractRecalculatePagesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {TocSettings} [tocSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractSaveTocSettingsPost(tocSettings?: TocSettings, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TocData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractSaveTocSettingsPost(tocSettings, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TocApi.apiLegalxtractSaveTocSettingsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TocApi - factory interface
 * @export
 */
export const TocApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TocApiFp(configuration)
    return {
        /**
         * 
         * @param {EditTocHeaderParam} [editTocHeaderParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractEditTocHeaderPost(editTocHeaderParam?: EditTocHeaderParam, options?: any): AxiosPromise<TocDataApiResult> {
            return localVarFp.apiLegalxtractEditTocHeaderPost(editTocHeaderParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TocRow} [tocRow] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractEditTocRowPost(tocRow?: TocRow, options?: any): AxiosPromise<TocDataApiResult> {
            return localVarFp.apiLegalxtractEditTocRowPost(tocRow, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetTocRowGet(options?: any): AxiosPromise<TocRowListApiResult> {
            return localVarFp.apiLegalxtractGetTocRowGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [isShowToc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractProcessTocGet(isShowToc?: boolean, options?: any): AxiosPromise<DocumentInfoApiResult> {
            return localVarFp.apiLegalxtractProcessTocGet(isShowToc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReOrderTOCParam} [reOrderTOCParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractReOrderTOCPost(reOrderTOCParam?: ReOrderTOCParam, options?: any): AxiosPromise<TocDataApiResult> {
            return localVarFp.apiLegalxtractReOrderTOCPost(reOrderTOCParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRecalculatePagesGet(pageCount?: number, options?: any): AxiosPromise<DocumentInfoApiResult> {
            return localVarFp.apiLegalxtractRecalculatePagesGet(pageCount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TocSettings} [tocSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSaveTocSettingsPost(tocSettings?: TocSettings, options?: any): AxiosPromise<TocData> {
            return localVarFp.apiLegalxtractSaveTocSettingsPost(tocSettings, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TocApi - object-oriented interface
 * @export
 * @class TocApi
 * @extends {BaseAPI}
 */
export class TocApi extends BaseAPI {
    /**
     * 
     * @param {EditTocHeaderParam} [editTocHeaderParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TocApi
     */
    public apiLegalxtractEditTocHeaderPost(editTocHeaderParam?: EditTocHeaderParam, options?: RawAxiosRequestConfig) {
        return TocApiFp(this.configuration).apiLegalxtractEditTocHeaderPost(editTocHeaderParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TocRow} [tocRow] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TocApi
     */
    public apiLegalxtractEditTocRowPost(tocRow?: TocRow, options?: RawAxiosRequestConfig) {
        return TocApiFp(this.configuration).apiLegalxtractEditTocRowPost(tocRow, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TocApi
     */
    public apiLegalxtractGetTocRowGet(options?: RawAxiosRequestConfig) {
        return TocApiFp(this.configuration).apiLegalxtractGetTocRowGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [isShowToc] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TocApi
     */
    public apiLegalxtractProcessTocGet(isShowToc?: boolean, options?: RawAxiosRequestConfig) {
        return TocApiFp(this.configuration).apiLegalxtractProcessTocGet(isShowToc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReOrderTOCParam} [reOrderTOCParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TocApi
     */
    public apiLegalxtractReOrderTOCPost(reOrderTOCParam?: ReOrderTOCParam, options?: RawAxiosRequestConfig) {
        return TocApiFp(this.configuration).apiLegalxtractReOrderTOCPost(reOrderTOCParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [pageCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TocApi
     */
    public apiLegalxtractRecalculatePagesGet(pageCount?: number, options?: RawAxiosRequestConfig) {
        return TocApiFp(this.configuration).apiLegalxtractRecalculatePagesGet(pageCount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TocSettings} [tocSettings] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TocApi
     */
    public apiLegalxtractSaveTocSettingsPost(tocSettings?: TocSettings, options?: RawAxiosRequestConfig) {
        return TocApiFp(this.configuration).apiLegalxtractSaveTocSettingsPost(tocSettings, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ViewApi - axios parameter creator
 * @export
 */
export const ViewApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [pageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSetCurrentPageNoGet: async (pageNo?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/SetCurrentPageNo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ViewApi - functional programming interface
 * @export
 */
export const ViewApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ViewApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [pageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractSetCurrentPageNoGet(pageNo?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LxPageData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractSetCurrentPageNoGet(pageNo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ViewApi.apiLegalxtractSetCurrentPageNoGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ViewApi - factory interface
 * @export
 */
export const ViewApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ViewApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [pageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSetCurrentPageNoGet(pageNo?: number, options?: any): AxiosPromise<LxPageData> {
            return localVarFp.apiLegalxtractSetCurrentPageNoGet(pageNo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ViewApi - object-oriented interface
 * @export
 * @class ViewApi
 * @extends {BaseAPI}
 */
export class ViewApi extends BaseAPI {
    /**
     * 
     * @param {number} [pageNo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViewApi
     */
    public apiLegalxtractSetCurrentPageNoGet(pageNo?: number, options?: RawAxiosRequestConfig) {
        return ViewApiFp(this.configuration).apiLegalxtractSetCurrentPageNoGet(pageNo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WatchDogApi - axios parameter creator
 * @export
 */
export const WatchDogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CalculateWatchDogParam} [calculateWatchDogParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCalculateWatchDogPost: async (calculateWatchDogParam?: CalculateWatchDogParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/CalculateWatchDog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calculateWatchDogParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UnSelectedAllSelectAllRowParam} [unSelectedAllSelectAllRowParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeSelectAllPost: async (unSelectedAllSelectAllRowParam?: UnSelectedAllSelectAllRowParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/DeSelectAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unSelectedAllSelectAllRowParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UnSelectedAllSelectAllRowParam} [unSelectedAllSelectAllRowParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSelectAllPost: async (unSelectedAllSelectAllRowParam?: UnSelectedAllSelectAllRowParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/SelectAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unSelectedAllSelectAllRowParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WatchDogApi - functional programming interface
 * @export
 */
export const WatchDogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WatchDogApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CalculateWatchDogParam} [calculateWatchDogParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractCalculateWatchDogPost(calculateWatchDogParam?: CalculateWatchDogParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WatchDogNode>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractCalculateWatchDogPost(calculateWatchDogParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WatchDogApi.apiLegalxtractCalculateWatchDogPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UnSelectedAllSelectAllRowParam} [unSelectedAllSelectAllRowParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractDeSelectAllPost(unSelectedAllSelectAllRowParam?: UnSelectedAllSelectAllRowParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HighLightSearchResultsResponseApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractDeSelectAllPost(unSelectedAllSelectAllRowParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WatchDogApi.apiLegalxtractDeSelectAllPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UnSelectedAllSelectAllRowParam} [unSelectedAllSelectAllRowParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractSelectAllPost(unSelectedAllSelectAllRowParam?: UnSelectedAllSelectAllRowParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HighLightSearchResultsResponseApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractSelectAllPost(unSelectedAllSelectAllRowParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WatchDogApi.apiLegalxtractSelectAllPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WatchDogApi - factory interface
 * @export
 */
export const WatchDogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WatchDogApiFp(configuration)
    return {
        /**
         * 
         * @param {CalculateWatchDogParam} [calculateWatchDogParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCalculateWatchDogPost(calculateWatchDogParam?: CalculateWatchDogParam, options?: any): AxiosPromise<Array<WatchDogNode>> {
            return localVarFp.apiLegalxtractCalculateWatchDogPost(calculateWatchDogParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UnSelectedAllSelectAllRowParam} [unSelectedAllSelectAllRowParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeSelectAllPost(unSelectedAllSelectAllRowParam?: UnSelectedAllSelectAllRowParam, options?: any): AxiosPromise<HighLightSearchResultsResponseApiResult> {
            return localVarFp.apiLegalxtractDeSelectAllPost(unSelectedAllSelectAllRowParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UnSelectedAllSelectAllRowParam} [unSelectedAllSelectAllRowParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSelectAllPost(unSelectedAllSelectAllRowParam?: UnSelectedAllSelectAllRowParam, options?: any): AxiosPromise<HighLightSearchResultsResponseApiResult> {
            return localVarFp.apiLegalxtractSelectAllPost(unSelectedAllSelectAllRowParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WatchDogApi - object-oriented interface
 * @export
 * @class WatchDogApi
 * @extends {BaseAPI}
 */
export class WatchDogApi extends BaseAPI {
    /**
     * 
     * @param {CalculateWatchDogParam} [calculateWatchDogParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WatchDogApi
     */
    public apiLegalxtractCalculateWatchDogPost(calculateWatchDogParam?: CalculateWatchDogParam, options?: RawAxiosRequestConfig) {
        return WatchDogApiFp(this.configuration).apiLegalxtractCalculateWatchDogPost(calculateWatchDogParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UnSelectedAllSelectAllRowParam} [unSelectedAllSelectAllRowParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WatchDogApi
     */
    public apiLegalxtractDeSelectAllPost(unSelectedAllSelectAllRowParam?: UnSelectedAllSelectAllRowParam, options?: RawAxiosRequestConfig) {
        return WatchDogApiFp(this.configuration).apiLegalxtractDeSelectAllPost(unSelectedAllSelectAllRowParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UnSelectedAllSelectAllRowParam} [unSelectedAllSelectAllRowParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WatchDogApi
     */
    public apiLegalxtractSelectAllPost(unSelectedAllSelectAllRowParam?: UnSelectedAllSelectAllRowParam, options?: RawAxiosRequestConfig) {
        return WatchDogApiFp(this.configuration).apiLegalxtractSelectAllPost(unSelectedAllSelectAllRowParam, options).then((request) => request(this.axios, this.basePath));
    }
}




import React, { useState } from "react";
import { Button, Col, Input, Modal, Row } from "antd";
import { useLang } from "../../i18n/useLang";
import {
  MDStampIndexService,
  MDStampIndexServiceProps,
} from "../../Services/Hook/Modal/Stamp/MDStampIndexService";

const MDStampIndex = ({
  open,
  onClose,
  editItem,
}: MDStampIndexServiceProps) => {
  const { tr } = useLang();
  const { formik, ResetForm } = MDStampIndexService({
    onClose,
    open,
    editItem,
  });
  return (
    <>
      <Modal
        title={tr("stamp")}
        footer={
          <>
            <Button
              style={{ minWidth: 100 }}
              type="default"
              onClick={() => {
                onClose && onClose();
              }}
            >
              {tr("close")}
            </Button>
            <Button
              style={{ minWidth: 100 }}
              type="default"
              onClick={ResetForm}
            >
              {tr("clear")}
            </Button>
            <Button
              style={{ minWidth: 100 }}
              type="primary"
              onClick={() => {
                formik.submitForm();
              }}
            >
              {tr("submit")}
            </Button>
          </>
        }
        centered
        open={open}
        maskClosable={false}
        width={550}
        onCancel={() => {
          onClose && onClose();
        }}
      >
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <label>{tr("index")}</label>
            <Input
              name="index"
              value={formik.values.index || ""}
              onChange={formik.handleChange}
              width={"100%"}
            ></Input>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default MDStampIndex;

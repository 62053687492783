import { create } from "zustand";
import { persist } from "zustand/middleware";
import * as Api from "../Api/src/index";
interface TagState {
  Tags?: Api.TagData | null;
  ShowTagList?: boolean;
}
interface TagStore {
  TagState: TagState;
  SetTagStore: (tagState: TagState) => void;
}
const useTagStore = create<TagStore>()((set) => ({
  TagState: { Tags: null, ShowTagList: false },
  SetTagStore: (tagState) =>
    set((state) => {
      return {
        ...state,
        TagState: { ...state.TagState, ...tagState },
      };
    }),
}));
export default useTagStore;

import useReplaceStore from "../../../ZustandStore/ReplaceStore";
import useTocStore from "../../../ZustandStore/TocStore";
import useWatchdogStore from "../../../ZustandStore/WatchdogStore";
import * as Api from "../../../Api/src";
import useLeftPanelStore, {
  LeftTabName,
} from "../../../ZustandStore/LeftPanelStore";
import { buildBookmarkTree } from "./BookmarkPanelService";
import useBookmarkStore from "../../../ZustandStore/BookmarkStore";
import useNoteStore from "../../../ZustandStore/NoteStore";
import useLogStore from "../../../ZustandStore/LogStore";
import useCommentStore from "../../../ZustandStore/CommentStore";
import { AppCache } from "../../../Cache/AppCache";
import useSearchTabStore from "../../../ZustandStore/SearchTabStore";
import { SearchService } from "../../NotHook/SearchService";
import { ThumbnailPanelService } from "./ThumbnailPanelService";
const ReplaceApi = new Api.ReplaceApi();
const TocApi = new Api.TocApi();
const WatchDogApi = new Api.WatchDogApi();
const NoteApi = new Api.NoteApi();
const HistoryApi = new Api.HistoryApi();
const BookMarkApi = new Api.BookMarkApi();
const CommentApi = new Api.CommentApi();
const SearchApi = new Api.SearchApi();
export const LeftPanelService = () => {
  const { BookmarkState, SetBookmarkStore } = useBookmarkStore();
  const { SetReplaceStore } = useReplaceStore();
  const { SetTocStore } = useTocStore();
  const { SearchTabState, SetSearchTabStore } = useSearchTabStore();
  const {
    SetWatchDogParamWeb,
    SetWatchdogDatas,
    WatchDogParamWeb,
    WatchdogDatas,
    setExpandedRowKeys,
  } = useWatchdogStore();
  const { SetNoteStore } = useNoteStore();
  const { CommentState, SetCommentStore } = useCommentStore();
  const { LogState, SetLogStore } = useLogStore();
  const { LeftPanelState, SetLeftPanelStore } = useLeftPanelStore();
  const { GetSearchTemplateParam } = SearchService();
  const { RefreshThumnails } = ThumbnailPanelService();
  const LoadToc = () => {
    TocApi.apiLegalxtractGetTocRowGet().then((res) => {
      SetTocStore({
        TocRows: res.data.result || [],
      });
    });
  };
  const LoadReplaceObjDisplays = () => {
    ReplaceApi.apiLegalxtractCalculateReplaceGet().then((res) => {
      SetReplaceStore({ ReplaceObjDisplays: res.data });
    });
  };
  const LoadWatchDogData = async (watchDogParamWeb?: Api.WatchDogParamWeb) => {
    const data = await WatchDogApi.apiLegalxtractCalculateWatchDogPost({
      param: { ...WatchDogParamWeb, ...(watchDogParamWeb || {}) },
      searchTemplateParam: SearchService().GetSearchTemplateParam(),
    });
    if (watchDogParamWeb) {
      SetWatchDogParamWeb(watchDogParamWeb);
    }
    SetWatchdogDatas(data.data);
    setExpandedRowKeys(data.data.map((x) => x.id!));
  };
  const LoadBookmark = () => {
    BookMarkApi.apiLegalxtractGetAllBookmarkObjsGet().then((response) => {
      if (response.data.result) {
        const tree = buildBookmarkTree(response.data.result);
        let selectedKey = BookmarkState.BookmarkSelectedKeys || [];
        if (
          BookmarkState.BookmarkSelectedKeys?.length === 0 &&
          tree.length > 0
        ) {
          selectedKey = [tree[0].key.toString()];
        }
        SetBookmarkStore({
          BookmarkRows: response.data.result,
          BookmarkTree: tree,
          BookmarkSelectedKeys: selectedKey,
        });
      }
    });
  };
  const LoadNoteData = () => {
    NoteApi.apiLegalxtractGetListNoteGet().then((res) => {
      SetNoteStore({ NoteTextObjs: res.data.result || [] });
    });
  };
  const LoadLogsData = (TabID?: string) => {
    HistoryApi.apiLegalxtractGetDataHistoryGet(TabID || LogState.TabID).then(
      (res) => {
        SetLogStore({ HistoryViewItemWebs: res.data.result || [] });
      }
    );
  };
  const LoadCommentData = (OrderBy?: Api.CommentOrderByEnum) => {
    CommentApi.apiLegalxtractGetListCommentGet(
      OrderBy || CommentState.OrderBy
    ).then((res) => {
      SetCommentStore({ CommentObjDisplayWebs: res.data.result || [] });
    });
  };
  const LoadLeftPanelData = (LeftTab?: LeftTabName[]) => {
    let tab = AppCache.LeftTabActive as LeftTabName | undefined;
    if (LeftTab && LeftTab.length > 0 && LeftTab?.indexOf(tab!) < 0) {
      tab = undefined;
    }
    switch (tab) {
      case "pnReplace":
        LoadReplaceObjDisplays();
        break;
      case "pnWatchDog":
        LoadWatchDogData();
        break;
      case "pnTOC":
        LoadToc();
        break;
      case "pnBookmark":
        LoadBookmark();
        break;
      case "pnNote":
        LoadNoteData();
        break;
      case "pnLog":
        LoadLogsData();
        break;
      case "pnComment":
        LoadCommentData();
        break;
      case "pnFind":
        SetSearchTabStore({ runSearch: true });
        break;
      case "pnThumbnail":
        RefreshThumnails();
        break;
      default:
        break;
    }
  };
  return {
    LoadLeftPanelData,
    LoadBookmark,
    LoadNoteData,
    LoadLogsData,
    LoadCommentData,
  };
};

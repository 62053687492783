import { create } from "zustand";
import { persist } from "zustand/middleware";
interface PageState {
  CurrentPageNo?: number;
  TotalPages?: number;
}
interface PageStore {
  PageState: PageState;
  SetPageStore: (pageState: PageState) => void;
}
const usePageStore = create<PageStore>()((set) => ({
  PageState: { CurrentPageNo: 0, TotalPages: 0 },
  SetPageStore: (pageState) =>
    set((state) => ({
      ...state,
      PageState: { ...state.PageState, ...pageState },
    })),
}));

export default usePageStore;

import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  ColorPicker,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
} from "antd";
import { useLang } from "../i18n/useLang";
import "../Assets/Styles/Modal/MDSettings.scss";
import TextArea from "antd/es/input/TextArea";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Country,
  MDAppSettingsProps,
  MDAppSettingsService,
} from "../Services/Hook/Modal/MDAppSettingsService";
import * as Api from "../Api/src/index";
const MDSettings = ({ onClose, open }: MDAppSettingsProps) => {
  const { tr } = useLang();
  const {
    formik,
    fontList,
    fileInputWatermarkRef,
    fileInputHiddenPageRef,
    onSelectedWatermarkFile,
    ChooseWatermarkFile,
    DeleteWatermarkFile,
    onSelectedHiddenFile,
    ChooseHiddenFile,
    DeleteHiddenFile,
  } = MDAppSettingsService({ onClose, open });
  return (
    <>
      <Modal
        title={tr("settings")}
        footer={
          <>
            <Button
              style={{ minWidth: 100 }}
              type="default"
              onClick={() => {
                onClose && onClose();
              }}
            >
              {tr("close")}
            </Button>
            <Button
              onClick={() => {
                formik.handleSubmit();
              }}
              style={{ minWidth: 100 }}
              type="primary"
            >
              {tr("save")}
            </Button>
          </>
        }
        centered
        open={open}
        maskClosable={false}
        width={850}
        onCancel={() => {
          onClose && onClose();
        }}
      >
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Col span={24} className="table-settings">
              <Col span={24} className="table-header">
                <label>{tr("openAndSelect")}</label>
              </Col>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <Checkbox
                    name="ocrWhenOpeningDocuments"
                    checked={formik.values.ocrWhenOpeningDocuments}
                    onChange={formik.handleChange}
                    style={{ width: "100%" }}
                  >
                    {tr("ocrWhenOpeningDocuments")}
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <label>{tr("colorSelection")}</label>
                  <ColorPicker
                    value={formik.values.option_Color_selection}
                    onChange={(value) => {
                      formik.setFieldValue(
                        "option_Color_selection",
                        value.toHexString()
                      );
                    }}
                    style={{ width: "100%" }}
                  />
                </Col>
                <Col span={24}>
                  <Checkbox
                    style={{ width: "100%" }}
                    name="autoHighlightMask"
                    checked={formik.values.autoHighlightMask}
                    onChange={formik.handleChange}
                  >
                    {tr("selectAutomaticallyUsingMaskFunction")}
                  </Checkbox>
                </Col>
              </Row>
            </Col>
            <Col span={24} className="table-settings">
              <Col span={24} className="table-header">
                <label>{tr("ocr")}</label>
              </Col>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <label>
                    {tr(
                      "ifTheOCRRequirementExceedsTheNumberOfPagesIndicatedBelow"
                    )}
                  </label>
                  <InputNumber
                    name="dontSendOCRMailPageCount"
                    min={0}
                    value={formik.values.dontSendOCRMailPageCount || 0}
                    onChange={(value) => {
                      formik.setFieldValue(
                        "dontSendOCRMailPageCount",
                        value || 0
                      );
                    }}
                    style={{ width: "100%" }}
                  ></InputNumber>
                </Col>
              </Row>
            </Col>
            <Col span={24} className="table-settings">
              <Col span={24} className="table-header">
                <label>{tr("searchName")}</label>
              </Col>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <label>{tr("dictionaryName")}</label>
                  <Select
                    mode="multiple"
                    value={formik.values.dictionaryNameClient}
                    onChange={(value) => {
                      formik.setFieldValue("dictionaryNameClient", value);
                    }}
                    style={{ width: "100%" }}
                    options={[
                      { value: Country.Danish, label: Api.Country.Danish },
                      { value: Country.Belgium, label: Api.Country.Belgium },
                      { value: Country.Germany, label: Api.Country.Germany },
                      {
                        value: Country.Netherlands,
                        label: Api.Country.Netherlands,
                      },
                      {
                        value: Country.International,
                        label: Api.Country.International,
                      },
                    ]}
                  />
                </Col>
                <Col span={24}>
                  <label>{tr("namesWillNotBeginOrEndWith")}</label>
                  <TextArea
                    name="namesWillNotBeginOrEndWith"
                    value={formik.values.namesWillNotBeginOrEndWith || ""}
                    onChange={formik.handleChange}
                    style={{ width: "100%" }}
                  ></TextArea>
                </Col>
              </Row>
            </Col>
            <Col span={24} className="table-settings">
              <Col span={24} className="table-header">
                <label>{tr("hidePages")}</label>
              </Col>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <label>{tr("logo")}</label>
                  <div
                    style={{
                      height: 150,
                      width: "100%",
                      border: "1px solid #d9d9d9",
                      display: "flex",
                      borderRadius: 8,
                    }}
                  >
                    <input
                      style={{ display: "none" }}
                      multiple={false}
                      ref={(file) => (fileInputHiddenPageRef.current = file)}
                      type="file"
                      onChange={(e) => {
                        onSelectedHiddenFile(e.target.files);
                        e.target.value = "";
                      }}
                      accept="image/x-png,image/gif,image/jpeg"
                    />
                    {(formik.values.hiddenPageImage && (
                      <img
                        style={{
                          height: "100%",
                          maxWidth: "100%",
                          margin: "auto",
                        }}
                        src={
                          (formik.values.hiddenPageImage.indexOf("base64") < 0
                            ? "data:image/png;base64, "
                            : "") + formik.values.hiddenPageImage
                        }
                        alt={""}
                      />
                    )) || (
                      <div style={{ margin: "auto" }}>{tr("noImageData")}</div>
                    )}
                  </div>
                </Col>
                <Col span={6}>
                  <Space>
                    <Button
                      onClick={ChooseHiddenFile}
                      icon={<UploadOutlined />}
                    />
                    <Button
                      onClick={DeleteHiddenFile}
                      icon={<DeleteOutlined />}
                    />
                  </Space>
                </Col>
                <Col
                  style={{ display: "flex", alignItems: "center" }}
                  span={18}
                >
                  <label style={{ marginRight: 8 }}>{tr("title")}</label>
                  <Input
                    name="hiddenPageTitle"
                    value={formik.values.hiddenPageTitle || ""}
                    onChange={formik.handleChange}
                    style={{ flex: 1 }}
                  ></Input>
                </Col>
              </Row>
            </Col>
          </Col>
          <Col span={12}>
            <Col span={24} className="table-settings">
              <Col span={24} className="table-header">
                <label>{tr("toc")}</label>
              </Col>
              <Row gutter={[8, 8]}>
                <Col span={12}>
                  <label>{tr("tocIndexPattern")}</label>
                  <Input
                    name="tocIndexPattern"
                    value={formik.values.tocIndexPattern || ""}
                    onChange={formik.handleChange}
                    width={"100%"}
                  ></Input>
                </Col>
                <Col span={12}>
                  <label style={{ color: "transparent" }}>{"sss"}</label>
                  <Checkbox
                    name="drawIndexInTocPages"
                    checked={formik.values.drawIndexInTocPages}
                    onChange={formik.handleChange}
                    style={{ width: "100%" }}
                  >
                    {tr("drawIndexInTOCPages")}
                  </Checkbox>
                </Col>
              </Row>
            </Col>
            <Col span={24} className="table-settings">
              <Col span={24} className="table-header">
                <label>{tr("replace")}</label>
              </Col>
              <Row gutter={[8, 8]}>
                <Col span={12}>
                  <label>{tr("replaceFontSetting")}</label>
                  <Select
                    value={formik.values.replaceFontName}
                    onChange={(value) => {
                      formik.setFieldValue("replaceFontName", value);
                    }}
                    style={{ width: "100%" }}
                    options={fontList}
                  />
                </Col>
                <Col span={12}>
                  <label>{tr("fontSize")}</label>
                  <InputNumber
                    name="replaceFontSize"
                    min={1}
                    step={0.5}
                    value={formik.values.replaceFontSize || 1}
                    onChange={(value) => {
                      formik.setFieldValue("replaceFontSize", value || 1);
                    }}
                    style={{ width: "100%" }}
                  ></InputNumber>
                </Col>
              </Row>
            </Col>
            <Col span={24} className="table-settings">
              <Col span={24} className="table-header">
                <label>{tr("publish")}</label>
              </Col>
              <Row gutter={[8, 8]}>
                <Col span={12}>
                  <label>{tr("publishColor")}</label>
                  <ColorPicker
                    style={{ width: "100%" }}
                    value={formik.values.highlightPublishColor}
                    onChange={(value) => {
                      formik.setFieldValue(
                        "highlightPublishColor",
                        value.toHexString()
                      );
                    }}
                  />
                </Col>
                <Col span={12}>
                  <label style={{ color: "transparent" }}>{"sss"}</label>
                  <Checkbox
                    name="compressExtractFiles"
                    checked={formik.values.compressExtractFiles}
                    onChange={formik.handleChange}
                    style={{ width: "100%" }}
                  >
                    {tr("compressPublishedPDFFiles")}
                  </Checkbox>
                </Col>
              </Row>
            </Col>
            <Col span={24} className="table-settings">
              <Col span={24} className="table-header">
                <label>
                  {tr(
                    "showWarningMessageIfUserHaveNotSelectedAllSearchResultForKeywordTemplateTag"
                  )}
                </label>
              </Col>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <Checkbox
                    name="warningKeyword"
                    checked={formik.values.warningKeyword}
                    onChange={formik.handleChange}
                    style={{ width: "100%" }}
                  >
                    {tr("keywords")}
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    name="warningTemplate"
                    checked={formik.values.warningTemplate}
                    onChange={formik.handleChange}
                    style={{ width: "100%" }}
                  >
                    {tr("template")}
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    name="warningTag"
                    checked={formik.values.warningTag}
                    onChange={formik.handleChange}
                    style={{ width: "100%" }}
                  >
                    {tr("tag")}
                  </Checkbox>
                </Col>
              </Row>
            </Col>
            <Col span={24} className="table-settings">
              <Col span={24} className="table-header">
                <label>{tr("watermark")}</label>
              </Col>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <Checkbox
                    checked={formik?.values.showWaterMark || false}
                    onChange={formik.handleChange}
                    name="showWaterMark"
                  >
                    {tr("showWaterMark")}
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <label>{tr("logo")}</label>
                  <div
                    style={{
                      height: 154,
                      width: "100%",
                      border: "1px solid #d9d9d9",
                      borderRadius: 8,
                      display: "flex",
                    }}
                  >
                    <input
                      style={{ display: "none" }}
                      multiple={false}
                      ref={(file) => (fileInputWatermarkRef.current = file)}
                      type="file"
                      onChange={(e) => {
                        onSelectedWatermarkFile(e.target.files);
                        e.target.value = "";
                      }}
                      accept="image/x-png,image/gif,image/jpeg"
                    />
                    {(formik.values.waterMarkImage && (
                      <img
                        style={{
                          height: "100%",
                          maxWidth: "100%",
                          margin: "auto",
                        }}
                        src={
                          (formik.values.waterMarkImage.indexOf("base64") < 0
                            ? "data:image/png;base64, "
                            : "") + formik.values.waterMarkImage
                        }
                        alt={""}
                      />
                    )) || (
                      <div style={{ margin: "auto" }}>{tr("noImageData")}</div>
                    )}
                  </div>
                </Col>
                <Col span={6}>
                  <Space>
                    <Button
                      onClick={ChooseWatermarkFile}
                      icon={<UploadOutlined />}
                    />
                    <Button
                      onClick={DeleteWatermarkFile}
                      icon={<DeleteOutlined />}
                    />
                  </Space>
                </Col>
                <Col
                  style={{ display: "flex", alignItems: "center" }}
                  span={18}
                >
                  <label style={{ marginRight: 8 }}>{tr("title")}</label>
                  <Input
                    name="waterMarkTitle"
                    value={formik.values.waterMarkTitle || ""}
                    onChange={formik.handleChange}
                    style={{ flex: 1 }}
                  ></Input>
                </Col>
              </Row>
            </Col>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default MDSettings;

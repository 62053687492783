import { Button, Modal } from "antd";
import UseConfirmDialogStore from "../ZustandStore/ConfirmDialogStore";
import { useLang } from "../i18n/useLang";
import {
  InfoCircleOutlined,
  QuestionCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";

export const ConfirmDialog = () => {
  const { ConfirmDialogState, SetConfirmDialogState } = UseConfirmDialogStore();
  const { tr } = useLang();
  const CloseDialog = () => {
    SetConfirmDialogState({
      Show: false,
      OnNo: undefined,
      OnYes: undefined,
      OnCancel: undefined,
      NoText: undefined,
      YesText: undefined,
      CancelText: undefined,
      Icon: "Info",
    });
  };
  return (
    <Modal
      className="confirm-dialog"
      classNames={{
        wrapper: "confirm-dialog",
        mask: "confirm-dialog-mask",
      }}
      style={{ width: "300px", minWidth: 300, maxWidth: 650 }}
      open={ConfirmDialogState.Show}
      title={ConfirmDialogState.Title || tr("warning")}
      centered
      maskClosable={false}
      onCancel={() => {
        CloseDialog();
        ConfirmDialogState.OnCancel && ConfirmDialogState.OnCancel();
      }}
      footer={() => {
        return (
          <>
            <Button
              style={{ minWidth: 80 }}
              onClick={() => {
                CloseDialog();
                ConfirmDialogState.OnYes && ConfirmDialogState.OnYes();
              }}
            >
              {ConfirmDialogState.YesText || tr("ok")}
            </Button>
            {ConfirmDialogState.Type === "ConfirmYesNoCancel" && (
              <Button
                style={{ minWidth: 80 }}
                onClick={() => {
                  CloseDialog();
                  ConfirmDialogState.OnNo && ConfirmDialogState.OnNo();
                }}
              >
                {ConfirmDialogState.NoText || tr("no")}
              </Button>
            )}
            {(ConfirmDialogState.Type === "ConfirmYesNoCancel" ||
              ConfirmDialogState.Type === "Confirm") && (
              <Button
                style={{ minWidth: 80 }}
                onClick={() => {
                  CloseDialog();
                  ConfirmDialogState.OnCancel && ConfirmDialogState.OnCancel();
                }}
              >
                {ConfirmDialogState.CancelText || tr("cancel")}
              </Button>
            )}
          </>
        );
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ fontSize: 45, marginRight: 8 }}>
          {ConfirmDialogState.Icon === "Info" && (
            <InfoCircleOutlined style={{ color: "#0958d9" }}  size={40} />
          )}
          {ConfirmDialogState.Icon === "Warning" && (
            <WarningOutlined style={{ color: "#e8b842" }} size={40} />
          )}
          {ConfirmDialogState.Icon === "Question" && (
            <QuestionCircleOutlined style={{ color: "#0958d9" }}  size={40} />
          )}
        </div>
        <div style={{ flex: 1 }}>{ConfirmDialogState.Msg}</div>
      </div>
    </Modal>
  );
};

import React, { useState } from "react";
import {
  Alert,
  Button,
  Col,
  ColorPicker,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
} from "antd";
import { useLang } from "../../i18n/useLang";
import {
  MDTagCategoryProps,
  MDTagCategoryService,
} from "../../Services/Hook/Modal/Tags/MDTagCategoryService";

const MDTagCategory = ({ onClose, open }: MDTagCategoryProps) => {
  const { tr } = useLang();
  const { formik, showErrors } = MDTagCategoryService({ onClose: onClose });

  return (
    <>
      <Modal
        title={tr("tagName")}
        footer={
          <>
            <Button
              style={{ minWidth: 100 }}
              onClick={() => {
                onClose && onClose(null);
              }}
              type="default"
            >
              {tr("close")}
            </Button>
            <Button
              style={{ minWidth: 100 }}
              onClick={() => {
                formik.submitForm();
              }}
              type="primary"
            >
              {tr("save")}
            </Button>
          </>
        }
        centered
        open={open}
        width={550}
        onCancel={() => {
          onClose && onClose(null);
        }}
        maskClosable={false}
      >
        <Row gutter={[8, 8]}>
          <Col span={24}>
            {!formik.isValid && (
              <Alert
                description={showErrors(formik.errors)}
                type="warning"
                showIcon
              />
            )}
          </Col>
          <Col span={24}>
            <label>{tr("name")}</label>
            <Input
              style={{ width: "100%" }}
              name="key"
              value={formik.values.key || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleChange}
            ></Input>
          </Col>
          <Col span={24}>
            <label>{`${tr("color")}`}</label>
            <ColorPicker
              style={{ width: "100%" }}
              value={formik.values.color}
              onChange={(color) => {
                formik.setFieldValue("color", color.toHexString());
              }}
            ></ColorPicker>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default MDTagCategory;

import React, { useState } from "react";
import {
  Button,
  Col,
  ColorPicker,
  Modal,
  Row,
} from "antd";
import { useLang } from "../i18n/useLang";
import { MDChangeLineColorProps, MDChangeLineColorService } from "../Services/Hook/Modal/MDChangeLineColorService";
const MDMDChangeLineColor = ({ onClose, open, line }: MDChangeLineColorProps) => {
  const { tr } = useLang();
  const { formik } = MDChangeLineColorService({
    onClose,
    line,
  });
  return (
    <>
      <Modal
        title={tr("note")}
        footer={
          <>
            <Button
              style={{ minWidth: 100 }}
              type="default"
              onClick={() => {
                onClose && onClose();
              }}
            >
              {tr("close")}
            </Button>
            <Button
              style={{ minWidth: 100 }}
              onClick={() => {
                formik.handleSubmit();
              }}
              type="primary"
            >
              {tr("save")}
            </Button>
          </>
        }
        centered
        open={open}
        maskClosable={false}
        width={550}
        onCancel={() => {
          onClose && onClose();
        }}
      >
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <label>{tr("fontColor")}</label>
            <ColorPicker
              value={formik.values.color}
              onChange={(value) => {
                formik.setFieldValue("color", value.toHexString());
              }}
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default MDMDChangeLineColor;

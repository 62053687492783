import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  ColorPicker,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Slider,
} from "antd";
import * as Api from "../Api/src/index";
import { useLang } from "../i18n/useLang";
import TextArea from "antd/es/input/TextArea";
import {
  MDCommentService,
  MDNoteProps,
} from "../Services/Hook/Modal/MDCommentService";
const MDComment = ({
  onClose,
  open,
  editItem,
  pageNo,
  parentId,
}: MDNoteProps) => {
  const { tr } = useLang();
  const { formik, statusCommentOptions } = MDCommentService({
    onClose,
    editItem,
    pageNo,
    parentId,
  });
  return (
    <>
      <Modal
        title={tr("note")}
        footer={
          <>
            <Button
              style={{ minWidth: 100 }}
              type="default"
              onClick={() => {
                onClose && onClose();
              }}
            >
              {tr("close")}
            </Button>
            <Button
              style={{ minWidth: 100 }}
              onClick={() => {
                formik.handleSubmit();
              }}
              type="primary"
            >
              {tr("save")}
            </Button>
          </>
        }
        centered
        open={open}
        maskClosable={false}
        width={550}
        onCancel={() => {
          onClose && onClose();
        }}
      >
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <label>{tr("author")}</label>
            <Input
              value={formik.values.author || ""}
              name="author"
              onChange={formik.handleChange}
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={24}>
            <label>{tr("message")}</label>
            <TextArea
              value={formik.values.comment || ""}
              name="comment"
              onChange={formik.handleChange}
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={24}>
            <label>{tr("status")}</label>
            <Select
              value={formik.values.status || 0}
              onChange={(value) => {
                formik.setFieldValue("status", value as Api.CommentStatusEnum);
              }}
              style={{ width: "100%" }}
              options={statusCommentOptions}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default MDComment;

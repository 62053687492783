import { Core } from "@pdftron/webviewer";
import * as Api from "../../Api/src/index";
import { App } from "antd";
import { AppCache } from "../../Cache/AppCache";
const StampApi = new Api.StampApi();
export const StampService = () => {
  const UpdateStamp = (newStamp: Core.Annotations.Annotation | undefined) => {
    if (!newStamp) return;
    StampApi.apiLegalxtractUpdateAnnotationStampPost(
      AppCache.CurrentTab()?.uniqueId,
      {
        x: newStamp.X / 72,
        y: newStamp.Y / 72,
        height: newStamp.Height / 72,
        width: newStamp.Width / 72,
      }
    );
  };
  return { UpdateStamp };
};

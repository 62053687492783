import UndoRedoManagerObj from "../UndoRedomanager";
import * as Api from "../../Api/src/index";
import { AppCache } from "../../Cache/AppCache";
import { PageService } from "../../Services/NotHook/PageService";
import { AppLocalStorage } from "../../Constant/AppContant";
import { UndoHightLight } from "./HightLightCommand";
import { LeftPanelService } from "../../Services/Hook/LeftPanel/LeftPanelService";
const WatchDogApi = new Api.WatchDogApi();
export const WatchdogUnHightLightAllCommand = () => {
  const { SetDataCurrentPage } = PageService();
  const { LoadLeftPanelData } = LeftPanelService();
  const HightLight = async (param: Api.UnSelectedAllSelectAllRowParam) => {
    localStorage.setItem(AppLocalStorage.DocumentHasChanged, "true");
    var res = await WatchDogApi.apiLegalxtractDeSelectAllPost(param);
    SetDataCurrentPage(
      res.data.result?.lxPageData!,
      AppCache.CurrentPageNo || 0
    );
    LoadLeftPanelData(["pnWatchDog"]);
    return res;
  };
  const Do = async (param: Api.UnSelectedAllSelectAllRowParam) => {
    const res = await HightLight(param);
    UndoRedoManagerObj.add({
      Redo: () => {
        Redo(param);
      },
      Undo: () => {
        Undo({
          preState: JSON.stringify(res?.data.result?.dicPreState),
          viewerPageNo: AppCache.CurrentPageNo || 0,
        });
      },
    });
  };
  const Redo = (param: Api.UnSelectedAllSelectAllRowParam) => {
    HightLight(param);
  };
  const Undo = async (param: Api.UndoHightLightParam) => {
    await UndoHightLight(param);
  };
  return { Do };
};

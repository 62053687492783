import React, { useEffect, useRef, useState } from "react";
import * as Api from "../../Api/src";
import { useLang } from "../../i18n/useLang";
import SelectionContainer from "../../Controls/SelectionContainer";
import { Button, Space } from "antd";
import { CheckVisibleElement } from "../../Common/CommonFunction";
import { PageThumbnailDupli } from "./PageThumbnailDupli";
import {
  ThumbnailDuplicatePreview,
  webViewerInstanceCheckDuplicate,
} from "./PreviewCheckDuplicateModal";
import { AppCache } from "../../Cache/AppCache";
import useLoaddingAppStore from "../../ZustandStore/LoaddingAppStore";
import { Item, Menu, useContextMenu } from "react-contexify";
export let _selectPages = [] as number[];
interface Position {
  bottom: number;
  height: number;
  left: number;
  right: number;
  top: number;
  width: number;
  x: number;
  y: number;
}
interface ThumbnailDuplicateProps {
  DuplicatePages: { [key: string]: Array<Api.PageStatus> } | null;
  ThumbnailDuplicatePreviews: ThumbnailDuplicatePreview[] | null;
  onClickItem: (pageNo: number) => void;
  currentPage: number;
  changedStatus: (thumbnails: Api.PageStatus[]) => void;
  onSelectPages: (pageNos: number[]) => void;
}

export default ({
  DuplicatePages,
  ThumbnailDuplicatePreviews,
  onClickItem,
  currentPage,
  changedStatus,
  onSelectPages,
}: ThumbnailDuplicateProps) => {
  useState<Api.HiddenEnum | null>();
  const { tr } = useLang();
  const [selectPages, SetSelectPages] = useState<number[]>([]);
  const [thumbnailContextMenuProps, SetThumbnailContextMenuProps] = useState({
    isShow: false,
    pageSelecteds: [] as number[],
    targetPoint: {},
  });
  const _SetSelectPages = (pages: number[]) => {
    SetSelectPages(pages);
    onSelectPages(pages);
  };
  useEffect(() => {
    const pageNos = GetPagesShow();
    if (pageNos.indexOf(currentPage) < 0) {
      const a = document.getElementById(`thumbnail-duplicate-${currentPage}`);
      const container = document.getElementById(`thumbnailContainerDuplicate`);
      container?.scrollTo(0, a?.offsetTop || 0);
    }
    if (selectPages.length <= 1) {
      _SetSelectPages([currentPage]);
    }
  }, [currentPage]);
  useEffect(() => {
    _selectPages = selectPages;
  }, [selectPages]);
  const StartSelection = useRef<boolean>(false);
  const checkCollisionDetected = (rect1: Position, rect2: Position) => {
    if (
      rect1.x < rect2.right &&
      rect1.right > rect2.x &&
      rect1.y < rect2.bottom &&
      rect1.bottom > rect2.y
    ) {
      return true;
    }
    return false;
  };
  const getSelectedItem = (e: any, elementRect: HTMLDivElement | null) => {
    if (!elementRect) return;
    const pageSelects: number[] = [];
    const items = document.getElementsByClassName("item-thumbnail-duplicate");
    const rect1 = elementRect.getBoundingClientRect();
    for (let index = 0; index < items.length; index++) {
      const element = items[index];
      if (
        element &&
        ((element.attributes as any)["itemtype"].nodeValue || "")
          .toString()
          .indexOf("NotSelect") < 0
      ) {
        const rect2 = element.getBoundingClientRect();
        const valueCheck = checkCollisionDetected(rect1, rect2);
        const pageIndex = parseInt(
          element.id.replace("thumbnail-duplicate-", "")
        );
        if (valueCheck) {
          pageSelects.push(pageIndex);
        }
      }
    }
    addPageSelect(e.ctrlKey, pageSelects);
  };
  const GetPagesShow = (): number[] => {
    const pageNos: number[] = [];
    const container = document.getElementById(`thumbnailContainerDuplicate`);
    ListDuplicatePage()?.forEach((item) => {
      const el = document.getElementById(
        `thumbnail-duplicate-${item.viewerPageNo}`
      );
      if (CheckVisibleElement(el, container || undefined)) {
        if (item.viewerPageNo) {
          pageNos.push(item.viewerPageNo);
        }
      }
    });
    return pageNos;
  };
  const addPageSelect = (ctrlKey: boolean, pageSelects: number[]) => {
    let pageOK: number[] = [];
    if (ctrlKey) {
      pageOK = JSON.parse(JSON.stringify(selectPages));
    }
    (pageSelects || []).forEach((index) => {
      if (ctrlKey) {
        const page = pageOK.find((o) => o == index);
        if (page) {
          pageOK = pageOK.filter((o) => o != index);
        } else {
          pageOK.push(index);
        }
      } else {
        pageOK.push(index);
      }
    });
    _SetSelectPages(pageOK);
  };
  const addPageSelectShifKey = (shifKey: boolean, pageSelect: number) => {
    let pageOK: number[] = [];
    if (shifKey) {
      pageOK = JSON.parse(JSON.stringify(selectPages));
    }
    let minPage = Math.min(...pageOK);
    let maxPage = Math.max(...pageOK);
    if (pageSelect > maxPage) {
      minPage = maxPage;
      maxPage = pageSelect;
    }
    if (pageSelect < maxPage) {
      minPage = pageSelect;
    }
    const pageSelecteds = ListDuplicatePage().filter(
      (o) =>
        (o.viewerPageNo || 0) <= maxPage && (o.viewerPageNo || 0) >= minPage
    );
    _SetSelectPages(pageSelecteds.map((o) => o.viewerPageNo || 0));
  };
  const ListDuplicatePage = () => {
    let pages: Api.PageStatus[] = [];
    for (const key in DuplicatePages) {
      const element = DuplicatePages[key];
      pages = [...pages, ...element];
    }
    return pages;
  };
  const ThumbnailItems = () => {
    const listItems = [] as any[];
    var thumnailgroups = Object.groupBy(
      ThumbnailDuplicatePreviews || [],
      ({ group }) => group
    );
    const listDuplicatePage = ListDuplicatePage();
    for (const key in thumnailgroups) {
      const element = thumnailgroups[key];
      const thumnailIngroups = element?.map((y, index) => {
        return (
          <PageThumbnailDupli
            onClick={(viewPageNo, e) => {
              if (e.shiftKey) {
                addPageSelectShifKey(e.shiftKey, viewPageNo);
              } else {
                addPageSelect(e.ctrlKey, [viewPageNo]);
              }
              onClickItem(viewPageNo);
            }}
            onMouseDown={(viewPageNo, e) => {
              if (e.button == 2) {
                if (selectPages.indexOf(viewPageNo) < 0) {
                  addPageSelect(e.ctrlKey, [viewPageNo]);
                }
              }
            }}
            onContextMenu={(viewPageNo, e) => {
              e.preventDefault();
              e.stopPropagation();
              if (selectPages.length <= 1) {
                _SetSelectPages([viewPageNo]);
                onClickItem(viewPageNo);
              }
              SetThumbnailContextMenuProps({
                ...thumbnailContextMenuProps,
                targetPoint: { x: e.clientX, y: e.clientY },
                isShow: true,
                pageSelecteds: selectPages || [],
              });
              ShowMenuContext(e);
            }}
            Item={y}
            selectedPage={selectPages}
            thumbnailStatus={
              listDuplicatePage.find((o) => o.viewerPageNo === y.viewerPageNo)
                ?.status || 0
            }
            active={y.pageNoPreview == currentPage}
            pageNo={y.pageNoPreview}
            key={index}
          />
        );
      });
      const group = (
        <div key={key + "group"}>
          <p style={{ textAlign: "center" }}>
            <strong>{element?.length}</strong>
          </p>
          <div
            style={{
              width: "100%",
              display: "inline-block",
            }}
          >
            {thumnailIngroups}
          </div>
        </div>
      );
      listItems.push(group);
    }
    return listItems;
  };
  const SelectAllLast = () => {
    let pageSelects = [] as number[];
    for (const key in DuplicatePages) {
      const element = DuplicatePages[key];
      const pages = element.filter((o, index) => index > 0);
      const thumbnails = ListDuplicatePage()?.filter((o) => pages.includes(o));
      const a = thumbnails?.map((o) => o.viewerPageNo || 0) || [];
      pageSelects = [...pageSelects, ...a];
    }
    var pageNoPreviewSelecteds =
      ThumbnailDuplicatePreviews?.filter((o) => {
        return pageSelects.includes(o.viewerPageNo || 0);
      }).map((o) => o.pageNoPreview || 0) || [];
    _SetSelectPages(pageNoPreviewSelecteds);
  };
  const SelectAllFirst = () => {
    let pageSelects = [] as number[];
    for (const key in DuplicatePages) {
      const element = DuplicatePages[key];
      const pages = element.filter((o, index) => index < element.length - 1);
      const thumbnails = ListDuplicatePage()?.filter((o) =>
        pages.includes(o || 0)
      );
      const a = thumbnails?.map((o) => o.viewerPageNo || 0) || [];
      pageSelects = [...pageSelects, ...a];
    }
    var pageNoPreviewSelecteds =
      ThumbnailDuplicatePreviews?.filter((o) => {
        return pageSelects.includes(o.viewerPageNo || 0);
      }).map((o) => o.pageNoPreview || 0) || [];
    _SetSelectPages(pageNoPreviewSelecteds);
  };
  const MenuID = "CheckDuplicateMenu";
  const { show, hideAll } = useContextMenu({
    id: MenuID,
  });
  function ShowMenuContext(event: any) {
    show({
      event,
    });
  }
  const checkdisableButton = (status: Api.HiddenWithDeleteEnum) => {
    for (const key in DuplicatePages) {
      const element = DuplicatePages[key];
      const item = element.find(
        (o) => selectPages.includes(o.previewPageNo || 0) && o.status != status
      );
      if (item) {
        return false;
      }
    }
    return true;
  };
  const OnChangeStatus = (status: Api.HiddenWithDeleteEnum) => {
    for (const key in DuplicatePages) {
      const element = DuplicatePages[key];
      element.forEach((o) => {
        if (selectPages.includes(o.previewPageNo || 0)) {
          o.status = status;
        }
      });
      changedStatus(ListDuplicatePage());
      hideAll();
    }
  };
  const DrawMenuItem = () => {
    return (
      <Menu id={MenuID}>
        <Item
          disabled={checkdisableButton(Api.HiddenWithDeleteEnum.Show)}
          onClick={() => {
            OnChangeStatus(Api.HiddenWithDeleteEnum.Show);
          }}
        >
          {tr("show")}
        </Item>
        <Item
          disabled={checkdisableButton(Api.HiddenWithDeleteEnum.Hide)}
          onClick={() => {
            OnChangeStatus(Api.HiddenWithDeleteEnum.Hide);
          }}
        >
          {tr("hide")}
        </Item>
        <Item
          disabled={checkdisableButton(Api.HiddenWithDeleteEnum.HideOnExtract)}
          onClick={() => {
            OnChangeStatus(Api.HiddenWithDeleteEnum.HideOnExtract);
          }}
        >
          {tr("hideOnExtract")}
        </Item>
        <Item
          disabled={checkdisableButton(Api.HiddenWithDeleteEnum.Delete)}
          onClick={() => {
            OnChangeStatus(Api.HiddenWithDeleteEnum.Delete);
          }}
        >
          {tr("delete")}
        </Item>
      </Menu>
    );
  };
  return (
    <>
      <SelectionContainer
        id="thumbnailContainerDuplicate"
        style={{
          height: "calc(100% - 47px)",
          overflow: "auto",
        }}
        onEndSelection={(e, elementRect) => {
          StartSelection.current = false;
          getSelectedItem(e, elementRect);
        }}
        onStartSelection={() => {
          StartSelection.current = true;
        }}
      >
        <div id={"thumbnail_container-duplicate"}>
          <div className={"thumbnail-container"}>{ThumbnailItems()}</div>
        </div>
      </SelectionContainer>
      {DrawMenuItem()}
      <div style={{ display: "flex", padding: 8 }}>
        <Button
          style={{ flex: 1, marginRight: 8 }}
          onClick={() => {
            SelectAllFirst();
          }}
          type="default"
        >
          {tr("selectAllFirstDuplicates")}
        </Button>
        <Button
          style={{ flex: 1 }}
          onClick={() => {
            SelectAllLast();
          }}
          type="default"
        >
          {tr("selectAllLastDuplicates")}
        </Button>
      </div>
    </>
  );
};
// const MenuContextThumbnail = ({
//   targetPoint,
//   hideMenu,
//   changeStatus,
//   DuplicateThumbnails,
//   pageSelected,
// }: {
//   targetPoint: any;
//   hideMenu: () => void;
//   changeStatus: (status: Api.HiddenWithDeleteEnum) => void;
//   DuplicateThumbnails: Api.DuplicateThumbnails;
//   pageSelected: number[];
// }) => {
//   const { tr } = useLang();
//   useEffect(() => {
//     document
//       .getElementsByClassName("ms-Modal-scrollableContent")[1]
//       ?.addEventListener("mousedown", hideMenu);
//     return () => {
//       document
//         .getElementsByClassName("ms-Modal-scrollableContent")[1]
//         ?.removeEventListener("mousedown", hideMenu);
//     };
//   }, []);
//   const checkdisableButton = (status: Api.HiddenWithDeleteEnum) => {
//     return !DuplicateThumbnails.thumbnails?.find(
//       (o) => _selectPages.includes(o.viewPageNo || 0) && o.status != status
//     );
//   };
//   const items = [
//     {
//       key: "show",
//       name: tr("show"),
//       disabled: checkdisableButton(Api.HiddenWithDeleteEnum.Show),
//       onClick: (ev) => {
//         hideMenu();
//         changeStatus(Api.HiddenWithDeleteEnum.Show);
//       },
//     },
//     {
//       key: "hide",
//       name: tr("hide"),
//       disabled: checkdisableButton(Api.HiddenWithDeleteEnum.Hide),
//       onClick: (ev) => {
//         changeStatus(Api.HiddenWithDeleteEnum.Hide);
//         hideMenu();
//       },
//     },
//     {
//       key: "hideOnExtract",
//       name: tr("hideOnExtract"),
//       disabled: checkdisableButton(Api.HiddenWithDeleteEnum.HideOnExtract),
//       onClick: (ev) => {
//         changeStatus(Api.HiddenWithDeleteEnum.HideOnExtract);
//         hideMenu();
//       },
//     },
//     {
//       key: "delete",
//       name: tr("delete"),
//       disabled: checkdisableButton(Api.HiddenWithDeleteEnum.Delete),
//       onClick: (ev) => {
//         changeStatus(Api.HiddenWithDeleteEnum.Delete);
//         hideMenu();
//       },
//     },
//   ] as IContextualMenuItem[];
//   return <ContextualMenu target={targetPoint} items={items} />;
// };

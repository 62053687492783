import {
  Item,
  ItemParams,
  Menu,
  Separator,
  Submenu,
  useContextMenu,
} from "react-contexify";
import "react-contexify/ReactContexify.css";
import { useLang } from "../../i18n/useLang";
import {
  MenuContextProps,
  MenuContextService,
} from "../../Services/Hook/MainPanel/MenuContextService";
import { DeleteOutlined } from "@ant-design/icons";
import { Space } from "antd";
import MDStampSetting from "../../Modal/Stamp/MDStampSetting";
export const MENU_ID = "mainContextMenu";
import OpenIcon from "../../Assets/IconApp/folder_open.svg";
import save from "../../Assets/IconApp/save.svg";
import ocr from "../../Assets/IconApp/scan_text.svg";
import find_duplicate from "../../Assets/IconApp/find_duplicate.svg";
import print from "../../Assets/IconApp/print.svg";
import document_arrow_down from "../../Assets/IconApp/document_arrow_down.svg";
import tab_add_box from "../../Assets/IconApp/tab_add_box.svg";
import line from "../../Assets/IconApp/line.svg";
import expand_up_right from "../../Assets/IconApp/expand_up_right.svg";
import cursor_click from "../../Assets/IconApp/cursor_click.svg";
import rename from "../../Assets/IconApp/rename.svg";
import undo from "../../Assets/IconApp/undo.svg";
import redo from "../../Assets/IconApp/redo.svg";
import tag from "../../Assets/IconApp/tag.svg";
import desktop from "../../Assets/IconApp/desktop.svg";
import MDStampIndex from "../../Modal/Stamp/MDStampIndex";
import { MDAddBookmark } from "../../Modal/MDAddBookMark";
import MDNote from "../../Modal/MDNote";
import MDComment from "../../Modal/MDComment";
import MDChangeLineColor from "../../Modal/MDChangeLineColor";
export const MenuContext = ({
  ShowMenuContext,
  onVisibilityChange,
}: MenuContextProps) => {
  const { tr } = useLang();
  const {
    MenuState,
    ShowStampSetting,
    CloseMDStamp,
    OpenMDStamp,
    RemoveStamp,
    AddStamp,
    BoxClick,
    SectionClick,
    HightLightAllPage,
    UnHightLightAllPage,
    HightLightWord,
    UnHightLightWord,
    HightLightLine,
    ShowStampIndex,
    OpenMDStampIndex,
    CloseMDStampIndex,
    EditReplaceClick,
    DeleteReplace,
    AddReplaceClick,
    ShowAddBookMark,
    CloseMDAddBookMark,
    SetShowAddBookMark,
    ShowAddNote,
    SetShowAddNote,
    DeleteNote,
    EditNote,
    SetShowAddComment,
    ShowAddComment,
    FindTag,
    TagData,
    onSelectOrUnSelectAllTag,
    ReplaceBox,
    DeleteReplaceBox,
    EditReplaceBox,
    DeleteLine,
    EditSizeLine,
    ShowChangeLineColor,
    SetShowChangeLineColor,
    OnShowChangeLineColor,
    DeleteBox,
    hightLightMask,
    AddReplaceMaskClick,
    Find,
  } = MenuContextService({
    ShowMenuContext,
    onVisibilityChange,
  });
  return (
    <>
      {ShowStampSetting.open && (
        <MDStampSetting
          open={ShowStampSetting.open}
          onClose={CloseMDStamp}
          editItem={ShowStampSetting.editItem}
        ></MDStampSetting>
      )}
      {ShowStampIndex.open && (
        <MDStampIndex
          open={ShowStampIndex.open}
          onClose={CloseMDStampIndex}
          editItem={ShowStampIndex.editItem}
        ></MDStampIndex>
      )}
      {ShowAddBookMark.open && (
        <MDAddBookmark
          open={ShowAddBookMark.open}
          onClose={CloseMDAddBookMark}
          EditItem={null}
        ></MDAddBookmark>
      )}
      {ShowAddNote.open && (
        <MDNote
          open={ShowAddNote.open}
          onClose={() => {
            SetShowAddNote({ open: false, editItem: null });
          }}
          editItem={ShowAddNote.editItem}
        ></MDNote>
      )}
      {ShowAddComment.open && (
        <MDComment
          open={ShowAddComment.open}
          onClose={() => {
            SetShowAddComment({ open: false });
          }}
          editItem={null}
        ></MDComment>
      )}
      {ShowChangeLineColor.open && (
        <MDChangeLineColor
          open={ShowChangeLineColor.open}
          onClose={() => {
            SetShowChangeLineColor({ open: false, line: null });
          }}
          line={ShowChangeLineColor.line}
        ></MDChangeLineColor>
      )}
      <Menu
        id={MENU_ID}
        animation={"slide"}
        onVisibilityChange={onVisibilityChange}
      >
        <Item
          onClick={OpenMDStampIndex}
          hidden={!MenuState?.mniAddStampIndex}
          id="mniAddStampIndex"
        >
          {tr("addStampIndex")}
        </Item>
        <Item
          hidden={!MenuState?.mniEditStamp}
          onClick={OpenMDStamp}
          id="mniEditStamp"
        >
          {tr("editStamp")}
        </Item>

        <Item
          onClick={() => {
            AddStamp();
          }}
          hidden={!MenuState?.mniAddStamp}
          id="mniAddStamp"
        >
          {tr("addStamp")}
        </Item>
        <Item
          onClick={() => {
            RemoveStamp();
          }}
          hidden={!MenuState?.mniRemoveStamp}
          id="mniRemoveStamp"
        >
          <Space>
            <DeleteOutlined />
            {tr("removeStamp")}
          </Space>
        </Item>
        <Item
          hidden={!MenuState?.mniSelect}
          onClick={HightLightWord}
          id="mniSelect"
        >
          {tr("select")}
        </Item>
        <Item hidden={!MenuState?.mniBox} onClick={BoxClick} id="mniBox">
          <Space>
            <img width={15} src={tab_add_box}></img>
            {tr("box")}
          </Space>
        </Item>
        <Item
          hidden={!MenuState?.mniSection}
          onClick={SectionClick}
          id="mniSection"
        >
          <Space>
            <img width={15} src={expand_up_right}></img>
            {tr("Section")}
          </Space>
        </Item>
        <Item
          hidden={!MenuState?.mniAddNote}
          onClick={() => {
            SetShowAddNote({ open: true, editItem: null });
          }}
          id="mniAddNote"
        >
          {tr("addNote")}
        </Item>
        <Item
          hidden={!MenuState?.mniAddBookMark}
          onClick={() => {
            SetShowAddBookMark({ open: true });
          }}
          id="mniAddBookMark"
        >
          {tr("addBookmark")}
        </Item>
        <Item hidden={!MenuState?.mniPageBreakToc} id="mniPageBreakToc">
          {tr("pageBreak")}
        </Item>
        <Item
          hidden={!MenuState?.mniRemovePageBreakToc}
          id="mniRemovePageBreakToc"
        >
          {tr("removePageBreak")}
        </Item>
        <Item hidden={!MenuState?.mniTocSettings} id="mniTocSettings">
          {tr("fontSettings")}
        </Item>
        <Item
          hidden={!MenuState?.mniComment}
          onClick={() => {
            SetShowAddComment({ open: true });
          }}
          id="mniComment"
        >
          {tr("addComment")}
        </Item>
        <Item
          hidden={!MenuState?.mniDeleteBox}
          onClick={DeleteBox}
          id="mniDeleteBox"
        >
          {tr("delete")}
        </Item>
        <Item
          hidden={!MenuState?.mniUnSelect}
          onClick={UnHightLightWord}
          id="mniUnSelect"
        >
          {tr("unselect")}
        </Item>
        <Item
          hidden={!MenuState?.mniSelectLine}
          onClick={HightLightLine}
          id="mniSelectLine"
        >
          {tr("selectLine")}
        </Item>
        <Item
          hidden={!MenuState?.mniSelectPage}
          onClick={HightLightAllPage}
          id="mniSelectPage"
        >
          {tr("selectPage")}
        </Item>
        <Item
          hidden={!MenuState?.mniUnselectPage}
          onClick={UnHightLightAllPage}
          id="mniUnselectPage"
        >
          {tr("unselectPage")}
        </Item>
        <Item
          hidden={!MenuState?.mniEditNote}
          onClick={EditNote}
          id="mniEditNote"
        >
          {tr("editNote")}
        </Item>
        <Item
          hidden={!MenuState?.mniDeleteNote}
          onClick={DeleteNote}
          id="mniDeleteNote"
        >
          {tr("deleteNote")}
        </Item>
        <Item
          hidden={!MenuState?.mniDeleteLine}
          onClick={DeleteLine}
          id="mniDeleteLine"
        >
          {tr("delete")}
        </Item>
        <Item
          hidden={!MenuState?.mniDeleteLine}
          onClick={() => {
            EditSizeLine(0.01 * 72);
          }}
          id="mniLine1"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: 20,
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                background: "#333",
                height: 2,
                width: "100%",
              }}
            ></div>
          </div>
        </Item>
        <Item
          hidden={!MenuState?.mniDeleteLine}
          onClick={() => {
            EditSizeLine(0.02 * 72);
          }}
          id="mniLine2"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: 20,
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                background: "#333",
                height: 4,
                width: "100%",
              }}
            ></div>
          </div>
        </Item>
        <Item
          hidden={!MenuState?.mniDeleteLine}
          onClick={() => {
            EditSizeLine(0.04 * 72);
          }}
          id="mniLine3"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: 20,
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                background: "#333",
                height: 6,
                width: "100%",
              }}
            ></div>
          </div>
        </Item>
        <Item
          hidden={!MenuState?.mniDeleteLine}
          onClick={() => {
            EditSizeLine(0.08 * 72);
          }}
          id="mniLine4"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: 20,
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                background: "#333",
                height: 8,
                width: "100%",
              }}
            ></div>
          </div>
        </Item>
        <Item
          hidden={!MenuState?.mniDeleteLine}
          onClick={() => {
            OnShowChangeLineColor();
          }}
          id="mniLineColor"
        >
          {tr("lineColor")}
        </Item>
        <Item hidden={!MenuState?.mniFind} onClick={Find} id="mniFind">
          {tr("find")}
        </Item>
        <Item
          hidden={!MenuState?.mniReplace}
          onClick={AddReplaceClick}
          id="mniReplace"
        >
          {tr("replace")}
        </Item>
        <Item
          hidden={!MenuState?.mniEditReplace}
          onClick={EditReplaceClick}
          id="mniEditReplace"
        >
          {tr("edit") + " " + tr("replace")}
        </Item>
        <Item
          hidden={!MenuState?.mniDeleteReplace}
          onClick={DeleteReplace}
          id="mniDeleteReplace"
        >
          {tr("deleteReplace")}
        </Item>
        <Item
          hidden={!MenuState?.mniReplaceBox}
          onClick={ReplaceBox}
          id="mniReplaceBox"
        >
          {tr("replace")}
        </Item>
        <Item
          hidden={!MenuState?.mniEditReplaceBox}
          onClick={EditReplaceBox}
          id="mniEditReplaceBox"
        >
          {tr("edit") + " " + tr("replace")}
        </Item>
        <Item
          hidden={!MenuState?.mniDeleteReplaceBox}
          onClick={DeleteReplaceBox}
          id="mniDeleteReplaceBox"
        >
          {tr("deleteReplace")}
        </Item>
        <Item
          hidden={!MenuState?.mniAddFrontPageContent}
          id="mniAddFrontPageContent"
        >
          {tr("addFrontpageContent")}
        </Item>
        <Item
          hidden={!MenuState?.mniRemoveFrontPageContent}
          id="mniRemoveFrontPageContent"
        >
          {tr("removeFrontpageContent")}
        </Item>
        <Item hidden={!MenuState?.mniDeskew} id="mniDeskew">
          {tr("deskew")}
        </Item>
        <Item
          hidden={!MenuState?.mniSelectMask}
          id="mniSelectMask"
          onClick={() => {
            hightLightMask(true);
          }}
        >
          {tr("select")}
        </Item>
        <Item
          hidden={!MenuState?.mniUnSelectMask}
          id="mniUnSelectMask"
          onClick={() => {
            hightLightMask(false);
          }}
        >
          {tr("unselect")}
        </Item>
        <Item
          hidden={!MenuState?.mniReplaceMask}
          onClick={AddReplaceMaskClick}
          id="mniReplaceMask"
        >
          {tr("replace")}
        </Item>
        <Submenu
          hidden={!MenuState?.mniTag}
          id="mniTag"
          label={
            tr("tag") +
            " " +
            TagData.tagCategory?.keyword +
            "/" +
            TagData.tagName?.keyword
          }
        >
          <Item id="findTag" onClick={FindTag}>
            {tr("findTag")}
          </Item>
          <Item
            id="selectAllTag"
            onClick={(e) => {
              onSelectOrUnSelectAllTag(e, "selectAll");
            }}
          >
            {tr("selectAllTag")}
          </Item>
          <Item
            id="unselectAllTag"
            onClick={(e) => {
              onSelectOrUnSelectAllTag(e, "deSelectAll");
            }}
          >
            {tr("unselectAllTag")}
          </Item>
        </Submenu>
      </Menu>
    </>
  );
};

import UndoRedoManagerObj from "../../UndoRedomanager";
import * as Api from "../../../Api/src/index";
import { AppCache } from "../../../Cache/AppCache";
import { PageService } from "../../../Services/NotHook/PageService";
const LineApi = new Api.LineApi();
export const LineCommand = () => {
  const { DrawLineAnnotation } = PageService();
  const DrawLine = async (pageNo: number, param: Api.AddLineParam | null) => {
    if (!param) return;
    param.borderWidth = AppCache.LineBorderWidth?.borderAdd || 0.02 * 72;
    param.color = AppCache.LineBorderWidth?.colorAdd || "#323130";
    var res = await LineApi.apiLegalxtractAddLinePost(pageNo, param);
    if (AppCache.CurrentPageNo === pageNo) {
      AppCache.DataCurrentPage = res.data.result?.pageData || null;
      DrawLineAnnotation(pageNo || 0);
    }
    return res;
  };
  const Do = async (pageNo: number, param: Api.AddLineParam | null) => {
    var res = await DrawLine(pageNo, param);
    UndoRedoManagerObj.add({
      Redo: () => {
        Redo(pageNo, param);
      },
      Undo: () => {
        Undo(res?.data.result?.data?.id || "", pageNo);
      },
    }); //add undo redo
  };
  const Redo = (pageNo: number, param: Api.AddLineParam | null) => {
    DrawLine(pageNo, param);
  };
  const Undo = async (id: string | null, pageNo: number) => {
    const res = await LineApi.apiLegalxtractRemoveLinePost(id || "", pageNo);
    if (AppCache.CurrentPageNo === pageNo) {
      AppCache.DataCurrentPage = res.data.result?.pageData || null;
      DrawLineAnnotation(pageNo || 0);
    }
  };
  return { Do };
};

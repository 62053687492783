import { create } from "zustand";
import { persist } from "zustand/middleware";
import * as Api from "../Api/src/index";
interface CommentState {
  CommentObjDisplayWebs?: Api.CommentObjDisplayWeb[];
  OrderBy?: Api.CommentOrderByEnum ;
}
interface CommentStore {
  CommentState: CommentState;
  SetCommentStore: (CommentState: CommentState) => void;
  GetCommentState: () => CommentState;
}
const useCommentStore = create<CommentStore>()((set, get) => ({
  CommentState: {
    CommentObjDisplayWebs: [],
    OrderBy: Api.CommentOrderByEnum.Page,
  },
  SetCommentStore: (CommentState) =>
    set((state) => ({
      ...state,
      CommentState: { ...state.CommentState, ...CommentState },
    })),
  GetCommentState: () => {
    const state = get().CommentState;
    return state;
  },
}));

export default useCommentStore;

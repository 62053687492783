import * as React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import App from "./App";
import Login from "./Components/Login/Login";
import { userService } from "./Services/NotHook/UserService";
import { useEffect } from "react";
import Cookies from "js-cookie";
import * as Api from "./Api/src/index";
import { axiosInstance } from "./Api/axiosConfig";
import i18n from "i18next";
import HttpBackend, { HttpBackendOptions } from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { v4 } from "uuid";
import {
  AppConstants,
  AppLocalStorage,
  CompanyCode,
  ConfigSettings,
} from "./Constant/AppContant";
const {
  REACT_APP_IsDev,
  REACT_APP_ConfigAppUrl,
  REACT_APP_SENTRY_DNS,
  NODE_ENV,
} = process.env;
function AppRoute() {
    // @ts-ignore
    window["ConfigAppUrl"] = REACT_APP_ConfigAppUrl;
    // @ts-ignore
  let initCheck = React.useRef(false);
  const [loadedConfig, SetLoadedConfig] = React.useState(false);
  const { getUser } = userService();
  useEffect(() => {
    if (!initCheck.current) {
      initCheck.current = true;
      localStorage.removeItem(AppLocalStorage.Token);
      let identifiedKey = "";
      let configUrl = `http://localhost:44315/Connect/`;
      if (
        !REACT_APP_IsDev ||
        (REACT_APP_IsDev === "false" &&
          window.origin.indexOf("localhost") < 0 &&
          window.origin.indexOf("kubernetes.docker.internal") < 0)
      ) {
        // @ts-ignore
        configUrl = `${window.origin}${window["ConfigAppUrl"]}`;
      }
      axiosInstance.get(configUrl + "GetConfigApp").then(async (res: any) => {
        ConfigSettings.REACT_APP_API_ENDPOINT = res.data.legalXtractAPIUrl;
        ConfigSettings.REACT_APP_CompanyCode = (
          res.data.companyCode as string
        ).toLocaleLowerCase();
        ConfigSettings.OCRAPI = res.data.apiocr;
        ConfigSettings.RegisterLink = res.data.registerLink;
        // @ts-ignore
        window["REACT_APP_API_ENDPOINT"] = res.data.legalXtractAPIUrl;
        // @ts-ignore
        window["AppCode"] = ConfigSettings.REACT_APP_CompanyCode;
        if (!ConfigSettings.REACT_APP_CompanyCode) {
          const user = getUser();
          if (
            !user &&
            window.location.href.toLocaleLowerCase().indexOf("/login") < 0
          ) {
            window.location.href = "/login";
          }
        } else if (ConfigSettings.REACT_APP_CompanyCode === CompanyCode.Nova) {
          if (!Cookies.get(AppConstants.TokenThirdParty)) {
            window.location.href = configUrl + "Error";
          } else {
            axiosInstance.post(
              ConfigSettings.OCRAPI + "/api/legalXtractocr/setconfignova",
              {
                requestId: Cookies.get(AppConstants.RequestId) || "",
                tokenThirdParty: Cookies.get(AppConstants.TokenThirdParty),
              },
              {
                headers: {
                  "Content-Type": "application/json-patch+json",
                },
              }
            );
            const res1 = await axiosInstance.post(
              ConfigSettings.REACT_APP_API_ENDPOINT +
                "/api/legalxtract/ValidateNovaToken",
              {
                requestId: Cookies.get(AppConstants.RequestId) || "",
                tokenThirdParty: Cookies.get(AppConstants.TokenThirdParty),
                email: Cookies.get(AppConstants.Email) || "",
                fullName: Cookies.get(AppConstants.FullName) || "",
                licenseKey: Cookies.get(AppConstants.LicenseKey) || "",
                userId: Cookies.get(AppConstants.UserId) || "",
              },
              {
                headers: {
                  IdentifiedKey: Cookies.get(AppConstants.RequestId) || "",
                  "Content-Type": "application/json-patch+json",
                },
              }
            );
            identifiedKey = Cookies.get(AppConstants.RequestId) || "";
            if (res1.data) {
              localStorage.setItem(
                AppLocalStorage.UserInfo,
                JSON.stringify(res1.data)
              );
            }
          }
        } else if (ConfigSettings.REACT_APP_CompanyCode === CompanyCode.lc) {
          // const href = window.location.href;
          // identifiedKey = getParameterByName("docId", href) || "";
          // if (!identifiedKey) {
          //   identifiedKey = localStorage.IdentifiedKey.toString();
          // }
          // const res1 = await axiosInstance.get(
          //   DataCache.REACT_APP_API_ENDPOINT +
          //     `/api/legalxtract/thirdpartyconnect?documentId=${identifiedKey}`
          // );
          // if (!res1.data.ErrorMessage && res1.data.result) {
          //   localStorage.setItem(
          //     LocalStorageKey.UserInfo,
          //     JSON.stringify(res1.data.result)
          //   );
          // }
        }
        if (identifiedKey && identifiedKey !== undefined) {
          localStorage.setItem(AppLocalStorage.IdentifiedKey, identifiedKey);
        } else if (!localStorage.IdentifiedKey) {
          localStorage.setItem(AppLocalStorage.IdentifiedKey, v4());
        }
        i18n
          .use(HttpBackend)
          .use(LanguageDetector)
          .use(initReactI18next)
          .init<HttpBackendOptions>({
            supportedLngs: ["de", "en", "da"],
            debug: false,
            interpolation: {
              escapeValue: false,
            },
            backend: {
              loadPath:
                ConfigSettings.REACT_APP_API_ENDPOINT +
                "/api/legalxtract/GetLanguages/{{lng}}",
              customHeaders: () => ({
                IdentifiedKey: localStorage.IdentifiedKey.toString(),
              }),
            },
          })
          .then(async () => {});
        setTimeout(async () => {
          SetLoadedConfig(true);
        }, 200);
      });
    }
  }, []);
  if (!loadedConfig) {
    return <div></div>;
  }
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App></App>} />
          {!ConfigSettings.REACT_APP_CompanyCode && (
            <Route path="/login" element={<Login></Login>} />
          )}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default AppRoute;

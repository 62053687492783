import { create } from "zustand";
export const enum ModaType {
  None = "None",
  MDStampSetting = "MDStampSetting",
  MDReplaceWord = "MDReplaceWord",
  MDReplaceBox = "MDReplaceBox",
  MDSaveFile = "MDSaveFile",
}
interface ModalState {
  Show: boolean;
  Type?: ModaType;
  Status?: "Edit" | "Add";
}
interface ModalStore {
  ModalState: ModalState;
  SetModalState: (pageState: ModalState) => void;
  GetModalState: () => ModalState;
}
const UseModalStore = create<ModalStore>()((set, get) => ({
  ModalState: { Show: false, Type: ModaType.None, Status: "Add" },
  SetModalState: (ModalState) =>
    set((state) => ({
      ...state,
      ModalState: {
        ...state.ModalState,
        ...ModalState,
      },
    })),
  GetModalState: () => {
    return get().ModalState;
  },
}));

export default UseModalStore;

import UndoRedoManagerObj from "../UndoRedomanager";
import * as Api from "../../Api/src/index";
import { AppCache } from "../../Cache/AppCache";
import { PageService } from "../../Services/NotHook/PageService";
import { UndoHightLight } from "./HightLightCommand";
import { LeftPanelService } from "../../Services/Hook/LeftPanel/LeftPanelService";
const HighlightApi = new Api.HighlightApi();
export const HightLightSectionCommand = () => {
  const { SetDataCurrentPage } = PageService();
  const { LoadLeftPanelData } = LeftPanelService();
  const HightLight = async (
    pageNo: number,
    rectangleSelection: Api.RectangleSelection | null
  ) => {
    if (!rectangleSelection) return;
    var res =
      await HighlightApi.apiLegalxtractHighLightOrUnHighLightSectionPost({
        mode: Api.HighLightMode.None,
        viewerPageNo: pageNo,
        rectangleSelection: [rectangleSelection],
      });
    if (AppCache.CurrentPageNo === pageNo) {
      SetDataCurrentPage(res.data.lxPageData!, AppCache.CurrentPageNo || 0);
    }
    LoadLeftPanelData(["pnFind", "pnWatchDog", "pnLog"]);
    return res;
  };
  const Do = async (
    pageNo: number,
    rectangleSelection: Api.RectangleSelection | null
  ) => {
    const res = await HightLight(pageNo, rectangleSelection);
    UndoRedoManagerObj.add({
      Redo: () => {
        Redo(pageNo, rectangleSelection);
      },
      Undo: () => {
        Undo({
          preState: JSON.stringify(res?.data.dicPreState),
          viewerPageNo: pageNo,
        });
      },
    });
  };
  const Redo = (
    pageNo: number,
    rectangleSelection: Api.RectangleSelection | null
  ) => {
    HightLight(pageNo, rectangleSelection);
  };
  const Undo = async (param: Api.UndoHightLightParam) => {
    await UndoHightLight(param);
  };
  return { Do };
};

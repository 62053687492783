import { TableColumnsType } from "antd";
import useTagStore from "../../../../ZustandStore/TagStore";
import * as Api from "../../../../Api/src/index";
import { useEffect, useRef, useState } from "react";
import TagCommand from "../../../../UndoRedo/Commands/TagCommand";
import { AppCache } from "../../../../Cache/AppCache";
const TagApi = new Api.TagApi();
export interface TreeTagNode {
  key: string;
  color: string;
  label: string;
  children: { key: string; label: string }[];
}
export const MDTagSelectService = () => {
  const { TagState } = useTagStore();
  const tagCommand = TagCommand();

  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);
  const initCheck = useRef(false);
  useEffect(() => {
    if (initCheck.current) return;
    setExpandedRowKeys(
      TagState.Tags?.tagCategories?.map((o) => o.id || "") || []
    );
    initCheck.current = true;
  }, []);
  const columns: TableColumnsType<TreeTagNode> = [
    {
      title: "label",
      dataIndex: "label",
      key: "label",
    },
  ];

  const getNodes = (): TreeTagNode[] => {
    const treeNodes: TreeTagNode[] = [];
    if (TagState.Tags) {
      TagState.Tags?.tagCategories?.forEach((tag: Api.TagCategory) => {
        const index = TagState.Tags?.tagNames?.findIndex(
          (o) => o.tagCategoryId == tag.id
        );
        if (index != undefined && index >= 0) {
          treeNodes.push({
            key: tag.id || "",
            label: tag.keyword || "",
            color: tag.color || "",
            children:
              TagState.Tags?.tagNames
                ?.filter((o) => o.tagCategoryId === tag.id)
                .map((child: Api.TagName) => {
                  return {
                    key: child.id,
                    label: child.keyword,
                  } as { key: string; label: string };
                }) || [],
          });
        }
      });
    }
    return treeNodes;
  };
  const onAddTag = (item: TreeTagNode) => {
    var RectangleSelections = AppCache.WordTagsFocus.flatMap((o) => {
      return o.rects;
    }).map((o) => {
      return {
        height: o?.height || 0,
        left: o?.left || 0,
        top: o?.top || 0,
        width: o?.width || 0,
      } as Api.RectangleSelection;
    });
    tagCommand.Do(AppCache.CurrentPageNo, RectangleSelections, {
      backColor: item.color,
      keyWord: item.label,
      tagNameId: item.key,
    });
  };
  return { onAddTag, getNodes, columns, expandedRowKeys, setExpandedRowKeys };
};

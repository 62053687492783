import React, { useEffect, useRef, useState } from "react";
import * as Api from "../../../Api/src";
import { useLang } from "../../../i18n/useLang";
import { PageThumbnail } from "./PageThumbnail";
import SelectionContainer from "../../../Controls/SelectionContainer";
import { AppCache } from "../../../Cache/AppCache";
import {
  MenuID,
  ThumbnailPanelService,
} from "../../../Services/Hook/LeftPanel/ThumbnailPanelService";
import { Col, Row } from "antd";
import "../../../Assets/Styles/LeftPanels/ThumbnailPanel.scss";
import { Item, Menu } from "react-contexify";
import MDSplitDocument from "../../../Modal/MDSplitDocument";
const ThumbnailApi = new Api.ThumbnailApi();
let oldScrollPosition = 0;
let timeOutGetThumbnail: any;
interface Position {
  bottom: number;
  height: number;
  left: number;
  right: number;
  top: number;
  width: number;
  x: number;
  y: number;
}
interface ThumbnailPanelProps {
  Selected: boolean;
}
export const ThumbnailPanel = () => {
  const {
    generateThumbnail,
    DrawMenuItem,
    ThumbnailState,
    getSelectedItem,
    LoadThumnailScroll,
    thumbnail_container,
    ShowMenuContext,
    ShowSplitDocument,
    SetShowSplitDocument,
  } = ThumbnailPanelService();
  const [paramSplitDocument, setParamSplitDocument] = useState({
    pageNo: 0,
    tabName: "",
    show: false,
  });
  const StartSelection = useRef<boolean>(false);
  const { tr } = useLang();

  return (
    <div className="left-panel-container">
      <Row style={{ height: "100%" }} gutter={[8, 8]}>
        <Col span={24} className="header-panel">
          {tr("thumbnail")}
        </Col>
        <Col style={{ height: "calc(100% - 44px)" }} span={24}>
          <SelectionContainer
            id="thumbnailSelectionContainer"
            style={{
              overflow: "auto",
            }}
            onEndSelection={(e, elementRect) => {
              getSelectedItem(e, elementRect);
            }}
            onStartSelection={() => {}}
            onScroll={() => {
              LoadThumnailScroll();
            }}
            onClick={(e) => {
              //   CacheApp.FocusComponent = ComponentNames.Thumbnail;
            }}
          >
            <div
              className={"thumbnail-container"}
              ref={thumbnail_container}
              id={"thumbnail_container"}
            >
              {generateThumbnail()}
            </div>
          </SelectionContainer>
          {DrawMenuItem()}
        </Col>
        {ShowSplitDocument.open && (
          <MDSplitDocument
            name={ShowSplitDocument.name}
            pageNo={ShowSplitDocument.pageNo}
            onClose={() => {
              SetShowSplitDocument({ open: false, name: "", pageNo: 0 });
            }}
            open={ShowSplitDocument.open}
          ></MDSplitDocument>
        )}
      </Row>
    </div>
  );
};

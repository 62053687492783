import { useState } from "react";
import * as Api from "../../../Api/src/index";
import { v4 } from "uuid";
import { FormikErrors, useFormik } from "formik";
import * as yup from "yup";
import { useLang } from "../../../i18n/useLang";
import { CustomSearchTemplate } from "../../NotHook/SearchService";
const TagApi = new Api.TagApi();
export interface MDCustomTemplaceSearchProps {
  open?: boolean;
  onClose?: (Item: CustomSearchTemplate | null) => void;
  editItem?: CustomSearchTemplate | null;
}
export const MDCustomTemplaceSearchService = ({
  editItem,
  onClose,
}: MDCustomTemplaceSearchProps) => {
  const { tr } = useLang();
  const validationTagNameSchema = yup.object({
    name: yup.string().required(tr("pleaseSetName")),
    value: yup.string().required(tr("pleaseSetPattern")),
  });
  const showErrors = (error: FormikErrors<CustomSearchTemplate>) => {
    return (
      <>
        {error.name && <div>{error.name}</div>}
        {error.value && <div>{error.value}</div>}
      </>
    );
  };
  const GetInitDataFormik: () => CustomSearchTemplate = () => {
    if (editItem) return editItem;
    return {
      id: v4(),
      isRegex: false,
      name: "",
      selected: false,
      value: "",
    } as CustomSearchTemplate;
  };
  const formik = useFormik({
    initialValues: GetInitDataFormik(),
    validationSchema: validationTagNameSchema,
    onSubmit: async (values) => {
      onClose && onClose(values);
    },
  });
  return { formik, showErrors };
};

import UndoRedoManagerObj from "../UndoRedomanager";
import * as Api from "../../Api/src/index";
import { AppCache } from "../../Cache/AppCache";
import { PageService } from "../../Services/NotHook/PageService";
import { UndoHightLight } from "./HightLightCommand";
import { LeftPanelService } from "../../Services/Hook/LeftPanel/LeftPanelService";
const HighlightApi = new Api.HighlightApi();
export const HightLightMaskCommand = () => {
  const { SetDataCurrentPage } = PageService();
  const { LoadLeftPanelData } = LeftPanelService();
  const HightLight = async (
    mode: Api.HighLightMode,
    pageNo: number,
    rectangleSelections: Api.RectangleSelection[] | null
  ) => {
    if (!rectangleSelections || rectangleSelections.length === 0) return;
    var res = await HighlightApi.apiLegalxtractHighLightOrUnHighLightMaskPost({
      mode: mode,
      viewerPageNo: pageNo,
      rectangleSelection: rectangleSelections,
    });
    if (AppCache.CurrentPageNo === pageNo) {
      AppCache.DataCurrentPage = res.data.lxPageData || null;
      SetDataCurrentPage(res.data.lxPageData!, AppCache.CurrentPageNo || 0);
    }
    LoadLeftPanelData(["pnFind", "pnWatchDog", "pnLog"]);
    return res;
  };
  const Do = async (
    mode: Api.HighLightMode,
    pageNo: number,
    rectangleSelections: Api.RectangleSelection[] | null
  ) => {
    const res = await HightLight(mode, pageNo, rectangleSelections);
    UndoRedoManagerObj.add({
      Redo: () => {
        Redo(mode, pageNo, rectangleSelections);
      },
      Undo: () => {
        Undo({
          preState: JSON.stringify(res?.data.dicPreState),
          viewerPageNo: pageNo,
        });
      },
    });
  };
  const Redo = (
    mode: Api.HighLightMode,
    pageNo: number,
    rectangleSelections: Api.RectangleSelection[] | null
  ) => {
    HightLight(mode, pageNo, rectangleSelections);
  };
  const Undo = (param: Api.UndoHightLightParam) => {
    UndoHightLight(param);
  };
  return { Do };
};

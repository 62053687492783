import { CoreFileOptions, fileOpen } from "browser-fs-access";
import { AppCache } from "../Cache/AppCache";
export const FileDialog = {
  ShowFileTypeSupportDialog: async (): Promise<File[]> => {
    const arrFile: File[] = [];
    const fileSupports = [] as CoreFileOptions[];
    Object.keys(AppCache.FileTypeSupport).forEach((key: string) => {
      fileSupports.push({
        description: key.charAt(0).toUpperCase() + key.slice(1),
        extensions: AppCache.FileTypeSupport[key]
          .split(";")
          .map((ext: any) => `.${ext.toLowerCase()}`),
      });
    });
    try {
      const files = await fileOpen([
        {
          description: "All File",
          extensions: AppCache.FileInputAccept.split(","),
          multiple: true,
          excludeAcceptAllOption: true,
        },
        ...fileSupports,
      ]);
      for (let index = 0; index < files.length; index++) {
        arrFile.push(files[index]);
      }
      return arrFile;
    } catch (error) {
      console.log(error);
    }
    return arrFile;
  },
};

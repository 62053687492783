import { useState } from "react";
import useTagStore from "../../../ZustandStore/TagStore";
import useMenuStripStore from "../../../ZustandStore/MenuStripStore";
import * as Api from "../../../Api/src/index";
import { AppLocalStorage } from "../../../Constant/AppContant";
import { StampSettingsParam } from "../Modal/Stamp/MDStamSettingService";
import { AppCache } from "../../../Cache/AppCache";
import { PageService } from "../../NotHook/PageService";
import { AddBlankPageCommand } from "../../../UndoRedo/Commands/AddBlankPageCommand";
import { PageNumberingCommand } from "../../../UndoRedo/Commands/PageNumberingCommand";
import { defaultPageNumberingInfo } from "../Modal/MDPageNumberingService";
import { LeftPanelService } from "../LeftPanel/LeftPanelService";
import useTabFileInfoStore from "../../../ZustandStore/TabFileInfoStore";
import { OpenFileService } from "../OpenFileService";
import usePageStore from "../../../ZustandStore/PageStore";
import { useNavigate } from "react-router-dom";
import { userService } from "../../NotHook/UserService";
import { fileOpen } from "browser-fs-access";
import { PdfTronService } from "../../NotHook/PdfTronService";
const StampApi = new Api.StampApi();
const CloseFileApi = new Api.CloseFileApi();
const FrontPageApi = new Api.FrontPageApi();
export const TopMenuStripService = () => {
  const navidate = useNavigate();
  const { InsertBlankPage, MergeDocument } = PageService();
  const { DeletePages } = PdfTronService();
  const { getUser } = userService();
  const { TagState, SetTagStore } = useTagStore();
  const { SetTabFileInfoState } = useTabFileInfoStore();
  const { PageState, SetPageStore } = usePageStore();
  const { MenuStripState, SetMenuStripStore } = useMenuStripStore();
  const { LoadLeftPanelData } = LeftPanelService();
  const addBlankPageCommand = AddBlankPageCommand();
  const pageNumberingCommand = PageNumberingCommand();
  const { SetDataCurrentPage } = PageService();
  const [ShowStampSetting, SetShowStampSetting] = useState<{
    open: boolean;
  }>({ open: false });
  const [ShowAppSetting, SetShowAppSetting] = useState<{
    open: boolean;
  }>({ open: false });
  const [ShowPageNumbering, SetShowPageNumbering] = useState<{
    open: boolean;
  }>({ open: false });
  const { OpenFiles } = OpenFileService();
  const OpenClick = () => {
    OpenFiles(true);
  };
  const AddClick = () => {
    OpenFiles(false);
  };
  const TagOptionsClick = () => {
    SetTagStore({ ShowTagList: true });
  };
  const TagOptionsClose = () => {
    SetTagStore({ ShowTagList: false });
  };
  const ShowStampClick = async () => {
    if (AppCache.DocumentInfo?.showStamp) {
      var res = await StampApi.apiLegalxtractRemoveAllStampPost();
      if (res && res.data.isSuccess) {
        AppCache.DataCurrentPage = res.data.result?.pageData || null;
        AppCache.DocumentInfo = res.data.result?.data || null;
      }
    } else {
      var Stamp = AppLocalStorage.GetItem(
        AppLocalStorage.StampInfo
      ) as StampSettingsParam;
      var res = await StampApi.apiLegalxtractApplyStampAllPagePost(
        Stamp.showFromToc,
        Stamp.automaticCount,
        Stamp.index || "",
        Stamp
      );
      if (res && res.data.isSuccess) {
        AppCache.DataCurrentPage = res.data.result?.pageData || null;
        AppCache.DocumentInfo = res.data.result?.data || null;
      }
    }
    SetMenuStripStore({ ShowStamp: AppCache.DocumentInfo?.showStamp || false });
    SetDataCurrentPage(
      AppCache.DataCurrentPage! || null,
      AppCache.CurrentPageNo || 0
    );
  };
  const AddBlankPage = () => {
    addBlankPageCommand.Do(!AppCache.DocumentInfo?.isBlankPage);
  };
  const ShowPageNumberingClick = () => {
    var obj = AppLocalStorage.GetItem(
      AppLocalStorage.PageNumbering
    ) as Api.PageNumberingInfo;
    if (!obj) {
      obj = defaultPageNumberingInfo;
    }
    obj.included = !obj.included;
    pageNumberingCommand.Do(AppCache.CurrentPageNo, obj);
  };
  const CloseClick = async () => {
    var res = await CloseFileApi.apiLegalxtractCloseAllFileGet();
    AppCache.DocumentInfo = res.data.result || null;
    AppCache.WebViewerInstance?.Core.documentViewer.closeDocument();
    SetTabFileInfoState({ TabDetails: [] });
    SetPageStore({ TotalPages: 0, CurrentPageNo: 0 });
    LoadLeftPanelData([]);
  };
  const LogoutClick = () => {
    new Api.LoginApi().apiLegalxtractLogoutPost().then(() => {
      AppCache.WebViewerInstance?.Core.documentViewer.closeDocument();
      AppCache.DocumentInfo = null;
      SetTabFileInfoState({ TabDetails: [] });
      SetPageStore({ TotalPages: 0, CurrentPageNo: 0 });
      navidate("/login");
    });
  };
  const CreateFrontPage = (addFrontPage: boolean) => {
    FrontPageApi.apiLegalxtractProcessFrontPagePost(
      addFrontPage,
      undefined
    ).then(async (res) => {
      if (res && res.data) {
        await RemoveFrontPage();
        SetMenuStripStore({ CreateFrontPage: addFrontPage });
        const tabNews =
          res.data.result?.data?.tabDetails?.filter(
            (o) =>
              AppCache.DocumentInfo?.tabDetails?.findIndex(
                (p) => p.uniqueId === o.uniqueId
              ) === -1
          ) || [];
        AppCache.DocumentInfo = res.data.result?.data || null;
        SetPageStore({
          TotalPages: AppCache.DocumentInfo?.totalPage || 0,
        });
        const CurrentTab = AppCache.DocumentInfo?.tabDetails![0];
        SetTabFileInfoState({
          TabDetails: AppCache.DocumentInfo?.tabDetails || [],
          CurrentTab: CurrentTab || null,
        });
        if (addFrontPage) {
          tabNews
            .flatMap((o) => o.pages || [])
            .forEach((o) => {
              InsertBlankPage(o.viewerPageNo || 0, 8.27 * 72, 11.69 * 72);
            });
        }
        if (!AppCache.WebViewerInstance?.Core) return;
        const { documentViewer } = AppCache.WebViewerInstance?.Core;
        documentViewer.refreshAll();
      }
    });
  };
  const UploadFrontPage = async (addFrontPage: boolean) => {
    let file = undefined;
    if(addFrontPage){
      file = await fileOpen([
        {
          description: 'Pdf',
          extensions: ['.pdf'],
          multiple: false,
        },
      ]);
      if (!file) return;
    }else {
       file = undefined;
    }
    
    
    FrontPageApi.apiLegalxtractProcessFrontPagePost(addFrontPage, file).then(
      async (res) => {
        if (res && res.data) {
          await RemoveFrontPage();
          SetMenuStripStore({ UploadFrontPage: addFrontPage });
          AppCache.DocumentInfo = res.data.result?.data || null;
          SetPageStore({
            TotalPages: AppCache.DocumentInfo?.totalPage || 0,
            CurrentPageNo: 1,
          });
          const CurrentTab = AppCache.DocumentInfo?.tabDetails![0];
          SetTabFileInfoState({
            TabDetails: AppCache.DocumentInfo?.tabDetails || [],
            CurrentTab: CurrentTab || null,
          });
          if (addFrontPage && file) {
            MergeDocument(file, 1);
          }
          if (!AppCache.WebViewerInstance?.Core) return;
          const { documentViewer } = AppCache.WebViewerInstance?.Core;
          documentViewer.refreshAll();
        }
      }
    );
  };
  const RemoveFrontPage = async () => {
    var tab = AppCache.DocumentInfo?.tabDetails?.find(
      (o) => o.tabFileType === Api.TabFileType.FrontPage
    );
    if (tab) {
      await DeletePages(tab.pages!.map((o) => o.viewerPageNo!));
    }
  };
  return {
    TagOptionsClick,
    TagOptionsClose,
    TagState,
    SetShowStampSetting,
    ShowStampSetting,
    ShowAppSetting,
    SetShowAppSetting,
    ShowPageNumbering,
    SetShowPageNumbering,
    MenuStripState,
    ShowStampClick,
    AddBlankPage,
    ShowPageNumberingClick,
    CloseClick,
    AddClick,
    OpenClick,
    LogoutClick,
    getUser,
    CreateFrontPage,
    UploadFrontPage,
  };
};

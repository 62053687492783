import React from "react";
import { AppCache } from "../../../Cache/AppCache";
import { ToolMode } from "../../../Constant/AppEnum";
import { HightLightWordCommand } from "../../../UndoRedo/Commands/HightLightCommand";
import * as Api from "../../../Api/src/index";
import { PageService } from "../../NotHook/PageService";
import usePageStore from "../../../ZustandStore/PageStore";
import useTabFileInfoStore from "../../../ZustandStore/TabFileInfoStore";
import { AnnoSubject, AppLocalStorage } from "../../../Constant/AppContant";
import { Core } from "@pdftron/webviewer";
import { StampService } from "../../NotHook/StampService";
import { GetTextSite } from "../../../Common/CommonFunction";
import { PdfTronService } from "../../NotHook/PdfTronService";
import { useContextMenu } from "react-contexify";
import useMenuStripStore from "../../../ZustandStore/MenuStripStore";
import { BlankPageService } from "../../NotHook/BlankPageService";
import { NoteService } from "../../NotHook/NoteService";
import { LeftPanelService } from "../LeftPanel/LeftPanelService";
import { ThumbnailPanelService } from "../LeftPanel/ThumbnailPanelService";
import { MENU_ID } from "../../../Components/MainPanel/MenuContext";
import { EditLineCommand } from "../../../UndoRedo/Commands/Line/EditLineCommand";
import { EditBoxCommand } from "../../../UndoRedo/Commands/Box/EditBoxCommand";
import useLoaddingAppStore from "../../../ZustandStore/LoaddingAppStore";
let pageNumberUpdatedTimeOut = null as any;
let tabUpdatedTimeOut = null as any;
let AnnotationSelected = false;
const DocumentApi = new Api.DocumentApi();
export const MainViewResgisterEvent = () => {
  const { SetPageStore } = usePageStore();
  const {
    GetAnnotationByMousePosition,
    SetCurrentPageNo,
    RectConvertToPDFCoordinates,
  } = PdfTronService();
  const { SetTabFileInfoState } = useTabFileInfoStore();
  const { SetMenuStripStore } = useMenuStripStore();
  const { SetLoaddingAppStore } = useLoaddingAppStore();
  const { UpdateNote } = NoteService();
  const { LoadNoteData } = LeftPanelService();
  const [page, setPage] = React.useState();
  const hightLightWordCommand = HightLightWordCommand();
  const editLineCommand = EditLineCommand();
  const editBoxCommand = EditBoxCommand();

  const {
    GetWordFocus,
    DrawFocusWord,
    DrawHighlightTextAnnotation,
    SetCurrentPageNoOnServer,
    GetDataCurrentPage,
    DrawAnnotationOnPage,
    ChooseWordForTag,
    DrawReplaceToolTipAnnotation,
    RotatePagesOnView,
  } = PageService();
  const InitEvent = () => {
    MouseMove();
    MouseLeftUp();
    MouseRightUp();
    PageNumberUpdated();
    DocumentLoaded();
    AnnotationChanged();
  };
  const MouseMove = () => {
    if (!AppCache.WebViewerInstance?.Core) return;
    const { documentViewer } = AppCache.WebViewerInstance?.Core;
    documentViewer.addEventListener("mouseMove", (e) => {
      AppCache.LastWordFocus = null;
      switch (AppCache.ToolMode) {
        case ToolMode.Select:
        case ToolMode.Mask: {
          if (AnnotationSelected) return;
          var mousePosition =
            documentViewer.getViewerCoordinatesFromMouseEvent(e);
          var drawReplaceToolTip = DrawReplaceToolTipAnnotation(
            AppCache.CurrentPageNo,
            mousePosition.x,
            mousePosition.y
          );
          if (drawReplaceToolTip) return;
          var mousePosition = documentViewer.getPDFCoordinatesFromMouseEvent(e);
          const word = GetWordFocus(mousePosition.x, mousePosition.y);
          if (word) {
            DrawFocusWord(word, documentViewer.getCurrentPage());
            AppCache.LastWordFocus = word;
          }
          break;
        }
        case ToolMode.Tag: {
          if (AnnotationSelected) return;
          var mousePosition = documentViewer.getPDFCoordinatesFromMouseEvent(e);
          const word = GetWordFocus(mousePosition.x, mousePosition.y);
          DrawFocusWord(word, documentViewer.getCurrentPage());
          AppCache.LastWordFocus = word;
          break;
        }
        case ToolMode.Line:
          break;
      }
    });
  };
  const { show, hideAll } = useContextMenu({
    id: MENU_ID,
  });
  function ShowContextMenu(event: any) {
    hideAll();
    setTimeout(() => {
      if (!AppCache.WebViewerInstance?.Core) return;
      const { documentViewer } = AppCache.WebViewerInstance?.Core;
      event.preventDefault();
      event.stopPropagation();
      var mousePosition =
        documentViewer.getViewerCoordinatesFromMouseEvent(event);
      const anno = GetAnnotationByMousePosition(
        documentViewer.getCurrentPage(),
        mousePosition.x,
        mousePosition.y
      );
      AppCache.AnnotationSelected = anno || null;
      show({
        event,
        position: { x: event.screenX, y: event.screenY - 100 },
      });
    }, 200);
  }
  const MouseLeftUp = () => {
    if (!AppCache.WebViewerInstance?.Core) return;
    const { documentViewer } = AppCache.WebViewerInstance?.Core;
    documentViewer.addEventListener("mouseLeftUp", (e) => {
      AppCache.WordSelected = AppCache.LastWordFocus;
      hideAll();
      switch (AppCache.ToolMode) {
        case ToolMode.Select:
          DrawHighlightTextAnnotation(
            AppCache.WordSelected,
            documentViewer.getCurrentPage()
          );
          var mousePosition = documentViewer.getPDFCoordinatesFromMouseEvent(e);
          hightLightWordCommand.Do(documentViewer.getCurrentPage(), {
            x: mousePosition.x,
            y: mousePosition.y,
          });
          break;
        case ToolMode.Tag:
          ChooseWordForTag(
            AppCache.WordSelected,
            documentViewer.getCurrentPage()
          );
          break;
        case ToolMode.Line:
          break;
        case ToolMode.Mask:
          const lxSetting = AppLocalStorage.GetItem(
            AppLocalStorage.LxSetting
          ) as Api.LxSetting;
          if (
            !lxSetting.autoHighlightMask &&
            documentViewer.getSelectedText().length > 0
          ) {
            ShowContextMenu(e);
          }
          break;
      }
    });
  };
  const MouseRightUp = () => {
    if (!AppCache.WebViewerInstance?.Core) return;
    const { documentViewer } = AppCache.WebViewerInstance?.Core;
    documentViewer.addEventListener("mouseRightUp", (e) => {
      AppCache.WordSelected = AppCache.LastWordFocus;
      const pos = documentViewer.getPDFCoordinatesFromMouseEvent(e);
      AppCache.LastMousePositionServer = { x: pos.x, y: pos.y };
      ShowContextMenu(e);
    });
  };
  const PageNumberUpdated = () => {
    if (!AppCache.WebViewerInstance?.Core) return;
    const { documentViewer } = AppCache.WebViewerInstance?.Core;
    documentViewer.addEventListener(
      "pageNumberUpdated",
      (pageNumber: number) => {
        if (pageNumberUpdatedTimeOut) {
          clearTimeout(pageNumberUpdatedTimeOut);
        }
        pageNumberUpdatedTimeOut = setTimeout(async () => {
          SetCurrentPageNoOnServer(pageNumber);
          SelectedTabFromPageNo(pageNumber);
          SetPageStore({ CurrentPageNo: pageNumber });
        }, 300);
      }
    );
  };
  const AnnotationChanged = () => {
    if (!AppCache.WebViewerInstance?.Core) return;
    const { annotationManager, Annotations } = AppCache.WebViewerInstance?.Core;
    const { UpdateStamp } = StampService();
    annotationManager.addEventListener(
      "annotationChanged",
      async (annos: Core.Annotations.Annotation[], action: string) => {
        switch (action) {
          case "add":
            break;
          case "modify": {
            var stampAnno = annos.find((anno: Core.Annotations.Annotation) => {
              return anno.Subject === AnnoSubject.stamp;
            });
            var noteAnno = annos.find((anno: Core.Annotations.Annotation) => {
              return anno.Subject === AnnoSubject.note;
            });
            var lineAnno = annos.find((anno: Core.Annotations.Annotation) => {
              return anno.Subject === AnnoSubject.line;
            });

            if (stampAnno) {
              UpdateStamp(stampAnno);
            }
            if (noteAnno) {
              await UpdateNote(noteAnno);
              if (AppCache.LeftTabActive === "pnNote") LoadNoteData();
            }
            if (lineAnno) {
              const newRect = RectConvertToPDFCoordinates(
                {
                  left: lineAnno.X,
                  top: lineAnno.Y,
                  right: lineAnno.X + lineAnno.Width,
                  bottom: lineAnno.Y + lineAnno.Height,
                },
                AppCache.CurrentPageNo
              );
              let width = newRect?.width || 0;
              let height = newRect?.height || 0;
              if (width > height) {
                height = 0;
              } else {
                width = 0;
              }
              editLineCommand.Do(AppCache.CurrentPageNo, {
                height: height,
                id: lineAnno.getCustomData("lineId"),
                left: newRect?.left,
                top: newRect?.top,
                width: width,
              });
            }
            var boxAnno = annos.find((anno: Core.Annotations.Annotation) => {
              return (
                anno.Subject === AnnoSubject.box ||
                anno.Subject === AnnoSubject.replacebox
              );
            });
            if (boxAnno) {
              const newRect = RectConvertToPDFCoordinates(
                {
                  left: boxAnno.X,
                  top: boxAnno.Y,
                  right: boxAnno.X + boxAnno.Width,
                  bottom: boxAnno.Y + boxAnno.Height,
                },
                AppCache.CurrentPageNo
              );
              editBoxCommand.Do(AppCache.CurrentPageNo, {
                height: newRect?.height || 0,
                id: boxAnno.getCustomData("boxId"),
                left: newRect?.left || 0,
                top: newRect?.top || 0,
                width: newRect?.width || 0,
              });
            }
            break;
          }

          case "delete":
            break;
        }
      }
    );
    annotationManager.addEventListener(
      "annotationSelected",
      (annos: any[], action: string) => {
        AnnotationSelected = action === "selected" ? true : false;
      }
    );
  };
  const DocumentLoaded = () => {
    if (!AppCache.WebViewerInstance?.Core) return;
    const { documentViewer, PDFNet } = AppCache.WebViewerInstance?.Core;
    documentViewer.addEventListener("documentLoaded", async () => {
      if (documentViewer.getPageCount() > 0) {
        const res = await DocumentApi.apiLegalxtractGetDocumentInfoGet();
        AppCache.DocumentInfo = res.data.result || null;
        RotatePagesOnView();
        SetMenuStripStore({
          ShowStamp: AppCache.DocumentInfo?.showStamp || false,
          AddBlankPage: AppCache.DocumentInfo?.isBlankPage || false,
          CreateFrontPage: AppCache.DocumentInfo?.isCreateFrontPage || false,
          UploadFrontPage: AppCache.DocumentInfo?.isUploadFrontPage || false,
          ShowPageNumbering:
            AppCache.DocumentInfo?.isIncludedPageNumber || false,
        });
        SetPageStore({
          TotalPages: res.data.result?.totalPage || 0,
          CurrentPageNo: documentViewer.getCurrentPage(),
        });
        SetTabFileInfoState({
          TabDetails: res.data.result?.tabDetails || [],
          CurrentTab: res.data.result?.tabDetails![0] || null,
        });
        if (AppCache.OldCurrentPageNo > 0) {
          setTimeout(() => {
            SetCurrentPageNo(AppCache.OldCurrentPageNo);
            AppCache.OldCurrentPageNo = 0;
          }, 500);
        } else {
          AppCache.CurrentPageNo = documentViewer.getCurrentPage();
          await GetDataCurrentPage(documentViewer.getCurrentPage());
          DrawAnnotationOnPage(documentViewer.getCurrentPage());
        }
        BlankPageService().InsertPage();
      }
      SetLoaddingAppStore({ Show: false });
    });
  };
  const SelectedTabFromPageNo = (pageNo: number) => {
    if (tabUpdatedTimeOut) {
      clearTimeout(tabUpdatedTimeOut);
    }
    tabUpdatedTimeOut = setTimeout(() => {
      const tabItem = AppCache.DocumentInfo?.tabDetails?.find(
        (o) =>
          o.startPage === pageNo ||
          (o.pageCount || 0) + (o.startPage || 0) - 1 === pageNo
      );
      if (!tabItem) return;
      SetTabFileInfoState({ CurrentTab: tabItem });
      tabUpdatedTimeOut = null;
    }, 500);
  };
  return {
    InitEvent,
    page,
  };
};

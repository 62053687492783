import { create } from "zustand";
import * as Api from "../Api/src/index";
interface TocState {
  TocRows?: Api.TocRow[];
}
interface TocStore {
  TocState: TocState;
  SetTocStore: (TocState: TocState) => void;
}
const useTocStore = create<TocStore>()((set) => ({
  TocState: { TocRows: [] },
  SetTocStore: (TocState) =>
    set((state) => ({
      ...state,
      TocState: { ...state.TocState, ...TocState },
    })),
}));
export default useTocStore;

import { useEffect, useRef, useState } from "react";
import * as Api from "../../../Api/src/index";
import { AppLocalStorage } from "../../../Constant/AppContant";
import {
  CustomSearchTemplate,
  SearchService,
  SearchTemplateParamClient,
} from "../../NotHook/SearchService";
import UseConfirmDialogStore from "../../../ZustandStore/ConfirmDialogStore";
import { useLang } from "../../../i18n/useLang";
import useSearchTabStore from "../../../ZustandStore/SearchTabStore";
export const TemplateSearchPanelService = () => {
  const { tr } = useLang();
  const { SetConfirmDialogState } = UseConfirmDialogStore();
  const { GetSearchTemplateParam } = SearchService();
  const [SearchTemplateParam, SetSearchTemplateParam] =
    useState<SearchTemplateParamClient>({
      customTemplateItems: [],
      templateItem: {},
    });
  const initCheck = useRef<boolean>(false);
  const [MDCustomeTemplaceState, SetMDCustomeTemplaceState] = useState<{
    open?: boolean;
    EditItem?: CustomSearchTemplate | null;
  }>({
    open: false,
    EditItem: null,
  });
  useEffect(() => {
    if (!initCheck.current) {
      const obj = GetSearchTemplateParam();
      SetSearchTemplateParam(obj);
      initCheck.current = true;
    }
  }, []);
  useEffect(() => {
    AppLocalStorage.SetItem(
      AppLocalStorage.TemplateSearch,
      SearchTemplateParam
    );
  }, [SearchTemplateParam]);
  const AddCustomTemplate = () => {
    SetMDCustomeTemplaceState({ open: true, EditItem: null });
  };
  const EditCustomTemplate = (item: CustomSearchTemplate) => {
    SetMDCustomeTemplaceState({ open: true, EditItem: item });
  };
  const RemoveCustomTemplate = (item: CustomSearchTemplate) => {
    const Delete = () => {
      SetSearchTemplateParam({
        ...SearchTemplateParam,
        customTemplateItems: SearchTemplateParam.customTemplateItems?.filter(
          (o) => o.id !== item.id
        ),
      });
    };
    SetConfirmDialogState({
      Show: true,
      Type: "Confirm",
      Icon: "Question",
      CancelText: tr("no"),
      YesText: tr("yes"),
      Msg: tr("doYouWantToDelete"),
      OnYes: () => {
        Delete();
      },
    });
  };
  const rowCustomTemplaceSelection = {
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: CustomSearchTemplate[]
    ) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
  };
  return {
    SearchTemplateParam,
    SetSearchTemplateParam,
    AddCustomTemplate,
    EditCustomTemplate,
    RemoveCustomTemplate,
    MDCustomeTemplaceState,
    SetMDCustomeTemplaceState,
    rowCustomTemplaceSelection,
  };
};

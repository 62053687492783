import UndoRedoManagerObj from "../UndoRedomanager";
import * as Api from "../../Api/src/index";
import { AppCache } from "../../Cache/AppCache";
import { PageService } from "../../Services/NotHook/PageService";
import { AppLocalStorage } from "../../Constant/AppContant";
import { UndoHightLight } from "./HightLightCommand";
import { LeftPanelService } from "../../Services/Hook/LeftPanel/LeftPanelService";
const TagApi = new Api.TagApi();
const TagCommand = () => {
  const { SetDataCurrentPage } = PageService();
  const { LoadLeftPanelData } = LeftPanelService();
  const HightLightTag = async (
    pageNo: number,
    rectangleSelection: Api.RectangleSelection[] | null,
    tagInfo: Api.TagInfo
  ) => {
    if (!rectangleSelection) return;
    localStorage.setItem(AppLocalStorage.DocumentHasChanged, "true");
    var res = await TagApi.apiLegalxtractSelectionTagPost({
      selectionBoxes: rectangleSelection,
      viewerPageNo: pageNo,
      tagInfo: tagInfo,
    });
    if (AppCache.CurrentPageNo === pageNo) {
      SetDataCurrentPage(res.data.pageData!, pageNo || 0);
    }
    LoadLeftPanelData(["pnFind", "pnWatchDog"]);
    return res;
  };
  const Do = async (
    pageNo: number,
    rectangleSelection: Api.RectangleSelection[] | null,
    tagInfo: Api.TagInfo
  ) => {
    const res = await HightLightTag(pageNo, rectangleSelection, tagInfo);
    UndoRedoManagerObj.add({
      Redo: () => {
        Redo(pageNo, rectangleSelection, tagInfo);
      },
      Undo: () => {
        Undo({
          preState: JSON.stringify(res?.data.data),
          viewerPageNo: pageNo,
        });
      },
    });
  };
  const Redo = (
    pageNo: number,
    rectangleSelection: Api.RectangleSelection[] | null,
    tagInfo: Api.TagInfo
  ) => {
    HightLightTag(pageNo, rectangleSelection, tagInfo);
  };
  const Undo = async (param: Api.UndoHightLightParam) => {
    await UndoHightLight(param);
  };
  return { Do };
};

export default TagCommand;

import { create } from "zustand";
import { ToolMode } from "../Constant/AppEnum";
import { AppCache } from "../Cache/AppCache";
interface MenuIconState {
  ToolMode: ToolMode;
}
interface MenuIconStore {
  MenuIconState: MenuIconState;
  SetMenuIconStore: (menuIconState: MenuIconState) => void;
}
const useMenuIconStore = create<MenuIconStore>()((set) => ({
  MenuIconState: { ToolMode: ToolMode.Select },
  SetMenuIconStore: (tagState) =>
    set((state) => 
      {
        AppCache.ToolMode = tagState.ToolMode;
        return {
          ...state,
          MenuIconState: { ...state.MenuIconState, ...tagState },
        }
      }),
}));
export default useMenuIconStore;

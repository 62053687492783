import { useEffect, useRef, useState } from "react";
import * as Api from "../../../Api/src/index";
import { v4 } from "uuid";
import useTagStore from "../../../ZustandStore/TagStore";
import { FormikErrors, useFormik } from "formik";
import * as yup from "yup";
import { useLang } from "../../../i18n/useLang";
import UseConfirmDialogStore from "../../../ZustandStore/ConfirmDialogStore";
import {
  AppConstants,
  AppLocalStorage,
  CompanyCode,
  ConfigSettings,
} from "../../../Constant/AppContant";
import { userService } from "../../NotHook/UserService";
import { AppCache } from "../../../Cache/AppCache";
import { resizeImageToBase64 } from "../../../Common/CommonFunction";
const SettingApi = new Api.SettingApi();
const FontApi = new Api.FontApi();
export interface MDAppSettingsProps {
  open?: boolean;
  onClose?: () => void;
}
export interface AppSettingsParam extends Api.LxSetting {
  dictionaryNameClient: number[];
}
export const MDAppSettingsService = ({ onClose, open }: MDAppSettingsProps) => {
  const { tr } = useLang();
  const [fontList, setFontList] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);

  const fileInputHiddenPageRef = useRef<any>(null);
  const fileInputWatermarkRef = useRef<any>(null);
  const InitCheck = useRef<boolean>(false);
  useEffect(() => {
    if (InitCheck.current) return;
    GetFontList();
    InitCheck.current = true;
  }, []);
  const validationAppSettingsSchema = yup.object({
    AppSettings: yup.string().required(tr("pleaseSetName")),
  });
  const formik = useFormik({
    initialValues: GetLxSetting(),
    // validationSchema: validationAppSettingsSchema,
    onSubmit: async (values) => {
      const dicNameSelected = values.dictionaryNameClient
        .filter((o) => o != 0)
        .reduce((acc, c) => acc | c);
      values.dictionaryName = dicNameSelected as any;
      AppLocalStorage.SetItem(AppLocalStorage.LxSetting, values);
      SettingApi.apiLegalxtractUploadSettingPost(false, values).then((res) => {
        onClose && onClose();
      });
    },
  });
  const GetFontList = () => {
    FontApi.apiLegalxtractGetListFontGet().then((res) => {
      if (res && res.data) {
        setFontList(res.data.map((item) => ({ value: item, label: item })));
      }
    });
  };
  const onSelectedWatermarkFile = async (imageFile: any) => {
    formik.setFieldValue(
      "waterMarkImage",
      await resizeImageToBase64(imageFile[0], 600, 600)
    );
  };
  const ChooseWatermarkFile = () => {
    if (!fileInputWatermarkRef.current) return;
    fileInputWatermarkRef?.current.click();
  };
  const DeleteWatermarkFile = () => {
    formik.values.waterMarkImage;
    formik.setFieldValue("waterMarkImage", null);
  };
  const onSelectedHiddenFile = async (imageFile: any) => {
    formik.setFieldValue(
      "hiddenPageImage",
      await resizeImageToBase64(imageFile[0], 500, 500)
    );
  };
  const ChooseHiddenFile = () => {
    if (!fileInputHiddenPageRef.current) return;
    fileInputHiddenPageRef?.current.click();
  };
  const DeleteHiddenFile = () => {
    formik.setFieldValue("hiddenPageImage", null);
  };
  return {
    formik,
    fontList,
    fileInputWatermarkRef,
    fileInputHiddenPageRef,
    onSelectedWatermarkFile,
    ChooseWatermarkFile,
    DeleteWatermarkFile,
    onSelectedHiddenFile,
    ChooseHiddenFile,
    DeleteHiddenFile,
  };
};
export enum Country {
  Danish = 1,
  Belgium = 2,
  Germany = 4,
  Netherlands = 8,
  International = 32768,
}
export const lxSettingDefault = {
  replaceFontName: "Arial",
  option_Color_selection: "yellow",
  highlightPublishColor: "black",
  extractCombineTab: true,
  replaceFontSize: 10,
  extractMaxFileSize: 0,
  autoHighlightMask: true,
  archiveFileFormat: 1,
  ocrWhenOpeningDocuments: false,
  compressExtractFiles: false,
  extractPdfFileType: Api.PdfFileType.PdfA,
  warningKeyword: false,
  warningTemplate: false,
  warningTag: false,
  tocIndexPattern: "1",
  drawIndexInTocPages: true,
  dictionaryNameClient: [
    Country.Danish,
    Country.Belgium,
    Country.Germany,
    Country.Netherlands,
    Country.International,
  ],
  namesWillNotBeginOrEndWith: `la, von, van, le, du, dos, di, á, van't, zum, við, del, ter, te, í, ten, el, los, das, des, a, av, d', han, en, de, kilde, den, det, dem, der, fam, vi, af, om, et, da, ip, no, ja, ny, id no, kan, er, man, in, the, til, dear, legal, ek, dir, nem, id, jeg, se, tak, raad, voor, about, us`,
  dontSendOCRMailPageCount: 100,
  hiddenPageImage: AppConstants.hiddenImg,
  hiddenPageTitle: "Content blocked",
} as AppSettingsParam;
export const GetLxSetting = () => {
  const { getUser } = userService();
  const user = getUser();
  let lx = AppLocalStorage.GetItem(
    AppLocalStorage.LxSetting
  ) as AppSettingsParam | null;
  if (lx) {
    if (!lx.dictionaryName) {
      lx.dictionaryNameClient = [
        Country.Danish,
        Country.Belgium,
        Country.Germany,
        Country.Netherlands,
        Country.International,
      ];
    }
    if (
      lx.dontSendOCRMailPageCount == undefined ||
      lx.dontSendOCRMailPageCount == null
    ) {
      lx.dontSendOCRMailPageCount = 100;
    }
    lx.hiddenPageImage = lx.hiddenPageImage || null;
    lx.hiddenPageTitle = lx.hiddenPageTitle || "Content blocked";
  } else {
    lx = lxSettingDefault;
    if (ConfigSettings.REACT_APP_CompanyCode == CompanyCode.Nova) {
      lx = {
        ...lx,
        dictionaryNameClient: [Country.Danish],
        namesWillNotBeginOrEndWith: "",
      };
    }
    if (user?.userDataLogin?.result?.user_info?.countryCode == "DNK") {
      lx = {
        ...lx,
        dictionaryNameClient: [Country.Danish],
      };
    }
  }
  AppLocalStorage.SetItem(AppLocalStorage.LxSetting, lx);
  return lx;
};

import UndoRedoManagerObj from "../../UndoRedomanager";
import * as Api from "../../../Api/src/index";
import { AppCache } from "../../../Cache/AppCache";
import { PageService } from "../../../Services/NotHook/PageService";
const BoxApi = new Api.BoxApi();
export const BoxCommand = () => {
  const { SetDataCurrentPage } = PageService();
  const AddBox = async (
    pageNo: number,
    addBoxParam: Api.AddBoxParam | null
  ) => {
    if (!addBoxParam) return;
    var res = await BoxApi.apiLegalxtractAddBoxPost(pageNo, addBoxParam);
    if (AppCache.CurrentPageNo === pageNo) {
      SetDataCurrentPage(res.data.result?.pageData! || null, pageNo || 0);
    }
    return res;
  };
  const DeleteBox = async (pageNo: number, boxId?: string | undefined) => {
    var res = await BoxApi.apiLegalxtractRemoveBoxPost(boxId, pageNo);
    var box = AppCache.DataCurrentPage?.boxObjs?.find((o) => o.id === boxId);
    if (AppCache.CurrentPageNo === pageNo) {
      SetDataCurrentPage(res.data.result?.pageData! || null, pageNo || 0);
    }
    return { box, pageNo: AppCache.CurrentPageNo };
  };
  const DoAdd = async (pageNo: number, addBoxParam: Api.AddBoxParam | null) => {
    const res = await AddBox(pageNo, addBoxParam);
    UndoRedoManagerObj.add({
      Redo: () => {
        RedoAdd(pageNo, addBoxParam);
      },
      Undo: () => {
        UndoAdd(pageNo, res?.data.result?.dataUndo?.id);
      },
    });
  };
  const DoDelete = async (pageNo: number, boxId?: string | undefined) => {
    const res = await DeleteBox(pageNo, boxId);
    UndoRedoManagerObj.add({
      Redo: () => {
        RedoDelete(pageNo, boxId);
      },
      Undo: () => {
        UndoDelete(res.pageNo, {
          height: res.box?.rect?.bottom,
          id: res.box?.id,
          left: res.box?.rect?.left,
          top: res.box?.rect?.top,
          width: res.box?.rect?.width,
        });
      },
    });
  };
  const RedoAdd = (pageNo: number, addBoxParam: Api.AddBoxParam | null) => {
    AddBox(pageNo, addBoxParam);
  };
  const RedoDelete = (pageNo: number, boxId?: string | undefined) => {
    DeleteBox(pageNo, boxId);
  };
  const UndoAdd = (pageNo: number, boxId?: string | undefined) => {
    DeleteBox(pageNo, boxId);
  };
  const UndoDelete = (pageNo: number, addBoxParam: Api.AddBoxParam | null) => {
    DoAdd(pageNo, addBoxParam);
  };
  return { DoAdd, DoDelete };
};

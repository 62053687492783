import UndoRedoManagerObj from "../UndoRedomanager";
import * as Api from "../../Api/src/index";
import { AppCache } from "../../Cache/AppCache";
import { PageService } from "../../Services/NotHook/PageService";
import { AppLocalStorage } from "../../Constant/AppContant";
import { UndoHightLight } from "./HightLightCommand";
import { LeftPanelService } from "../../Services/Hook/LeftPanel/LeftPanelService";
const HighlightApi = new Api.HighlightApi();
export const HightLightLineCommand = () => {
  const { SetDataCurrentPage } = PageService();
  const { LoadLeftPanelData } = LeftPanelService();
  const HightLight = async (
    pageNo: number,
    point: { x: number; y: number } | null,
    mode: Api.HighLightMode
  ) => {
    if (!point || !AppCache.WordSelected) return;
    localStorage.setItem(AppLocalStorage.DocumentHasChanged, "true");
    var res = await HighlightApi.apiLegalxtractHighLightLinePost({
      pageNo: pageNo,
      x: point.x,
      y: point.y,
      mode: mode,
    });
    if (AppCache.CurrentPageNo === pageNo) {
      SetDataCurrentPage(res.data.lxPageData!, AppCache.CurrentPageNo || 0);
    }
    LoadLeftPanelData(["pnFind", "pnWatchDog", "pnLog"]);
    return res;
  };
  const Do = async (
    pageNo: number,
    point: { x: number; y: number } | null,
    mode: Api.HighLightMode
  ) => {
    if (!point || !AppCache.WordSelected) return;
    const res = await HightLight(pageNo, point, mode);
    UndoRedoManagerObj.add({
      Redo: () => {
        Redo(pageNo, point, mode);
      },
      Undo: () => {
        Undo({
          preState: JSON.stringify(res?.data.dicPreState),
          viewerPageNo: pageNo,
        });
      },
    });
  };
  const Redo = (
    pageNo: number,
    point: { x: number; y: number } | null,
    mode: Api.HighLightMode
  ) => {
    HightLight(pageNo, point, mode);
  };
  const Undo = async (param: Api.UndoHightLightParam) => {
    await UndoHightLight(param);
  };
  return { Do };
};

import React from "react";
import { useLang } from "../../i18n/useLang";
import { Button, InputNumber, Space } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import "../../Assets/Styles/TopMenu/PageToolbar.scss";
import previous from "../../Assets/IconApp/previous.svg";
import left from "../../Assets/IconApp/left.svg";
import right from "../../Assets/IconApp/right.svg";
import next from "../../Assets/IconApp/next.svg";
import zoom_in from "../../Assets/IconApp/zoom_in.svg";
import zoom_out from "../../Assets/IconApp/zoom_out.svg";
import rotate_counterclockwise from "../../Assets/IconApp/rotate_counterclockwise.svg";
import rotate_clockwise from "../../Assets/IconApp/rotate_clockwise.svg";
import fit_width from "../../Assets/IconApp/fit_width.svg";
import page_fit from "../../Assets/IconApp/page_fit.svg";
import usePageStore from "../../ZustandStore/PageStore";
import { AppCache } from "../../Cache/AppCache";
import { PdfTronService } from "../../Services/NotHook/PdfTronService";
import { PageToolbarService } from "../../Services/Hook/TopMenu/PageToolbarService";
const PageToolbar = () => {
  const { tr } = useLang();
  const {
    FitPage,
    FitWidth,
    PageState,
    SetPageNo,
    ZoomIn,
    ZoomOut,
    RotateLeft,
    RotateRight,
  } = PageToolbarService();
  return (
    <div className="pagetoolbar">
      <Space>
        <label>{tr("page")}</label>
        <Button
          disabled={(PageState.CurrentPageNo || 0) <= 1}
          icon={<img width={12} src={previous} />}
          onClick={() => {
            SetPageNo(1);
          }}
        />
        <Button
          disabled={(PageState.CurrentPageNo || 0) <= 1}
          onClick={() => {
            SetPageNo((PageState.CurrentPageNo || 0) - 1);
          }}
          icon={<img width={12} src={left} />}
        />
        <InputNumber
          value={PageState.CurrentPageNo}
          style={{ width: 60 }}
          onChange={(value) => {
            if (value) SetPageNo(value as number);
          }}
        ></InputNumber>
        <Button
          disabled={
            (PageState.CurrentPageNo || 0) >= (PageState.TotalPages || 0)
          }
          onClick={() => {
            SetPageNo((PageState.CurrentPageNo || 0) + 1);
          }}
          icon={<img width={12} src={right} />}
        />
        <Button
          disabled={
            (PageState.CurrentPageNo || 0) >= (PageState.TotalPages || 0)
          }
          onClick={() => {
            SetPageNo(PageState.TotalPages || 0);
          }}
          icon={<img width={12} src={next} />}
        />
        <label>{tr("of")}</label>
        <label>{PageState.TotalPages}</label>
        <Button
          title={tr("zoomIn")}
          onClick={ZoomIn}
          icon={<img width={12} src={zoom_in} />}
        />
        <Button
          title={tr("zoomOut")}
          onClick={ZoomOut}
          icon={<img width={12} src={zoom_out} />}
        />
        <Button
          onClick={() => {
            RotateLeft();
          }}
          icon={<img width={12} src={rotate_counterclockwise} />}
        />
        <Button
          onClick={() => {
            RotateRight();
          }}
          icon={<img width={12} src={rotate_clockwise} />}
        />
        <Button
          onClick={() => {
            FitWidth();
          }}
          title={tr("fitWidth")}
          icon={<img width={12} src={fit_width} />}
        />
        <Button
          title={tr("fitPage")}
          onClick={() => {
            FitPage();
          }}
          icon={<img width={12} src={page_fit} />}
        />
      </Space>
    </div>
  );
};
export default PageToolbar;

import { Checkbox, Space, TableProps } from "antd";
import * as Api from "../../../Api/src";
import { useLang } from "../../../i18n/useLang";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import useNoteStore from "../../../ZustandStore/NoteStore";
import UseModalStore, { ModaType } from "../../../ZustandStore/ModalStore";
import UseConfirmDialogStore from "../../../ZustandStore/ConfirmDialogStore";
import { AppCache } from "../../../Cache/AppCache";
import { NoteService } from "../../NotHook/NoteService";
import { useState } from "react";
import { LeftPanelService } from "./LeftPanelService";
const NoteApi = new Api.NoteApi();
export const NotePanelService = () => {
  const { tr } = useLang();
  const { SetModalState } = UseModalStore();
  const { NoteState, SetNoteStore, GetNoteState } = useNoteStore();
  const { SetConfirmDialogState } = UseConfirmDialogStore();
  const { DeleteNote, UpdateVisibleOnExtract } = NoteService();
  const { LoadNoteData } = LeftPanelService();
  const [ShowAddNote, SetShowAddNote] = useState<{
    open: boolean;
    editItem: Api.NoteTextObj | null;
  }>({ open: false, editItem: null });
  const columns: TableProps<Api.NoteTextObj>["columns"] = [
    {
      title: tr("note"),
      dataIndex: "noteText",
      key: "noteText",
      render: (text, record) => text,
    },
    {
      width: 60,
      title: tr("visibility"),
      key: "fontColor",
      dataIndex: "fontColor",
      render: (_, record) => (
        <Checkbox
          checked={record.visibleOnExtract}
          onChange={async () => {
            await UpdateVisibleOnExtract(record);
            LoadNoteData();
          }}
        ></Checkbox>
      ),
    },
    {
      width: 50,
      key: "action",
      render: (_, record, i) => {
        return (
          <Space>
            <a
              onClick={() => {
                EditNoteClick(record);
              }}
            >
              <EditOutlined />
            </a>
            <a
              onClick={() => {
                DeleteNoteClick(record.id || "");
              }}
            >
              <DeleteOutlined />
            </a>
          </Space>
        );
      },
    },
  ];
  const EditNoteClick = (item: Api.NoteTextObj) => {
    SetShowAddNote({
      editItem: item,
      open: true,
    });
  };

  const DeleteNoteClick = (id: string) => {
    const Delete = async () => {
      await DeleteNote(id);
      LoadNoteData();
    };
    SetConfirmDialogState({
      Show: true,
      Type: "Confirm",
      Icon: "Question",
      CancelText: tr("no"),
      YesText: tr("yes"),
      Msg: tr("doYouWantToDelete"),
      OnYes: () => {
        Delete();
      },
    });
  };
  return {
    NoteState,
    columns,
    ShowAddNote,
    DeleteNoteClick,
    SetShowAddNote,
  };
};

import { saveAs } from "file-saver";
// import printJS from "print-js";
import * as Api from "../../Api/src";
import { fileSave } from "browser-fs-access";
import { AppCache } from "../../Cache/AppCache";
import { AppLocalStorage } from "../../Constant/AppContant";
const SavingApi = new Api.SaveApi();
export const SaveService = {
  SaveXtract: async (fileName: string) => {
    if (AppCache.DocumentInfo?.tabDetails?.length === 0) return;
    AppLocalStorage.RemoveItem(AppLocalStorage.DocumentHasChanged);
    const response = (
      await SavingApi.apiLegalxtractSaveXtractFileGet(fileName, {
        responseType: "blob",
      })
    ).data;
    if (response) {
      saveAs(response, `${fileName}.xtract`);
    }
  },
};

import { Core } from "@pdftron/webviewer";
import { AppCache } from "../../Cache/AppCache";
import { isPointInRectangle } from "../../Common/CommonFunction";
import html2canvas from "html2canvas";
import { match } from "assert";

export const PdfTronService = () => {
  const DeletePages = async (pages: number[]) => {
    if (!AppCache.WebViewerInstance?.Core) return;
    const { documentViewer } = AppCache.WebViewerInstance?.Core;
    const doc = documentViewer.getDocument();
    await doc.removePages(pages);
  };
  const RectConvertToViewerCoordinates = (
    rect: { left: number; top: number; right: number; bottom: number },
    pageNo: number
  ) => {
    if (!AppCache.WebViewerInstance?.Core) return;
    const { documentViewer } = AppCache.WebViewerInstance?.Core;
    const doc = documentViewer.getDocument();
    const leftTop = doc.getViewerCoordinates(
      pageNo,
      rect.left || 0,
      rect.top || 0
    );
    const rightBottom = doc.getViewerCoordinates(
      pageNo,
      rect.right || 0,
      rect.bottom || 0
    );
    return {
      left: leftTop.x,
      top: rightBottom.y,
      right: rightBottom.x,
      bottom: leftTop.y,
      width: rightBottom.x - leftTop.x,
      height: leftTop.y - rightBottom.y,
    };
  };
  const RectConvertToPDFCoordinates = (
    rect: { left: number; top: number; right: number; bottom: number },
    pageNo: number
  ) => {
    if (!AppCache.WebViewerInstance?.Core) return;
    const { documentViewer } = AppCache.WebViewerInstance?.Core;
    const doc = documentViewer.getDocument();
    const leftTop = doc.getPDFCoordinates(
      pageNo,
      rect.left || 0,
      rect.top || 0
    );
    const rightBottom = doc.getPDFCoordinates(
      pageNo,
      rect.right || 0,
      rect.bottom || 0
    );
    // trục toạ độ của pdf là từ dưới lên trên
    return {
      left: leftTop.x,
      top: rightBottom.y,
      right: rightBottom.x,
      bottom: leftTop.y,
      width: rightBottom.x - leftTop.x,
      height: leftTop.y - rightBottom.y,
    };
  };
  const SetCurrentPageNo = (pageNo: number) => {
    if (!AppCache.WebViewerInstance?.Core) return;
    const { documentViewer } = AppCache.WebViewerInstance?.Core;
    documentViewer.setCurrentPage(pageNo, true);
  };
  const RotatePages = (pageNos: number[], pageRotation: number) => {
    if (!AppCache.WebViewerInstance?.Core) return;
    const { documentViewer, PageRotation } = AppCache.WebViewerInstance?.Core;
    const doc = documentViewer.getDocument();
    if (pageRotation === 0) doc.rotatePages(pageNos, PageRotation.E_0);
    else if (pageRotation === 90) doc.rotatePages(pageNos, PageRotation.E_90);
    else if (pageRotation === 180) doc.rotatePages(pageNos, PageRotation.E_180);
    else if (pageRotation === 270) doc.rotatePages(pageNos, PageRotation.E_270);
    else doc.rotatePages(pageNos, PageRotation.E_0);
  };
  const GetAnnotationByMousePosition = (
    pageNo: number,
    x: number,
    y: number
  ) => {
    if (!AppCache.WebViewerInstance?.Core) return;
    const { annotationManager } = AppCache.WebViewerInstance?.Core;
    const annos = annotationManager.getAnnotationsList();
    const anno = annos.find((anno) => {
      const rect = anno.getRect();
      return (
        isPointInRectangle(
          x,
          y,
          rect.getLeft(),
          rect.getTop(),
          rect.getWidth(),
          rect.getHeight()
        ) && anno.PageNumber === pageNo
      );
    });
    return anno;
  };
  const GetScreenshotOfPage = (
    posX?: number,
    posY?: number,
    width?: number,
    height?: number,
    callback?: (data: string) => void
  ) => {
    if (!AppCache.WebViewerInstance?.Core) return;
    const { documentViewer } = AppCache.WebViewerInstance?.Core;
    var zoomLevel = documentViewer.getPageZoom(AppCache.CurrentPageNo!);
    const webviewer = document.getElementById("webviewer-1") as any;
    const documentWebView = webviewer?.contentDocument;
    const canvaspage = documentWebView?.getElementsByClassName(
      "canvas" + AppCache.CurrentPageNo
    )[0];
    const DocumentContainer =
      documentWebView?.getElementsByClassName("DocumentContainer")[0];

    if (!canvaspage) return;
    const page = documentWebView.getElementById(
      "pageContainer" + AppCache.CurrentPageNo
    );

    if (!page) {
      return;
    }
    const containerScrollTop = DocumentContainer.scrollTop;
    const containerHeight = DocumentContainer.clientHeight;
    const pageOffsetTop = page.offsetTop;
    const pageHeight = page.clientHeight;
    // Tính chiều cao bị ẩn của phần tử
    const hiddenHeight = Math.max(
      0,
      Math.abs(pageOffsetTop - containerScrollTop)
    );
    html2canvas(canvaspage, {
      width: width! * zoomLevel!,
      height: height! * zoomLevel!,
      x: posX! * zoomLevel! - hiddenHeight,
      y: posY! * zoomLevel! - hiddenHeight,
      useCORS: true,
      allowTaint: false,
    }).then((canvas) => {
      callback!(canvas.toDataURL().replace("data:image/png;base64,", ""));
    });
  };

  return {
    DeletePages,
    RectConvertToViewerCoordinates,
    SetCurrentPageNo,
    RectConvertToPDFCoordinates,
    GetAnnotationByMousePosition,
    RotatePages,
    GetScreenshotOfPage,
  };
};

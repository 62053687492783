import React, { useState } from "react";
import { Alert, Button, Col, Input, Modal, Row } from "antd";
import { useLang } from "../i18n/useLang";
import {
  MDSplitDocumentProps,
  MDSplitDocumentService,
} from "../Services/Hook/Modal/MDSplitDocumentService";
import { MDAddBlankPageService, MDAddBlankPageProps } from "../Services/Hook/Modal/MDAddBlankPageService";

const MDSplitDocument = ({
  onClose,
  open,
  selectedTabIds,
  name,
}: MDAddBlankPageProps) => {
  const { tr } = useLang();
  const { formik, showErrors } = MDAddBlankPageService({
    onClose: onClose,
    selectedTabIds,
    name,
  });

  return (
    <>
      <Modal
        title={tr("enterANewFileName")}
        footer={
          <>
            <Button
              style={{ minWidth: 100 }}
              onClick={() => {
                onClose && onClose();
              }}
              type="default"
            >
              {tr("cancel")}
            </Button>
            <Button
              style={{ minWidth: 100 }}
              onClick={() => {
                formik.submitForm();
              }}
              type="primary"
            >
              {tr("ok")}
            </Button>
          </>
        }
        centered
        open={open}
        width={550}
        onCancel={() => {
          onClose && onClose();
        }}
        maskClosable={false}
      >
        <Row gutter={[8, 8]}>
          <Col span={24}>
            {!formik.isValid && (
              <Alert
                description={showErrors(formik.errors)}
                type="warning"
                showIcon
              />
            )}
          </Col>
          <Col span={24}>
            <label>{tr("name")}</label>
            <Input
              style={{ width: "100%" }}
              name="name"
              value={formik.values.name || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleChange}
            ></Input>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default MDSplitDocument;

import React from "react";
import { DownOutlined } from "@ant-design/icons";
import { Col, Row, Tree } from "antd";
import type { TreeDataNode, TreeProps } from "antd";
import { useLang } from "../../../i18n/useLang";

const treeData: TreeDataNode[] = [
  {
    title: "parent 1",
    key: "0-0",
    children: [
      {
        title: "parent 1-0",
        key: "0-0-0",
        children: [
          {
            title: "leaf",
            key: "0-0-0-0",
          },
          {
            title: "leaf",
            key: "0-0-0-1",
          },
          {
            title: "leaf",
            key: "0-0-0-2",
          },
        ],
      },
      {
        title: "parent 1-1",
        key: "0-0-1",
        children: [
          {
            title: "leaf",
            key: "0-0-1-0",
          },
        ],
      },
      {
        title: "parent 1-2",
        key: "0-0-2",
        children: [
          {
            title: "leaf",
            key: "0-0-2-0",
          },
          {
            title: "leaf",
            key: "0-0-2-1",
          },
        ],
      },
    ],
  },
];
import pnBookmark from "../../../Assets/IconApp/npBookmark.svg";
import {
  BookmarkPanelService,
  MenuID,
} from "../../../Services/Hook/LeftPanel/BookmarkPanelService";
import { Item, Menu } from "react-contexify";
import { MDAddBookmark } from "../../../Modal/MDAddBookMark";

const BookmarkPanel = () => {
  const {
    BookmarkState,
    LoadBookmark,
    onRowClick,
    RightClick,
    DeleteBookMark,
    ShowAddBookMark,
    EditBookMark,
    CloseMDAddBookMark,
  } = BookmarkPanelService();
  const { tr } = useLang();
  return (
    <div className="left-panel-container">
      <Row style={{ height: "100%", alignContent: "baseline" }} gutter={[8, 8]}>
        <Col span={24} className="header-panel">
          {tr("Bookmark")}
        </Col>
        <Col span={24} style={{ alignContent: "baseline" }}>
          <Tree
            showLine
            switcherIcon={<DownOutlined />}
            defaultExpandAll
            selectedKeys={BookmarkState.BookmarkSelectedKeys}
            onClick={(e, node) => {
              onRowClick(e, node);
            }}
            onRightClick={({ event, node }) => {
              RightClick(event, node);
            }}
            treeData={BookmarkState.BookmarkTree}
            titleRender={(node) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <img width={17} src={pnBookmark}></img>{" "}
                  {node.title?.toString()}
                </div>
              );
            }}
          />
          <Menu id={MenuID}>
            <Item
              onClick={() => {
                EditBookMark();
              }}
            >
              {tr("edit")}
            </Item>
            <Item
              onClick={() => {
                DeleteBookMark();
              }}
            >
              {tr("delete")}
            </Item>
          </Menu>
          {ShowAddBookMark.open && (
            <MDAddBookmark
              open={ShowAddBookMark.open}
              onClose={CloseMDAddBookMark}
              EditItem={ShowAddBookMark.editItem}
            ></MDAddBookmark>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default BookmarkPanel;

import { useState } from "react";
import * as Api from "../../../Api/src/index";
import { v4 } from "uuid";
import useTagStore from "../../../ZustandStore/TagStore";
import { FormikErrors, useFormik } from "formik";
import * as yup from "yup";
import { useLang } from "../../../i18n/useLang";
import UseConfirmDialogStore from "../../../ZustandStore/ConfirmDialogStore";
import { AddTagNameParam } from "../../../Api/src/index";
import { AppCache } from "../../../Cache/AppCache";
import useTabFileInfoStore from "../../../ZustandStore/TabFileInfoStore";
const SplitDocumentApi = new Api.SplitDocumentApi();
export interface MDSplitDocumentProps {
  pageNo: number;
  name: string;
  open?: boolean;
  onClose?: () => void;
}
export const MDSplitDocumentService = ({
  onClose,
  pageNo,
  name,
}: MDSplitDocumentProps) => {
  const { SetTabFileInfoState } = useTabFileInfoStore();
  const { tr } = useLang();
  const validationTagNameSchema = yup.object({
    name: yup.string().required(tr("pleaseSetName")),
  });
  const showErrors = (error: FormikErrors<{ name: string }>) => {
    if (error.name) {
      return <p>{error.name}</p>;
    }
  };
  const formik = useFormik({
    initialValues: {
      name: name,
    },
    validationSchema: validationTagNameSchema,
    onSubmit: async (values) => {
      SplitDocumentApi.apiLegalxtractSplitDocumentGet(pageNo, values.name).then(
        (res) => {
          if (res && res.data.isSuccess && res.data.result) {
            AppCache.DocumentInfo = res.data.result;
            SetTabFileInfoState({
              TabDetails: res.data.result?.tabDetails || [],
              CurrentTab: res.data.result.tabDetails?.find((o) =>
                o.pages?.some((o) => (o.viewerPageNo === pageNo))
              ),
            });
          }
          onClose && onClose();
        }
      );
    },
  });
  return { formik, showErrors };
};

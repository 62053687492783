import * as Api from "../../../../Api/src/index";
import { FormikErrors, useFormik } from "formik";
import * as yup from "yup";
import { useLang } from "../../../../i18n/useLang";
import { useEffect, useRef, useState } from "react";
import { resizeImageToBase64 } from "../../../../Common/CommonFunction";
import { AppLocalStorage } from "../../../../Constant/AppContant";
import { AppCache } from "../../../../Cache/AppCache";
import { PageService } from "../../../NotHook/PageService";
import useMenuStripStore from "../../../../ZustandStore/MenuStripStore";
import { App } from "antd";
const StampApi = new Api.StampApi();
const FontApi = new Api.FontApi();
const SettingApi = new Api.SettingApi();
export interface MDStampSettingsProps {
  open?: boolean;
  onClose?: () => void;
  editItem: StampSettingsParam | null;
}
export interface StampSettingsParam extends Api.TabStamp {
  automaticCount?: boolean | undefined;
  showFromToc?: boolean | undefined;
  actionType: "ApplyStampAll" | "ApplyStampCurrent" | "";
}
export const defaultStampData = {
  title: "",
  autoScale: false,
  automaticCount: true,
  visibility: 50,
  fontName: "Microsoft Sans Serif",
  fontSize: 25,
  index: "",
  showFromTOC: false,
  showStamp: true,
  logoBase64: null,
  fontColor: "#333",
  addFrame: true,
  location: {
    width: 2,
    height: 2,
    marginRight: 0.1,
    marginTop: 0.1,
    heightStamp: 0,
    widthStamp: 0,
  },
  showFromToc: false,
  transparent: 0,
  actionType: "",
} as StampSettingsParam;
export const MDStampSettingsService = ({
  onClose,
  editItem,
}: MDStampSettingsProps) => {
  const { tr } = useLang();
  const [fontList, setFontList] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);
  const validationStampSettingsSchema = yup.object({
    title: yup.string().required(tr("pleaseSetName")),
  });
  var LxSetting = AppLocalStorage.GetItem(
    AppLocalStorage.LxSetting
  ) as Api.LxSetting;
  const { SetDataCurrentPage } = PageService();
  const { SetMenuStripStore } = useMenuStripStore();
  const [StampSettingsParam, SetStampSettingsParam] =
    useState<StampSettingsParam>(defaultStampData);
  const fileInputRef = useRef<any>(null);
  const InitCheck = useRef<boolean>(false);
  useEffect(() => {
    if (InitCheck.current) return;
    GetFontList();
    SetInitDataForm();
    InitCheck.current = true;
  }, []);
  const SetInitDataForm = (): StampSettingsParam => {
    if (editItem) return editItem;
    var data = AppLocalStorage.GetItem(
      AppLocalStorage.StampInfo
    ) as StampSettingsParam;
    if (data) {
      return data;
    } else {
      return defaultStampData;
    }
  };
  const showErrors = (error: FormikErrors<StampSettingsParam>) => {
    if (error.title) {
      return <p>{error.title}</p>;
    }
  };
  const formik = useFormik({
    initialValues: SetInitDataForm(),
    onSubmit: async (values) => {
      if (values.automaticCount) {
        values.index = values.index || "1";
      }
      values.logoBase64 =
        values.logoBase64?.replace("data:image/jpeg;base64,", "") || null;
      if (values.actionType === "ApplyStampCurrent") {
        StampApi.apiLegalxtractApplyOneStampPost(AppCache.CurrentTabId || "", {
          addFrame: values.addFrame,
          autoScale: values.autoScale,
          fontColor: values.fontColor,
          fontName: values.fontName,
          fontSize: values.fontSize,
          index: values.index,
          location: values.location,
          logoBase64: values.logoBase64,
          title: values.title,
          transparent: values.transparent,
        }).then((res) => {
          if (res && res.data.isSuccess) {
            AppCache.DocumentInfo = res.data.result?.data || null;
            SetDataCurrentPage(
              res.data.result?.pageData ! || null,
              AppCache.CurrentPageNo || 0
            );
            SetMenuStripStore({
              ShowStamp: AppCache.DocumentInfo?.showStamp || false,
            });
            onClose && onClose();
          }
        });
      } else if (values.actionType === "ApplyStampAll") {
        if (
          values.automaticCount &&
          LxSetting.tocIndexPattern != values.index
        ) {
          LxSetting.tocIndexPattern = values.index;
          await SettingApi.apiLegalxtractUploadSettingPost(false, LxSetting);
          AppLocalStorage.SetItem(AppLocalStorage.LxSetting, LxSetting);
        }
        AppLocalStorage.SetItem(AppLocalStorage.StampInfo, values);
        StampApi.apiLegalxtractApplyStampAllPagePost(
          values.showFromToc,
          values.automaticCount,
          values.index || "",
          values
        ).then((res) => {
          if (res && res.data.isSuccess) {
            AppCache.DocumentInfo = res.data.result?.data || null;
            SetMenuStripStore({
              ShowStamp: AppCache.DocumentInfo?.showStamp || false,
            });
            SetDataCurrentPage(
              res.data.result?.pageData ! || null,
              AppCache.CurrentPageNo || 0
            );
            onClose && onClose();
          }
        });
      }
    },
  });
  const GetFontList = () => {
    FontApi.apiLegalxtractGetListFontGet().then((res) => {
      if (res && res.data) {
        setFontList(res.data.map((item) => ({ value: item, label: item })));
      }
    });
  };
  const onSelectedFile = async (imageFile: any) => {
    formik.setFieldValue(
      "logoBase64",
      await resizeImageToBase64(imageFile[0], 300, 300)
    );
  };
  const ChooseFile = () => {
    if (!fileInputRef.current) return;
    fileInputRef?.current.click();
  };
  const DeleteFile = () => {
    formik.setFieldValue("logoBase64", null);
  };
  const ResetForm = () => {
    formik.setValues(defaultStampData);
  };
  return {
    formik,
    showErrors,
    fontList,
    onSelectedFile,
    fileInputRef,
    ChooseFile,
    DeleteFile,
    ResetForm,
  };
};

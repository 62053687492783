import WebViewer, { Core } from "@pdftron/webviewer";
import { useEffect, useRef, useState } from "react";
import { AppCache } from "../../../Cache/AppCache";
import { MainViewResgisterEvent } from "./MainViewRegisterEvent";
import * as Api from "../../../Api/src/index";
import { ToolService } from "../../NotHook/ToolService";
import useLoaddingAppStore from "../../../ZustandStore/LoaddingAppStore";
const DocumentApi = new Api.DocumentApi();
const UseMainViewService = () => {
  const viewer = useRef<HTMLDivElement>(null);
  let initCheck = useRef(false);
  const [menuContextState, SetMenuContextState] = useState<{
    show: boolean;
  }>();
  const { SelectTool } = ToolService();
  const { InitEvent, page } = MainViewResgisterEvent();
  const { SetLoaddingAppStore } = useLoaddingAppStore();
  const MenuContextVisibilityChange = (show: boolean) => {
    SetMenuContextState({ ...menuContextState, show: show });
  };
  useEffect(() => {
    if (!initCheck.current) {
      SetLoaddingAppStore({ Show: true });
      initCheck.current = true;
      if (viewer.current != null) {
        WebViewer(
          {
            path: "/lib/pdftron",
            licenseKey:
              "demo:1712804211613:7f1522ce03000000003c9d6c10d25ea63f46b884bdfc5877de41976cd5",
            css: "/viewer.css",
            fullAPI: true,
            disabledElements: [
              "contextMenuPopup",
              "viewControlsButton",
              "viewControlsOverlay",
              "header",
              "toolsHeader",
              "pageNavOverlay",
              "textPopup",
            ],
          },
          viewer?.current
        ).then(async (instance) => {
          // PDFNet is only available with full API enabled
          const { PDFNet } = instance.Core;
          // Start the full workers
          await PDFNet.initialize(
            "demo:1712804211613:7f1522ce03000000003c9d6c10d25ea63f46b884bdfc5877de41976cd5"
          );
          AppCache.WebViewerInstance = instance;
          instance.UI.setFitMode(instance.UI.FitMode.FitWidth);
          SelectTool();
          InitEvent();
          setTimeout(() => {
            GetDocument();
          }, 500);
        });
      }
    }
  }, []);
  const GetDocument = () => {
    SetLoaddingAppStore({ Show: true });
    DocumentApi.apiLegalxtractGetDocumentFromCacheGet({
      responseType: "blob",
    }).then((res) => {
      if (res.status === 200 && res.data.size > 0) {
        AppCache.WebViewerInstance?.Core.documentViewer.loadDocument(res.data);
      } else {
        SetLoaddingAppStore({ Show: false });
      }
    });
  };
  return { viewer, menuContextState, MenuContextVisibilityChange, page };
};
export default UseMainViewService;

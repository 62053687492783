import { Core } from "@pdftron/webviewer";
import App from "../../App";
import { AppCache } from "../../Cache/AppCache";
import { isPointInRectangle } from "../../Common/CommonFunction";
import * as Api from "../../Api/src/index";
import { AnnoSubject } from "../../Constant/AppContant";
import { ColorHelper } from "../../Common/AppHelpers";
const PageApi = new Api.PageApi();
const ViewApi = new Api.ViewApi();
export const AnnotationManagerService = () => {
  const GetAnnotationsList = (pageNo: number) => {
    if (!AppCache.WebViewerInstance?.Core) return [];
    const { annotationManager } =
      AppCache.WebViewerInstance?.Core;
    const annos = annotationManager.getAnnotationsList();
    const annoPage = annos.filter(
      (o) => o.PageNumber == pageNo);
      return annoPage;
  };
  return {
    GetAnnotationsList
  };
};

import { useEffect, useRef, useState } from "react";
import * as Api from "../../../Api/src/index";
import { SearchService } from "../../NotHook/SearchService";
import { AppCache } from "../../../Cache/AppCache";
import { HightLightSearchResultCommand } from "../../../UndoRedo/Commands/HightLightSearchResultCommand";
import { PageService } from "../../NotHook/PageService";
import UseModalStore, { ModaType } from "../../../ZustandStore/ModalStore";
import useReplaceStore from "../../../ZustandStore/ReplaceStore";
import useSearchTabStore from "../../../ZustandStore/SearchTabStore";
const SearchApi = new Api.SearchApi();
export interface SearchResultProps {
  SearchClick?: () => void;
  SelectAllClick: () => void;
  DeselectAllClick: () => void;
  ReplaceSelectedClick: () => void;
  ReplaceAllClick: () => void;
}

export const SearchResultService = () => {
  const hightLightSearchResultCommand = HightLightSearchResultCommand();
  const { DrawFocusWordSearchResults } = PageService();
  const { SetModalState } = UseModalStore();
  const { SearchTabState, SetSearchTabStore } = useSearchTabStore();
  const [SearchResultData, SetSearchResultData] = useState<
    Api.SearchResultData & {
      hasMore?: boolean;
      loadding?: boolean;
    }
  >({
    searchData: [],
    totalPage: 0,
    totalSearchResult: 0,
    hasMore: false,
  });
  const { GetReplaceState, SetReplaceStore } = useReplaceStore();
  const { GetSearchTemplateParam } = SearchService();
  const lastScrollTop = useRef(0);
  const initCheck = useRef<boolean>(false);
  useEffect(() => {
    if (!initCheck.current) {
      initCheck.current = true;
      SetSearchResultData({
        searchData: [],
        totalPage: 0,
        totalSearchResult: 0,
        hasMore: false,
      });
    }
  }, []);
  useEffect(() => {
    if (SearchTabState.runSearch) {
      SearchClick();
    }
  }, [SearchTabState.runSearch]);
  useEffect(() => {
    if (SearchTabState.clearSearch) {
      SetSearchResultData({
        searchData: [],
        totalPage: 0,
        totalSearchResult: 0,
        hasMore: false,
      });
      SetSearchTabStore({
        clearSearch: false,
      });
    }
  }, [SearchTabState.clearSearch]);
  const SearchClick = async () => {
    var SearchTabVm = SearchTabState.SearchTabVm;
    let searchResult: Api.SearchResultData = {
      searchData: [],
      totalPage: 0,
      totalSearchResult: 0,
    };
    lastScrollTop.current = 0;
    SetSearchResultData({
      ...SearchResultData,
      loadding: true,
    });
    if (!SearchTabVm) return;
    let pageNo = AppCache.CurrentPageNo;
    if (SearchTabVm.allPages) {
      pageNo = 0;
    }
    switch (SearchTabVm?.searchTabType) {
      case Api.SearchTabType.Keyword: {
        if (!SearchTabVm.searchKeywordParam?.keyword) break;
        const res = await SearchApi.apiLegalxtractSearchKeywordPost({
          searchTabVm: SearchTabVm,
          currentPageNo: pageNo,
        });
        searchResult = res.data;
        break;
      }
      case Api.SearchTabType.Template: {
        var templateSearchParam = GetSearchTemplateParam();
        const customSearchSelected =
          templateSearchParam?.customTemplateItems?.filter((item) => {
            return item.selected == true;
          });
        SearchTabVm.searchTemplateParam = {
          templateItems: Object.values(templateSearchParam.templateItem || {}),
          customTemplateItems: customSearchSelected as Api.CustomTemplateItem[],
        };
        const res = await SearchApi.apiLegalxtractSearchTemplatePost({
          searchTabVm: SearchTabVm,
          currentPageNo: pageNo,
        });
        searchResult = res.data;
        break;
      }
      case Api.SearchTabType.Tag: {
        if (!SearchTabVm.searchTagParam) return;
        SearchTabVm.searchTagParam.pageNo = pageNo;
        const res = await SearchApi.apiLegalxtractSearchTagPost(SearchTabVm);
        searchResult = res.data;
        break;
      }
    }
    if (SearchTabState.runSelect != "none") {
      setTimeout(() => {
        if (SearchTabState.runSelect == "selectAll") {
          SelectAllClick();
        } else if (SearchTabState.runSelect == "deSelectAll") {
          DeselectAllClick();
        }
      }, 300);
    } else {
      SetSearchResultData({
        ...searchResult,
        hasMore:
          (searchResult?.totalSearchResult || 0) >
          (searchResult?.searchData?.length || 0),
        loadding: false,
      });
    }
    SetSearchTabStore({
      runSearch: false,
      runSelect: "none",
    });
  };
  const SelectAllClick = async () => {
    var res = await hightLightSearchResultCommand.Do({
      isHighlight: true,
      pageNo: AppCache.CurrentPageNo,
      isSelectAll: true,
      sortKeys: [],
    });
    lastScrollTop.current = 0;
    SetSearchResultData({
      ...(res?.data?.searchResultData || {
        searchData: [],
        totalPage: 0,
        totalSearchResult: 0,
      }),
      hasMore:
        (res?.data?.searchResultData?.totalSearchResult || 0) >
        (res?.data?.searchResultData?.searchData?.length || 0),
      loadding: false,
    });
  };
  const DeselectAllClick = async () => {
    var res = await hightLightSearchResultCommand.Do({
      isHighlight: false,
      pageNo: AppCache.CurrentPageNo,
      isSelectAll: true,
      sortKeys: [],
    });
    lastScrollTop.current = 0;
    SetSearchResultData({
      ...(res?.data?.searchResultData || {
        searchData: [],
        totalPage: 0,
        totalSearchResult: 0,
      }),
      hasMore:
        (res?.data?.searchResultData?.totalSearchResult || 0) >
        (res?.data?.searchResultData?.searchData?.length || 0),
      loadding: false,
    });
  };
  const CheckBoxItemClick = async (
    searchResult: Api.SearchResult,
    isHighlight: boolean
  ) => {
    var res = await hightLightSearchResultCommand.Do({
      isHighlight: isHighlight,
      pageNo: AppCache.CurrentPageNo,
      isSelectAll: false,
      sortKeys: [searchResult.sortKey || -1],
    });
    SetSearchResultData({
      ...(res?.data?.searchResultData || {
        searchData: [],
        totalPage: 0,
        totalSearchResult: 0,
      }),
      hasMore:
        (res?.data?.searchResultData?.totalSearchResult || 0) >
        (res?.data?.searchResultData?.searchData?.length || 0),
    });
    DrawFocusWordSearchResults(searchResult);
  };
  const ReplaceSelectedClick = () => {
    SetReplaceStore({
      ParamReplaceWord: {
        ...GetReplaceState().ParamReplaceWord,
        ReplaceType: "ReplaceAllItemSelected",
        viewerPageNo: AppCache.CurrentPageNo,
        disableReplaceOne: true,
      },
    });
    SetModalState({
      Show: true,
      Status: "Add",
      Type: ModaType.MDReplaceWord,
    });
  };
  const ReplaceAllClick = () => {
    SetReplaceStore({
      ParamReplaceWord: {
        ...GetReplaceState().ParamReplaceWord,
        ReplaceType: "ReplaceAllSearch",
        viewerPageNo: AppCache.CurrentPageNo,
        disableReplaceOne: true,
      },
    });
    SetModalState({
      Show: true,
      Status: "Add",
      Type: ModaType.MDReplaceWord,
    });
  };
  const FetchMoreSearchData = () => {
    SetSearchResultData({
      ...SearchResultData,
      loadding: true,
    });
    setTimeout(() => {
      const page = Math.round(
        (SearchResultData?.searchData?.length || 0) / 500
      );
      SearchApi.apiLegalxtractGetDataSearchResultPagingGet(page + 1, 500).then(
        (res) => {
          const data = res.data;
          if (data) {
            const oldData = SearchResultData?.searchData || [];
            const newData = [...oldData, ...(data.searchData || [])];
            SetSearchResultData({
              searchData: newData,
              totalPage: data.totalPage || 0,
              totalSearchResult: data.totalSearchResult || 0,
              hasMore: (data?.totalSearchResult || 0) > (newData?.length || 0),
              loadding: false,
            });
          }
        }
      );
    }, 200);
  };
  const CancelSearch = () => {
    SetSearchResultData({
      ...SearchResultData,
      loadding: false,
    });
    SearchApi.apiLegalxtractCancelSearchGet();
  };
  return {
    SearchClick,
    SelectAllClick,
    DeselectAllClick,
    ReplaceSelectedClick,
    ReplaceAllClick,
    CheckBoxItemClick,
    DrawFocusWordSearchResults,
    SearchTabState,
    SearchResultData,
    SetSearchTabStore,
    FetchMoreSearchData,
    CancelSearch,
    lastScrollTop,

  };
};

import { useEffect, useRef, useState } from "react";
import * as Api from "../../../Api/src/index";
import { v4 } from "uuid";
import { FormikErrors, useFormik } from "formik";
import * as yup from "yup";
import { useLang } from "../../../i18n/useLang";
import UseConfirmDialogStore from "../../../ZustandStore/ConfirmDialogStore";
import { AddTagNameParam } from "../../../Api/src/index";
import { PageNumberingCommand } from "../../../UndoRedo/Commands/PageNumberingCommand";
import { AppLocalStorage } from "../../../Constant/AppContant";
import { AppCache } from "../../../Cache/AppCache";
const FontApi = new Api.FontApi();
export interface MDPageNumberingProps {
  open?: boolean;
  onClose?: () => void;
}
export const MDPageNumberingService = ({ onClose }: MDPageNumberingProps) => {
  const pageNumberingCommand = PageNumberingCommand();
  const { tr } = useLang();
  const [fontList, setFontList] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);
  const InitCheck = useRef<boolean>(false);
  useEffect(() => {
    if (InitCheck.current) return;
    GetFontList();
    InitCheck.current = true;
  }, []);
  const GetInitDataForm = (): Api.PageNumberingInfo => {
    var obj = AppLocalStorage.GetItem(AppLocalStorage.PageNumbering);
    if (obj) return obj;

    return defaultPageNumberingInfo;
  };
  const formik = useFormik({
    initialValues: GetInitDataForm(),
    onSubmit: async (values) => {
      values.included = true;
      pageNumberingCommand.Do(AppCache.CurrentPageNo, values);
      onClose && onClose();
    },
  });
  const GetFontList = () => {
    FontApi.apiLegalxtractGetListFontGet().then((res) => {
      if (res && res.data) {
        setFontList(res.data.map((item) => ({ value: item, label: item })));
      }
    });
  };
  const ResetForm = () => {
    formik.setValues(defaultPageNumberingInfo);
  };
  const POSITION_OPTIONS = [
    { value: 0, label: tr("topLeft") },
    { value: 1, label: tr("topMiddle") },
    { value: 2, label: tr("topRight") },
    { value: 3, label: tr("bottomLeft") },
    { value: 5, label: tr("bottomRight") },
    { value: 4, label: tr("bottomMiddle") },
  ];
  return { formik, fontList, POSITION_OPTIONS, ResetForm };
};
export const defaultPageNumberingInfo: Api.PageNumberingInfo = {
  fontSize: 12,
  included: false,
  margin: 2,
  marginSide: 2,
  position: 0,
  fontName: "Arial",
  showFromFrontPage: false,
  showFromTOC: false,
  addFrame: true,
  backColor: "#fff",
  fontColor: "#000",
};

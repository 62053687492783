import UndoRedoManagerObj from "../UndoRedomanager";
import * as Api from "../../Api/src/index";
import { AppCache } from "../../Cache/AppCache";
import { AppLocalStorage } from "../../Constant/AppContant";
import { PageService } from "../../Services/NotHook/PageService";
const PageNumberingApi = new Api.PageNumberingApi();
export const PageNumberingCommand = () => {
  const { DrawPageNumbering } = PageService();
  const ProcessPageNumbering = async (
    pageNo: number,
    param: Api.PageNumberingInfo | null
  ) => {
    if (!param) return;
    AppLocalStorage.SetItem(AppLocalStorage.PageNumbering, param);
    var res = await PageNumberingApi.apiLegalxtractProcessPageNumberingPost(
      param
    );
    if (AppCache.CurrentPageNo === pageNo) {
      AppCache.DocumentInfo = res.data.result || null;
    }
    const page = AppCache.DocumentInfo?.tabDetails
    ?.flatMap((o) => o.pages)
    .find((o) => o?.viewerPageNo === pageNo);
   DrawPageNumbering(pageNo, page?.pageWidth || 0, page?.pageHeight || 0);
    return res;
  };
  const Do = async (pageNo: number, param: Api.PageNumberingInfo | null) => {
    var res = await ProcessPageNumbering(pageNo, param);
    UndoRedoManagerObj.add({
      Redo: () => {
        Redo(pageNo, param);
      },
      Undo: () => {
        Undo(pageNo, param);
      },
    }); //add undo redo
  };
  const Redo = (pageNo: number, param: Api.PageNumberingInfo | null) => {
    ProcessPageNumbering(pageNo, param);
  };
  const Undo = (pageNo: number, param: Api.PageNumberingInfo | null) => {
    if (!param) return;
    param.included = !param?.included;
    ProcessPageNumbering(pageNo, param);
  };
  return { Do };
};

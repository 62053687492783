import { isPointInRectangle } from "./CommonFunction";
import tinycolor from "tinycolor2";
export const specialChars =[':', ',', ';', ')', '(', '.', '-', '=', '_', '?', '>', '<', '', '”', '“', '"', '\\', '*', '—', '!', '@',
        '#', '^', '&', '[', ']', '\'', '/', '|', '’', '`', '´', '‘', ' '];
        export const RectHelper = {
    CheckPointInRect: (rects: any, pointX: any, pointY:any) => {
        let ok = false;
        rects.forEach((r: any) => {
            ok = isPointInRectangle(pointX, pointY, r.x, r.y, r.width, r.height);
            if (ok) return;
        })
        return ok;
    },
}
export const ColorHelper = {
    colorStringToRGB: (colorString: string) => {
        try {
            // Use TinyColor to convert the color string to RGB
            var color = tinycolor(colorString);
            var rgb = color.toRgb();
            return rgb;
        } catch (error) {
            console.error("Error:", error);
            return null;
        }
    }
}
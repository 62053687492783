import React, { useEffect, useContext, CSSProperties } from "react";
import loadding from "../Assets/IconApp/loading.gif";
import { Button } from "antd";
import { useLang } from "../i18n/useLang";

const LoaderSearch = ({
  showCancelLoader,
  showLoader,
  onClickCancel,
  styte,
}: {
  showCancelLoader: boolean;
  showLoader: boolean;
  styte?: CSSProperties;
  onClickCancel: () => void;
}) => {
  const { tr } = useLang();
  return (
    <>
      {showLoader && (
        <>
          <div className="loadding-app">
            <div>
              <img
                src={loadding}
                style={{ display: "block", margin: "auto" }}
                alt="loadding"
              />
              {showCancelLoader && (
                <Button
                  style={{ marginTop: "10px" }}
                  type="primary"
                  onClick={() => {
                    onClickCancel();
                  }}
                >
                  {tr("cancel")}
                </Button>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default LoaderSearch;
